import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import Parser from 'html-react-parser'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice, formatPrice17, formatPrice3, toNumberValues } from '../../../../../utils/transform'
import scope3Service from '../../../../../services/scope3Service'
import carFleetService from '../../../../../services/carFleetService'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import inventoryService from '../../../../../services/inventoryService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import { toast } from 'react-hot-toast'
import { ModalImportData } from '../../../../../components/ModalImportData'
import { Loading } from '../../../../../components/Loading'

export const CarTrip = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [loadingModal, setLoadingModal] = useState(false);
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [OpenModalAutoFleet, setOpenModalAutoFleet] = useState(false)
  const [OpenModalAutoCombus, setOpenModalAutoCombus] = useState(false)
  const [openModalImportDistance, setOpenModalImportDistance] = useState(false)

  const [fuels, setFuels] = useState<any>([])
  const [roadYear, setRoad] = useState<any>([])
  const [carFleets, setCarFleets] = useState<any>([])
  const [roadFuel, setRoadFuel] = useState<any>([])
  const [roadDistance, setRoadDistance] = useState<any>([])
  const [emissionCo2Road, setEmissionCo2Road] = useState<any>()
  const [emissionCh4Road, setEmissionCh4Road] = useState<any>()
  const [emissionN2oRoad, setEmissionN2oRoad] = useState<any>()
  const [scopeRoad, setScopeRoad] = useState<string | undefined>()
  const [emissionTco2eRoad, setEmissionTco2eRoad] = useState<any>()
  const [emissionCo2RoadFuel, setEmissionCo2RoadFuel] = useState<any>()
  const [emissionCh4RoadFuel, setEmissionCh4RoadFuel] = useState<any>()
  const [emissionN2oRoadFuel, setEmissionN2oRoadFuel] = useState<any>()
  const [scopeRoadFuel, setScopeRoadFuel] = useState<string | undefined>()
  const [emissionTco2eBioRoad, setEmissionTco2eBioRoad] = useState<any>()
  const [emissionTco2eRoadFuel, setEmissionTco2eRoadFuel] = useState<any>()
  const [emissionCo2RoadDistance, setEmissionCo2RoadDistance] = useState<any>()
  const [emissionCh4RoadDistance, setEmissionCh4RoadDistance] = useState<any>()
  const [emissionN2oRoadDistance, setEmissionN2oRoadDistance] = useState<any>()
  const [scopeRoadDistance, setScopeRoadDistance] = useState<string | undefined>()
  const [emissionTco2eBioRoadFuel, setEmissionTco2eBioRoadFuel] = useState<any>()
  const [emissionTco2eRoadDistance, setEmissionTco2eRoadDistance] = useState<any>()
  const [emissionTco2eBioRoadDistance, setEmissionTco2eBioRoadDistance] = useState<any>()

  const [emissionTco2eTotal, setEmissionTco2eTotal] = useState<any>()
  const [emissionTco2eBioTotal, setEmissionTco2eBioTotal] = useState<any>()

  const { isCommonUser } = useAuth()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/viagens-a-negocios'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'viagens_a_negocios', 'viagens_em_automoveis')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  useEffect(() => {
    setEmissionTco2eTotal((emissionTco2eRoad ? emissionTco2eRoad : 0) + (emissionTco2eRoadFuel ? emissionTco2eRoadFuel : 0) + 
            (emissionTco2eRoadDistance ? emissionTco2eRoadDistance : 0))
  }, [emissionTco2eRoad, emissionTco2eRoadFuel, emissionTco2eRoadDistance])

  useEffect(() => {
    setEmissionTco2eBioTotal((emissionTco2eBioRoad ? emissionTco2eBioRoad : 0) + (emissionTco2eBioRoadFuel ? emissionTco2eBioRoadFuel : 0) + 
            (emissionTco2eBioRoadDistance ? emissionTco2eBioRoadDistance : 0))
  }, [emissionTco2eBioRoad, emissionTco2eBioRoadFuel, emissionTco2eBioRoadDistance])

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const handleChangeYear = (index: number, ignore_field?: string) => {
    const year = parseInt(roadYear[index].year)
    const carFleetId = parseInt(roadYear[index].car_fleet_id)
    const consumptionJan = parseFloat(roadYear[index].consumption_jan ? roadYear[index].consumption_jan : 0)
    const consumptionFeb = parseFloat(roadYear[index].consumption_feb ? roadYear[index].consumption_feb : 0)
    const consumptionMar = parseFloat(roadYear[index].consumption_mar ? roadYear[index].consumption_mar : 0)
    const consumptionApr = parseFloat(roadYear[index].consumption_apr ? roadYear[index].consumption_apr : 0)
    const consumptionMay = parseFloat(roadYear[index].consumption_may ? roadYear[index].consumption_may : 0)
    const consumptionJun = parseFloat(roadYear[index].consumption_jun ? roadYear[index].consumption_jun : 0)
    const consumptionJul = parseFloat(roadYear[index].consumption_jul ? roadYear[index].consumption_jul : 0)
    const consumptionAug = parseFloat(roadYear[index].consumption_aug ? roadYear[index].consumption_aug : 0)
    const consumptionSep = parseFloat(roadYear[index].consumption_sep ? roadYear[index].consumption_sep : 0)
    const consumptionOct = parseFloat(roadYear[index].consumption_oct ? roadYear[index].consumption_oct : 0)
    const consumptionNov = parseFloat(roadYear[index].consumption_nov ? roadYear[index].consumption_nov : 0)
    const consumptionDec = parseFloat(roadYear[index].consumption_dec ? roadYear[index].consumption_dec : 0)

    const consumptionYearly2 = parseFloat(
          roadYear[index].consumption_yearly ? roadYear[index].consumption_yearly : 0
        )

    const onlyViewYearly = consumptionJan > 0 || consumptionFeb > 0 || consumptionMar > 0 || consumptionApr > 0 ||
      consumptionMay > 0 || consumptionJun > 0 || consumptionJul > 0 || consumptionAug > 0 || consumptionSep > 0 ||
      consumptionOct > 0 || consumptionNov > 0 || consumptionDec > 0

    const onlyViewMonthly = consumptionYearly2 > 0

    const consumptionYearly = onlyViewYearly ? 0 : consumptionYearly2

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripAutoRoad(
        carFleetId,
        year,
        consumptionJan,
        consumptionFeb,
        consumptionMar,
        consumptionApr,
        consumptionMay,
        consumptionJun,
        consumptionJul,
        consumptionAug,
        consumptionSep,
        consumptionOct,
        consumptionNov,
        consumptionDec,
        consumptionYearly
      )

      if (result) {
        roadYear[index].only_view_yearly = onlyViewYearly
        roadYear[index].only_view_monthly = onlyViewMonthly

        roadYear[index].emission_ch4 = result.emission_ch4
        roadYear[index].emission_n2o = result.emission_n2o
        roadYear[index].emission_tco2e = result.emission_tco2e
        roadYear[index].factor_kg_ch4_l = result.factor_kg_ch4_l
        roadYear[index].factor_kg_n2o_l = result.factor_kg_n2o_l
        roadYear[index].consumption_yearly = result.consumption_yearly
        roadYear[index].emission_bio_co2_t = result.emission_bio_co2_t
        roadYear[index].factor_bio_kg_co2_l = result.factor_bio_kg_co2_l
        roadYear[index].emission_fossil_co2_t = result.emission_fossil_co2_t
        roadYear[index].factor_fossil_kg_co2_l = result.factor_fossil_kg_co2_l
        roadYear[index].consumption_bio_jan = result.consumption_bio_jan
        roadYear[index].consumption_bio_feb = result.consumption_bio_feb
        roadYear[index].consumption_bio_mar = result.consumption_bio_mar
        roadYear[index].consumption_bio_apr = result.consumption_bio_apr
        roadYear[index].consumption_bio_may = result.consumption_bio_may
        roadYear[index].consumption_bio_jun = result.consumption_bio_jun
        roadYear[index].consumption_bio_jul = result.consumption_bio_jul
        roadYear[index].consumption_bio_aug = result.consumption_bio_aug
        roadYear[index].consumption_bio_sep = result.consumption_bio_sep
        roadYear[index].consumption_bio_oct = result.consumption_bio_oct
        roadYear[index].consumption_bio_nov = result.consumption_bio_nov
        roadYear[index].consumption_bio_dec = result.consumption_bio_dec
        roadYear[index].consumption_bio_yearly = result.consumption_bio_yearly
        roadYear[index].consumption_fossil_jan = result.consumption_fossil_jan
        roadYear[index].consumption_fossil_feb = result.consumption_fossil_feb
        roadYear[index].consumption_fossil_mar = result.consumption_fossil_mar
        roadYear[index].consumption_fossil_apr = result.consumption_fossil_apr
        roadYear[index].consumption_fossil_may = result.consumption_fossil_may
        roadYear[index].consumption_fossil_jun = result.consumption_fossil_jun
        roadYear[index].consumption_fossil_jul = result.consumption_fossil_jul
        roadYear[index].consumption_fossil_aug = result.consumption_fossil_aug
        roadYear[index].consumption_fossil_sep = result.consumption_fossil_sep
        roadYear[index].consumption_fossil_oct = result.consumption_fossil_oct
        roadYear[index].consumption_fossil_nov = result.consumption_fossil_nov
        roadYear[index].consumption_fossil_dec = result.consumption_fossil_dec
        roadYear[index].consumption_fossil_yearly = result.consumption_fossil_yearly

        setScopeRoad(
          'change_scope_item_' +
            index +
            '_' +
            carFleetId +
            '_' +
            year +
            '_' +
            consumptionJan +
            '_' +
            consumptionFeb +
            '_' +
            consumptionMar +
            '_' +
            consumptionApr +
            '_' +
            consumptionMay +
            '_' +
            consumptionJun +
            '_' +
            consumptionJul +
            '_' +
            consumptionAug +
            '_' +
            consumptionSep +
            '_' +
            consumptionOct +
            '_' +
            consumptionNov +
            '_' +
            consumptionDec +
            '_' +
            consumptionYearly
        )

        setRoadYearValues(roadYear, ignore_field)
        calculateRoadYear(roadYear)
      }
    }

    calculate()
  }

  const handleChangeFuel = (index: number, ignore_field?: string) => {
    const vehicle_fuel_id = roadFuel[index].vehicle_fuel_id
    const consumptionJan = roadFuel[index].consumption_jan ? parseFloat(roadFuel[index].consumption_jan): 0
    const consumptionFeb = roadFuel[index].consumption_feb ? parseFloat(roadFuel[index].consumption_feb): 0
    const consumptionMar = roadFuel[index].consumption_mar ? parseFloat(roadFuel[index].consumption_mar): 0
    const consumptionApr = roadFuel[index].consumption_apr ? parseFloat(roadFuel[index].consumption_apr): 0
    const consumptionMay = roadFuel[index].consumption_may ? parseFloat(roadFuel[index].consumption_may): 0
    const consumptionJun = roadFuel[index].consumption_jun ? parseFloat(roadFuel[index].consumption_jun): 0
    const consumptionJul = roadFuel[index].consumption_jul ? parseFloat(roadFuel[index].consumption_jul): 0
    const consumptionAug = roadFuel[index].consumption_aug ? parseFloat(roadFuel[index].consumption_aug): 0
    const consumptionSep = roadFuel[index].consumption_sep ? parseFloat(roadFuel[index].consumption_sep): 0
    const consumptionOct = roadFuel[index].consumption_oct ? parseFloat(roadFuel[index].consumption_oct): 0
    const consumptionNov = roadFuel[index].consumption_nov ? parseFloat(roadFuel[index].consumption_nov): 0
    const consumptionDec = roadFuel[index].consumption_dec ? parseFloat(roadFuel[index].consumption_dec): 0

    const onlyViewYearly =
      consumptionJan > 0 ||
      consumptionFeb > 0 ||
      consumptionMar > 0 ||
      consumptionApr > 0 ||
      consumptionMay > 0 ||
      consumptionJun > 0 ||
      consumptionJul > 0 ||
      consumptionAug > 0 ||
      consumptionSep > 0 ||
      consumptionOct > 0 ||
      consumptionNov > 0 ||
      consumptionDec > 0

    const consumptionYearly = onlyViewYearly
      ? 0
      : roadFuel[index].consumption_yearly
      ? parseFloat(roadFuel[index].consumption_yearly)
      : 0

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripAutoFuel(
        vehicle_fuel_id,
        consumptionJan,
        consumptionFeb,
        consumptionMar,
        consumptionApr,
        consumptionMay,
        consumptionJun,
        consumptionJul,
        consumptionAug,
        consumptionSep,
        consumptionOct,
        consumptionNov,
        consumptionDec,
        consumptionYearly
      )

      if (result) {
        roadFuel[index].only_view_yearly = onlyViewYearly

        roadFuel[index].emission_co2 = parseFloat(result.emission_co2)
        roadFuel[index].emission_ch4 = parseFloat(result.emission_ch4)
        roadFuel[index].emission_n2o = parseFloat(result.emission_n2o)
        roadFuel[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        roadFuel[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        roadFuel[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        roadFuel[index].emission_tco2e = parseFloat(result.emission_tco2e)
        roadFuel[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        roadFuel[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        roadFuel[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        roadFuel[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        roadFuel[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        roadFuel[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        roadFuel[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        roadFuel[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        roadFuel[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        roadFuel[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        roadFuel[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        roadFuel[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        roadFuel[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        roadFuel[index].factor_ch4_kg_bio = parseFloat(result.factor_ch4_kg_bio)
        roadFuel[index].factor_n2o_kg_bio = parseFloat(result.factor_n2o_kg_bio)
        roadFuel[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        roadFuel[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        roadFuel[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        roadFuel[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        roadFuel[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        roadFuel[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        roadFuel[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        roadFuel[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        roadFuel[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        roadFuel[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        roadFuel[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        roadFuel[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        roadFuel[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        roadFuel[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        roadFuel[index].fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)

        setScopeRoadFuel(
          'change_scope_item_' +
            index +
            '_' +
            vehicle_fuel_id +
            '_' +
            consumptionJan +
            '_' +
            consumptionFeb +
            '_' +
            consumptionMar +
            '_' +
            consumptionApr +
            '_' +
            consumptionMay +
            consumptionJun +
            '_' +
            consumptionJul +
            '_' +
            consumptionAug +
            '_' +
            consumptionSep +
            consumptionOct +
            '_' +
            consumptionNov +
            '_' +
            consumptionDec +
            '_' +
            consumptionYearly
        )

        setRoadFuelValues(roadFuel, ignore_field)
        calculateRoadFuel(roadFuel)
      }
    }

    calculate()
  }

  const handleChangeDistance = (index: number, ignore_field?: string) => {
    const car_fleet_id = roadDistance[index].car_fleet_id
    const year = roadDistance[index].year ? parseInt(roadDistance[index].year) : 0
    const distanceJan = roadDistance[index].distance_jan ? parseFloat(roadDistance[index].distance_jan): 0
    const distanceFeb = roadDistance[index].distance_feb ? parseFloat(roadDistance[index].distance_feb): 0
    const distanceMar = roadDistance[index].distance_mar ? parseFloat(roadDistance[index].distance_mar): 0
    const distanceApr = roadDistance[index].distance_apr ? parseFloat(roadDistance[index].distance_apr): 0
    const distanceMay = roadDistance[index].distance_may ? parseFloat(roadDistance[index].distance_may): 0
    const distanceJun = roadDistance[index].distance_jun ? parseFloat(roadDistance[index].distance_jun): 0
    const distanceJul = roadDistance[index].distance_jul ? parseFloat(roadDistance[index].distance_jul): 0
    const distanceAug = roadDistance[index].distance_aug ? parseFloat(roadDistance[index].distance_aug): 0
    const distanceSep = roadDistance[index].distance_sep ? parseFloat(roadDistance[index].distance_sep): 0
    const distanceOct = roadDistance[index].distance_oct ? parseFloat(roadDistance[index].distance_oct): 0
    const distanceNov = roadDistance[index].distance_nov ? parseFloat(roadDistance[index].distance_nov): 0
    const distanceDec = roadDistance[index].distance_dec ? parseFloat(roadDistance[index].distance_dec): 0
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const distanceYearly2 = parseFloat(roadDistance[index].distance_yearly ? roadDistance[index].distance_yearly : 0)

    const onlyViewYearly =
      distanceJan > 0 ||
      distanceFeb > 0 ||
      distanceMar > 0 ||
      distanceApr > 0 ||
      distanceMay > 0 ||
      distanceJun > 0 ||
      distanceJul > 0 ||
      distanceAug > 0 ||
      distanceSep > 0 ||
      distanceOct > 0 ||
      distanceNov > 0 ||
      distanceDec > 0

    const distanceYearly = onlyViewYearly
      ? 0
      : roadDistance[index].distance_yearly
      ? parseFloat(roadDistance[index].distance_yearly)
      : 0

      const consumptionYearly2 = parseFloat(
        roadDistance[index].distance_yearly ? roadDistance[index].distance_yearly : 0
      )

      const onlyViewMonthly = consumptionYearly2 > 0

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripAutoDistance(
        year,
        car_fleet_id,
        distanceJan,
        distanceFeb,
        distanceMar,
        distanceApr,
        distanceMay,
        distanceJun,
        distanceJul,
        distanceAug,
        distanceSep,
        distanceOct,
        distanceNov,
        distanceDec,
        distanceYearly
      )

      if (result) {
        roadDistance[index].only_view_yearly = onlyViewYearly
        roadDistance[index].only_view_monthly = onlyViewMonthly

        roadDistance[index].emission_co2 = parseFloat(result.emission_co2)
        roadDistance[index].emission_ch4 = parseFloat(result.emission_ch4)
        roadDistance[index].emission_n2o = parseFloat(result.emission_n2o)
        roadDistance[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        roadDistance[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        roadDistance[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        roadDistance[index].emission_tco2e = parseFloat(result.emission_tco2e)
        roadDistance[index].fuel_amount_jan = parseFloat(result.fuel_amount_jan)
        roadDistance[index].fuel_amount_feb = parseFloat(result.fuel_amount_feb)
        roadDistance[index].fuel_amount_mar = parseFloat(result.fuel_amount_mar)
        roadDistance[index].fuel_amount_apr = parseFloat(result.fuel_amount_apr)
        roadDistance[index].fuel_amount_may = parseFloat(result.fuel_amount_may)
        roadDistance[index].fuel_amount_jun = parseFloat(result.fuel_amount_jun)
        roadDistance[index].fuel_amount_jul = parseFloat(result.fuel_amount_jul)
        roadDistance[index].fuel_amount_aug = parseFloat(result.fuel_amount_aug)
        roadDistance[index].fuel_amount_sep = parseFloat(result.fuel_amount_sep)
        roadDistance[index].fuel_amount_oct = parseFloat(result.fuel_amount_oct)
        roadDistance[index].fuel_amount_nov = parseFloat(result.fuel_amount_nov)
        roadDistance[index].fuel_amount_dec = parseFloat(result.fuel_amount_dec)
        roadDistance[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        roadDistance[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        roadDistance[index].fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
        roadDistance[index].fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
        roadDistance[index].fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
        roadDistance[index].fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
        roadDistance[index].fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
        roadDistance[index].fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
        roadDistance[index].fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
        roadDistance[index].fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
        roadDistance[index].fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
        roadDistance[index].fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
        roadDistance[index].fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
        roadDistance[index].fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
        roadDistance[index].distanceYearly = parseFloat(result.distance_yearly)
        roadDistance[index].fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
        roadDistance[index].fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
        roadDistance[index].fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
        roadDistance[index].fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
        roadDistance[index].fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
        roadDistance[index].fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
        roadDistance[index].fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
        roadDistance[index].fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
        roadDistance[index].fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
        roadDistance[index].fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
        roadDistance[index].fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
        roadDistance[index].fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
        roadDistance[index].fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
        roadDistance[index].fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
        roadDistance[index].average_consumption_fuel = parseFloat(result.average_consumption_fuel)
        roadDistance[index].fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)

        setScopeRoadDistance(
          'change_scope_item_' +
            index +
            '_' +
            car_fleet_id +
            '_' +
            distanceJan +
            '_' +
            distanceFeb +
            '_' +
            distanceMar +
            '_' +
            distanceApr +
            '_' +
            distanceMay +
            distanceJun +
            '_' +
            distanceJul +
            '_' +
            distanceAug +
            '_' +
            distanceSep +
            distanceOct +
            '_' +
            distanceNov +
            '_' +
            distanceDec +
            '_' +
            distanceYearly
        )

        setRoadDistanceValues(roadDistance, ignore_field)
        calculateRoadDistance(roadDistance)
      }
    }

    calculate()
  }

  function handleDeleteYear(index: number) {
    const itens = []

    for (let i = 0; i < roadYear?.length; i++) {
      if (i !== index) {
        itens.push(roadYear[i])
      }
    }

    setScopeRoad('remove_scope_item_' + index)

    setValue('car_freet_road_year', [])

    setRoadYearValues(itens)
    calculateRoadYear(itens)
  }

  function handleDeleteFuel(index: number) {
    const itens = []

    for (let i = 0; i < roadFuel?.length; i++) {
      if (i !== index) {
        itens.push(roadFuel[i])
      }
    }

    setScopeRoadFuel('remove_scope_item_' + index)

    setValue('fuel_private_vehicle', [])

    setRoadFuelValues(itens)
    calculateRoadFuel(itens)
  }

  function handleDeleteDistance(index: number) {
    const itens = []

    for (let i = 0; i < roadDistance?.length; i++) {
      if (i !== index) {
        itens.push(roadDistance[i])
      }
    }

    setScopeRoadDistance('remove_scope_item_' + index)

    setValue('car_freet_road_year_distance', [])

    setRoadDistanceValues(itens)
    calculateRoadDistance(itens)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const setRoadYearValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`roadYear.${i}.car_fleet_id`, item.car_fleet_id)
        setValue(`roadYear.${i}.car_fleet_name`, item.car_fleet_name)

        setValue(`roadYear.${i}.fuel_id`, item.fuel_id)
        setValue(`roadYear.${i}.fuel_name`, item.fuel_name)

        setValue(`roadYear.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`roadYear.${i}.bio_fuel_name`, item.bio_fuel_name)

        setValue(`roadYear.${i}.source`, item.source)
        setValue(`roadYear.${i}.description`, item.description)

        if ('year' !== ignore_field) {setValue(`roadYear.${i}.year`, item.year)}
        if ('consumption_jan' !== ignore_field) {setValue(`roadYear.${i}.consumption_jan`, formatPrice(item.consumption_jan, false))}
        if ('consumption_feb' !== ignore_field) {setValue(`roadYear.${i}.consumption_feb`, formatPrice(item.consumption_feb, false))}
        if ('consumption_mar' !== ignore_field) {setValue(`roadYear.${i}.consumption_mar`, formatPrice(item.consumption_mar, false))}
        if ('consumption_apr' !== ignore_field) {setValue(`roadYear.${i}.consumption_apr`, formatPrice(item.consumption_apr, false))}
        if ('consumption_may' !== ignore_field) {setValue(`roadYear.${i}.consumption_may`, formatPrice(item.consumption_may, false))}
        if ('consumption_jun' !== ignore_field) {setValue(`roadYear.${i}.consumption_jun`, formatPrice(item.consumption_jun, false))}
        if ('consumption_jul' !== ignore_field) {setValue(`roadYear.${i}.consumption_jul`, formatPrice(item.consumption_jul, false))}
        if ('consumption_aug' !== ignore_field) {setValue(`roadYear.${i}.consumption_aug`, formatPrice(item.consumption_aug, false))}
        if ('consumption_sep' !== ignore_field) {setValue(`roadYear.${i}.consumption_sep`, formatPrice(item.consumption_sep, false))}
        if ('consumption_oct' !== ignore_field) {setValue(`roadYear.${i}.consumption_oct`, formatPrice(item.consumption_oct, false))}
        if ('consumption_nov' !== ignore_field) {setValue(`roadYear.${i}.consumption_nov`, formatPrice(item.consumption_nov, false))}
        if ('consumption_dec' !== ignore_field) {setValue(`roadYear.${i}.consumption_dec`, formatPrice(item.consumption_dec, false))}
        if ('consumption_yearly' !== ignore_field) {setValue(`roadYear.${i}.consumption_yearly`, formatPrice(item.consumption_yearly, false))}

        setValue(`roadYear.${i}.emission_ch4`, formatPrice(item.emission_ch4, false))
        setValue(`roadYear.${i}.emission_n2o`, formatPrice(item.emission_n2o, false))
        setValue(`roadYear.${i}.emission_tco2e`, formatPrice(item.emission_tco2e, false))
        setValue(`roadYear.${i}.factor_kg_ch4_l`, formatPrice(item.factor_kg_ch4_l, false))
        setValue(`roadYear.${i}.factor_kg_n2o_l`, formatPrice(item.factor_kg_n2o_l, false))
        setValue(`roadYear.${i}.emission_bio_co2_t`, formatPrice(item.emission_bio_co2_t, false))
        setValue(`roadYear.${i}.factor_bio_kg_co2_l`, formatPrice(item.factor_bio_kg_co2_l, false))
        setValue(`roadYear.${i}.consumption_bio_jan`, formatPrice(item.consumption_bio_jan, false))
        setValue(`roadYear.${i}.consumption_bio_feb`, formatPrice(item.consumption_bio_feb, false))
        setValue(`roadYear.${i}.consumption_bio_mar`, formatPrice(item.consumption_bio_mar, false))
        setValue(`roadYear.${i}.consumption_bio_apr`, formatPrice(item.consumption_bio_apr, false))
        setValue(`roadYear.${i}.consumption_bio_jun`, formatPrice(item.consumption_bio_jun, false))
        setValue(`roadYear.${i}.consumption_bio_jul`, formatPrice(item.consumption_bio_jul, false))
        setValue(`roadYear.${i}.consumption_bio_aug`, formatPrice(item.consumption_bio_aug, false))
        setValue(`roadYear.${i}.consumption_bio_sep`, formatPrice(item.consumption_bio_sep, false))
        setValue(`roadYear.${i}.consumption_bio_oct`, formatPrice(item.consumption_bio_oct, false))
        setValue(`roadYear.${i}.consumption_bio_nov`, formatPrice(item.consumption_bio_nov, false))
        setValue(`roadYear.${i}.consumption_bio_dec`, formatPrice(item.consumption_bio_dec, false))
        setValue(`roadYear.${i}.emission_fossil_co2_t`, formatPrice(item.emission_fossil_co2_t, false))
        setValue(`roadYear.${i}.consumption_bio_yearly`, formatPrice(item.consumption_bio_yearly, false))
        setValue(`roadYear.${i}.factor_fossil_kg_co2_l`, formatPrice(item.factor_fossil_kg_co2_l, false))
        setValue(`roadYear.${i}.consumption_fossil_jan`, formatPrice(item.consumption_fossil_jan, false))
        setValue(`roadYear.${i}.consumption_fossil_feb`, formatPrice(item.consumption_fossil_feb, false))
        setValue(`roadYear.${i}.consumption_fossil_mar`, formatPrice(item.consumption_fossil_mar, false))
        setValue(`roadYear.${i}.consumption_fossil_apr`, formatPrice(item.consumption_fossil_apr, false))
        setValue(`roadYear.${i}.consumption_fossil_may`, formatPrice(item.consumption_fossil_may, false))
        setValue(`roadYear.${i}.consumption_fossil_jun`, formatPrice(item.consumption_fossil_jun, false))
        setValue(`roadYear.${i}.consumption_fossil_jul`, formatPrice(item.consumption_fossil_jul, false))
        setValue(`roadYear.${i}.consumption_fossil_aug`, formatPrice(item.consumption_fossil_aug, false))
        setValue(`roadYear.${i}.consumption_fossil_sep`, formatPrice(item.consumption_fossil_sep, false))
        setValue(`roadYear.${i}.consumption_fossil_oct`, formatPrice(item.consumption_fossil_oct, false))
        setValue(`roadYear.${i}.consumption_fossil_nov`, formatPrice(item.consumption_fossil_nov, false))
        setValue(`roadYear.${i}.consumption_fossil_dec`, formatPrice(item.consumption_fossil_dec, false))
        setValue(`roadYear.${i}.consumption_fossil_yearly`, formatPrice(item.consumption_fossil_yearly, false))

        changeScope =
          changeScope +
          item.car_fleet_id +
          '_' +
          item.year +
          '_' +
          item.consumption_jan +
          '_' +
          item.consumption_feb +
          '_' +
          item.consumption_mar +
          '_' +
          item.consumption_apr +
          '_' +
          item.consumption_may +
          '_' +
          item.consumption_jun +
          '_' +
          item.consumption_jul +
          '_' +
          item.consumption_aug +
          '_' +
          item.consumption_sep +
          '_' +
          item.consumption_oct +
          '_' +
          item.consumption_nov +
          '_' +
          item.consumption_dec +
          '_' +
          item.consumption_yearly
      }
    }

    setRoad(itens)
    setScopeRoad(scopeRoad)
  }

  const setRoadFuelValues = (itens: any, ignore_field?: string) => {
    let scopeRoad = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`roadFuel.${i}.year`, item.year)
        setValue(`roadFuel.${i}.source`, item.source)
        setValue(`roadFuel.${i}.description`, item.description)
        setValue(`roadFuel.${i}.average_consumption_fuel`, item.average_consumption_fuel)

        setValue(`roadFuel.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
        setValue(`roadFuel.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

        setValue(`roadFuel.${i}.fuel_id`, item.fuel_id)
        setValue(`roadFuel.${i}.fuel_name`, item.fuel_name)

        setValue(`roadFuel.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`roadFuel.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('emission_co2' !== ignore_field) {setValue(`roadFuel.${i}.emission_co2`, formatPrice(item.emission_co2, false))}
        if ('emission_ch4' !== ignore_field) {setValue(`roadFuel.${i}.emission_ch4`, formatPrice(item.emission_ch4, false))}
        if ('emission_n2o' !== ignore_field) {setValue(`roadFuel.${i}.emission_n2o`, formatPrice(item.emission_n2o, false))}
        if ('factor_ch4_kg' !== ignore_field) {setValue(`roadFuel.${i}.factor_ch4_kg`, formatPrice(item.factor_ch4_kg, false))}
        if ('factor_co2_kg' !== ignore_field) {setValue(`roadFuel.${i}.factor_co2_kg`, formatPrice(item.factor_co2_kg, false))}
        if ('factor_n2o_kg' !== ignore_field) {setValue(`roadFuel.${i}.factor_n2o_kg`, formatPrice(item.factor_n2o_kg, false))}
        if ('emission_tco2e' !== ignore_field) {setValue(`roadFuel.${i}.emission_tco2e`, formatPrice(item.emission_tco2e, false))}
        if ('consumption_jan' !== ignore_field) {setValue(`roadFuel.${i}.consumption_jan`, formatPrice(item.consumption_jan, false))}
        if ('consumption_feb' !== ignore_field) {setValue(`roadFuel.${i}.consumption_feb`, formatPrice(item.consumption_feb, false))}
        if ('consumption_mar' !== ignore_field) {setValue(`roadFuel.${i}.consumption_mar`, formatPrice(item.consumption_mar, false))}
        if ('consumption_apr' !== ignore_field) {setValue(`roadFuel.${i}.consumption_apr`, formatPrice(item.consumption_apr, false))}
        if ('consumption_may' !== ignore_field) {setValue(`roadFuel.${i}.consumption_may`, formatPrice(item.consumption_may, false))}
        if ('consumption_jun' !== ignore_field) {setValue(`roadFuel.${i}.consumption_jun`, formatPrice(item.consumption_jun, false))}
        if ('consumption_jul' !== ignore_field) {setValue(`roadFuel.${i}.consumption_jul`, formatPrice(item.consumption_jul, false))}
        if ('consumption_aug' !== ignore_field) {setValue(`roadFuel.${i}.consumption_aug`, formatPrice(item.consumption_aug, false))}
        if ('consumption_sep' !== ignore_field) {setValue(`roadFuel.${i}.consumption_sep`, formatPrice(item.consumption_sep, false))}
        if ('consumption_oct' !== ignore_field) {setValue(`roadFuel.${i}.consumption_oct`, formatPrice(item.consumption_oct, false))}
        if ('consumption_nov' !== ignore_field) {setValue(`roadFuel.${i}.consumption_nov`, formatPrice(item.consumption_nov, false))}
        if ('consumption_dec' !== ignore_field) {setValue(`roadFuel.${i}.consumption_dec`, formatPrice(item.consumption_dec, false))}
        if ('fuel_amount_jan' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_jan`, formatPrice(item.fuel_amount_jan, false))}
        if ('fuel_amount_feb' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_feb`, formatPrice(item.fuel_amount_feb, false))}
        if ('fuel_amount_mar' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_mar`, formatPrice(item.fuel_amount_mar, false))}
        if ('fuel_amount_apr' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_apr`, formatPrice(item.fuel_amount_apr, false))}
        if ('fuel_amount_may' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_may`, formatPrice(item.fuel_amount_may, false))}
        if ('fuel_amount_jun' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_jun`, formatPrice(item.fuel_amount_jun, false))}
        if ('fuel_amount_jul' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_jul`, formatPrice(item.fuel_amount_jul, false))}
        if ('fuel_amount_aug' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_aug`, formatPrice(item.fuel_amount_aug, false))}
        if ('fuel_amount_sep' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_sep`, formatPrice(item.fuel_amount_sep, false))}
        if ('fuel_amount_oct' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_oct`, formatPrice(item.fuel_amount_oct, false))}
        if ('fuel_amount_nov' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_nov`, formatPrice(item.fuel_amount_nov, false))}
        if ('fuel_amount_dec' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_dec`, formatPrice(item.fuel_amount_dec, false))}
        if ('factor_co2_kg_bio' !== ignore_field) {setValue(`roadFuel.${i}.factor_co2_kg_bio`, formatPrice(item.factor_co2_kg_bio, false))}
        if ('factor_ch4_kg_bio' !== ignore_field) {setValue(`roadFuel.${i}.factor_ch4_kg_bio`, formatPrice(item.factor_ch4_kg_bio, false))}
        if ('factor_n2o_kg_bio' !== ignore_field) {setValue(`roadFuel.${i}.factor_n2o_kg_bio`, formatPrice(item.factor_n2o_kg_bio, false))}
        if ('emission_tco2e_bio' !== ignore_field) {setValue(`roadFuel.${i}.emission_tco2e_bio`, formatPrice(item.emission_tco2e_bio, false))}
        if ('fuel_amount_yearly' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_yearly`, formatPrice(item.fuel_amount_yearly, false))}
        if ('fuel_amount_bio_jan' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_jan`, formatPrice(item.fuel_amount_bio_jan, false))}
        if ('fuel_amount_bio_feb' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_feb`, formatPrice(item.fuel_amount_bio_feb, false))}
        if ('fuel_amount_bio_mar' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_mar`, formatPrice(item.fuel_amount_bio_mar, false))}
        if ('fuel_amount_bio_apr' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_apr`, formatPrice(item.fuel_amount_bio_apr, false))}
        if ('fuel_amount_bio_may' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_may`, formatPrice(item.fuel_amount_bio_may, false))}
        if ('fuel_amount_bio_jun' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_jun`, formatPrice(item.fuel_amount_bio_jun, false))}
        if ('fuel_amount_bio_jul' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_jul`, formatPrice(item.fuel_amount_bio_jul, false))}
        if ('fuel_amount_bio_aug' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_aug`, formatPrice(item.fuel_amount_bio_aug, false))}
        if ('fuel_amount_bio_sep' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_sep`, formatPrice(item.fuel_amount_bio_sep, false))}
        if ('fuel_amount_bio_oct' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_oct`, formatPrice(item.fuel_amount_bio_oct, false))}
        if ('fuel_amount_bio_nov' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_nov`, formatPrice(item.fuel_amount_bio_nov, false))}
        if ('fuel_amount_bio_dec' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_dec`, formatPrice(item.fuel_amount_bio_dec, false))}
        if ('fuel_amount_bio_yearly' !== ignore_field) {setValue(`roadFuel.${i}.fuel_amount_bio_yearly`, formatPrice(item.fuel_amount_bio_yearly, false))}
        scopeRoad =
          scopeRoad +
          '_' +
          item.car_fleet_id +
          '_' +
          item.consumption_jan +
          '_' +
          item.consumption_feb +
          '_' +
          item.consumption_mar +
          '_' +
          item.consumption_apr +
          '_' +
          item.consumption_may +
          '_' +
          item.consumption_jun +
          '_' +
          item.consumption_jul +
          '_' +
          item.consumption_aug +
          '_' +
          item.consumption_sep +
          '_' +
          item.consumption_oct +
          '_' +
          item.consumption_nov +
          '_' +
          item.consumption_dec +
          '_' +
          item.consumption_yearly
      }
    }

    setRoadFuel(itens)
    setScopeRoadFuel(scopeRoad)
  }

  const setRoadDistanceValues = (itens: any, ignore_field?: string) => {
    let scopeRoad = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`roadDistance.${i}.year`, item.year)
        setValue(`roadDistance.${i}.source`, item.source)
        setValue(`roadDistance.${i}.description`, item.description)
        setValue(
          `roadDistance.${i}.average_consumption_fuel`,
          item.average_consumption_fuel
        )

        setValue(`roadDistance.${i}.car_fleet_id`, item.car_fleet_id)
        setValue(`roadDistance.${i}.car_fleet_name`, item.car_fleet_name)

        setValue(`roadDistance.${i}.fuel_id`, item.fuel_id)
        setValue(`roadDistance.${i}.fuel_name`, item.fuel_name)

        setValue(`roadDistance.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`roadDistance.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('average_distance' !== ignore_field) {setValue(`roadDistance.${i}.average_distance`, formatPrice(item.average_distance, false))}
        if ('emission_co2' !== ignore_field) {setValue(`roadDistance.${i}.emission_co2`, formatPrice(item.emission_co2, false))}
        if ('emission_ch4' !== ignore_field) {setValue(`roadDistance.${i}.emission_ch4`, formatPrice(item.emission_ch4, false))}
        if ('emission_n2o' !== ignore_field) {setValue(`roadDistance.${i}.emission_n2o`, formatPrice(item.emission_n2o, false))}
        if ('factor_ch4_kg' !== ignore_field) {setValue(`roadDistance.${i}.factor_ch4_kg`, formatPrice(item.factor_ch4_kg, false))}
        if ('factor_co2_kg' !== ignore_field) {setValue(`roadDistance.${i}.factor_co2_kg`, formatPrice(item.factor_co2_kg, false))}
        if ('factor_n2o_kg' !== ignore_field) {setValue(`roadDistance.${i}.factor_n2o_kg`, formatPrice(item.factor_n2o_kg, false))}
        if ('emission_tco2e' !== ignore_field) {setValue(`roadDistance.${i}.emission_tco2e`, formatPrice(item.emission_tco2e, false))}
        if ('distance_jan' !== ignore_field) {setValue(`roadDistance.${i}.distance_jan`, formatPrice(item.distance_jan, false))}
        if ('distance_feb' !== ignore_field) {setValue(`roadDistance.${i}.distance_feb`, formatPrice(item.distance_feb, false))}
        if ('distance_mar' !== ignore_field) {setValue(`roadDistance.${i}.distance_mar`, formatPrice(item.distance_mar, false))}
        if ('distance_apr' !== ignore_field) {setValue(`roadDistance.${i}.distance_apr`, formatPrice(item.distance_apr, false))}
        if ('distance_may' !== ignore_field) {setValue(`roadDistance.${i}.distance_may`, formatPrice(item.distance_may, false))}
        if ('distance_jun' !== ignore_field) {setValue(`roadDistance.${i}.distance_jun`, formatPrice(item.distance_jun, false))}
        if ('distance_jul' !== ignore_field) {setValue(`roadDistance.${i}.distance_jul`, formatPrice(item.distance_jul, false))}
        if ('distance_aug' !== ignore_field) {setValue(`roadDistance.${i}.distance_aug`, formatPrice(item.distance_aug, false))}
        if ('distance_sep' !== ignore_field) {setValue(`roadDistance.${i}.distance_sep`, formatPrice(item.distance_sep, false))}
        if ('distance_oct' !== ignore_field) {setValue(`roadDistance.${i}.distance_oct`, formatPrice(item.distance_oct, false))}
        if ('distance_nov' !== ignore_field) {setValue(`roadDistance.${i}.distance_nov`, formatPrice(item.distance_nov, false))}
        if ('distance_dec' !== ignore_field) {setValue(`roadDistance.${i}.distance_dec`, formatPrice(item.distance_dec, false))}
        if ('fuel_amount_jan' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_jan`, formatPrice(item.fuel_amount_jan, false))}
        if ('fuel_amount_feb' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_feb`, formatPrice(item.fuel_amount_feb, false))}
        if ('fuel_amount_mar' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_mar`, formatPrice(item.fuel_amount_mar, false))}
        if ('fuel_amount_apr' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_apr`, formatPrice(item.fuel_amount_apr, false))}
        if ('fuel_amount_may' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_may`, formatPrice(item.fuel_amount_may, false))}
        if ('fuel_amount_jun' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_jun`, formatPrice(item.fuel_amount_jun, false))}
        if ('fuel_amount_jul' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_jul`, formatPrice(item.fuel_amount_jul, false))}
        if ('fuel_amount_aug' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_aug`, formatPrice(item.fuel_amount_aug, false))}
        if ('fuel_amount_sep' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_sep`, formatPrice(item.fuel_amount_sep, false))}
        if ('fuel_amount_oct' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_oct`, formatPrice(item.fuel_amount_oct, false))}
        if ('fuel_amount_nov' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_nov`, formatPrice(item.fuel_amount_nov, false))}
        if ('fuel_amount_dec' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_dec`, formatPrice(item.fuel_amount_dec, false))}
        if ('factor_co2_kg_bio' !== ignore_field) {setValue(`roadDistance.${i}.factor_co2_kg_bio`, formatPrice(item.factor_co2_kg_bio, false))}
        if ('emission_tco2e_bio' !== ignore_field) {setValue(`roadDistance.${i}.emission_tco2e_bio`, formatPrice(item.emission_tco2e_bio, false))}
        if ('fuel_amount_yearly' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_yearly`, formatPrice(item.fuel_amount_yearly, false))}
        if ('fuel_amount_bio_jan' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_jan`, formatPrice(item.fuel_amount_bio_jan, false))}
        if ('fuel_amount_bio_feb' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_feb`, formatPrice(item.fuel_amount_bio_feb, false))}
        if ('fuel_amount_bio_mar' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_mar`, formatPrice(item.fuel_amount_bio_mar, false))}
        if ('fuel_amount_bio_apr' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_apr`, formatPrice(item.fuel_amount_bio_apr, false))}
        if ('fuel_amount_bio_may' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_may`, formatPrice(item.fuel_amount_bio_may, false))}
        if ('fuel_amount_bio_jun' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_jun`, formatPrice(item.fuel_amount_bio_jun, false))}
        if ('fuel_amount_bio_jul' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_jul`, formatPrice(item.fuel_amount_bio_jul, false))}
        if ('fuel_amount_bio_aug' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_aug`, formatPrice(item.fuel_amount_bio_aug, false))}
        if ('fuel_amount_bio_sep' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_sep`, formatPrice(item.fuel_amount_bio_sep, false))}
        if ('fuel_amount_bio_oct' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_oct`, formatPrice(item.fuel_amount_bio_oct, false))}
        if ('fuel_amount_bio_nov' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_nov`, formatPrice(item.fuel_amount_bio_nov, false))}
        if ('fuel_amount_bio_dec' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_dec`, formatPrice(item.fuel_amount_bio_dec, false))}
        if ('distance_yearly' !== ignore_field) {setValue(`roadDistance.${i}.distance_yearly`, formatPrice(item.distance_yearly, false))}
        if ('fuel_amount_bio_yearly' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_bio_yearly`,formatPrice(item.fuel_amount_bio_yearly, false))}
        if ('fuel_amount_fossil_jan' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_jan`,formatPrice(item.fuel_amount_fossil_jan, false))}
        if ('fuel_amount_fossil_feb' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_feb`,formatPrice(item.fuel_amount_fossil_feb, false))}
        if ('fuel_amount_fossil_mar' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_mar`,formatPrice(item.fuel_amount_fossil_mar, false))}
        if ('fuel_amount_fossil_apr' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_apr`,formatPrice(item.fuel_amount_fossil_apr, false))}
        if ('fuel_amount_fossil_may' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_may`,formatPrice(item.fuel_amount_fossil_may, false))}
        if ('fuel_amount_fossil_jun' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_jun`,formatPrice(item.fuel_amount_fossil_jun, false))}
        if ('fuel_amount_fossil_jul' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_jul`,formatPrice(item.fuel_amount_fossil_jul, false))}
        if ('fuel_amount_fossil_aug' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_aug`,formatPrice(item.fuel_amount_fossil_aug, false))}
        if ('fuel_amount_fossil_sep' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_sep`,formatPrice(item.fuel_amount_fossil_sep, false))}
        if ('fuel_amount_fossil_oct' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_oct`,formatPrice(item.fuel_amount_fossil_oct, false))}
        if ('fuel_amount_fossil_nov' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_nov`,formatPrice(item.fuel_amount_fossil_nov, false))}
        if ('fuel_amount_fossil_dec' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_dec`,formatPrice(item.fuel_amount_fossil_dec, false))}
        if ('fuel_amount_fossil_yearly' !== ignore_field) {setValue(`roadDistance.${i}.fuel_amount_fossil_yearly`,formatPrice(item.fuel_amount_fossil_yearly, false))}
        scopeRoad =
          scopeRoad +
          '_' +
          item.car_fleet_id +
          '_' +
          item.distance_jan +
          '_' +
          item.distance_feb +
          '_' +
          item.distance_mar +
          '_' +
          item.distance_apr +
          '_' +
          item.distance_may +
          '_' +
          item.distance_jun +
          '_' +
          item.distance_jul +
          '_' +
          item.distance_aug +
          '_' +
          item.distance_sep +
          '_' +
          item.distance_oct +
          '_' +
          item.distance_nov +
          '_' +
          item.distance_dec +
          '_' +
          item.distance_yearly
      }
    }

    setRoadDistance(itens)
    setScopeRoadDistance(scopeRoad)
  }

  const calculateRoadYear = (itens: any) => {
    let emissionCo2Road = 0
    let emissionCh4Road = 0
    let emissionN2oRoad = 0
    let emissionTco2eRoad = 0
    let emissionTco2eBioRoad = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_fossil_co2_t) {
          emissionCo2Road = emissionCo2Road + item.emission_fossil_co2_t
        }

        if (item.emission_ch4) {
          emissionCh4Road = emissionCh4Road + item.emission_ch4
        }

        if (item.emission_n2o) {
          emissionN2oRoad = emissionN2oRoad + item.emission_n2o
        }

        if (item.emission_tco2e) {
          emissionTco2eRoad = emissionTco2eRoad + item.emission_tco2e
        }

        if (item.emission_bio_co2_t) {
          emissionTco2eBioRoad = emissionTco2eBioRoad + item.emission_bio_co2_t
        }
      }
    }

    setEmissionCo2Road(emissionCo2Road)
    setEmissionCh4Road(emissionCh4Road)
    setEmissionN2oRoad(emissionN2oRoad)
    setEmissionTco2eRoad(emissionTco2eRoad)
    setEmissionTco2eBioRoad(emissionTco2eBioRoad)

    setScopeRoad(
      'calculate_iten_' +
        emissionCo2Road +
        '_' +
        emissionCh4Road +
        '_' +
        emissionN2oRoad +
        '_' +
        emissionTco2eRoad +
        '_' +
        emissionTco2eBioRoad
    )
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeCarFleetYear = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await carFleetService.getById(id)

      if (result) {
        const item = {
          car_fleet_id: result.id,
          car_fleet_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false, 
          only_view_monthly: false
        }

        const index = roadYear.length

        const itens = roadYear.concat(item)

        setScopeRoad('new_scope_item_' + index)

        setRoadYearValues(itens)
        calculateRoadYear(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('car_freet_road_year', [])
  }

  const handleChangeCarFleetFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false,
          only_view_monthly: false
          
        }

        const index = roadFuel.length

        const itens = roadFuel.concat(item)

        setScopeRoadFuel('new_scope_item_' + index)

        setRoadFuelValues(itens)
        calculateRoadFuel(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuel_private_vehicle', [])
  }

  const handleChangeCarFleetDistance = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await carFleetService.getById(id)

      if (result) {
        const item = {
          car_fleet_id: result.id,
          distance_yearly: result.distance_yearly,
          car_fleet_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false,
          only_view_monthly: false
        }

        const index = roadDistance.length

        const itens = roadDistance.concat(item)

        setScopeRoadDistance('new_scope_item_' + index)

        setRoadDistanceValues(itens)
        calculateRoadDistance(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('car_freet_road_year_distance', [])
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const carFleets = await carFleetService.getByParams({ limit: 9000, page: 1, type: 1 })

    setCarFleets(
      carFleets?.itens.map((carFleet: any) => {
        return {
          label: carFleet.name,
          value: carFleet.id
        }
      })
    )

    const fuels = await vehicleFuelService.getByParams({
      limit: 9000,
      page: 1,
      type: 'road'
    })

    setFuels(
      fuels?.itens.map((fuel: any) => {
        return {
          label: fuel.name,
          value: fuel.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getBusinessTripAuto(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'viagens_a_negocios',
      'viagens_em_automoveis'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      for (const item of entity?.years) {
        item.only_view_yearly =
          item.consumption_jan > 0 ||
          item.consumption_feb > 0 ||
          item.consumption_mar > 0 ||
          item.consumption_apr > 0 ||
          item.consumption_may > 0 ||
          item.consumption_jun > 0 ||
          item.consumption_jul > 0 ||
          item.consumption_aug > 0 ||
          item.consumption_sep > 0 ||
          item.consumption_oct > 0 ||
          item.consumption_nov > 0 ||
          item.consumption_dec > 0

        item.only_view_monthly = item.consumption_yearly > 0
      }

      

      for (const item of entity?.fuels) {
        item.only_view_yearly =
          item.consumption_jan > 0 ||
          item.consumption_feb > 0 ||
          item.consumption_mar > 0 ||
          item.consumption_apr > 0 ||
          item.consumption_may > 0 ||
          item.consumption_jun > 0 ||
          item.consumption_jul > 0 ||
          item.consumption_aug > 0 ||
          item.consumption_sep > 0 ||
          item.consumption_oct > 0 ||
          item.consumption_nov > 0 ||
          item.consumption_dec > 0

        item.only_view_monthly = item.consumption_yearly > 0
      }

      for (const item of entity?.distances) {
        item.only_view_yearly =
          item.distance_jan > 0 ||
          item.distance_feb > 0 ||
          item.distance_mar > 0 ||
          item.distance_apr > 0 ||
          item.distance_may > 0 ||
          item.distance_jun > 0 ||
          item.distance_jul > 0 ||
          item.distance_aug > 0 ||
          item.distance_sep > 0 ||
          item.distance_oct > 0 ||
          item.distance_nov > 0 ||
          item.distance_dec > 0

        item.only_view_monthly = item.distance_yearly > 0
      }

      setRoadYearValues(entity?.years)
      calculateRoadYear(entity?.years)

      setRoadFuelValues(entity?.fuels)
      calculateRoadFuel(entity?.fuels)

      setRoadDistanceValues(entity?.distances)
      calculateRoadDistance(entity?.distances)

      setScopeRoad('load_scope_' + entity.id + '_roadYear_' + entity?.years.length)
      setScopeRoadFuel('load_scope_' + entity.id + '_roadYear_' + entity?.fuels.length)
      setScopeRoadDistance(
        'load_scope_' + entity.id + '_roadYear_' + entity?.distances.length
      )
    } else {
      setScopeSelected({})

      setRoadYearValues([])
      calculateRoadYear([])

      setRoadFuelValues([])
      calculateRoadFuel([])

      setRoadDistanceValues([])
      calculateRoadDistance([])

      setIgnoreFill(false)

      setScopeRoad('load_scope_0_roadYear_0')
      setScopeRoadFuel('load_scope_0_roadYear_0')
      setScopeRoadDistance('load_scope_0_roadYear_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const calculateRoadDistance = (itens: any) => {
    let emissionCo2Road = 0
    let emissionCh4Road = 0
    let emissionN2oRoad = 0
    let emissionTco2eRoad = 0
    let emissionTco2eBioRoad = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Road = emissionCo2Road + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Road = emissionCh4Road + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oRoad = emissionN2oRoad + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eRoad = emissionTco2eRoad + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioRoad = emissionTco2eBioRoad + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2RoadDistance(emissionCo2Road)
    setEmissionCh4RoadDistance(emissionCh4Road)
    setEmissionN2oRoadDistance(emissionN2oRoad)
    setEmissionTco2eRoadDistance(emissionTco2eRoad)
    setEmissionTco2eBioRoadDistance(emissionTco2eBioRoad)

    setScopeRoadDistance(
      'calculate_iten_' +
        emissionCo2Road +
        '_' +
        emissionCh4Road +
        '_' +
        emissionN2oRoad +
        '_' +
        emissionTco2eRoad +
        '_' +
        emissionTco2eBioRoad
    )
  }

  const calculateRoadFuel = (itens: any) => {
    let emissionCo2Road = 0
    let emissionCh4Road = 0
    let emissionN2oRoad = 0
    let emissionTco2eRoad = 0
    let emissionTco2eBioRoad = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Road = emissionCo2Road + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Road = emissionCh4Road + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oRoad = emissionN2oRoad + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eRoad = emissionTco2eRoad + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioRoad = emissionTco2eBioRoad + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2RoadFuel(emissionCo2Road)
    setEmissionCh4RoadFuel(emissionCh4Road)
    setEmissionN2oRoadFuel(emissionN2oRoad)
    setEmissionTco2eRoadFuel(emissionTco2eRoad)
    setEmissionTco2eBioRoadFuel(emissionTco2eBioRoad)

    setScopeRoadFuel(
      'calculate_iten_' +
        emissionCo2Road +
        '_' +
        emissionCh4Road +
        '_' +
        emissionN2oRoad +
        '_' +
        emissionTco2eRoad +
        '_' +
        emissionTco2eBioRoad
    )
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const roadYearItens: any[] = roadYear.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          car_fleet_id: item.car_fleet_id,
          year: item.year ? parseInt(item.year) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4.toFixed(6)) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o.toFixed(6)) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e.toFixed(6)) : 0,
          consumption_jan: item.consumption_jan ? parseFloat(item.consumption_jan.toFixed(6)) : 0,
          consumption_feb: item.consumption_feb ? parseFloat(item.consumption_feb.toFixed(6)) : 0,
          consumption_mar: item.consumption_mar ? parseFloat(item.consumption_mar.toFixed(6)) : 0,
          consumption_apr: item.consumption_apr ? parseFloat(item.consumption_apr.toFixed(6)) : 0,
          consumption_may: item.consumption_may ? parseFloat(item.consumption_may.toFixed(6)) : 0,
          consumption_jun: item.consumption_jun ? parseFloat(item.consumption_jun.toFixed(6)) : 0,
          consumption_jul: item.consumption_jul ? parseFloat(item.consumption_jul.toFixed(6)) : 0,
          consumption_aug: item.consumption_aug ? parseFloat(item.consumption_aug.toFixed(6)) : 0,
          consumption_sep: item.consumption_sep ? parseFloat(item.consumption_sep.toFixed(6)) : 0,
          consumption_oct: item.consumption_oct ? parseFloat(item.consumption_oct.toFixed(6)) : 0,
          consumption_nov: item.consumption_nov ? parseFloat(item.consumption_nov.toFixed(6)) : 0,
          consumption_dec: item.consumption_dec ? parseFloat(item.consumption_dec.toFixed(6)) : 0,
          factor_kg_ch4_l: item.factor_kg_ch4_l ? parseFloat(item.factor_kg_ch4_l.toFixed(6)) : 0,
          factor_kg_n2o_l: item.factor_kg_n2o_l ? parseFloat(item.factor_kg_n2o_l.toFixed(6)) : 0,
          consumption_yearly: item.consumption_yearly ? parseFloat(item.consumption_yearly.toFixed(6)) : 0,
          emission_bio_co2_t: item.emission_bio_co2_t ? parseFloat(item.emission_bio_co2_t.toFixed(6)) : 0,
          factor_bio_kg_co2_l: item.factor_bio_kg_co2_l ? parseFloat(item.factor_bio_kg_co2_l.toFixed(6)) : 0,
          consumption_bio_jan: item.consumption_bio_jan ? parseFloat(item.consumption_bio_jan.toFixed(6)) : 0,
          consumption_bio_feb: item.consumption_bio_feb ? parseFloat(item.consumption_bio_feb.toFixed(6)) : 0,
          consumption_bio_mar: item.consumption_bio_mar ? parseFloat(item.consumption_bio_mar.toFixed(6)) : 0,
          consumption_bio_apr: item.consumption_bio_apr ? parseFloat(item.consumption_bio_apr.toFixed(6)) : 0,
          consumption_bio_may: item.consumption_bio_may ? parseFloat(item.consumption_bio_may.toFixed(6)): 0,
          consumption_bio_jun: item.consumption_bio_jun ? parseFloat(item.consumption_bio_jun.toFixed(6)): 0,
          consumption_bio_jul: item.consumption_bio_jul ? parseFloat(item.consumption_bio_jul.toFixed(6)): 0,
          consumption_bio_aug: item.consumption_bio_aug ? parseFloat(item.consumption_bio_aug.toFixed(6)): 0,
          consumption_bio_sep: item.consumption_bio_sep ? parseFloat(item.consumption_bio_sep.toFixed(6)): 0,
          consumption_bio_oct: item.consumption_bio_oct ? parseFloat(item.consumption_bio_oct.toFixed(6)): 0,
          consumption_bio_nov: item.consumption_bio_nov ? parseFloat(item.consumption_bio_nov.toFixed(6)): 0,
          consumption_bio_dec: item.consumption_bio_dec ? parseFloat(item.consumption_bio_dec.toFixed(6)): 0,
          emission_fossil_co2_t: item.emission_fossil_co2_t ? parseFloat(item.emission_fossil_co2_t.toFixed(6)): 0,
          consumption_bio_yearly: item.consumption_bio_yearly ? parseFloat(item.consumption_bio_yearly.toFixed(6)): 0,
          factor_fossil_kg_co2_l: item.factor_fossil_kg_co2_l ? parseFloat(item.factor_fossil_kg_co2_l.toFixed(6)): 0,
          consumption_fossil_jan: item.consumption_fossil_jan ? parseFloat(item.consumption_fossil_jan.toFixed(6)): 0,
          consumption_fossil_feb: item.consumption_fossil_feb ? parseFloat(item.consumption_fossil_feb.toFixed(6)): 0,
          consumption_fossil_mar: item.consumption_fossil_mar ? parseFloat(item.consumption_fossil_mar.toFixed(6)): 0,
          consumption_fossil_apr: item.consumption_fossil_apr ? parseFloat(item.consumption_fossil_apr.toFixed(6)): 0,
          consumption_fossil_may: item.consumption_fossil_may ? parseFloat(item.consumption_fossil_may.toFixed(6)): 0,
          consumption_fossil_jun: item.consumption_fossil_jun ? parseFloat(item.consumption_fossil_jun.toFixed(6)): 0,
          consumption_fossil_jul: item.consumption_fossil_jul ? parseFloat(item.consumption_fossil_jul.toFixed(6)): 0,
          consumption_fossil_aug: item.consumption_fossil_aug ? parseFloat(item.consumption_fossil_aug.toFixed(6)): 0,
          consumption_fossil_sep: item.consumption_fossil_sep ? parseFloat(item.consumption_fossil_sep.toFixed(6)): 0,
          consumption_fossil_oct: item.consumption_fossil_oct ? parseFloat(item.consumption_fossil_oct.toFixed(6)): 0,
          consumption_fossil_nov: item.consumption_fossil_nov ? parseFloat(item.consumption_fossil_nov.toFixed(6)): 0,
          consumption_fossil_dec: item.consumption_fossil_dec ? parseFloat(item.consumption_fossil_dec.toFixed(6)): 0,
          consumption_fossil_yearly: item.consumption_fossil_yearly ? parseFloat(item.consumption_fossil_yearly): 0
        }
      })

      const roadFuelItens: any[] = roadFuel.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          vehicle_fuel_id: item.vehicle_fuel_id,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4.toFixed(6)) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o.toFixed(6)) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2.toFixed(6)) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg.toFixed(6)) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg.toFixed(6)) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg.toFixed(6)) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e.toFixed(6)) : 0,
          consumption_jan: item.consumption_jan ? parseFloat(item.consumption_jan.toFixed(6)) : 0,
          consumption_feb: item.consumption_feb ? parseFloat(item.consumption_feb.toFixed(6)) : 0,
          consumption_mar: item.consumption_mar ? parseFloat(item.consumption_mar.toFixed(6)) : 0,
          consumption_apr: item.consumption_apr ? parseFloat(item.consumption_apr.toFixed(6)) : 0,
          consumption_may: item.consumption_may ? parseFloat(item.consumption_may.toFixed(6)) : 0,
          consumption_jun: item.consumption_jun ? parseFloat(item.consumption_jun.toFixed(6)) : 0,
          consumption_jul: item.consumption_jul ? parseFloat(item.consumption_jul.toFixed(6)) : 0,
          consumption_aug: item.consumption_aug ? parseFloat(item.consumption_aug.toFixed(6)) : 0,
          consumption_sep: item.consumption_sep ? parseFloat(item.consumption_sep.toFixed(6)) : 0,
          consumption_oct: item.consumption_oct ? parseFloat(item.consumption_oct.toFixed(6)) : 0,
          consumption_nov: item.consumption_nov ? parseFloat(item.consumption_nov.toFixed(6)) : 0,
          consumption_dec: item.consumption_dec ? parseFloat(item.consumption_dec.toFixed(6)) : 0,
          fuel_amount_jan: item.fuel_amount_jan ? parseFloat(item.fuel_amount_jan.toFixed(6)) : 0,
          fuel_amount_feb: item.fuel_amount_feb ? parseFloat(item.fuel_amount_feb.toFixed(6)) : 0,
          fuel_amount_mar: item.fuel_amount_mar ? parseFloat(item.fuel_amount_mar.toFixed(6)) : 0,
          fuel_amount_apr: item.fuel_amount_apr ? parseFloat(item.fuel_amount_apr.toFixed(6)) : 0,
          fuel_amount_may: item.fuel_amount_may ? parseFloat(item.fuel_amount_may.toFixed(6)) : 0,
          fuel_amount_jun: item.fuel_amount_jun ? parseFloat(item.fuel_amount_jun.toFixed(6)) : 0,
          fuel_amount_jul: item.fuel_amount_jul ? parseFloat(item.fuel_amount_jul.toFixed(6)) : 0,
          fuel_amount_aug: item.fuel_amount_aug ? parseFloat(item.fuel_amount_aug.toFixed(6)) : 0,
          fuel_amount_sep: item.fuel_amount_sep ? parseFloat(item.fuel_amount_sep.toFixed(6)) : 0,
          fuel_amount_oct: item.fuel_amount_oct ? parseFloat(item.fuel_amount_oct.toFixed(6)) : 0,
          fuel_amount_nov: item.fuel_amount_nov ? parseFloat(item.fuel_amount_nov.toFixed(6)) : 0,
          fuel_amount_dec: item.fuel_amount_dec ? parseFloat(item.fuel_amount_dec.toFixed(6)) : 0,
          factor_ch4_kg_bio: item.factor_ch4_kg_bio ? parseFloat(item.factor_ch4_kg_bio.toFixed(6)): 0,
          factor_co2_kg_bio: item.factor_co2_kg_bio ? parseFloat(item.factor_co2_kg_bio.toFixed(6)): 0,
          factor_n2o_kg_bio: item.factor_n2o_kg_bio ? parseFloat(item.factor_n2o_kg_bio.toFixed(6)): 0,
          emission_tco2e_bio: item.emission_tco2e_bio ? parseFloat(item.emission_tco2e_bio.toFixed(6)): 0,
          fuel_amount_yearly: item.fuel_amount_yearly ? parseFloat(item.fuel_amount_yearly.toFixed(6)): 0,
          consumption_yearly: item.consumption_yearly ? parseFloat(item.consumption_yearly.toFixed(6)): 0,
          fuel_amount_bio_jan: item.fuel_amount_bio_jan ? parseFloat(item.fuel_amount_bio_jan.toFixed(6)): 0,
          fuel_amount_bio_feb: item.fuel_amount_bio_feb ? parseFloat(item.fuel_amount_bio_feb.toFixed(6)): 0,
          fuel_amount_bio_mar: item.fuel_amount_bio_mar ? parseFloat(item.fuel_amount_bio_mar.toFixed(6)): 0,
          fuel_amount_bio_apr: item.fuel_amount_bio_apr ? parseFloat(item.fuel_amount_bio_apr.toFixed(6)): 0,
          fuel_amount_bio_may: item.fuel_amount_bio_may ? parseFloat(item.fuel_amount_bio_may.toFixed(6)): 0,
          fuel_amount_bio_jun: item.fuel_amount_bio_jun ? parseFloat(item.fuel_amount_bio_jun.toFixed(6)): 0,
          fuel_amount_bio_jul: item.fuel_amount_bio_jul ? parseFloat(item.fuel_amount_bio_jul.toFixed(6)): 0,
          fuel_amount_bio_aug: item.fuel_amount_bio_aug ? parseFloat(item.fuel_amount_bio_aug.toFixed(6)): 0,
          fuel_amount_bio_sep: item.fuel_amount_bio_sep ? parseFloat(item.fuel_amount_bio_sep.toFixed(6)): 0,
          fuel_amount_bio_oct: item.fuel_amount_bio_oct ? parseFloat(item.fuel_amount_bio_oct.toFixed(6)): 0,
          fuel_amount_bio_nov: item.fuel_amount_bio_nov ? parseFloat(item.fuel_amount_bio_nov.toFixed(6)): 0,
          fuel_amount_bio_dec: item.fuel_amount_bio_dec ? parseFloat(item.fuel_amount_bio_dec.toFixed(6)): 0,
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearly ? parseFloat(item.fuel_amount_bio_yearly.toFixed(6)): 0
        }
      })

      const roadDistanceItens: any[] = roadDistance.map((item: any, index: number) => {
        return {
          source: item.source,
          year: parseInt(item.year),
          description: item.description,
          car_fleet_id: item.car_fleet_id,
          distance_jan: item.distance_jan ? parseFloat(item.distance_jan.toFixed(6)) : 0,
          distance_feb: item.distance_feb ? parseFloat(item.distance_feb.toFixed(6)) : 0,
          distance_mar: item.distance_mar ? parseFloat(item.distance_mar.toFixed(6)) : 0,
          distance_apr: item.distance_apr ? parseFloat(item.distance_apr.toFixed(6)) : 0,
          distance_may: item.distance_may ? parseFloat(item.distance_may.toFixed(6)) : 0,
          distance_jun: item.distance_jun ? parseFloat(item.distance_jun.toFixed(6)) : 0,
          distance_jul: item.distance_jul ? parseFloat(item.distance_jul.toFixed(6)) : 0,
          distance_aug: item.distance_aug ? parseFloat(item.distance_aug.toFixed(6)) : 0,
          distance_sep: item.distance_sep ? parseFloat(item.distance_sep.toFixed(6)) : 0,
          distance_oct: item.distance_oct ? parseFloat(item.distance_oct.toFixed(6)) : 0,
          distance_nov: item.distance_nov ? parseFloat(item.distance_nov.toFixed(6)) : 0,
          distance_dec: item.distance_dec ? parseFloat(item.distance_dec.toFixed(6)) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2.toFixed(14)) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4.toFixed(14)) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o.toFixed(14)) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg.toFixed(14)) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg.toFixed(14)) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg.toFixed(14)) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e.toFixed(14)) : 0,
          fuel_amount_jan: item.fuel_amount_jan ? parseFloat(item.fuel_amount_jan.toFixed(6)) : 0,
          fuel_amount_feb: item.fuel_amount_feb ? parseFloat(item.fuel_amount_feb.toFixed(6)) : 0,
          fuel_amount_mar: item.fuel_amount_mar ? parseFloat(item.fuel_amount_mar.toFixed(6)) : 0,
          fuel_amount_apr: item.fuel_amount_apr ? parseFloat(item.fuel_amount_apr.toFixed(6)) : 0,
          fuel_amount_may: item.fuel_amount_may ? parseFloat(item.fuel_amount_may.toFixed(6)) : 0,
          fuel_amount_jun: item.fuel_amount_jun ? parseFloat(item.fuel_amount_jun.toFixed(6)) : 0,
          fuel_amount_jul: item.fuel_amount_jul ? parseFloat(item.fuel_amount_jul.toFixed(6)) : 0,
          fuel_amount_aug: item.fuel_amount_aug ? parseFloat(item.fuel_amount_aug.toFixed(6)) : 0,
          fuel_amount_sep: item.fuel_amount_sep ? parseFloat(item.fuel_amount_sep.toFixed(6)) : 0,
          fuel_amount_oct: item.fuel_amount_oct ? parseFloat(item.fuel_amount_oct.toFixed(6)) : 0,
          fuel_amount_nov: item.fuel_amount_nov ? parseFloat(item.fuel_amount_nov.toFixed(6)) : 0,
          fuel_amount_dec: item.fuel_amount_dec ? parseFloat(item.fuel_amount_dec.toFixed(6)) : 0,
          distance_yearly: item.distance_yearly ? parseFloat(item.distance_yearly.toFixed(6)) : 0,
          factor_co2_kg_bio: item.factor_co2_kg_bio? parseFloat(item.factor_co2_kg_bio.toFixed(14)): 0,
          emission_tco2e_bio: item.emission_tco2e_bio? parseFloat(item.emission_tco2e_bio.toFixed(6)): 0,
          fuel_amount_yearly: item.fuel_amount_yearly? parseFloat(item.fuel_amount_yearly.toFixed(6)): 0,
          fuel_amount_bio_jan: item.fuel_amount_bio_jan? parseFloat(item.fuel_amount_bio_jan.toFixed(6)): 0,
          fuel_amount_bio_feb: item.fuel_amount_bio_feb? parseFloat(item.fuel_amount_bio_feb.toFixed(6)): 0,
          fuel_amount_bio_mar: item.fuel_amount_bio_mar? parseFloat(item.fuel_amount_bio_mar.toFixed(6)): 0,
          fuel_amount_bio_apr: item.fuel_amount_bio_apr? parseFloat(item.fuel_amount_bio_apr.toFixed(6)): 0,
          fuel_amount_bio_may: item.fuel_amount_bio_may? parseFloat(item.fuel_amount_bio_may.toFixed(6)): 0,
          fuel_amount_bio_jun: item.fuel_amount_bio_jun? parseFloat(item.fuel_amount_bio_jun.toFixed(6)): 0,
          fuel_amount_bio_jul: item.fuel_amount_bio_jul? parseFloat(item.fuel_amount_bio_jul.toFixed(6)): 0,
          fuel_amount_bio_aug: item.fuel_amount_bio_aug? parseFloat(item.fuel_amount_bio_aug.toFixed(6)): 0,
          fuel_amount_bio_sep: item.fuel_amount_bio_sep? parseFloat(item.fuel_amount_bio_sep.toFixed(6)): 0,
          fuel_amount_bio_oct: item.fuel_amount_bio_oct? parseFloat(item.fuel_amount_bio_oct.toFixed(6)): 0,
          fuel_amount_bio_nov: item.fuel_amount_bio_nov? parseFloat(item.fuel_amount_bio_nov.toFixed(6)): 0,
          fuel_amount_bio_dec: item.fuel_amount_bio_dev? parseFloat(item.fuel_amount_bio_dec.toFixed(6)): 0,
          fuel_amount_bio_yearly: item.fuel_amount_bio_yearly? parseFloat(item.fuel_amount_bio_yearly.toFixed(6)): 0,
          fuel_amount_fossil_jan: item.fuel_amount_fossil_jan? parseFloat(item.fuel_amount_fossil_jan.toFixed(6)): 0,
          fuel_amount_fossil_feb: item.fuel_amount_fossil_feb? parseFloat(item.fuel_amount_fossil_feb.toFixed(6)): 0,
          fuel_amount_fossil_mar: item.fuel_amount_fossil_mar? parseFloat(item.fuel_amount_fossil_mar.toFixed(6)): 0,
          fuel_amount_fossil_apr: item.fuel_amount_fossil_apr? parseFloat(item.fuel_amount_fossil_apr.toFixed(6)): 0,
          fuel_amount_fossil_may: item.fuel_amount_fossil_may? parseFloat(item.fuel_amount_fossil_may.toFixed(6)): 0,
          fuel_amount_fossil_jun: item.fuel_amount_fossil_jun? parseFloat(item.fuel_amount_fossil_jun.toFixed(6)): 0,
          fuel_amount_fossil_jul: item.fuel_amount_fossil_jul? parseFloat(item.fuel_amount_fossil_jul.toFixed(6)): 0,
          fuel_amount_fossil_aug: item.fuel_amount_fossil_aug? parseFloat(item.fuel_amount_fossil_aug.toFixed(6)): 0,
          fuel_amount_fossil_sep: item.fuel_amount_fossil_sep? parseFloat(item.fuel_amount_fossil_sep.toFixed(6)): 0,
          fuel_amount_fossil_oct: item.fuel_amount_fossil_oct? parseFloat(item.fuel_amount_fossil_oct.toFixed(6)): 0,
          fuel_amount_fossil_nov: item.fuel_amount_fossil_nov? parseFloat(item.fuel_amount_fossil_nov.toFixed(6)): 0,
          fuel_amount_fossil_dec: item.fuel_amount_fossil_dec? parseFloat(item.fuel_amount_fossil_dec.toFixed(6)): 0,
          average_consumption_fuel: item.average_consumption_fuel? parseFloat(item.average_consumption_fuel.toFixed(6)): 0,
          fuel_amount_fossil_yearly: item.fuel_amount_fossil_yearly? parseFloat(item.fuel_amount_fossil_yearly.toFixed(6)): 0
        }
      })

      const scope = {
        status: 2,
        years: roadYearItens,
        fuels: roadFuelItens,
        evidence: formData.evidence,
        distances: roadDistanceItens,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope3Service.saveBusinessTripAuto(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('BusinessTripAuto', JSON.stringify(scope));
        
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_automoveis'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'viagens_a_negocios',
        file: base64,
        filename: filename,
        second_name: 'viagens_em_automoveis'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_automoveis'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  const handleOpenModalAutoFleet = () => {
    setOpenModalAutoFleet(!OpenModalAutoFleet)
  }

  const handleOpenModalAutoCombus = () => {
    setOpenModalAutoCombus(!OpenModalAutoCombus)
  }

  const handleOpenModalAutoDistance = () => {
    setOpenModalImportDistance(!openModalImportDistance)
  }

  const handleImportFuel = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = roadFuel

        for (let i = 3; i < data?.length; i++) {
          const vehicleFuel = data[i][2]
  
          if (vehicleFuel){
            let obj:any = undefined
  
            for (const t of fuels) {
              if (t.label === vehicleFuel){
                obj = t
                break;
              }
            }
  
            if (obj){
              const fuelObj = await carFleetService.getById(obj.value)
        
              if (fuelObj) {
                const fuel = fuelObj.fuel
                const bioFuel = fuelObj.bioFuel

                const source = data[i][0]
                const description = data[i][1]
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const year = data[i][3]

                const consumption_jan = data[i][3] ? data[i][3] : 0
                const consumption_feb = data[i][4] ? data[i][4] : 0
                const consumption_mar = data[i][5] ? data[i][5] : 0
                const consumption_apr = data[i][6] ? data[i][6] : 0
                const consumption_may = data[i][7] ? data[i][7] : 0
                const consumption_jun = data[i][8] ? data[i][8] : 0
                const consumption_jul = data[i][9] ? data[i][9] : 0
                const consumption_aug = data[i][10] ? data[i][10] : 0
                const consumption_sep = data[i][11] ? data[i][11] : 0
                const consumption_oct = data[i][12] ? data[i][12] : 0
                const consumption_nov = data[i][13] ? data[i][13] : 0
                const consumption_dec = data[i][14] ? data[i][14] : 0
                const consumption_yearly = data[i][15] ? data[i][15] : 0
                
                const scope = { source: source, description: description, vehicle_fuel_id: fuelObj.id, vehicle_fuel_name: obj.label, 
                  fuel_id: fuel ? fuel.id : undefined, fuel_name: fuel ? fuel.name : undefined, 
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined, bio_fuel_name: bioFuel ? bioFuel.name : undefined, 
                  consumption_jan: consumption_jan, consumption_feb: consumption_feb, consumption_mar: consumption_mar,
                  consumption_apr: consumption_apr, consumption_may: consumption_may, consumption_jun: consumption_jun,
                  consumption_jul: consumption_jul, consumption_aug: consumption_aug, consumption_sep: consumption_sep,
                  consumption_oct: consumption_oct, consumption_nov: consumption_nov, consumption_dec: consumption_dec,
                  consumption_yearly: consumption_yearly, emission_n2o: 0, emission_co2: 0, emission_ch4: 0, factor_ch4_kg: 0, 
                  factor_co2_kg: 0, factor_n2o_kg: 0, emission_tco2e: 0, fuel_amount_jan: 0, fuel_amount_feb: 0, 
                  fuel_amount_mar: 0, fuel_amount_apr: 0, fuel_amount_may: 0, fuel_amount_jun: 0, fuel_amount_jul: 0,
                  fuel_amount_aug: 0, fuel_amount_sep: 0, fuel_amount_oct: 0, fuel_amount_nov: 0, fuel_amount_dec: 0,
                  factor_ch4_kg_bio: 0, factor_co2_kg_bio: 0, factor_n2o_kg_bio: 0, emission_tco2e_bio: 0, fuel_amount_yearly: 0,
                  fuel_amount_bio_jan: 0, fuel_amount_bio_feb: 0, fuel_amount_bio_mar: 0, fuel_amount_bio_apr: 0,
                  fuel_amount_bio_may: 0, fuel_amount_bio_jun: 0, fuel_amount_bio_jul: 0, fuel_amount_bio_aug: 0,
                  fuel_amount_bio_sep: 0, fuel_amount_bio_oct: 0, fuel_amount_bio_nov: 0, fuel_amount_bio_dec: 0,
                  fuel_amount_bio_yearly: 0, only_view_yearly: false, only_view_monthly: false
                }

                const result = await scope3Service.calculateBusinessTripAutoFuel(
                  scope.vehicle_fuel_id, scope.consumption_jan, scope.consumption_feb, scope.consumption_mar,
                  scope.consumption_apr, scope.consumption_may, scope.consumption_jun, scope.consumption_jul, scope.consumption_aug,
                  scope.consumption_sep, scope.consumption_oct, scope.consumption_nov, scope.consumption_dec, scope.consumption_yearly
                )

                const onlyViewYearly = scope.consumption_jan > 0 || scope.consumption_feb > 0 || scope.consumption_mar > 0 || 
                  scope.consumption_apr > 0 || scope.consumption_may > 0 || scope.consumption_jun > 0 || 
                  scope.consumption_jul > 0 || scope.consumption_aug > 0 || scope.consumption_sep > 0 ||
                  scope.consumption_oct > 0 || scope.consumption_nov > 0 || scope.consumption_dec > 0
            
                const onlyViewMonthly = scope.consumption_yearly > 0
            
                scope.consumption_yearly = onlyViewYearly ? 0 : scope.consumption_yearly
          
                if (result) {
                  scope.only_view_yearly = onlyViewYearly
                  scope.only_view_monthly = onlyViewMonthly
          
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.factor_ch4_kg_bio = parseFloat(result.factor_ch4_kg_bio)
                  scope.factor_n2o_kg_bio = parseFloat(result.factor_n2o_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
  
                  itens.push(scope)
                }
              }else{
                errors.push({id: i, label: vehicleFuel})
              }
            }else{
              errors.push({id: i, label: vehicleFuel})
            }
          }
        }
        
        setScopeRoad(`import_scope_data_${data?.length}`);
  
        setRoadFuelValues(itens)
        calculateRoadFuel(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportDistance = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = roadDistance

        for (let i = 3; i < data?.length; i++) {
          const carFleet = data[i][2]
  
          if (carFleet){
            let obj:any = undefined
  
            for (const t of carFleets) {
              if (t.label === carFleet){
                obj = t
                break;
              }
            }
  
            if (obj){
              const carObj = await carFleetService.getById(obj.value)
        
              if (carObj) {
                const fuel = carObj.fuel
                const bioFuel = carObj.bioFuel

                const source = data[i][0]
                const description = data[i][1]
                const year = data[i][3]

                const distance_jan = data[i][4] ? data[i][4] : 0
                const distance_feb = data[i][5] ? data[i][5] : 0
                const distance_mar = data[i][6] ? data[i][6] : 0
                const distance_apr = data[i][7] ? data[i][7] : 0
                const distance_may = data[i][8] ? data[i][8] : 0
                const distance_jun = data[i][9] ? data[i][9] : 0
                const distance_jul = data[i][10] ? data[i][10] : 0
                const distance_aug = data[i][11] ? data[i][11] : 0
                const distance_sep = data[i][12] ? data[i][12] : 0
                const distance_oct = data[i][13] ? data[i][13] : 0
                const distance_nov = data[i][14] ? data[i][14] : 0
                const distance_dec = data[i][15] ? data[i][15] : 0
                const distance_yearly = data[i][16] ? data[i][16] : 0
                
                
                const scope = { distance_yearly: distance_yearly, source: source, description: description, car_fleet_id: carObj.id, car_fleet_name: obj.label, 
                  year: year, fuel_id: fuel ? fuel.id : undefined, fuel_name: fuel ? fuel.name : undefined, 
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined, bio_fuel_name: bioFuel ? bioFuel.name : undefined,
                  distance_jan: distance_jan, distance_feb: distance_feb, distance_mar: distance_mar, distance_apr: distance_apr,
                  distance_may: distance_may, distance_jun: distance_jun, distance_jul: distance_jul, distance_aug: distance_aug, 
                  distance_sep: distance_sep, distance_oct: distance_oct, distance_nov: distance_nov, distance_dec: distance_dec, 
                  emission_co2: 0, emission_ch4: 0, emission_n2o: 0, factor_co2_kg: 0, factor_ch4_kg: 0, factor_n2o_kg: 0, 
                  emission_tco2e: 0, fuel_amount_jan: 0, fuel_amount_feb: 0, fuel_amount_mar: 0, fuel_amount_apr: 0,
                  fuel_amount_may: 0, fuel_amount_jun: 0, fuel_amount_jul: 0, fuel_amount_aug: 0, fuel_amount_sep: 0,
                  fuel_amount_oct: 0, fuel_amount_nov: 0, fuel_amount_dec: 0, factor_co2_kg_bio: 0,
                  emission_tco2e_bio: 0, fuel_amount_yearly: 0, fuel_amount_bio_jan: 0, fuel_amount_bio_feb: 0, 
                  fuel_amount_bio_mar: 0, fuel_amount_bio_apr: 0, fuel_amount_bio_may: 0, fuel_amount_bio_jun: 0, 
                  fuel_amount_bio_jul: 0, fuel_amount_bio_aug: 0, fuel_amount_bio_sep: 0, fuel_amount_bio_oct: 0,
                  fuel_amount_bio_nov: 0, fuel_amount_bio_dec: 0, fuel_amount_bio_yearly: 0, fuel_amount_fossil_jan: 0, 
                  fuel_amount_fossil_feb: 0, fuel_amount_fossil_mar: 0, fuel_amount_fossil_apr: 0, fuel_amount_fossil_may: 0,
                  fuel_amount_fossil_jun: 0, fuel_amount_fossil_jul: 0, fuel_amount_fossil_aug: 0, fuel_amount_fossil_sep: 0,
                  fuel_amount_fossil_oct: 0, fuel_amount_fossil_nov: 0, fuel_amount_fossil_dec: 0, average_consumption_fuel: 0,
                  fuel_amount_fossil_yearly: 0, only_view_yearly: false, only_view_monthly: false
                }

                const result = await scope3Service.calculateBusinessTripAutoDistance(
                  scope.year, scope.car_fleet_id, scope.distance_jan, scope.distance_feb, scope.distance_mar,
                  scope.distance_apr, scope.distance_may, scope.distance_jun, scope.distance_jul, scope.distance_aug,
                  scope.distance_sep, scope.distance_oct, scope.distance_nov, scope.distance_dec, scope.distance_yearly
                )

                const onlyViewYearly = scope.distance_jan > 0 || scope.distance_feb > 0 || scope.distance_mar > 0 || 
                  scope.distance_apr > 0 || scope.distance_may > 0 || scope.distance_jun > 0 || 
                  scope.distance_jul > 0 || scope.distance_aug > 0 || scope.distance_sep > 0 ||
                  scope.distance_oct > 0 || scope.distance_nov > 0 || scope.distance_dec > 0 
            
                const onlyViewMonthly = scope.distance_yearly > 0
            
                scope.distance_yearly = onlyViewYearly ? 0 : scope.distance_yearly
          
                if (result) {
                  scope.only_view_yearly = onlyViewYearly
                  scope.only_view_monthly = onlyViewMonthly

                  scope.distance_yearly = parseFloat(result.distance_yearly)
                  scope.emission_co2 = parseFloat(result.emission_co2)
                  scope.emission_ch4 = parseFloat(result.emission_ch4)
                  scope.emission_n2o = parseFloat(result.emission_n2o)
                  scope.factor_co2_kg = parseFloat(result.factor_co2_kg)
                  scope.factor_ch4_kg = parseFloat(result.factor_ch4_kg)
                  scope.factor_n2o_kg = parseFloat(result.factor_n2o_kg)
                  scope.emission_tco2e = parseFloat(result.emission_tco2e)
                  scope.fuel_amount_jan = parseFloat(result.fuel_amount_jan)
                  scope.fuel_amount_feb = parseFloat(result.fuel_amount_feb)
                  scope.fuel_amount_mar = parseFloat(result.fuel_amount_mar)
                  scope.fuel_amount_apr = parseFloat(result.fuel_amount_apr)
                  scope.fuel_amount_may = parseFloat(result.fuel_amount_may)
                  scope.fuel_amount_jun = parseFloat(result.fuel_amount_jun)
                  scope.fuel_amount_jul = parseFloat(result.fuel_amount_jul)
                  scope.fuel_amount_aug = parseFloat(result.fuel_amount_aug)
                  scope.fuel_amount_sep = parseFloat(result.fuel_amount_sep)
                  scope.fuel_amount_oct = parseFloat(result.fuel_amount_oct)
                  scope.fuel_amount_nov = parseFloat(result.fuel_amount_nov)
                  scope.fuel_amount_dec = parseFloat(result.fuel_amount_dec)
                  scope.factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
                  scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                  scope.fuel_amount_yearly = parseFloat(result.fuel_amount_yearly)
                  scope.fuel_amount_bio_jan = parseFloat(result.fuel_amount_bio_jan)
                  scope.fuel_amount_bio_feb = parseFloat(result.fuel_amount_bio_feb)
                  scope.fuel_amount_bio_mar = parseFloat(result.fuel_amount_bio_mar)
                  scope.fuel_amount_bio_apr = parseFloat(result.fuel_amount_bio_apr)
                  scope.fuel_amount_bio_may = parseFloat(result.fuel_amount_bio_may)
                  scope.fuel_amount_bio_jun = parseFloat(result.fuel_amount_bio_jun)
                  scope.fuel_amount_bio_jul = parseFloat(result.fuel_amount_bio_jul)
                  scope.fuel_amount_bio_aug = parseFloat(result.fuel_amount_bio_aug)
                  scope.fuel_amount_bio_sep = parseFloat(result.fuel_amount_bio_sep)
                  scope.fuel_amount_bio_oct = parseFloat(result.fuel_amount_bio_oct)
                  scope.fuel_amount_bio_nov = parseFloat(result.fuel_amount_bio_nov)
                  scope.fuel_amount_bio_dec = parseFloat(result.fuel_amount_bio_dec)
                  scope.fuel_amount_bio_yearly = parseFloat(result.fuel_amount_bio_yearly)
                  scope.fuel_amount_fossil_jan = parseFloat(result.fuel_amount_fossil_jan)
                  scope.fuel_amount_fossil_feb = parseFloat(result.fuel_amount_fossil_feb)
                  scope.fuel_amount_fossil_mar = parseFloat(result.fuel_amount_fossil_mar)
                  scope.fuel_amount_fossil_apr = parseFloat(result.fuel_amount_fossil_apr)
                  scope.fuel_amount_fossil_may = parseFloat(result.fuel_amount_fossil_may)
                  scope.fuel_amount_fossil_jun = parseFloat(result.fuel_amount_fossil_jun)
                  scope.fuel_amount_fossil_jul = parseFloat(result.fuel_amount_fossil_jul)
                  scope.fuel_amount_fossil_aug = parseFloat(result.fuel_amount_fossil_aug)
                  scope.fuel_amount_fossil_sep = parseFloat(result.fuel_amount_fossil_sep)
                  scope.fuel_amount_fossil_oct = parseFloat(result.fuel_amount_fossil_oct)
                  scope.fuel_amount_fossil_nov = parseFloat(result.fuel_amount_fossil_nov)
                  scope.fuel_amount_fossil_dec = parseFloat(result.fuel_amount_fossil_dec)
                  scope.average_consumption_fuel = parseFloat(result.average_consumption_fuel)
                  scope.fuel_amount_fossil_yearly = parseFloat(result.fuel_amount_fossil_yearly)
  
                  itens.push(scope)
                }
              }else{
                errors.push({id: i, label: carFleet})
              }
            }else{
              errors.push({id: i, label: carFleet})
            }
          }
        }
        
        setScopeRoad(`import_scope_data_${data?.length}`);
  
        setRoadDistanceValues(itens)
        calculateRoadDistance(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }


  const handleImportCarFleet = (type?:number, data?:any[]) => {
    async function importData() {

      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = roadYear

        for (let i = 3; i < data?.length; i++) {
          const carFleet = data[i][2]
  
          if (carFleet){
            let obj:any = undefined
  
            for (const t of carFleets) {
              if (t.label === carFleet){
                obj = t
                break;
              }
            }
  
            if (obj){
              const carObj = await carFleetService.getById(obj.value)
                      
              if (carObj) {
                const fuel = carObj.fuel
                const bioFuel = carObj.bioFuel

                const source = data[i][0]
                const description = data[i][1]
                const year = data[i][3]

                const consumption_jan = data[i][4] ? data[i][4] : 0
                const consumption_feb = data[i][5] ? data[i][5] : 0
                const consumption_mar = data[i][6] ? data[i][6] : 0
                const consumption_apr = data[i][7] ? data[i][7] : 0
                const consumption_may = data[i][8] ? data[i][8] : 0
                const consumption_jun = data[i][9] ? data[i][9] : 0
                const consumption_jul = data[i][10] ? data[i][10] : 0
                const consumption_aug = data[i][11] ? data[i][11] : 0
                const consumption_sep = data[i][12] ? data[i][12] : 0
                const consumption_oct = data[i][13] ? data[i][13] : 0
                const consumption_nov = data[i][14] ? data[i][14] : 0
                const consumption_dec = data[i][15] ? data[i][15] : 0
                const consumption_yearly = data[i][16] ? data[i][16] : 0
                
                const scope = { source: source, description: description, car_fleet_id: carObj.id, car_fleet_name: obj.label, 
                  year: year, fuel_id: fuel ? fuel.id : undefined, fuel_name: fuel ? fuel.name : undefined, 
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined, bio_fuel_name: bioFuel ? bioFuel.name : undefined, 
                  emission_ch4: 0, emission_n2o: 0, emission_tco2e: 0, consumption_jan: consumption_jan,
                  consumption_feb: consumption_feb, consumption_mar: consumption_mar, consumption_apr: consumption_apr,
                  consumption_may: consumption_may, consumption_jun: consumption_jun, consumption_jul: consumption_jul,
                  consumption_aug: consumption_aug, consumption_sep: consumption_sep, consumption_oct: consumption_oct,
                  consumption_nov: consumption_nov, consumption_dec: consumption_dec, factor_kg_ch4_l: 0,
                  factor_kg_n2o_l: 0, consumption_yearly: consumption_yearly, emission_bio_co2_t: 0,
                  factor_bio_kg_co2_l: 0, consumption_bio_jan: 0, consumption_bio_feb: 0, consumption_bio_mar: 0,
                  consumption_bio_apr: 0, consumption_bio_may: 0, consumption_bio_jun: 0, consumption_bio_jul: 0,
                  consumption_bio_aug: 0, consumption_bio_sep: 0, consumption_bio_oct: 0, consumption_bio_nov: 0,
                  consumption_bio_dec: 0, emission_fossil_co2_t: 0, consumption_bio_yearly: 0, factor_fossil_kg_co2_l: 0,
                  consumption_fossil_jan: 0, consumption_fossil_feb: 0, consumption_fossil_mar: 0, consumption_fossil_apr: 0,
                  consumption_fossil_may: 0, consumption_fossil_jun: 0, consumption_fossil_jul: 0, consumption_fossil_aug: 0,
                  consumption_fossil_sep: 0, consumption_fossil_oct: 0, consumption_fossil_nov: 0, consumption_fossil_dec: 0,
                  consumption_fossil_yearly: 0, only_view_yearly: false, only_view_monthly: false
                }

                const result = await scope3Service.calculateBusinessTripAutoRoad(
                  scope.car_fleet_id, scope.year, scope.consumption_jan, scope.consumption_feb, scope.consumption_mar,
                  scope.consumption_apr, scope.consumption_may, scope.consumption_jun, scope.consumption_jul, scope.consumption_aug,
                  scope.consumption_sep, scope.consumption_oct, scope.consumption_nov, scope.consumption_dec, scope.consumption_yearly
                )

                const onlyViewYearly = scope.consumption_jan > 0 || scope.consumption_feb > 0 || scope.consumption_mar > 0 || 
                  scope.consumption_apr > 0 || scope.consumption_may > 0 || scope.consumption_jun > 0 || 
                  scope.consumption_jul > 0 || scope.consumption_aug > 0 || scope.consumption_sep > 0 ||
                  scope.consumption_oct > 0 || scope.consumption_nov > 0 || scope.consumption_dec > 0
            
                const onlyViewMonthly = scope.consumption_yearly > 0
            
                scope.consumption_yearly = onlyViewYearly ? 0 : scope.consumption_yearly
          
                if (result) {
                  scope.only_view_yearly = onlyViewYearly
                  scope.only_view_monthly = onlyViewMonthly
          
                  scope.emission_ch4 = result.emission_ch4
                  scope.emission_n2o = result.emission_n2o
                  scope.emission_tco2e = result.emission_tco2e
                  scope.factor_kg_ch4_l = result.factor_kg_ch4_l
                  scope.factor_kg_n2o_l = result.factor_kg_n2o_l
                  scope.consumption_yearly = result.consumption_yearly
                  scope.emission_bio_co2_t = result.emission_bio_co2_t
                  scope.factor_bio_kg_co2_l = result.factor_bio_kg_co2_l
                  scope.emission_fossil_co2_t = result.emission_fossil_co2_t
                  scope.factor_fossil_kg_co2_l = result.factor_fossil_kg_co2_l
                  scope.consumption_bio_jan = result.consumption_bio_jan
                  scope.consumption_bio_feb = result.consumption_bio_feb
                  scope.consumption_bio_mar = result.consumption_bio_mar
                  scope.consumption_bio_apr = result.consumption_bio_apr
                  scope.consumption_bio_may = result.consumption_bio_may
                  scope.consumption_bio_jun = result.consumption_bio_jun
                  scope.consumption_bio_jul = result.consumption_bio_jul
                  scope.consumption_bio_aug = result.consumption_bio_aug
                  scope.consumption_bio_sep = result.consumption_bio_sep
                  scope.consumption_bio_oct = result.consumption_bio_oct
                  scope.consumption_bio_nov = result.consumption_bio_nov
                  scope.consumption_bio_dec = result.consumption_bio_dec
                  scope.consumption_bio_yearly = result.consumption_bio_yearly
                  scope.consumption_fossil_jan = result.consumption_fossil_jan
                  scope.consumption_fossil_feb = result.consumption_fossil_feb
                  scope.consumption_fossil_mar = result.consumption_fossil_mar
                  scope.consumption_fossil_apr = result.consumption_fossil_apr
                  scope.consumption_fossil_may = result.consumption_fossil_may
                  scope.consumption_fossil_jun = result.consumption_fossil_jun
                  scope.consumption_fossil_jul = result.consumption_fossil_jul
                  scope.consumption_fossil_aug = result.consumption_fossil_aug
                  scope.consumption_fossil_sep = result.consumption_fossil_sep
                  scope.consumption_fossil_oct = result.consumption_fossil_oct
                  scope.consumption_fossil_nov = result.consumption_fossil_nov
                  scope.consumption_fossil_dec = result.consumption_fossil_dec
                  scope.consumption_fossil_yearly = result.consumption_fossil_yearly
  
                  itens.push(scope)
                }
              }else{
                errors.push({id: i, label: carFleet})
              }
            }else{
              errors.push({id: i, label: carFleet})
            }
          }
        }
        
        setScopeRoad(`import_scope_data_${data?.length}`);
  
        setRoadYearValues(itens)
        calculateRoadYear(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}


        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >  
          {/* IGNOREFILL REFERENTE AO CALCULO OPÇÃO 01 */}
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 0,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    Opção 01 - Cálculo de emissões por tipo e ano de fabricação da frota
                    de veículos no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de combustível ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && carFleets && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="car_freet_road_year"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('car_freet_road_year')}
                          options={carFleets}
                          onChange={handleChangeCarFleetYear}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalAutoFleet()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 20,
                  width: 'calc(100vw - 300px)',
                  maxHeight: 'calc(100vh - 360px)'
                }}
              >
                {scopeRoad && roadYear && roadYear.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 400 }} colSpan={2}>{'Frota'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Tipo da frota de veículos'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Ano da frota'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>{'Fator de emissão (kg CO₂ / litro)'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>{'Fatores de emissão do combustível comercial'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={13}>{'Consumo mensal de combustível'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Emissões de CO₂ fóssil (t)'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Emissões de CH₄ (t)'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Emissões de N₂O (t)'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Emissões totais (t CO₂e)'}</th>
                        <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>{'Emissões de CO₂ biogênico (t)'}</th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Registro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Descrição'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>{'Comb. fóssil'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>{'Biocombustível'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>{'Kg CH₄ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>{'Kg N₂O / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Janeiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Fevereiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Março'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Abril'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Maio'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Junho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Julho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Agosto'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Setembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Outubro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Novembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 100 }}>{'Dezembro'}</th>
                        <th className="head-tr-item" style={{ width: 250 }}>{'Consumo anual'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roadYear.map((item: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td rowSpan={3}>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadYear.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadYear.${index}.source`,
                                      event.target.value
                                    )
                                    roadYear[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td rowSpan={3}>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadYear.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadYear.${index}.description`,
                                      event.target.value
                                    )
                                    roadYear[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 300 }} rowSpan={3}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {roadYear[index].car_fleet_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Combustível fóssil: '}</b>
                                      {roadYear[index].fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Biocombustível: '}</b>
                                      {roadYear[index].bio_fuel_name}
                                    </span>
                                  </div>

                                  {roadYear[index].car_fleet_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt = "informations" />
                                      <span className="tooltiptext">
                                        {roadYear[index].car_fleet_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td rowSpan={3}>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`roadYear.${index}.year`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const value = toNumberValues(currency(event))

                                    roadYear[index].year = value

                                    setValue(`roadYear.${index}.year`, value.toString())

                                    handleChangeYear(index, 'year')
                                  }}
                                />
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadYear[index].factor_fossil_kg_co2_l,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadYear[index].factor_bio_kg_co2_l,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadYear[index].factor_kg_ch4_l, false)}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadYear[index].factor_kg_n2o_l, false)}
                                </span>
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_jan`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_jan = value

                                      setValue(
                                        `roadYear.${index}.consumption_jan`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_jan')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_feb`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_feb = value

                                      setValue(
                                        `roadYear.${index}.consumption_feb`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_feb')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_mar`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_mar = value

                                      setValue(
                                        `roadYear.${index}.consumption_mar`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_mar')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_apr`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_apr = value

                                      setValue(
                                        `roadYear.${index}.consumption_apr`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_apr')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_may`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_may = value

                                      setValue(
                                        `roadYear.${index}.consumption_may`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_may')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_jun`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_jun = value

                                      setValue(
                                        `roadYear.${index}.consumption_jun`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_jun')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_jul`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_jul = value

                                      setValue(
                                        `roadYear.${index}.consumption_jul`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_jul')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_aug`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_aug = value

                                      setValue(
                                        `roadYear.${index}.consumption_aug`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_aug')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_sep`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_sep = value

                                      setValue(
                                        `roadYear.${index}.consumption_sep`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_sep')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_oct`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_oct = value

                                      setValue(
                                        `roadYear.${index}.consumption_oct`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_oct')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_nov`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_nov = value

                                      setValue(
                                        `roadYear.${index}.consumption_nov`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_nov')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_monthly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_dec`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_dec = value

                                      setValue(
                                        `roadYear.${index}.consumption_dec`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_dec')
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                {roadYear[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadYear.${index}.consumption_yearly`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      roadYear[index].consumption_yearly = value

                                      setValue(
                                        `roadYear.${index}.consumption_yearly`,
                                        formatValue
                                      )

                                      handleChangeYear(index, 'consumption_yearly')
                                    }}
                                  />
                                )}
                              </td>
                              <td style={{ width: 100 }} rowSpan={3}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadYear[index].emission_fossil_co2_t,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }} rowSpan={3}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadYear[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ width: 100 }} rowSpan={3}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadYear[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ width: 100 }} rowSpan={3}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadYear[index].emission_tco2e, false)}
                                </span>
                              </td>
                              <td style={{ width: 100 }} rowSpan={3}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadYear[index].emission_bio_co2_t,
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={3}>
                                  <div
                                    onClick={() => handleDeleteYear(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt= "lixeira" />
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                style={{ width: 200 }}
                                colSpan={4}
                                className="subhead-item"
                              >
                                {'Quantidade de combustível fóssil (litros ou m³): '}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_fossil_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr
                              style={{
                                borderBottomColor: '#ffffff',
                                borderBottomWidth: '5px'
                              }}
                            >
                              <td
                                style={{ width: 200 }}
                                colSpan={4}
                                className="subhead-item"
                              >
                                {'Quantidade de biocombustível (litros): '}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_jan,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_feb,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_mar,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_apr,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_may,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_jun,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_jul,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_aug,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_sep,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_oct,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_nov,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_monthly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_dec,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ width: 100 }}>
                                {roadYear[index].only_view_yearly === false && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadYear[index].consumption_bio_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={21}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Road, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4Road, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oRoad, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eRoad, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 100,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioRoad, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {roadYear && roadYear.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.roadYear && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* IGNOREFILL REFERENTE AO CALCULO OPÇÃO 02 */}
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    Opção 02 - Cálculo de emissões por tipo de combustível no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de combustível ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && fuels && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="fuel_private_vehicle"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('fuel_private_vehicle')}
                          options={fuels}
                          onChange={handleChangeCarFleetFuel}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalAutoCombus()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeRoadFuel && roadFuel && roadFuel.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }} colSpan={2}>{'Frota'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Tipo de combustível'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={12}>{'Consumo mensal de combustível'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Consumo anual'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>{'Fatores de Emissão do combustível fóssil'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>{'Fatores de Emissão do biocombustível'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CO₂ fóssil (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CH₄ (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de N₂O (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões totais em CO₂e (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CO₂ biogênico (t CO₂)'}</th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Registro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Descrição'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Janeiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Fevereiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Março'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Abril'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Maio'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Junho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Julho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Agosto'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Setembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Outubro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Novembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Dezembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CO₂ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CH₄ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg N₂O / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CO₂ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CH₄ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg N₂O / litro'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roadFuel.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadFuel.${index}.source`,
                                      event.target.value
                                    )
                                    roadFuel[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadFuel.${index}.description`,
                                      event.target.value
                                    )
                                    roadFuel[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {roadFuel[index].vehicle_fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Combustível fóssil: '}</b>
                                      {roadFuel[index].fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Biocombustível: '}</b>
                                      {roadFuel[index].bio_fuel_name}
                                    </span>
                                  </div>

                                  {roadFuel[index].vehicle_fuel_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="info" />
                                      <span className="tooltiptext">
                                        {roadFuel[index].car_fleet_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_jan`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_jan = value

                                    setValue(
                                      `roadFuel.${index}.consumption_jan`, formatValue )

                                    handleChangeFuel(index, 'consumption_jan')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_feb`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_feb = value

                                    setValue(`roadFuel.${index}.consumption_feb`, formatValue )

                                    handleChangeFuel(index, 'consumption_feb')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_mar`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_mar = value

                                    setValue(`roadFuel.${index}.consumption_mar`, formatValue )

                                    handleChangeFuel(index, 'consumption_mar')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_apr`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_apr = value

                                    setValue(`roadFuel.${index}.consumption_apr`, formatValue )

                                    handleChangeFuel(index, 'consumption_apr')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_may`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_may = value

                                    setValue(`roadFuel.${index}.consumption_may`, formatValue )

                                    handleChangeFuel(index, 'consumption_may')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_jun`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_jun = value

                                    setValue(`roadFuel.${index}.consumption_jun`, formatValue )

                                    handleChangeFuel(index, 'consumption_jun')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_jul`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_jul = value

                                    setValue(`roadFuel.${index}.consumption_jul`, formatValue )

                                    handleChangeFuel(index, 'consumption_jul')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_aug`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_aug = value

                                    setValue(`roadFuel.${index}.consumption_aug`, formatValue )

                                    handleChangeFuel(index, 'consumption_aug')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_sep`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_sep = value

                                    setValue(`roadFuel.${index}.consumption_sep`, formatValue )

                                    handleChangeFuel(index, 'consumption_sep')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_oct`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_oct = value

                                    setValue(`roadFuel.${index}.consumption_oct`, formatValue )

                                    handleChangeFuel(index, 'consumption_oct')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_nov`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_nov = value

                                    setValue(`roadFuel.${index}.consumption_nov`, formatValue )

                                    handleChangeFuel(index, 'consumption_nov')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadFuel.${index}.consumption_dec`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadFuel[index].consumption_dec = value

                                    setValue(`roadFuel.${index}.consumption_dec`, formatValue )

                                    handleChangeFuel(index, 'consumption_dec')
                                  }}
                                />
                              </td>
                              <td>
                                {roadFuel[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadFuel.${index}.consumption_yearly`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0
  
                                      roadFuel[index].consumption_yearly = value
  
                                      setValue(`roadFuel.${index}.consumption_yearly`, formatValue )
  
                                      handleChangeFuel(index, 'consumption_yearly')
                                    }}
                                  />
                                )}
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadFuel[index].consumption_yearly,
                                      false
                                    )}
                                  </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_co2_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_ch4_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].factor_n2o_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].emission_tco2e, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].emission_tco2e_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={3}>
                                  <div
                                    onClick={() => handleDeleteFuel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeira" />
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros, m³ ou kg)'}
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_jan, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_feb, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_mar, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_apr, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_may, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_jun, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_jul, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_aug, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_sep, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_oct, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_nov, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(roadFuel[index].fuel_amount_dec, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_yearly,
                                    false
                                  )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_jan,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_feb,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_mar,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_apr,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_may,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_jun,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_jul,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_aug,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_sep,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_oct,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_nov,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_dec,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadFuel[index].fuel_amount_bio_yearly,
                                    false
                                  )}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={22}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2RoadFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4RoadFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oRoadFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eRoadFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioRoadFuel, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {roadFuel && roadFuel.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar prosseguimento`
                    }
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.roadFuel && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* IGNOREFILL REFERENTE AO CALCULO OPÇÃO 03 */}
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    Opção 03 - Cálculo de emissões por distância percorrida no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && carFleets && (
                    <div
                      style={{
                        marginTop: 15,
                        marginRight: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                    <Controller
                      control={control}
                      name="car_freet_road_year_distance"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('car_freet_road_year_distance')}
                          options={carFleets}
                          onChange={handleChangeCarFleetDistance}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalAutoDistance()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeRoadDistance && roadDistance && roadDistance.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>{'Frota'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>{'Frota de veiculos'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={12}>{'Distância mensal percorrida (km)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Distância anual (km)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>{'Fator de Emissão (kg CO₂ / litro)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>{'Fatores de Emissão do combustível comercial'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CO₂ fóssil (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CH₄ (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de N₂O (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões totais em CO₂e (t)'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Emissões de CO₂ biogênico (t CO₂)'}</th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Registro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Descrição'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Tipo de frota'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Ano de frota'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Consumo médio sugerido'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Janeiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Fevereiro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Março'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Abril'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Maio'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Junho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Julho'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Agosto'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Setembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Outubro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Novembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Dezembro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Combustível fóssil'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Biocombustível'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CH₄ / litro'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg N₂O / litro'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roadDistance.map((distance: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadDistance.${index}.source`,
                                      event.target.value
                                    )
                                    roadDistance[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `roadDistance.${index}.description`,
                                      event.target.value
                                    )
                                    roadDistance[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {roadDistance[index].car_fleet_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Combustível fóssil: '}</b>
                                      {roadDistance[index].fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Biocombustível: '}</b>
                                      {roadDistance[index].bio_fuel_name}
                                    </span>
                                  </div>

                                  {roadDistance[index].car_fleet_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="info" />
                                      <span className="tooltiptext">
                                        {roadDistance[index].car_fleet_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.year`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const value = toNumberValues(currency(event))

                                    roadDistance[index].year = value


                                    setValue(`roadDistance.${index}.year`, value.toString())

                                    handleChangeDistance(index, 'year')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].average_consumption_fuel,
                                    false
                                  )}
                                </span>
                              </td>
                              <td>       
                              {roadDistance[index].only_view_monthly === false && (                     
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_jan`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_jan = value

                                    setValue(`roadDistance.${index}.distance_jan`, formatValue)

                                    handleChangeDistance(index, 'distance_jan')
                                  }}
                                />
                              )}
                              </td>
                              <td>
                              {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_feb`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_feb = value

                                    setValue(`roadDistance.${index}.distance_feb`, formatValue)

                                    handleChangeDistance(index, 'distance_feb')
                                  }}
                                />
                              )}
                              </td>
                              <td>
                              {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_mar`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_mar = value

                                    setValue(`roadDistance.${index}.distance_mar`, formatValue)

                                    handleChangeDistance(index, 'distance_mar')
                                  }}
                                />
                              )}
                              </td>
                              <td>
                              {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_apr`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_apr = value

                                    setValue(`roadDistance.${index}.distance_apr`, formatValue)

                                    handleChangeDistance(index, 'distance_apr')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_may`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_may = value

                                    setValue(`roadDistance.${index}.distance_may`, formatValue)

                                    handleChangeDistance(index, 'distance_may')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_jun`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_jun = value

                                    setValue(`roadDistance.${index}.distance_jun`, formatValue)

                                    handleChangeDistance(index, 'distance_jun')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_jul`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_jul = value

                                    setValue(`roadDistance.${index}.distance_jul`, formatValue)

                                    handleChangeDistance(index, 'distance_jul')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_aug`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_aug = value

                                    setValue(`roadDistance.${index}.distance_aug`, formatValue)

                                    handleChangeDistance(index, 'distance_aug')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_sep`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_sep = value

                                    setValue(`roadDistance.${index}.distance_sep`, formatValue)

                                    handleChangeDistance(index, 'distance_sep')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_oct`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_oct = value

                                    setValue(`roadDistance.${index}.distance_oct`, formatValue)

                                    handleChangeDistance(index, 'distance_oct')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_nov`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_nov = value

                                    setValue(`roadDistance.${index}.distance_nov`, formatValue)

                                    handleChangeDistance(index, 'distance_nov')
                                  }}
                                />
                                )}
                              </td>
                              <td>
                              {roadDistance[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`roadDistance.${index}.distance_dec`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0

                                    roadDistance[index].distance_dec = value

                                    setValue(`roadDistance.${index}.distance_dec`, formatValue)

                                    handleChangeDistance(index, 'distance_dec')
                                  }}
                                />
                              )}
                              </td>
                              <td>
                                {roadDistance[index].only_view_yearly === false && (
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`roadDistance.${index}.distance_yearly`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event);
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0
  
                                      roadDistance[index].distance_yearly = value
  
                                      setValue(`roadDistance.${index}.distance_yearly`, formatValue)
  
                                      handleChangeDistance(index, 'distance_yearly')
                                    }}
                                  />
                                )}
                                {roadDistance[index].only_view_yearly === true && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 100,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice3(
                                      roadDistance[index].distance_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].factor_co2_kg_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(roadDistance[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].emission_tco2e,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={4}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].emission_tco2e_bio,
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={4}>
                                  <div
                                    onClick={() => handleDeleteDistance(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível consumido'}
                              </td>
                              <td style={{ width: 100 }}>
                                

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_jan,
                                    false
                                  )}
                                </span>
                                 
                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_feb,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_mar,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_apr,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_may,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_jun,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_jul,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_aug,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_sep,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_oct,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_nov,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_dec,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_yearly,
                                    false
                                  )}
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros ou m³)'}
                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_jan,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_feb,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_mar,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_apr,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_may,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_jun,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_jul,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_aug,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_sep,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_oct,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_nov,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_dec,
                                    false
                                  )}
                                </span>

                              </td>
                              <td style={{ width: 100 }}>

                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_fossil_yearly,
                                    false
                                  )}
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={5}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_jan,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_feb,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_mar,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_apr,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_may,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_jun,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_jul,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_aug,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_sep,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_oct,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_nov,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_dec,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice3(
                                    roadDistance[index].fuel_amount_bio_yearly,
                                    false
                                  )}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={22}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2RoadDistance, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4RoadDistance, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oRoadDistance, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eRoadDistance, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioRoadDistance, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {roadDistance && roadDistance.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.roadDistance && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eTotal, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}}>
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eBioTotal, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}

      {OpenModalAutoFleet && (
        <ModalImportData
          entityType={1}
          isOpen={OpenModalAutoFleet}
          handleModalVisible={handleOpenModalAutoFleet}
          entityName="Cálculo de emissões por tipo e ano de fabricação da frota de veículos"
          templateFileName="template_business_Road1.xlsx"
          handleImportData={handleImportCarFleet}
        />
      )}

      {OpenModalAutoCombus && (
        <ModalImportData
          entityType={2}
          isOpen={OpenModalAutoCombus}
          handleModalVisible={handleOpenModalAutoCombus}
          entityName="Cálculo de emissões por tipo de combustível no ano"
          templateFileName="template_business_Road2.xlsx"
          handleImportData={handleImportFuel}
        />
      )}

      {openModalImportDistance && (
        <ModalImportData
          entityType={3}
          isOpen={openModalImportDistance}
          handleModalVisible={handleOpenModalAutoDistance}
          entityName="Cálculo de emissões por distância percorrida no ano"
          templateFileName="template_business_Road3.xlsx"
          handleImportData={handleImportDistance}
        />
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
