import React, { useEffect, useState } from 'react'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import useAuth from '../../../../../hooks/useAuth'
import inventoryService from '../../../../../services/inventoryService'
import { Container } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from '../../../../../components/Button/styles'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import Select from 'react-select'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import sectorService from '../../../../../services/sectorService'
import { formatPrice3, formatPrice5} from '../../../../../utils/transform'
import scope1Service from '../../../../../services/scope1Service'
import { Modal } from '../../../../../components/Modal'
import Parser from 'html-react-parser'
import { DescriptionText } from '../../../../../components/DescriptionText'
import scopeService from '../../../../../services/scopeService'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { Uninformed } from '../../../../../components/Uninformed'
import fuelService from '../../../../../services/fuelService'
import { toast } from 'react-hot-toast'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { Loading } from '../../../../../components/Loading'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'

export const StationaryFountains = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [fuelType, setFuelTyoe] = useState<any>()
  const [openModal, setOpenModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false);
  const [openModalFuel, setOpenModalFuel] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  const [selectedYear, setSelectedYear] = useState<any>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [sectorInfo, setSectorInfo] = useState<string | undefined>()
  const location = useLocation()
  const [scopes, setScopes] = useState<any>([])
  const [fuels, setFuels] = useState<any>([])
  const [sectors, setSectors] = useState<any>([])
  const [fuelSectors, setFuelSectors] = useState<any>([])
  const [sectorSelected, setSectorSelected] = useState<any>({})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [changeScope, setChangeScope] = useState<string | undefined>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorCo2, setFactorCo2] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorCh4, setFactorCh4] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorN2o, setFactorN2o] = useState<any>()
  const [emissionCo2, setEmissionCo2] = useState<any>()
  const [emissionCh4, setEmissionCh4] = useState<any>()
  const [emissionN2o, setEmissionN2o] = useState<any>()
  const [emissionTCo2e, setEmissionTCo2e] = useState<any>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorCo2Bio, setFactorCo2Bio] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorCh4Bio, setFactorCh4Bio] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [factorN2oBio, setFactorN2oBio] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()
  const [emissionCh4Bio, setEmissionCh4Bio] = useState<any>()
  const [emissionN2oBio, setEmissionN2oBio] = useState<any>()
  const [emissionTCo2eBio, setEmissionTCo2eBio] = useState<any>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

   const getYearId = (year: number): number | null => {
    const yearMap: { [key: number]: number } = {
      2024: 2024,
      2023: 2023,
      2022: 2022,
      2021: 2021,
      2020: 2020,
      2019: 2019,
    };
    return yearMap[year] || null;
  };

  const filteredFuels = fuels?.filter((fuel: any) => fuel.year_id === getYearId(selectedYear)) || [];

  useEffect(() => {
    if (inventorySelected.year) {
      setSelectedYear(inventorySelected.year); 
    }
  }, [inventorySelected.year]);

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'combustao_estacionaria', 'fontes_estacionarias')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itens: any[] = scopes.map((scope: any, index: number) => {
        let amount = 0

        try {
          const value = scope.amount.replace('.', '')
          amount = value ? parseFloat(value.replace(',', '.')) : 0
        } catch {
          amount = scope.amount
        }

        return {
          amount: amount,
          source: scope.source,
          amount_bio: scope.amount_bio,
          amount_fossil: scope.amount_fossil,
          factor_co2_bio: scope.factor_co2_bio,
          factor_ch4_bio: scope.factor_ch4_bio,
          factor_n2o_bio: scope.factor_n2o_bio,
          vehicle_fuel_id: scope.vehicle_fuel_id,
          emission_co2_bio: scope.emission_co2_bio,
          emission_ch4_bio: scope.emission_ch4_bio,
          emission_n2o_bio: scope.emission_n2o_bio,
          factor_co2_fossil: scope.factor_co2_fossil,
          factor_ch4_fossil: scope.factor_ch4_fossil,
          factor_n2o_fossil: scope.factor_n2o_fossil,
          emission_tco2e_bio: scope.emission_tco2e_bio,
          emission_co2_fossil: scope.emission_co2_fossil,
          emission_ch4_fossil: scope.emission_ch4_fossil,
          emission_n2o_fossil: scope.emission_n2o_fossil,
          emission_tco2e_fossil: scope.emission_tco2e_fossil,
          description: scope.description
        }
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        sector_id: sectorSelected.value,
        itens: itens
      }

      const result = await scope1Service.saveStationaryCombustionFountains(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('StationaryCombustionFountains', JSON.stringify(scope));

        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)
  
    const sectorsItens = await sectorService.getByParams({ limit: 9000, page: 1 })
  
    const sectors = sectorsItens?.itens.map((sector: any) => {
      return {
        label: sector.name,
        value: sector.id
      }
    })
  
    if (sectors.length > 0) {
      setSectorSelected(sectors[0])
      setValue('sectors', [sectors[0]])
      setSectorInfo(sectorsItens?.itens[0].description)
    }
  
    const fuelsItens = await vehicleFuelService.getStationaryCombustion()

    const fuels = fuelsItens?.itens.map((fuel: any) => {
      return {
        label: fuel.name,
          value: fuel.id,
          year_id: fuel.year_id
        }
      })
  
    setFuels(fuels)
  
    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getStationaryCombustionFountains(company_id, year)
  
    const result = await inventoryService.getFiles(inventory.company.id, inventory.year, 1, 
      'combustao_estacionaria', 'fontes_estacionarias' )
  
    setFiles(result.files)
  
    if (entity) {
      if (entity.sector) {
        const item = { value: entity.sector.id, label: entity.sector.name }
  
        setSectorSelected(item)
        setValue('sectors', [item])
        setSectorInfo(entity.sector.description)
      }
  
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)
  
      setScopeSelected(entity)
  
      setScopeItens(entity?.itens)
      calculateTotals(entity?.itens)
  
      setIgnoreFill(entity.ignore_fill)
  
      setChangeScope('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
    } else {
      setScopeSelected({})
  
      setScopeItens([])
  
      setIgnoreFill(false)
  
      setChangeScope('load_scope_0_scopes_0')
    }
  
    setSectors(sectors)
  
    setInventorySelected(inventory)
    setLoadingModal(false)
  } 
  
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{3})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  function moeda(a: string, e: string, r: string): string {
    // Remove caracteres não numéricos
    let n = a.replace(/[^\d]+/g, '');

    if (n.length === 0) return '0' + r + '0000';

    while (n.length < 5) {  
        n = '0' + n;
    }

    const len = n.length;
    const intPart = n.substring(0, len - 4);
    const decPart = n.substring(len - 4);

    // Remove zeros desnecessários da parte inteira
    const cleanedIntPart = intPart.replace(/^0+/, '') || '0';

    let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
    formattedValue += r + decPart;

    return formattedValue;
}
  
  const handleChangeSector = (e: any) => {
    async function retrieveSectorById(id: any) {
      const sector = await sectorService.getById(id)

      setSectorInfo(sector.description)
      setValue('sectors', [e])
      setSectorSelected(e)
    }

    if (e) {
      retrieveSectorById(e.value)
    }
  }

  const handleChangeFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_year_id: result.year_id,
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : '',
          fuel_unity: result.fuel ? result.fuel.unity : '',
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : '',
          bio_fuel_unity: result.bioFuel ? result.bioFuel.unity : '',
          fuel_composition: result.fuel ? result.fuel.composition : '',
          bio_fuel_composition: result.bioFuel ? result.bioFuel.composition : ''
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setChangeScope('new_scope_item_' + index)

        setScopeItens(itens)
        calculateTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuels', [])
  }

  const setScopeItens = (scopes: any, ignore_field?: string) => {
    let changeScore = 'change_scope_item_'

    if (scopes) {
      for (let i = 0; i < scopes?.length; i++) {
        const item = scopes[i]

        if (item) {
          setValue(`fuel.${i}.source`, item.source)
          setValue(`fuel.${i}.description`, item.description)

          setValue(`fuel.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
          setValue(`fuel.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

          setValue(`fuel.${i}.fuel_unity`, item.fuel_unity)
          setValue(`fuel.${i}.bio_fuel_unity`, item.bio_fuel_unity)
          setValue(`fuel.${i}.fuel_composition`, item.fuel_composition)
          setValue(`fuel.${i}.bio_fuel_composition`, item.bio_fuel_composition)

          if ('amount' !== ignore_field) {
            setValue(`fuel.${i}.amount`, formatPrice3(item.amount, false))
          }

          if ('factor_co2' !== ignore_field) {
            setValue(`fuel.${i}.factor_co2`, formatPrice3(item.factor_co2, false))
          }

          if ('factor_ch4' !== ignore_field) {
            setValue(`fuel.${i}.factor_ch4`, formatPrice3(item.factor_ch4, false))
          }

          if ('factor_n2o' !== ignore_field) {
            setValue(`fuel.${i}.factor_n2o`, formatPrice3(item.factor_n2o, false))
          }

          if ('emission_co2' !== ignore_field) {
            setValue(`fuel.${i}.emission_co2`, formatPrice3(item.emission_co2, false))
          }

          if ('emission_ch4' !== ignore_field) {
            setValue(`fuel.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))
          }

          if ('emission_n2o' !== ignore_field) {
            setValue(`fuel.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))
          }

          if ('emission_tco2e' !== ignore_field) {
            setValue(`fuel.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))
          }

          if ('factor_co2_bio' !== ignore_field) {
            setValue(`fuel.${i}.factor_co2_bio`, formatPrice3(item.factor_co2_bio, false))
          }

          if ('factor_ch4_bio' !== ignore_field) {
            setValue(`fuel.${i}.factor_ch4_bio`, formatPrice3(item.factor_ch4_bio, false))
          }

          if ('factor_n2o_bio' !== ignore_field) {
            setValue(`fuel.${i}.factor_n2o_bio`, formatPrice3(item.factor_n2o_bio, false))
          }

          if ('emission_co2_bio' !== ignore_field) {
            setValue(`fuel.${i}.emission_co2_bio`, formatPrice3(item.emission_co2_bio, false))
          }

          if ('emission_ch4_bio' !== ignore_field) {
            setValue(`fuel.${i}.emission_ch4_bio`, formatPrice3(item.emission_ch4_bio, false))
          }

          if ('emission_n2o_bio' !== ignore_field) {
            setValue(`fuel.${i}.emission_n2o_bio`, formatPrice3(item.emission_n2o_bio, false))
          }

          if ('emission_tco2e_bio' !== ignore_field) {
            setValue(`fuel.${i}.emission_tco2e_bio`, formatPrice3(item.emission_tco2e_bio, false))
          }

          changeScore = changeScore + '_' + item.fuel_id + '_' + item.amount
        }
      }
    }

    setScopes(scopes)
    setChangeScope(changeScore)
  }

  function handleDeleteScope(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setChangeScope('remove_scope_item_' + index)

    setValue('fuels', [])

    setScopeItens(itens)
    calculateTotals(itens)
  }

  const calculateTotals = (scopes: any) => {
    let _emissionCo2 = 0
    let _emissionCh4 = 0
    let _emissionN2o = 0
    let _emissionTCo2e = 0

    let _emissionCo2Bio = 0
    let _emissionCh4Bio = 0
    let _emissionN2oBio = 0
    let _emissionTCo2eBio = 0

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.emission_co2_fossil) {
            _emissionCo2 = _emissionCo2 + item.emission_co2_fossil
          }

          if (item.emission_co2_bio) {
            _emissionCo2Bio = _emissionCo2Bio + item.emission_co2_bio
          }

          if (item.emission_ch4_fossil) {
            _emissionCh4 = _emissionCh4 + item.emission_ch4_fossil
          }

          if (item.emission_ch4_bio) {
            _emissionCh4Bio = _emissionCh4Bio + item.emission_ch4_bio
          }

          if (item.emission_n2o_fossil) {
            _emissionN2o = _emissionN2o + item.emission_n2o_fossil
          }

          if (item.emission_n2o_bio) {
            _emissionN2oBio = _emissionN2oBio + item.emission_n2o_bio
          }

          if (item.emission_tco2e_fossil) {
            _emissionTCo2e = _emissionTCo2e + item.emission_tco2e_fossil
          }

          if (item.emission_tco2e_bio) {
            _emissionTCo2eBio = _emissionTCo2eBio + item.emission_tco2e_bio
          }
        }
      }
    }

    setEmissionCo2(_emissionCo2)
    setEmissionCh4(_emissionCh4)
    setEmissionN2o(_emissionN2o)
    setEmissionTCo2e(_emissionTCo2e)

    setEmissionCo2Bio(_emissionCo2Bio)
    setEmissionCh4Bio(_emissionCh4Bio)
    setEmissionN2oBio(_emissionN2oBio)
    setEmissionTCo2eBio(_emissionTCo2eBio)
  }

  const handleOpenFuelModal = (type?: number) => {
    async function loadData() {
      const itens = await fuelService.getBySector(
        Number(sectorSelected.value),
        Number(type)
      )
  
      if (itens) {
        for (const i of itens) {
          let amount = 0
  
          for (const s of scopes) {
            if (type === 1 && s.fuel_id === i.id) {
              amount = amount + parseFloat(s.amount_fossil)
            }
            
            if (type === 2 && s.bio_fuel_id === i.id) {
              amount = amount + parseFloat(s.amount_bio)
            }
          }
  
          i.amount = amount
        }
  
        // Filtrando os itens duplicados
        const uniqueItens = Array.from(new Map(itens.map((item: any) => [item.name, item])).values());
  
        setFuelSectors(uniqueItens)
      }
    }
  
    if (type) {
      loadData()
    }
  
    setOpenModalFuel(!openModalFuel)
    setFuelTyoe(type)
  }  

  const handleChangeAmount = (index: number, ignore_field?: string) => {
    const amount = scopes[index].amount ? parseFloat(scopes[index].amount) : 0

    const sectorId = sectorSelected.value
    const companyId = inventorySelected.company.id
    const vehicleFuelId = scopes[index].vehicle_fuel_id

    async function calculate() {
      const result = await scope1Service.calculateStationaryCombustion(
        sectorId,
        vehicleFuelId,
        amount,
        inventorySelected.year,
        companyId
      )

      if (result) {
        scopes[index].amount_bio = result.amount_bio
        scopes[index].factor_co2_bio = result.factor_co2_bio
        scopes[index].factor_ch4_bio = result.factor_ch4_bio
        scopes[index].factor_n2o_bio = result.factor_n2o_bio
        scopes[index].emission_co2_bio = result.emission_co2_bio
        scopes[index].emission_ch4_bio = result.emission_ch4_bio
        scopes[index].emission_n2o_bio = result.emission_n2o_bio
        scopes[index].emission_tco2e_bio = result.emission_tco2e_bio

        scopes[index].amount_fossil = result.amount_fossil
        scopes[index].factor_co2_fossil = result.factor_co2_fossil
        scopes[index].factor_ch4_fossil = result.factor_ch4_fossil
        scopes[index].factor_n2o_fossil = result.factor_n2o_fossil
        scopes[index].emission_co2_fossil = result.emission_co2_fossil
        scopes[index].emission_ch4_fossil = result.emission_ch4_fossil
        scopes[index].emission_n2o_fossil = result.emission_n2o_fossil
        scopes[index].emission_tco2e_fossil = result.emission_tco2e_fossil

        setChangeScope('change_scope_item_' + index + '_' + amount)

        setScopeItens(scopes, ignore_field)
        calculateTotals(scopes)
      }
    }

    if (amount) {
      calculate()
    } else {
      scopes[index].factor_co2_bio = undefined
      scopes[index].factor_ch4_bio = undefined
      scopes[index].factor_n2o_bio = undefined
      scopes[index].emission_co2_bio = undefined
      scopes[index].emission_ch4_bio = undefined
      scopes[index].emission_n2o_bio = undefined
      scopes[index].factor_co2_fossil = undefined
      scopes[index].factor_ch4_fossil = undefined
      scopes[index].factor_n2o_fossil = undefined
      scopes[index].emission_tco2e_bio = undefined
      scopes[index].emission_co2_fossil = undefined
      scopes[index].emission_ch4_fossil = undefined
      scopes[index].emission_n2o_fossil = undefined
      scopes[index].emission_tco2e_fossil = undefined

      setChangeScope('change_scope_item_' + index + '_' + amount)

      setScopeItens(scopes)
      calculateTotals(scopes)
    }
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'combustao_estacionaria',
        file: base64,
        filename: filename,
        second_name: 'fontes_estacionarias'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_estacionaria', 
          'fontes_estacionarias'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_estacionaria',
          'fontes_estacionarias'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const formatNumber = (number: any, decimalPlaces: any) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
  }
  

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && sectors && scopes && scopes.length === 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
            <span
              style={{
                marginTop: 15,
                fontWeight: 'bold',
                fontSize: '16px',
                marginRight: 10
              }}
            >
              Setor:
            </span>

            <Controller
              control={control}
              name="sectors"
              render={({ field }) => (
                <Select placeholder="[Selecione]"
                  {...field}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      width: '350px',
                      height: 40,
                      marginTop: 5,
                      marginBottom: 10,
                      marginRight: 20,
                      backgroundColor: 'rgb(246, 246, 246)'
                    }),
                    option: (styles) => {
                      return { ...styles, color: '#31363F' }
                    }
                  }}
                  defaultValue={getValues('sectors')}
                  options={sectors}
                  onChange={handleChangeSector}
                />
              )}
            />

            {sectorSelected && sectorInfo && (
              <div className="tooltip">
                <img alt="infoButton" src={InfoButtonMin} style={{ paddingTop: 10 }} />
                <span className="tooltiptext">{sectorInfo}</span>
              </div>
            )}
          </div>
        )}

        {ignoreFill === false && scopes && scopes.length > 0 && sectorSelected && (
          <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
            <span
              style={{
                marginTop: 5,
                fontWeight: 'bold',
                fontSize: '16px',
                marginRight: 10
              }}
            >
              Setor:
            </span>
            <span style={{ marginTop: 5, fontSize: '16px', marginRight: 10 }}>
              {sectorSelected.label}
            </span>

            {sectorSelected && sectorInfo && (
              <div className="tooltip" style={{ margin: '5px -5px' }}>
                <img src={InfoButtonMin} alt="information"/>
                <span className="tooltiptext">{sectorInfo}</span>
              </div>
            )}
          </div>
        )}

        {ignoreFill === false && (
          <div
            style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
          />
        )}

        {ignoreFill === false && (
          <div
            style={{
              overflow: 'hidden auto',
              position: 'relative',
              marginTop: 0,
              height: 'calc(100vh - 280px)',
              maxHeight: 'calc(100vh - 280px)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginTop: 5
                  }}
                >
                  Fontes estacionárias de combustão
                </span>
                {!isCommonUser && (
                  <span style={{ textAlign: 'start', fontSize: 14 }}>
                    Selecione, ao lado, o combustível desejado para incluir abaixo
                  </span>
                )}
              </div>

              {!isCommonUser && selectedYear && filteredFuels.length > 0 && (
                <Controller
                  control={control}
                  name="fuels"
                  render={({ field }) => (
                    <Select placeholder="[Selecione]" 
                      {...field}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: '350px',
                          height: 40,
                          marginTop: 5,
                          marginRight: 20,
                          backgroundColor: 'rgb(246, 246, 246)'
                        }),
                        option: (styles) => {
                          return { ...styles, color: '#31363F' }
                        }
                      }}
                      defaultValue={getValues('fuels')}
                      options={filteredFuels}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption); 
                        handleChangeFuel(selectedOption); 
                      }}
                    />
                  )}
                />
              )}
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                marginBottom: 10,
                width: 'calc(100vw - 300px)',
                maxWidth: 'calc(100vw - 300px)'
              }}
            >
              {changeScope && scopes && scopes.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Combustível'}
                      </th>
                      <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>
                        {'Quantidade consumida'}
                      </th>
                      <th className="head-tr-item" style={{ width: 100 }} rowSpan={2}>
                        {'Unidades'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={2}>
                        {'O combustível utilizado é formado por'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={2}>
                        {'Quantidade consumida de combustível (por unidade)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={3}>
                        {'Fatores de emissão - combustível fóssil'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={3}>
                        {'Fatores de emissão - biocombustível'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={3}>
                        {'Combustíveis fósseis'}
                      </th>
                      <th className="head-tr-item" style={{ width: 250 }} colSpan={3}>
                        {'Biocombustíveis'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Emissões fósseis totais tCO₂e'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Emissões biogênicas tCO₂'}
                      </th>
                      <th className="head-tr-item" style={{ width: 50 }} rowSpan={2} />
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 150 }}>
                        {'Registro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 150 }}>
                        {'Descrição'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 120 }}>
                        {'Combustível fóssil'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 240 }}>
                        {'Biocombustível'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 120 }}>
                        {'Combustível fóssil'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 240 }}>
                        {'Biocombustível'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'CO₂ (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'CH₄ (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'N₂O (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'CO₂ (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'CH₄ (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'N₂O (kg/un)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões CO₂ (t)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões CH₄ (t)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões N₂O (t)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões CO₂ (t)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões CH₄ (t)'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 100 }}>
                        {'Emissões N₂O (t)'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopes.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150 }}
                                className="input-item"
                                {...register(`fuel.${index}.source`, { required: true })}
                                onChange={(event) => {
                                  setValue(`fuel.${index}.source`, event.target.value)
                                  scopes[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 150 }}
                                className="input-item"
                                {...register(`fuel.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `fuel.${index}.description`,
                                    event.target.value
                                  )
                                  scopes[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td style={{ width: 200 }}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {item.vehicle_fuel_name}
                                  </span>
                                </div>

                                {item.fuel_description && (
                                  <div className="tooltip">
                                    <img src={InfoButtonMin} alt="information"/>
                                    <span className="tooltiptext">
                                      {item.fuel_description}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                            <input
                                    style={{ width: 150, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`fuel.${index}.amount`, {
                                        required: true
                                    })}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const inputValue = currency(event);
                                        const formattedValue = moeda(inputValue, '.', ',');

                                        const valueStr = formattedValue.replace(/[.,]/g, '');
                                        const value = valueStr ? parseFloat(valueStr) / 10000 : 0;

                                        scopes[index].amount = value;

                                        setValue(`fuel.${index}.amount`, formattedValue);
                                        handleChangeAmount(index, 'amount');
                                        event.target.value = formattedValue;
                                    }}
                                />

                            </td>
                            <td>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  display: 'inline-block'
                                }}
                              >
                                {item.fuel_unity ? item.fuel_unity : item.bio_fuel_unity}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  display: 'inline-block'
                                }}
                              >
                                {item.fuel_composition}
                              </span>
                            </td>
                            <td>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  display: 'inline-block'
                                }}
                              >
                                {item.bio_fuel_composition}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 120, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_unity && item.amount_fossil ? formatPrice3((item.amount_fossil), false) + ' ' + item.fuel_unity : ''}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 120, fontSize: 12, textAlign: 'right', display: 'inline-block' }} > 
                                {item.bio_fuel_unity && item.amount_bio ? formatPrice3(Number(item.amount_bio), false) + ' ' + item.bio_fuel_unity : ''}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.factor_co2_fossil, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.factor_ch4_fossil, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.factor_n2o_fossil, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.factor_co2_bio, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.factor_ch4_bio, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.factor_n2o_bio, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5,  width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.emission_co2_fossil, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.emission_ch4_fossil, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.fuel_composition ? formatPrice3(item.emission_n2o_fossil, false) : '-'}
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <span style={{ marginRight: 5, width: '90%', fontSize: 12, textAlign: 'right', display: 'inline-block' }}  >
                                {item.bio_fuel_composition ? formatPrice3(item.emission_co2_bio, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.emission_ch4_bio, false) : '-'}
                              </span>
                            </td>
                            <td>
                              <span style={{ marginRight: 5, width: 100, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.emission_n2o_bio, false) : '-'}
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <span style={{ marginRight: 5, width: 200, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {(item.fuel_composition || item.bio_fuel_composition) ? formatPrice3(item.emission_tco2e_fossil, false) : '-'}
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <span style={{ marginRight: 5, width: 200, fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                                {item.bio_fuel_composition ? formatPrice3(item.emission_tco2e_bio, false) : '-'}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }}>
                                <div onClick={() => handleDeleteScope(index)} style={{ cursor: 'pointer', textAlign: 'center', width: 70 }} >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                      <td
                        colSpan={15}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: 'right' }}
                      >
                        {'Total'}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionCo2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionCh4, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionN2o, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionCo2Bio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionCh4Bio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionN2oBio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 200,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(formatNumber(parseFloat(emissionTCo2e), 5), false).toString()}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 200,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(emissionTCo2eBio, false)}
                        </span>
                      </td>
                      <td colSpan={5} className="head-tr-item" />
                    </tr>
                  </tfoot>
                </table>
              )}

              {!scopes.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum combustível cadastrado`
                      : `Nenhum combustível foi selecionado, favor selecionar para dar
                    prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.fuel && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da Fonte, Descrição da Fonte e Quantidade consumida são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            {changeScope && scopes && (
              <div style={{ marginTop: 20, marginLeft: 20, marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <span style={{ textAlign: 'start', fontSize: 16, fontWeight: 'bold', marginTop: 5 }} >
                      Fatores de emissão para combustão de fontes estacionária
                    </span>
                    <span style={{ textAlign: 'start', fontSize: 14 }}>
                      Clique ao lado para ver os detalhes por tipo de combustível
                    </span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'center' }} >
                    <button type="button" style={{ width: '180px', height: '47px', background: '#ffffff',
                        color: '#31363F', borderColor: '#9E9E9E', borderRadius: '2.5px', cursor: 'pointer',
                        borderStyle: 'dashed', fontWeight: 'bold', borderWidth: '1px', marginRight: 10 }}
                        onClick={() => handleOpenFuelModal(1)} >
                      {`Combustíveis fósseis`}
                    </button>

                    <button
                      type="button"
                      style={{
                        width: '180px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleOpenFuelModal(2)}
                    >
                      {`Biocombustíveis`}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {ignoreFill === false && changeScope && scopes && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15,
                  marginTop: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(formatNumber(parseFloat(emissionTCo2e), 5), false).toString()}
                </span>
              </div>
            )}

            {ignoreFill === false && changeScope && scopes && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTCo2eBio, false)}
                </span>
              </div>
            )}

            {!ignoreFill && (
              <div className="column" style={{ margin: 10 }}>
                <div
                  style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
                />

                <div
                  className="column"
                  style={{
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div className="column" style={{ margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={downloadFile}
                    onChangeAttachment={attachmentChangeHandler}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </form>

      <Modal
        isOpen={openModalFuel}
        handleModalVisible={() => handleOpenFuelModal()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText
            level={1}
            title={fuelType === 1 ? 'Combustíveis fósseis' : 'Biocombustíveis'}
          />
          <DescriptionText
            title={`Fatores de emissão para combustão de fontes estacionárias`}
          />

          <table
            style={{
              maxWidth: '100%',
              width: '450px',
              height: 'auto',
              marginTop: 10,
              borderCollapse: 'collapse'
            }}
            className="table-scope"
          >
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  rowSpan={2}
                >
                  {'Tipo de combustível'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  rowSpan={2}
                >
                  {'Unidade'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  rowSpan={2}
                >
                  {'Consumo de combustível'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                  colSpan={3}
                >
                  {'Fatores de emissão do setor'}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    backgroundColor: '#C6C5C5',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'CO₂ (kg/un)'}
                </th>
                <th
                  style={{
                    backgroundColor: '#C6C5C5',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'CH₄ (kg/un)'}
                </th>
                <th
                  style={{
                    backgroundColor: '#C6C5C5',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'N₂O (kg/un)'}
                </th>
              </tr>
            </thead>
            <tbody>
              {fuelSectors.map((fuel: any) => {
                return (
                  <React.Fragment key={fuel.id}>
                    <tr>
                      <td style={{ width: 500 }}>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 250,
                            fontSize: 12,
                            fontWeight: 'bold',
                            display: 'inline-block'
                          }}
                        >
                          {fuel.name}
                        </span>
                      </td>
                      <td style={{ width: 500 }}>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 250,
                            fontSize: 12,
                            fontWeight: 'bold',
                            display: 'inline-block'
                          }}
                        >
                          {fuel.unity}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(fuel.amount ? fuel.amount : 0, false)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(fuel.factor_co2, false)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(fuel.factor_ch4, false)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice3(fuel.factor_n2o, false)}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
