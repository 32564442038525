import Parser from 'html-react-parser'
import Select from 'react-select'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import { Container } from './styles'
import { formatInteger, formatPrice3, formatPrice5 } from '../../../../utils/transform'
import inventoryService from '../../../../services/inventoryService'
import gasService from '../../../../services/gasService'
import scope1Service from '../../../../services/scope1Service'
import scopeService from '../../../../services/scopeService'
import TrashIcon from '../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../assets/icons/info-button.svg';
import { TitleText } from '../../../../components/TitleText'
import { GoBack } from '../../../../components/GoBack'
import { Button } from '../../../../components/Button/styles'
import { Modal } from '../../../../components/Modal'
import { Uninformed } from '../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../components/ScopeEvidenceAttachments'
import ReportRecordsCheckbox from '../../components/ReportRecordsCheckbox'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../components/Loading'

export const IndustrialProcesses = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [gases, setGases] = useState<any>([])
  const [scopes, setScopes] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [emissionTco2e, setEmissionTco2e] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()
  const [removalOfBioCo2, setRemovalOfBioCo2] = useState<any>()
  const [changeScope, setChangeScope] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySlug(1, 'processos_industriais')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itens: any[] = scopes.map((item: any) => {
        return {
          gas_id: item.gas_id,
          source: item.source,
          description: item.description,
          emissions_t: item.emissions_t,
          emission_tco2e: item.emission_tco2e,
          emission_co2_bio: item.emission_co2_bio,
          removal_of_bio_co2: item.removal_of_bio_co2,
          source_description: item.source_description
        }
      })

      const scope = {
        reason: formData.reason,
        status: 2,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        itens: itens
      }

      const result = await scope1Service.saveIndustrialProcesses(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('IndustrialProcesses', JSON.stringify(scope));
        
        setErrorApi(undefined)
        
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const gasesItens = await gasService.getIndustrialProcesses()

    setGases(
      gasesItens?.itens.map((gas: any) => {
        return {
          label: gas.name,
          value: gas.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getIndustrialProcesses(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'processos_industriais'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setScopeSelected(entity)

      setScopeValues(entity?.itens)
      calculateTotals(entity?.itens)

      setIgnoreFill(entity.ignore_fill)

      setChangeScope('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
    } else {
      setScopeSelected({})

      setScopeValues([])
      calculateTotals([])

      setIgnoreFill(false)

      setChangeScope('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)

    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeGas = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await gasService.getById(id)

      if (result) {
        const item = {
          gas_id: result.id,
          gas_name: result.name,
          gas_gwp: result.gwp,
          source: undefined,
          new_charge_kg: undefined,
          new_capacity_kg: undefined,
          exists_charge_kg: undefined,
          dismiss_capacity_kg: undefined,
          dismiss_recover_kg: undefined,
          emission_tco2e: undefined,
          gas_description: result.description
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setChangeScope('new_scope_item_' + index)

        setScopeValues(itens)
        calculateTotals(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('gases_cycle', [])
  }

  const setScopeValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scope.${i}.source`, item.source)
        setValue(`scope.${i}.description`, item.description)
        setValue(`scope.${i}.source_description`, item.source_description)

        setValue(`scope.${i}.gas_id`, item.gas_id)
        setValue(`scope.${i}.gas_gwp`, item.gas_gwp)
        setValue(`scope.${i}.gas_name`, item.gas_name)

        if ('emissions_t' !== ignore_field) {
          setValue(`scope.${i}.emissions_t`, formatPrice5(item.emissions_t, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`scope.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('emission_co2_bio' !== ignore_field) {
          setValue(`scope.${i}.emission_co2_bio`, formatPrice5(item.emission_co2_bio, false))
        }

        if ('removal_of_bio_co2' !== ignore_field) {
          setValue(`scope.${i}.removal_of_bio_co2`, formatPrice5(item.removal_of_bio_co2, false))
        }

        changeScope = changeScope + '_' + item.gas_id + '_' + item.amount
      }
    }

    setScopes(itens)
    setChangeScope(changeScope)
  }

  const calculateTotals = (itens: any) => {
    let emissionTco2e = 0
    let emissionCo2Bio = 0
    let removalOfBioCo2 = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_tco2e) {
          emissionTco2e = emissionTco2e + item.emission_tco2e
        }
        if (item.emission_co2_bio) {
          emissionCo2Bio = emissionCo2Bio + item.emission_co2_bio
        }
        if (item.removal_of_bio_co2) {
          removalOfBioCo2 = removalOfBioCo2 + item.removal_of_bio_co2
        }
      }
    }

    setEmissionTco2e(emissionTco2e)
    setEmissionCo2Bio(emissionCo2Bio)
    setRemovalOfBioCo2(removalOfBioCo2)

    setChangeScope(
      'calculate_iten_' + emissionTco2e + '_' + emissionCo2Bio + '_' + removalOfBioCo2
    )
  }

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const gasId = scopes[index].gas_id
    const emissionsT = scopes[index].emissions_t

    async function calculate() {
      const result = await scope1Service.calculateIndustrialProcesses(gasId, emissionsT)

      if (result) {
        scopes[index].emission_tco2e = result.emission_tco2e

        setChangeScope('change_scope_item_' + index + '_' + emissionsT)

        setScopeValues(scopes, ignore_field)
        calculateTotals(scopes)
      }
    }

    calculate()
  }

  function handleDelete(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setChangeScope('remove_scope_item_' + index)

    setValue('gases_cycle', [])

    setScopeValues(itens)
    calculateTotals(itens)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'processos_industriais'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'processos_industriais',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'processos_industriais'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {!ignoreFill && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 5,
                      fontWeight: 'bold'
                    }}
                  >
                    Relato de Emissões de Processos Industriais
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, }} >
                    Selecione, o gás/composto ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && gases && (
                  <Controller
                    control={control}
                    name="gases_cycle"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 5,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('gases_cycle')}
                        options={gases}
                        onChange={handleChangeGas}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScope && scopes && scopes.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Registro da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Descrição da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Descrição do'}
                          <br />
                          {'processo industrial'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Gás / Composto'}
                        </th>
                        <th className="head-tr-item" style={{ width: 450 }}>
                          {'GWP'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }}>
                          {'Emissões (t GEE)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 120 }}>
                          {'E = Emissões de'}
                          <br />
                          {'CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 100 }}>
                          {'Emissões de'}
                          <br />
                          {'CO₂ biogênico (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 120 }}>
                          {'Remoções de'}
                          <br />
                          {'CO₂ biogênico (t)'}
                        </th>
                        {!isCommonUser && (
                          <th className="head-tr-item" style={{ width: 50 }} />
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {scopes.map((scope: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`scope.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`scope.${index}.source`, event.target.value)
                                    scopes[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`scope.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `scope.${index}.description`,
                                      event.target.value
                                    )
                                    scopes[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`scope.${index}.source_description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `scope.${index}.source_description`,
                                      event.target.value
                                    )
                                    scopes[index].source_description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {scope.gas_name}
                                  </span>

                                  {scope.gas_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="information"/>
                                      <span className="tooltiptext">
                                        {scope.gas_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatInteger(scope.gas_gwp)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scope.${index}.emissions_t`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].emissions_t = value

                                    setValue(`scope.${index}.emissions_t`, formatValue)

                                    handleChangeValue(index, 'emissions_t')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(scope.emission_tco2e, false)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scope.${index}.emission_co2_bio`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].emission_co2_bio = value

                                    setValue(
                                      `scope.${index}.emission_co2_bio`,
                                      formatValue
                                    )

                                    calculateTotals(scopes)
                                  }}
                                />
                              </td>
                              <td style={{ width: 120 }}>
                                <input
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scope.${index}.removal_of_bio_co2`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    scopes[index].removal_of_bio_co2 = value

                                    setValue(
                                      `scope.${index}.removal_of_bio_co2`,
                                      formatValue
                                    )

                                    calculateTotals(scopes)
                                  }}
                                />
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDelete(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={6}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2e, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Bio, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(removalOfBioCo2, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td colSpan={5} className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopes.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum gás / composto cadastrado`
                        : `Nenhum gás / composto foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.scope && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da fonte, Descrição da fonte e Descrição do processo industrial são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && changeScope && scopes && scopes.length > 0 && (
            <div
              style={{
                borderStyle: 'dashed',
                margin: 5,
                borderWidth: '1px',
                width: 'calc(100vw - 330px)',
                maxWidth: 'calc(100vw - 330px)',
                borderColor: '#9E9E9E',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 15
              }}
            >
              <span style={{ margin: 10, width: '70%' }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: 'bold',
                  margin: 10,
                  textAlign: 'right',
                  width: '30%'
                }}
              >
                {formatPrice3(emissionTco2e, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && changeScope && scopes && scopes.length > 0 && (
            <div
              style={{
                borderStyle: 'dashed',
                margin: 5,
                borderWidth: '1px',
                width: 'calc(100vw - 330px)',
                maxWidth: 'calc(100vw - 330px)',
                borderColor: '#9E9E9E',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 15
              }}
            >
              <span style={{ margin: 10, width: '70%' }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: 'bold',
                  margin: 10,
                  textAlign: 'right',
                  width: '30%'
                }}
              >
                {formatPrice3(emissionCo2Bio, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && changeScope && scopes && scopes.length > 0 && (
            <div
              style={{
                borderStyle: 'dashed',
                margin: 5,
                borderWidth: '1px',
                width: 'calc(100vw - 330px)',
                maxWidth: 'calc(100vw - 330px)',
                borderColor: '#9E9E9E',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 15
              }}
            >
              <span style={{ margin: 10, width: '70%' }}>
                Remoções totais de CO₂ biogênico (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: 'bold',
                  margin: 10,
                  textAlign: 'right',
                  width: '30%'
                }}
              >
                {formatPrice3(removalOfBioCo2, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
