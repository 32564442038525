import {
  Page,
  Text,
  View,
  StyleSheet,
  Image as ImagePDF,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Logos } from "../../components/Logos";
import { Header } from "../../components/Header";
import { styles } from "../../styles";
import { IGEEProps } from "../../../interfaces/IGEEData";
import LogoVerdeEscuro from "../../../../../../../assets/images/versoes-logo-icone-ingee-03.png";

export const Page4: React.FC<IGEEProps> = (props) => {
  const { resume } = props;

  return (
    <Page size={[640, 480]} style={styles.Page}>
      <Header backColor="#81c787" title="2. Informações institucionais" />
      <View style={{ ...styles.body, height: "92%" }}>
        <View
          style={{
            padding: "20px",
            width: "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: 'justify'
          }}
        >
          <View style={{ borderRadius: "100%", overflow: 'hidden' }}>
            <ImagePDF 
              src={resume?.company_itens?.director_image_url}
              style={{ 
                width: 130, 
                height: 130,
                objectFit: "cover",
                backgroundColor: '#64766d'
              }}
            />
          </View>
          <View>
            <View style={local.container}>
              <Text style={{fontSize: 35, color: "#81c787", ...styles.extraBold, bottom: 15, marginRight: 5, left: -10}}>“</Text>
              <Text style={{ ...local.textDirector, ...styles.light }}>
                {resume?.company_itens.director_message}<Text style={{...styles.italic}}>”</Text>
              </Text>
            </View>
            <Text style={{ ...local.ceoName, ...styles.boldItalic }}>
              {resume?.company_itens.ceo_name}
            </Text>
            <Text style={{ ...local.title, ...styles.italic, marginLeft: 34 }}>
              CEO da {resume?.company_itens.corporate_name}
            </Text> 
          </View>
        </View>
        <View style={{ width: "65%", borderLeft: "1px solid #81c787", marginRight: '10px', textAlign: 'justify' }}>
          <View
            style={{
              position: "absolute",
              top: "20%",
              width: 20,
              height: 20,
              right: '111.5%',
              borderRadius: "50%",
              backgroundColor: "#81c787",
              transform: "translate(50%, -50%)",
            }}
          />
          <View style={{...local.item}}>
            <Text style={{ ...local.title, ...styles.light, marginBottom: 5 }}>
              <Text style={styles.Bold}>Missão:</Text>{" "}
              <Text style={{...local.text, ...styles.light}}>
                {resume?.company_itens.mission}
              </Text>
            </Text>
            <Text style={{ ...local.title, ...styles.light, marginBottom: 5 }}>
              <Text style={styles.Bold}>Visão:</Text>{" "}
              <Text style={{...local.text, ...styles.light}}>
                  {resume?.company_itens.vision}
              </Text> 
            </Text>
            <Text style={{ ...local.title, ...styles.light}}>
              <Text style={styles.Bold}>Valores:</Text>{" "}
              <Text style={{...local.text, ...styles.light}}>
                {resume?.company_itens.values}
              </Text>
            </Text>
          </View>
          <View style={local.item}>
            <Text style={{ ...local.title, ...styles.Bold }}>
              Motivo pela elaboração do IGEE:{" "}
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              {resume?.igee_itens.creation_reason}
            </Text>
          </View>
          <View style={local.item}>
            <Text style={{ ...local.title, ...styles.Bold }}>
              Motivo pela escolha do ano base:{" "}
            </Text>
            <Text style={{ ...local.text, ...styles.light }}>
              {resume?.igee_itens?.reason_year}
            </Text>
          </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 40}} >
              </View>
            <ImagePDF 
              src={resume?.company_itens.logo_image_url}
                style={{
                  width: 50,
                  right: '85px',
                  position: 'absolute',
                  bottom: '14px'
                }}
            />
            <ImagePDF src={LogoVerdeEscuro} 
              style={{
                width: 90,
                right: '0px',
                bottom: '14px',
                position: 'absolute'
              }}
            />
        </View>
      </View>
    </Page>
  );
};

const local = StyleSheet.create({
  item: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "96%", 
    marginLeft: 10,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 20,
    marginBottom: 20,
    marginRight: 10,
  },
  textDirector: {
    color: "#64766d",
    fontSize: 11,
    padding: "1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: 'justify',
    marginBottom: -15,
    marginLeft: -10,
  },
  text: {
    color: "#64766d",
    fontSize: 11,
    padding: "1px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "90em",
    textAlign: 'justify',
  },
  ceoName: {
    color: "#64766d",
    fontSize: 14,
    marginLeft: 34,
  },
  title: {
    color: "#64766d",
    fontSize: 12,
  },
  director: {
    height: "130",
    width: "130",
    backgroundColor: "#6c8a6e",
    borderRadius: "100%",
  },
});
