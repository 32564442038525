import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import climaticCharacteristicService from "../../../services/climaticCharacteristicService";
import { Container } from "./styles";
import { formatPrice4} from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import ibgeService from "../../../services/ibgeService";
import { toast } from "react-hot-toast";

interface Inputs {
  city: any;
  state: any;
  city_name: string;
  state_name: string;
  description: string;
  precipitation: any;
  average_temperature: string;
  potential_evapotranspiration: string;
}

export const ClimaticCharacteristicEdit = () => {
  const history = useHistory();

  const [include, setInclude] = useState(false);
  const [characteristicSelected, setCharacteristicSelected] = useState<any>({});
  const location = useLocation();
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.caract_id){
      getClimaticCharacteristicsById(item.caract_id)

      setInclude(false)
    }else{
      setInclude(true)
    }

    getStates()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  async function getStates() {
    const result = await ibgeService.getStates();

    const options = result.map((state: any) => {
      return {
        label: state.sigla,
        value: state.sigla,
      };
    });

      
    function getSortOrder(prop:any) {    
      return function(a:any, b:any) {    
          if (a[prop] > b[prop]) {    
              return 1;    
          } else if (a[prop] < b[prop]) {    
              return -1;    
          }    
          return 0;    
      }    
    }

    options.sort(getSortOrder("label"));

    setStates(options);
  }

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const precipitation = formData.precipitation.replace(".", "");
      const average_temperature = formData.average_temperature.replace(".", "");
      const potential_evapotranspiration = formData.potential_evapotranspiration.replace(".", "");

      const newClimaticCharacteristic = {
        description: formData.description,
        city: include ? formData.city.value : formData.city_name,
        state: include ? formData.state.value : formData.state_name,
        precipitation: precipitation ? parseFloat(precipitation.replace(",", ".")) : 0,
        average_temperature: average_temperature ? parseFloat(average_temperature.replace(",", ".")) : 0,
        potential_evapotranspiration: potential_evapotranspiration ? parseFloat(potential_evapotranspiration.replace(",", ".")) : 0,
      };

      if (characteristicSelected?.id) {
        const result = await climaticCharacteristicService.updateById(
          characteristicSelected.id,
          newClimaticCharacteristic
        );

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await climaticCharacteristicService.create(newClimaticCharacteristic);

        if (result && !result.error) {
         // history.goBack()

         // reset();
         // setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getClimaticCharacteristicsById(id: number) {
    const item = await climaticCharacteristicService.getById(id);

    setValue("city_name", item?.city);
    setValue("state_name", item?.state);

    setValue("precipitation", formatPrice4(Number(item.precipitation), false));
    setValue("average_temperature", formatPrice4(Number(item.average_temperature), false));
    setValue("potential_evapotranspiration", formatPrice4(Number(item.potential_evapotranspiration), false));

    setCharacteristicSelected(item)
  }

  const handleChangeState = (e: any) => {
    setCities([])
    setValue("state", e)
    setValue("city", {})

    async function getCities() {
      const cities = await ibgeService.getCitiesByState(e.value);
      
      const result = await climaticCharacteristicService.getByParams({ limit: 9999, page: 1, state: e.value });

      const itens = cities.filter(function(a:any) { 
        let exists = false

        for (const b of result.itens){
          if (b.city === a.nome){
            exists = true
            break
          }
        }

        return exists === false
      })
  
      const options = itens.map((sub: any) => {
        return {
          label: sub.nome,
          value: sub.nome,
        };
      });
      
      function getSortOrder(prop:any) {    
        return function(a:any, b:any) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
      }
  
      options.sort(getSortOrder("label"));

      setCities(options)
    }

    if (e && e.value){
      getCities();
    }
  };
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{4})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Característica climática" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >

          <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "30%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Estado
              </label>

              {include === false && (
                <input style={{width: '95%', }} readOnly {...register("state_name")}/>
              )}

              {include === true && (
                <Controller control={control} name="state"
                  render={({ field }) => (
                    <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                          ...styles, width: "100%", height: 40, marginTop: 10,
                          backgroundColor: "rgb(246, 246, 246)"}),
                        option: (styles) => { return { ...styles, color: "#31363F" };},
                      }} defaultValue={getValues("state")}
                      options={states} isClearable={true} onChange={handleChangeState}                       
                    />
                  )}
                />
              )}
                          
              {errors.state && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "70%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Cidade
              </label>

              {include === false && (
                <input style={{width: '95%', }} readOnly {...register("city_name")}/>
              )}

              {include === true && (
                <Controller control={control} name="city"
                  render={({ field }) => (
                    <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                          ...styles, width: "100%", height: 40, marginTop: 10,
                          backgroundColor: "rgb(246, 246, 246)"}),
                        option: (styles) => { return { ...styles, color: "#31363F" };},
                      }} defaultValue={getValues("city")}
                      options={cities} isClearable={true}                      
                    />
                  )}
                />
              )}
                          
              {errors.city && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "30%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Temperatura anual média [ºC]:
              </label>

              <input style={{width: '95%', }}
                    {...register("average_temperature")}
                    onChange={(event) => {
                        setValue("average_temperature", currency(event));
                    }}/>
                          
              {errors.average_temperature && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "32%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Precipitação anual <br/>[mm/ano]
              </label>

              <input style={{width: '95%', }}
                    {...register("precipitation")}
                    onChange={(event) => {
                        setValue("precipitation", currency(event));
                    }}/>
                          
              {errors.precipitation && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "38%" }}>
              <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Potencial de evapotranspiração [mm/ano]
              </label>

              <input style={{width: '95%', }}
                    {...register("potential_evapotranspiration")}
                    onChange={(event) => {
                        setValue("potential_evapotranspiration", currency(event));
                    }}/>
                          
              {errors.potential_evapotranspiration && (
                  <span className='requiredLabel' >Esse campo é obrigatório</span>
              )}
            </div>
          </div>





          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', }}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
