import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import Parser from 'html-react-parser'
import useAuth from '../../../../../hooks/useAuth'
import { formatPrice, formatPrice3, formatPrice5, toNumberValues } from '../../../../../utils/transform'
import { Container } from './styles'
import inventoryService from '../../../../../services/inventoryService'
import scope3Service from '../../../../../services/scope3Service'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'
import { ModalImportData } from '../../../../../components/ModalImportData'
import airportService from '../../../../../services/airportService'

export const AirTrip = () => {
  const history = useHistory()
 
  const [files, setFiles] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [title, setTitle] = useState<string | undefined>();
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()


  const [openModalAeroporto, setOpenModalAeroporto] = useState(false)
  const [openModalAeroKm, setOpenModalAeroKm] = useState(false)


  const [routes, setRoutes] = useState<any>([])
  const [distances, setDistances] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [emissionCo2Routes, setEmissionCo2Routes] = useState<any>()
  const [emissionCh4Routes, setEmissionCh4Routes] = useState<any>()
  const [emissionN2oRoutes, setEmissionN2oRoutes] = useState<any>()
  const [changeScopeRoute, setChangeScopeRoute] = useState<string | undefined>()
  const [emissionTco2eRoutes, setEmissionTco2eRoutes] = useState<any>()
  const [emissionCo2Distances, setEmissionCo2Distances] = useState<any>()
  const [emissionCh4Distances, setEmissionCh4Distances] = useState<any>()
  const [emissionN2oDistances, setEmissionN2oDistances] = useState<any>()
  const [changeScopeDistance, setChangeScopeDistance] = useState<string | undefined>()
  const [emissionTco2eDistances, setEmissionTco2eDistances] = useState<any>()
  const [emissionTco2eTotals, setEmissionTco2eTotals] = useState<any>()

  const { isCommonUser } = useAuth()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/viagens-a-negocios'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'viagens_a_negocios', 'viagens_em_aeronaves')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<any>()

  useEffect(() => {
    setEmissionTco2eTotals((emissionTco2eRoutes ? emissionTco2eRoutes : 0) + (emissionTco2eDistances ? emissionTco2eDistances : 0))
  }, [emissionTco2eRoutes, emissionTco2eDistances])

  const handleChangeRoute = (index: number, ignore_field?: string) => {
    const origin = routes[index].origin
    const destiny = routes[index].destiny
    const numberStretches = routes[index].number_stretches
      ? parseInt(routes[index].number_stretches)
      : 0
    const companyId = inventorySelected.company.id
    const year = inventorySelected.year

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripAirRoute(
        origin,
        destiny,
        numberStretches,
        companyId, 
        year
      )

      if (result) {
        routes[index].emission_co2 = parseFloat(result.emission_co2)
        routes[index].emission_ch4 = parseFloat(result.emission_ch4)
        routes[index].emission_n2o = parseFloat(result.emission_n2o)
        routes[index].emission_tco2e = parseFloat(result.emission_tco2e)
        routes[index].total_distance = parseFloat(result.total_distance)
        routes[index].factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
        routes[index].factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
        routes[index].factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)
        routes[index].stretch_distance = parseFloat(result.stretch_distance)
        routes[index].origin_valid_airport = result.origin_valid_airport
        routes[index].destiny_valid_airport = result.destiny_valid_airport

        setChangeScopeRoute(
          'change_scope_item_' +
            index +
            '_' +
            origin +
            '_' +
            destiny +
            '_' +
            numberStretches
        )

        setRouteValues(routes, ignore_field)
        calculateRoutes(routes)
      }
    }

    calculate()
  }

  const handleChangeDistance = (index: number, ignore_field?: string) => {
    const typeOfTrip = distances[index].type_of_trip
      ? parseInt(distances[index].type_of_trip)
      : 0
    const passengerMileage = distances[index].passenger_mileage
      ? parseFloat(distances[index].passenger_mileage)
      : 0
    const companyId = inventorySelected.company.id
    const year = inventorySelected.year

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripAirDistance(
        typeOfTrip,
        passengerMileage,
        companyId,
        year
      )

      if (result) {
        distances[index].emission_co2 = parseFloat(result.emission_co2)
        distances[index].emission_ch4 = parseFloat(result.emission_ch4)
        distances[index].emission_n2o = parseFloat(result.emission_n2o)
        distances[index].emission_tco2e = parseFloat(result.emission_tco2e)
        distances[index].factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
        distances[index].factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
        distances[index].factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)

        setChangeScopeRoute(
          'change_scope_item_' + index + '_' + typeOfTrip + '_' + passengerMileage
        )

        setDistanceValues(distances, ignore_field)
        calculateDistances(distances)
      }
    }

    calculate()
  }

  function handleDeleteRoute(index: number) {
    const itens = []

    for (let i = 0; i < routes?.length; i++) {
      if (i !== index) {
        itens.push(routes[i])
      }
    }

    setChangeScopeRoute('remove_scope_item_' + index)

    setRouteValues(itens)
    calculateRoutes(itens)
  }

  function handleDeleteDistance(index: number) {
    const itens = []

    for (let i = 0; i < distances?.length; i++) {
      if (i !== index) {
        itens.push(distances[i])
      }
    }

    setChangeScopeDistance('remove_scope_item_' + index)

    setDistanceValues(itens)
    calculateDistances(itens)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const setDistanceValues = (itens: any, ignore_field?: string) => {
    let scope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        if ('register' !== ignore_field) {
          setValue(`distances.${i}.register`, item.register)
        }

        if ('description' !== ignore_field) {
          setValue(`distances.${i}.description`, item.description)
        }

        if ('type_of_trip' !== ignore_field) {
          setValue(`distances.${i}.type_of_trip`, item.type_of_trip)
        }

        if ('passenger_mileage' !== ignore_field) {
          setValue(`distances.${i}.passenger_mileage`, formatPrice(Number(item.passenger_mileage), false))
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`distances.${i}.emission_co2`, formatPrice(Number(item.emission_co2), false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`distances.${i}.emission_ch4`, formatPrice(Number(item.emission_ch4), false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`distances.${i}.emission_n2o`, formatPrice(Number(item.emission_n2o), false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`distances.${i}.emission_tco2e`, formatPrice(Number(item.emission_tco2e), false))
        }

        if ('factor_kg_co2_l' !== ignore_field) {
          setValue(`distances.${i}.factor_kg_co2_l`, formatPrice(Number(item.factor_kg_co2_l), false))
        }

        if ('factor_kg_ch4_l' !== ignore_field) {
          setValue(`distances.${i}.factor_kg_ch4_l`, formatPrice(Number(item.factor_kg_ch4_l), false))
        }

        if ('factor_kg_n2o_l' !== ignore_field) {
          setValue(`distances.${i}.factor_kg_n2o_l`, formatPrice(Number(item.factor_kg_n2o_l), false))
        }

        scope =
          scope +
          '_' +
          item.register +
          '_' +
          item.type_of_trip +
          '_' +
          item.passenger_mileage
      }
    }

    setDistances(itens)
    setChangeScopeDistance(scope)
  }

  const setRouteValues = (itens: any, ignore_field?: string) => {
    let scope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        if ('origin' !== ignore_field) {
          setValue(`routes.${i}.origin`, item.origin)
        }

        if ('destiny' !== ignore_field) {
          setValue(`routes.${i}.destiny`, item.destiny)
        }

        if ('register' !== ignore_field) {
          setValue(`routes.${i}.register`, item.register)
        }

        if ('number_stretches' !== ignore_field) {
          setValue(`routes.${i}.number_stretches`, item.number_stretches)
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`routes.${i}.emission_co2`, formatPrice(Number(item.emission_co2), false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`routes.${i}.emission_ch4`, formatPrice(Number(item.emission_ch4), false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`routes.${i}.emission_n2o`, formatPrice(Number(item.emission_n2o), false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`routes.${i}.emission_tco2e`, formatPrice(Number(item.emission_tco2e), false))
        }

        if ('factor_kg_co2_l' !== ignore_field) {
          setValue(`routes.${i}.factor_kg_co2_l`, formatPrice(Number(item.factor_kg_co2_l), false))
        }

        if ('factor_kg_ch4_l' !== ignore_field) {
          setValue(`routes.${i}.factor_kg_ch4_l`, formatPrice(Number(item.factor_kg_ch4_l), false))
        }

        if ('factor_kg_n2o_l' !== ignore_field) {
          setValue(`routes.${i}.factor_kg_n2o_l`, formatPrice(Number(item.factor_kg_n2o_l), false))
        }

        if ('total_distance' !== ignore_field) {
          setValue(`routes.${i}.total_distance`, formatPrice(Number(item.total_distance), false))
        }

        if ('stretch_distance' !== ignore_field) {
          setValue(`routes.${i}.stretch_distance`, formatPrice(Number(item.stretch_distance), false))
        }

        if ('origin_valid_airport' !== ignore_field) {
          setValue(`routes.${i}.origin_valid_airport`, item.origin_valid_airport)
        }

        if ('destiny_valid_airport' !== ignore_field) {
          setValue(`routes.${i}.destiny_valid_airport`, item.destiny_valid_airport)
        }

        scope =
          scope + '_' + item.origin + '_' + item.destiny + '_' + item.number_stretches
      }
    }

    setRoutes(itens)
    setChangeScopeRoute(scope)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleNewRoute = () => {
    const item = {
      origin: undefined,
      destiny: undefined,
      number_stretches: undefined
    }

    const index = routes.length

    const itens = routes.concat(item)

    setChangeScopeRoute('new_scope_item_' + index)

    setRouteValues(itens)
    calculateRoutes(itens)
  }

  const handleNewDistance = () => {
    const item = {
      register: undefined,
      type_of_trip: 1
    }

    const index = distances.length

    const itens = distances.concat(item)

    setChangeScopeDistance('new_scope_item_' + index)

    setDistanceValues(itens)
    calculateDistances(itens)
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const routes = await vehicleFuelService.getByParams({
      limit: 9000,
      page: 1,
      type: 'air'
    })

    setRoutes(
      routes?.itens.map((fuel: any) => {
        return {
          label: routes.name,
          value: routes.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getBusinessTripAir(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'viagens_a_negocios',
      'viagens_em_aeronaves'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setRouteValues(entity?.routes)
      calculateRoutes(entity?.routes)

      setDistanceValues(entity?.distances)
      calculateDistances(entity?.distances)

      setChangeScopeRoute('load_scope_' + entity.id + '_routes_' + entity?.routes.length)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setRouteValues([])
      calculateRoutes([])

      setDistanceValues([])
      calculateDistances([])

      setChangeScopeRoute('load_scope_0_routes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const calculateRoutes = (itens: any) => {
    let emissionCo2Air = 0
    let emissionCh4Air = 0
    let emissionN2oAir = 0
    let emissionTco2eAir = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Air = emissionCo2Air + Number(item.emission_co2)
        }
        if (item.emission_ch4) {
          emissionCh4Air = emissionCh4Air + Number(item.emission_ch4)
        }
        if (item.emission_n2o) {
          emissionN2oAir = emissionN2oAir + Number(item.emission_n2o)
        }
        if (item.emission_tco2e) {
          emissionTco2eAir = emissionTco2eAir + Number(item.emission_tco2e)
        }
      }
    }

    setEmissionCo2Routes(emissionCo2Air)
    setEmissionCh4Routes(emissionCh4Air)
    setEmissionN2oRoutes(emissionN2oAir)
    setEmissionTco2eRoutes(emissionTco2eAir)

    setChangeScopeRoute(
      'calculate_iten_' +
        emissionCo2Air +
        '_' +
        emissionCh4Air +
        '_' +
        emissionN2oAir +
        '_' +
        emissionTco2eAir
    )
  }

  const calculateDistances = (itens: any) => {
    let emissionCo2Air = 0
    let emissionCh4Air = 0
    let emissionN2oAir = 0
    let emissionTco2eAir = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Air = emissionCo2Air + Number(item.emission_co2)
        }
        if (item.emission_ch4) {
          emissionCh4Air = emissionCh4Air + Number(item.emission_ch4)
        }
        if (item.emission_n2o) {
          emissionN2oAir = emissionN2oAir + Number(item.emission_n2o)
        }
        if (item.emission_tco2e) {
          emissionTco2eAir = emissionTco2eAir + Number(item.emission_tco2e)
        }
      }
    }

    setEmissionCo2Distances(emissionCo2Air)
    setEmissionCh4Distances(emissionCh4Air)
    setEmissionN2oDistances(emissionN2oAir)
    setEmissionTco2eDistances(emissionTco2eAir)

    setChangeScopeDistance(
      'calculate_iten_' +
        emissionCo2Air +
        '_' +
        emissionCh4Air +
        '_' +
        emissionN2oAir +
        '_' +
        emissionTco2eAir
    )
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {

      const routesItens: any[] = routes.map((item: any, index: number) => {
        return {
          origin: item.origin,
          destiny: item.destiny,
          register: item.register,
          origin_valid_airport: item.origin_valid_airport,
          destiny_valid_airport: item.destiny_valid_airport,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4).toString() : '0',
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o).toString() : '0',
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2).toString() : '0',
          total_distance: item.total_distance ? parseFloat(item.total_distance).toString() : '0',
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e).toString() : '0',
          factor_kg_co2_l: item.factor_kg_co2_l ? parseFloat(item.factor_kg_co2_l).toString() : '0',
          factor_kg_ch4_l: item.factor_kg_ch4_l ? parseFloat(item.factor_kg_ch4_l).toString() : '0',
          factor_kg_n2o_l: item.factor_kg_n2o_l ? parseFloat(item.factor_kg_n2o_l).toString() : '0',
          number_stretches: item.number_stretches ? parseFloat(item.number_stretches).toString() : '0',
          stretch_distance: item.stretch_distance ? parseFloat(item.stretch_distance).toString() : '0',
        }
      })
      
      const distenceItens: any[] = distances.map((item: any, index: number) => {
        return {
          register: item.register,
          description: item.description,
          type_of_trip: item.type_of_trip ? parseInt(item.type_of_trip) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4).toString() : '0',
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o).toString() : '0',
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2).toString() : '0',
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e).toString() : '0',
          factor_kg_co2_l: item.factor_kg_co2_l ? parseFloat(item.factor_kg_co2_l).toString() : '0',
          factor_kg_ch4_l: item.factor_kg_ch4_l ? parseFloat(item.factor_kg_ch4_l).toString() : '0',
          factor_kg_n2o_l: item.factor_kg_n2o_l ? parseFloat(item.factor_kg_n2o_l).toString() : '0',
          passenger_mileage: item.passenger_mileage ? parseFloat(item.passenger_mileage).toString() : '0',
        }
      })

      const scope = {
        status: 2,
        routes: routesItens,
        distances: distenceItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope3Service.saveBusinessTripAir(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('BusinessTripAir', JSON.stringify(scope));
        
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_aeronaves'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'viagens_a_negocios',
        file: base64,
        filename: filename,
        second_name: 'viagens_em_aeronaves'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_aeronaves'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  const handleImportAirport = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const airports = await airportService.getByParams({ limit: 999999, page: 1 });

        const errors:any[] = []
        const itens = routes

        for (let i = 1; i < data?.length; i++) {
          const register = data[i][0]
          const origin = data[i][1]
          const destiny = data[i][11]
          const numberStretches = data[i][22]
          const companyId = inventorySelected.company.id
          const year = inventorySelected.year

  
          if (origin && destiny){
            let originObj:any = undefined
            let destinyObj:any = undefined
  
            for (const t of airports?.itens) {
              if (t.name === origin){
                originObj = t
                break;
              }
            }
  
            for (const t of airports?.itens) {
              if (t.name === destiny){
                destinyObj = t
                break;
              }
            }
  
            if (originObj && destinyObj){
              const scope = { register: register, origin: origin, destiny: destiny, number_stretches: numberStretches, 
                emission_co2: 0, emission_ch4: 0,  emission_n2o: 0, emission_tco2e: 0, total_distance: 0, 
                factor_kg_co2_l: 0, factor_kg_ch4_l: 0, factor_kg_n2o_l: 0, stretch_distance: 0, origin_valid_airport: false, 
                destiny_valid_airport: false, 
              }

              const result = await scope3Service.calculateBusinessTripAirRoute(
                scope.origin,
                scope.destiny,
                scope.number_stretches,
                companyId, 
                year
              )
          
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.total_distance = parseFloat(result.total_distance)
                scope.factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
                scope.factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
                scope.factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)
                scope.stretch_distance = parseFloat(result.stretch_distance)
                scope.origin_valid_airport = result.origin_valid_airport
                scope.destiny_valid_airport = result.destiny_valid_airport

                itens.push(scope)
              }
            }else if (!originObj){
              errors.push({id: i, label: origin, type: 1})
            }else if (!destinyObj){
              errors.push({id: i, label: origin, type: 2})
            }
          }
        }
        
        setChangeScopeRoute(`import_scope_data_${data?.length}`);
  
        setRouteValues(itens)
        calculateRoutes(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportAirportBulk = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const airports = await airportService.getByParams({ limit: 999999, page: 1 });

        const errors:any[] = []
        const itens = routes
        const list_of_params:any[] = []
        const list_to_be_written: any[] = []
        for (let i = 1; i < data?.length; i++) {
          const register = data[i][0]
          const origin = data[i][1]
          const destiny = data[i][11]
          const number_stretches = data[i][22]

  
          if (origin && destiny){
            let originObj:any = undefined
            let destinyObj:any = undefined
  
            for (const t of airports?.itens) {
              if (t.name === origin){
                originObj = t
                break;
              }
            }
  
            for (const t of airports?.itens) {
              if (t.name === destiny){
                destinyObj = t
                break;
              }
            }
  
            if (originObj && destinyObj){
              const curr = {
                register: register,
                origin: origin,
                destiny: destiny,
                number_stretches: number_stretches
              };
              let new_param = true;
              for(let i = 0; i < list_of_params.length; i++){
                if((curr.register == list_of_params[i].register) &&
                   (curr.origin   == list_of_params[i].origin) &&
                   (curr.destiny  == list_of_params[i].destiny)){
                  new_param = false;
                  list_of_params[i].number_stretches += curr.number_stretches;
                  break;
                }
              }
              if(new_param){
                list_of_params.push({
                  register:         register,
                  origin:           origin,
                  destiny:          destiny,
                  number_stretches: number_stretches
                })
              }
            }else if (!originObj){
              errors.push({id: i, label: origin, type: 1})
            }else if (!destinyObj){
              errors.push({id: i, label: origin, type: 2})
            }
          }
        }
        const result = await scope3Service.calculateBusinessTripAirRouteBulk(inventorySelected.company.id, inventorySelected.year, list_of_params);
        for(let i = 0; i < list_of_params.length; ++i){
          const scope = { 
            register: list_of_params[i].register.toString(), 
            origin: list_of_params[i].origin, 
            destiny: list_of_params[i].destiny, 
            number_stretches: list_of_params[i].number_stretches, 
            emission_co2: result.results[i].emission_co2, 
            emission_ch4: result.results[i].emission_ch4,  
            emission_n2o: result.results[i].emission_n2o, 
            emission_tco2e: result.results[i].emission_tco2e, 
            total_distance: result.results[i].total_distance, 
            factor_kg_co2_l: result.results[i].factor_kg_co2_l, 
            factor_kg_ch4_l: result.results[i].factor_kg_ch4_l, 
            factor_kg_n2o_l: result.results[i].factor_kg_n2o_l, 
            stretch_distance: result.results[i].stretch_distance, 
            origin_valid_airport: result.results[i].origin_valid_airport, 
            destiny_valid_airport: result.results[i].destiny_valid_airport, 
          }
          itens.push(scope)
        }
        setChangeScopeRoute(`import_scope_data_${data?.length}`);
  
        setRouteValues(itens)
        calculateRoutes(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportDistance = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = distances

        for (let i = 1; i < data?.length; i++) {
          const register = data[i][0]
          const description = data[i][1]
          const name_trip = data[i][2]
          const passenger_mileage = data[i][3]
          const companyId = inventorySelected.company.id
          const year = inventorySelected.year


          let type_trip = 0

          if (name_trip === 'Curta distância (d < 500km)'){
            type_trip = 1
          } else if (name_trip === 'Média distância (500 < d < 3.700km)'){
            type_trip = 2
          }else if (name_trip === 'Longa distância (d > 3.700km)'){
            type_trip = 3
          }

          if (type_trip > 0){
            const scope = { register: register, description: description, type_of_trip: type_trip, 
              passenger_mileage: passenger_mileage, emission_co2: 0, emission_ch4: 0,  emission_n2o: 0, 
              emission_tco2e: 0, factor_kg_co2_l: 0, factor_kg_ch4_l: 0, factor_kg_n2o_l: 0 
            }

            const result = await scope3Service.calculateBusinessTripAirDistance(
              scope.type_of_trip,
              scope.passenger_mileage,
              companyId, 
              year
            )

            if (result) {
              scope.emission_co2 = parseFloat(result.emission_co2)
              scope.emission_ch4 = parseFloat(result.emission_ch4)
              scope.emission_n2o = parseFloat(result.emission_n2o)
              scope.emission_tco2e = parseFloat(result.emission_tco2e)
              scope.factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
              scope.factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
              scope.factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)

              itens.push(scope)
            }
          } else {
            errors.push({id: i, label: name_trip, type: 3})
          }
        }
        
        setChangeScopeDistance(`import_scope_data_${data?.length}`);
  
        setDistanceValues(itens)
        calculateDistances(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleOpenModalAeroporto = () => {
    setOpenModalAeroporto(!openModalAeroporto)
  }

  const handleOpenModalAeroKM = () => {
  setOpenModalAeroKm(!openModalAeroKm)
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0,
                    width: '80%'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        marginRight: 30
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'start',
                          fontSize: 16,
                          marginTop: 5,
                          fontWeight: 'bold'
                        }}
                      >
                        Calculo de emissões por viagem a negócios em aeronaves de acordo
                        aeroportos de origem e destino realizadas no ano
                      </span>
                      <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                        Clique, ao lado em novo registro, para adicionar nova linha
                      </span>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'right', width: '30%', marginBottom: 10 }}>
                  {!isCommonUser && (
                    <button
                      type="button"
                      style={{
                        width: '120px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleNewRoute()}
                    >
                      {`Novo registro`}
                    </button>
                  )}

                  {!isCommonUser && (
                    <button
                      className="button-view"
                      style={{ height: 47, width: 110, marginLeft: 5 }}
                      onClick={() => handleOpenModalAeroporto()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeRoute && routes && routes.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Registro da viagem'}
                        </th>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Partida'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'O aeroporto de partida é válido?'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Chegada'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'O aeroporto de chegada é válido?'}
                        </th>
                        <th className="head-tr-item" style={{ width: 250 }}>
                          {'Distância do trecho (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Número de trechos voados'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Distância total (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CO₂ (kg CO₂ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CH₄ (kg CH₄ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de N₂O (kg N₂O / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th className="head-tr-item" style={{ width: 50 }} />
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {routes.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`routes.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `routes.${index}.register`,
                                      event.target.value
                                    )
                                    routes[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`routes.${index}.origin`)}
                                  onBlur={(event) => {
                                    const { value } = event.target
                                    const numberValue = value.toUpperCase()

                                    event.target.value = numberValue

                                    routes[index].origin = numberValue

                                    setValue(`routes.${index}.origin`, numberValue)

                                    handleChangeRoute(index)
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {routes[index].origin_valid_airport ? 'Sim' : 'Não'}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`routes.${index}.destiny`)}
                                  onBlur={(event) => {
                                    const { value } = event.target
                                    const numberValue = value.toUpperCase()

                                    event.target.value = numberValue

                                    routes[index].destiny = numberValue

                                    setValue(`routes.${index}.destiny`, numberValue)

                                    handleChangeRoute(index)
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {routes[index].destiny_valid_airport ? 'Sim' : 'Não'}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice(Number(routes[index].stretch_distance), false)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`routes.${index}.number_stretches`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    routes[index].number_stretches = numberValue

                                    setValue(`routes.${index}.number_stretches`, numberValue)

                                    handleChangeRoute(index, 'number_stretches')
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice(Number(routes[index].total_distance), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].factor_kg_co2_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].factor_kg_ch4_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].factor_kg_n2o_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(routes[index].emission_tco2e), false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteRoute(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={11}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2Routes, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4Routes, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oRoutes, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eRoutes, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {routes && routes.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum registro cadastrado`
                        : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.routes && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0,
                    width: '80%'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        marginRight: 30
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'start',
                          fontSize: 16,
                          marginTop: 5,
                          fontWeight: 'bold'
                        }}
                      >
                        Calculo de emissões por viagem a negócios em aeronaves de acordo
                        com quilômetros percorridos no ano
                      </span>
                      <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                        Clique, ao lado em novo registro, para adicionar nova linha
                      </span>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'right', width: '30%', marginBottom: 10 }}>
                  {!isCommonUser && (
                    <button
                      type="button"
                      style={{
                        width: '120px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleNewDistance()}
                    >
                      {`Novo registro`}
                    </button>
                  )}

                  {!isCommonUser && (
                    <button
                      className="button-view"
                      style={{ height: 47, width: 110, marginLeft: 5 }}
                      onClick={() => handleOpenModalAeroKM()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeDistance && distances && distances.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Registro do passageiro'}
                        </th>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Descrição do passageiro'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Tipo de Viagem Aérea'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Quilometragem do passageiro (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CO₂ (kg CO₂ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CH₄ (kg CH₄ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de N₂O (kg N₂O / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th className="head-tr-item" style={{ width: 50 }} />
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {distances.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`distances.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `distances.${index}.register`,
                                      event.target.value
                                    )
                                    distances[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`distances.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `distances.${index}.description`,
                                      event.target.value
                                    )
                                    distances[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <select
                                  disabled={isCommonUser}
                                  style={{
                                    borderWidth: '0px',
                                    width: '250px',
                                    height: '25px'
                                  }}
                                  {...register(`distances.${index}.type_of_trip`)}
                                  onChange={(event) => {
                                    const { value } = event.target

                                    distances[index].type_of_trip = value

                                    setValue(`distances.${index}.type_of_trip`, value)

                                    handleChangeDistance(index)
                                  }}
                                >
                                  <option
                                    value="1"
                                    label="Curta distância (d ≤ 500 km)"
                                  />
                                  <option
                                    value="2"
                                    label="Média distância (500 < d ≤ 3.700 km)"
                                  />
                                  <option
                                    value="3"
                                    label="Longa distância (d > 3.700 km)"
                                  />
                                </select>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`distances.${index}.passenger_mileage`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    distances[index].passenger_mileage = value

                                    setValue(
                                      `distances.${index}.passenger_mileage`,
                                      formatValue
                                    )

                                    handleChangeDistance(index, 'passenger_mileage')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].factor_kg_co2_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].factor_kg_ch4_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].factor_kg_n2o_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(distances[index].emission_tco2e), false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteDistance(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={7}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2Distances, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4Distances, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oDistances, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eDistances, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {distances && distances.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum registro cadastrado`
                        : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.distances && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "98%", borderColor: "#9E9E9E", height: "40px", 
                          display: "flex", flexDirection: "row", marginLeft: 10}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eTotals, false)}
              </span>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {openModalAeroporto && (
        <ModalImportData
          entityType={1}
          isOpen={openModalAeroporto}
          handleModalVisible={handleOpenModalAeroporto}
          entityName="Viagem a negócios em aeronaves de acordo aeroportos de origem e destino"
          templateFileName="template_business_Air1.xlsx"
          handleImportData={handleImportAirportBulk}
        />
      )}

      {openModalAeroKm && (
        <ModalImportData
          entityType={2}
          isOpen={openModalAeroKm}
          handleModalVisible={handleOpenModalAeroKM}
          entityName="Viagem a negócios em aeronaves de acordo com quilômetros percorridos"
          templateFileName="template_business_Air2.xlsx"
          handleImportData={handleImportDistance}
        />
      )}

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {item.type === 1 ? 'Origem' : item.type === 2 ? 'Destino' : 'Geral'}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Item não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
