import Parser from 'html-react-parser'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, toNumberValues } from '../../../../../utils/transform'
import scope3Service from '../../../../../services/scope3Service'
import transportTypeService from '../../../../../services/transportTypeService'
import scopeService from '../../../../../services/scopeService'
import inventoryService from '../../../../../services/inventoryService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { ModalImportData } from '../../../../../components/ModalImportData'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { Uninformed } from '../../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import utilsService from '../../../../../services/utilsService'
import { Loading } from '../../../../../components/Loading'
import { toast } from 'react-hot-toast'

export const PublicTransport = () => {
  const history = useHistory()

  const location = useLocation()
  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [importErrors, setImportErrors] = useState<any>([])
  const [title, setTitle] = useState<string | undefined>();
  const [entityType, setEntityType] = useState<number | undefined>();
  const [entityName, setEntityName] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [openModalImportTrain, setOpenModalImportTrain] = useState(false)
  const [openModalImportFerry, setOpenModalImportFerry] = useState(false)
  const [openModalImportBus, setOpenModalImportBus] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)

  const [buses, setBuses] = useState<any>([])
  const [trains, setTrains] = useState<any>([])
  const [ferries, setFerries] = useState<any>([])
  const [busTypes, setBusTypes] = useState<any>([])
  const [trainTypes, setTrainTypes] = useState<any>([])
  const [ferryTypes, setFerryTypes] = useState<any>([])
  const [emissionCo2Bus, setEmissionCo2Bus] = useState<any>()
  const [emissionCh4Bus, setEmissionCh4Bus] = useState<any>()
  const [emissionN2oBus, setEmissionN2oBus] = useState<any>()
  const [emissionTco2eBus, setEmissionTco2eBus] = useState<any>()
  const [emissionCo2Ferry, setEmissionCo2Ferry] = useState<any>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  
  const [emissionCo2Trains, setEmissionCo2Trains] = useState<any>()
  const [emissionTco2eFerry, setEmissionTco2eFerry] = useState<any>()
  const [emissionTco2eBioBus, setEmissionTco2eBioBus] = useState<any>()
  const [emissionTco2eTrains, setEmissionTco2eTrains] = useState<any>()
  const [changeScopeBus, setChangeScopeBus] = useState<string | undefined>()
  const [changeScopeTrain, setChangeScopeTrain] = useState<string | undefined>()
  const [changeScopeFerry, setChangeScopeFerry] = useState<string | undefined>()

  const [trainsManual, setTrainsManual] = useState<any>([])
  const [trainTypesManual, setTrainTypesManual] = useState<any>([])
  const [changeScopeTrainManual, setChangeScopeTrainManual] = useState<string | undefined>()
  const [emissionCo2TrainsManual, setEmissionCo2TrainsManual] = useState<any>()
  const [emissionTco2eTrainsManual, setEmissionTco2eTrainsManual] = useState<any>()
  const [openModalImportTrainManual, setOpenModalImportTrainManual] = useState(false)

  const [busesManual, setBusesManual] = useState<any>([])
  const [changeScopeBusManual, setChangeScopeBusManual] = useState<string | undefined>()
  const [emissionCo2BusManual, setEmissionCo2BusManual] = useState<any>()
  const [emissionCh4BusManual, setEmissionCh4BusManual] = useState<any>()
  const [emissionN2oBusManual, setEmissionN2oBusManual] = useState<any>()
  const [emissionTco2eBusManual, setEmissionTco2eBusManual] = useState<any>()
  const [emissionTco2eBioBusManual, setEmissionTco2eBioBusManual] = useState<any>()
  const [openModalImportBusManual, setOpenModalImportBusManual] = useState(false)
  const [busTypesManual, setBusTypesManual] = useState<any>([])

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3'
      })
    }

    getGuidelines()
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'deslocamento_casa_trabalho', 'transporte_publico')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const trainItens: any[] = trains.map((item: any) => {
        return {
          register: item.register.toString(),
          description: item.description,
          address: item.address,
          travelled_distance: item.travelled_distance ? item.travelled_distance.toFixed(8) : '0',
          number_of_passengers: parseInt(item.number_of_passengers),
          worked_days: parseInt(item.worked_days),
          transport_type_id: item.transport_type_id,
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km.toFixed(8) : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km.toFixed(8) : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km.toFixed(8) : '0',
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4.toFixed(8) : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0'
        }
      })

      const trainItensManual: any[] = trainsManual.map((item: any) => {
        return {
          register: item.register.toString(),
          description: item.description,
          travelled_distance: item.travelled_distance ? item.travelled_distance.toFixed(8) : '0',
          number_of_passengers: parseInt(item.number_of_passengers),
          worked_days: parseInt(item.worked_days),
          transport_type_id: item.transport_type_id,
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km.toFixed(8) : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km.toFixed(8) : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km.toFixed(8) : '0',
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4.toFixed(8) : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0'
        }
      })

      const busItens: any[] = buses.map((item: any, index: number) => {
        return {
          address: item.address,
          description: item.description,
          register: item.register.toString(),
          worked_days: parseInt(item.worked_days),
          number_of_passengers: parseInt(item.number_of_passengers),
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4.toFixed(8) : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o.toFixed(8) : '0',
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km.toFixed(8) : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km.toFixed(8) : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0',
          emission_co2_bio: item.emission_co2_bio
            ? item.emission_co2_bio.toFixed(8)
            : '0',
          emission_ch4_bio: item.emission_ch4_bio
            ? item.emission_ch4_bio.toFixed(8)
            : '0',
          emission_n2o_bio: item.emission_n2o_bio
            ? item.emission_n2o_bio.toFixed(8)
            : '0',
          emission_tco2e_bio: item.emission_tco2e_bio
            ? item.emission_tco2e_bio.toFixed(8)
            : '0',
          travelled_distance: item.travelled_distance
            ? item.travelled_distance.toFixed(8)
            : '0',
          factor_gco2_bio_km: item.factor_gco2_bio_km
            ? item.factor_gco2_bio_km.toFixed(8)
            : '0',
          factor_gch4_bio_km: item.factor_gch4_bio_km
            ? item.factor_gch4_bio_km.toFixed(8)
            : '0',
          factor_gn2o_bio_km: item.factor_gn2o_bio_km
            ? item.factor_gn2o_bio_km.toFixed(8)
            : '0',
          emission_co2_diesel: item.emission_co2_diesel
            ? item.emission_co2_diesel.toFixed(8)
            : '0',
          emission_ch4_diesel: item.emission_ch4_diesel
            ? item.emission_ch4_diesel.toFixed(8)
            : '0',
          emission_n2o_diesel: item.emission_n2o_diesel
            ? item.emission_n2o_diesel.toFixed(8)
            : '0',
          transport_type_id: item.transport_type_id
        }
      })

      const busItensManual: any[] = busesManual.map((item: any, index: number) => {
        return {
          description: item.description,
          register: item.register.toString(),
          worked_days: parseInt(item.worked_days),
          number_of_passengers: parseInt(item.number_of_passengers),
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4.toFixed(8) : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o.toFixed(8) : '0',
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km.toFixed(8) : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km.toFixed(8) : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0',
          emission_co2_bio: item.emission_co2_bio
            ? item.emission_co2_bio.toFixed(8)
            : '0',
          emission_ch4_bio: item.emission_ch4_bio
            ? item.emission_ch4_bio.toFixed(8)
            : '0',
          emission_n2o_bio: item.emission_n2o_bio
            ? item.emission_n2o_bio.toFixed(8)
            : '0',
          emission_tco2e_bio: item.emission_tco2e_bio
            ? item.emission_tco2e_bio.toFixed(8)
            : '0',
          travelled_distance: item.travelled_distance
            ? item.travelled_distance.toFixed(8)
            : '0',
          factor_gco2_bio_km: item.factor_gco2_bio_km
            ? item.factor_gco2_bio_km.toFixed(8)
            : '0',
          factor_gch4_bio_km: item.factor_gch4_bio_km
            ? item.factor_gch4_bio_km.toFixed(8)
            : '0',
          factor_gn2o_bio_km: item.factor_gn2o_bio_km
            ? item.factor_gn2o_bio_km.toFixed(8)
            : '0',
          emission_co2_diesel: item.emission_co2_diesel
            ? item.emission_co2_diesel.toFixed(8)
            : '0',
          emission_ch4_diesel: item.emission_ch4_diesel
            ? item.emission_ch4_diesel.toFixed(8)
            : '0',
          emission_n2o_diesel: item.emission_n2o_diesel
            ? item.emission_n2o_diesel.toFixed(8)
            : '0',
          transport_type_id: item.transport_type_id
        }
      })

      const ferryItens: any[] = ferries.map((item: any, index: number) => {
        return {
          address: item.address,
          description: item.description,
          register: item.register.toString(),
          worked_days: parseInt(item.worked_days),
          transport_type_id: item.transport_type_id,
          emission_co2: item.emission_co2 ? item.emission_co2.toFixed(8) : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4.toFixed(8) : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o.toFixed(8) : '0',
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km.toFixed(8) : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km.toFixed(8) : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km.toFixed(8) : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e.toFixed(8) : '0',
          travelled_distance: item.travelled_distance ? item.travelled_distance.toFixed(8) : '0',
          number_of_passengers: parseInt(item.number_of_passengers)
        }
      })

      const scope = {
        bus: busItens,
        trains: trainItens,
        ferries: ferryItens,
        trainsManual: trainItensManual,
        busesManual: busItensManual,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope3Service.savePublicTransport(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('PublicTransport', JSON.stringify(scope));

        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const trainTypes = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 1
    })

    setTrainTypes(
      trainTypes?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const trainTypesManual = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 1
    })

    setTrainTypesManual(
      trainTypesManual?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const busTypes = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 2
    })

    setBusTypes(
      busTypes?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const busTypesManual = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 2
    })

    setBusTypesManual(
      busTypesManual?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const ferryTypes = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 3
    })

    setFerryTypes(
      ferryTypes?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getPublicTransport(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'deslocamento_casa_trabalho',
      'transporte_publico'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setBusValues(entity?.bus)
      calculateTotalsBus(entity?.bus)

      setTrainValues(entity?.trains)
      calculateTotalsTrain(entity?.trains)

      setTrainValuesManual(entity?.trainsManual)
      calculateTotalsTrainManual(entity?.trainsManual)

      setBusValuesManual(entity?.busesManual)
      calculateTotalsBusManual(entity?.busesManual)

      setFerryValues(entity?.ferries)
      calculateTotalsFerry(entity?.ferries)

      setChangeScopeBus('load_scope_' + entity.id + 'bus' + entity?.bus.length)
      setChangeScopeTrain('load_scope_' + entity.id + 'trains' + entity?.trains.length)
      setChangeScopeFerry('load_scope_' + entity.id + 'ferries' + entity?.ferries.length)
      setChangeScopeTrainManual('load_scope_' + entity.id + 'trains' + entity?.trainsManual.length)
      setChangeScopeBusManual('load_scope_' + entity.id + 'bus' + entity?.busesManual.length)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setBusValues([])
      calculateTotalsBus([])

      setTrainValues([])
      calculateTotalsTrain([])

      setTrainValuesManual([])
      calculateTotalsTrainManual([])

      setBusValuesManual([])
      calculateTotalsBusManual([])

      setFerryValues([])
      calculateTotalsFerry([])

      setChangeScopeBus('load_scope_0_bus_0')
      setChangeScopeTrain('load_scope_0_trains_0')
      setChangeScopeFerry('load_scope_0_ferries_0')
      setChangeScopeTrainManual('load_scope_0_trains_0')
      setChangeScopeBusManual('load_scope_0_bus_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const handleChangeBus = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = buses.length

        const itens = buses.concat(item)

        setChangeScopeBus('new_scope_item_' + index)

        setBusValues(itens)
        calculateTotalsBus(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_bus', [])
  }

  const handleChangeBusManual = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = busesManual.length

        const itens = busesManual.concat(item)

        setChangeScopeBusManual('new_scope_item_' + index)

        setBusValuesManual(itens)
        calculateTotalsBusManual(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_bus_manual', [])
  }

  const handleChangeTrain = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = trains.length

        const itens = trains.concat(item)

        setChangeScopeTrain('new_scope_item_' + index)

        setTrainValues(itens)
        calculateTotalsTrain(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_train', [])
  }

  const handleChangeTrainManual = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = trainsManual.length

        const itens = trainsManual.concat(item)

        setChangeScopeTrainManual('new_scope_item_' + index)

        setTrainValuesManual(itens)
        calculateTotalsTrainManual(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_train_manual', [])
  }

  const handleChangeFerry = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }

        const index = ferries.length

        const itens = ferries.concat(item)

        setChangeScopeFerry('new_scope_item_' + index)

        setFerryValues(itens)
        calculateTotalsFerry(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_ferry', [])
  }

  const setBusValues = (itens: any, ignore_field?: string) => {
    let changeScopeBus = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`bus.${i}.register`, item.register)
        setValue(`bus.${i}.address`, item.address)
        setValue(`bus.${i}.description`, item.description)

        setValue(`bus.${i}.transport_type_id`, item.transport_type_id)
        setValue(`bus.${i}.transport_type_name`, item.transport_type_name)

        if ('emission_co2' !== ignore_field) {
          setValue(`bus.${i}.emission_co2`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))
        }

        if ('worked_days' !== ignore_field) {
          setValue(`bus.${i}.worked_days`, item.worked_days)
        }

        if ('factor_gco2_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gco2_km`, formatPrice3(item.factor_gco2_km, false))
        }

        if ('factor_gch4_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gch4_km`, formatPrice3(item.factor_gch4_km, false))
        }

        if ('factor_gn2o_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gn2o_km`, formatPrice3(item.factor_gn2o_km, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`bus.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))
        }

        if ('emission_co2_bio' !== ignore_field) {
          setValue(`bus.${i}.emission_co2_bio`, formatPrice3(item.emission_co2_bio, false))
        }

        if ('emission_ch4_bio' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4_bio`, formatPrice3(item.emission_ch4_bio, false))
        }

        if ('emission_n2o_bio' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o_bio`, formatPrice3(item.emission_n2o_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`bus.${i}.emission_tco2e_bio`, formatPrice3(item.emission_tco2e_bio, false))
        }

        if ('factor_gco2_bio_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gco2_bio_km`, formatPrice3(item.factor_gco2_bio_km, false))
        }

        if ('factor_gch4_bio_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gch4_bio_km`, formatPrice3(item.factor_gch4_bio_km, false))
        }

        if ('factor_gn2o_bio_km' !== ignore_field) {
          setValue(`bus.${i}.factor_gn2o_bio_km`, formatPrice3(item.factor_gn2o_bio_km, false))
        }

        if ('travelled_distance' !== ignore_field) {
          setValue(
            `bus.${i}.travelled_distance`,
            formatPrice3(item.travelled_distance, false)
          )
        }

        if ('emission_co2_diesel' !== ignore_field) {
          setValue(`bus.${i}.emission_co2_diesel`, formatPrice3(item.emission_co2_diesel, false))
        }

        if ('emission_ch4_diesel' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4_diesel`, formatPrice3(item.emission_ch4_diesel, false))
        }

        if ('emission_n2o_diesel' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o_diesel`, formatPrice3(item.emission_n2o_diesel, false))
        }

     {/*   if ('number_of_passengers' !== ignore_field) {
          setValue(`bus.${i}.number_of_passengers`, item.number_of_passengers)
        } */}

        changeScopeBus =
          changeScopeBus+
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance +
          '_' +
          item.worked_days
      }
    }

    setBuses(itens)
    setChangeScopeBus(changeScopeBus)
  }

  const setBusValuesManual = (itens: any, ignore_field?: string) => {
    let changeScopeBus = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`bus.${i}.register_manual`, item.register)
        setValue(`bus.${i}.description_manual`, item.description)

        setValue(`bus.${i}.transport_type_id_manual`, item.transport_type_id)
        setValue(`bus.${i}.transport_type_name_manual`, item.transport_type_name)

        if ('emission_co2_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_co2_manual`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4_manual`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o_manual`, formatPrice3(item.emission_n2o, false))
        }

        if ('worked_days_manual' !== ignore_field) {
          setValue(`bus.${i}.worked_days_manual`, item.worked_days)
        }

        if ('factor_gco2_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gco2_km_manual`, formatPrice3(item.factor_gco2_km, false))
        }

        if ('factor_gch4_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gch4_km_manual`, formatPrice3(item.factor_gch4_km, false))
        }

        if ('factor_gn2o_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gn2o_km_manual`, formatPrice3(item.factor_gn2o_km, false))
        }

        if ('emission_tco2e_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_tco2e_manual`, formatPrice3(item.emission_tco2e, false))
        }

        if ('emission_co2_bio_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_co2_bio_manual`, formatPrice3(item.emission_co2_bio, false))
        }

        if ('emission_ch4_bio_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4_bio_manual`, formatPrice3(item.emission_ch4_bio, false))
        }

        if ('emission_n2o_bio_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o_bio_manual`, formatPrice3(item.emission_n2o_bio, false))
        }

        if ('emission_tco2e_bio_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_tco2e_bio_manual`, formatPrice3(item.emission_tco2e_bio, false))
        }

        if ('factor_gco2_bio_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gco2_bio_km_manual`, formatPrice3(item.factor_gco2_bio_km, false))
        }

        if ('factor_gch4_bio_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gch4_bio_km_manual`, formatPrice3(item.factor_gch4_bio_km, false))
        }

        if ('factor_gn2o_bio_km_manual' !== ignore_field) {
          setValue(`bus.${i}.factor_gn2o_bio_km_manual`, formatPrice3(item.factor_gn2o_bio_km, false))
        }

        if ('travelled_distance_manual' !== ignore_field) {
          setValue(
            `bus.${i}.travelled_distance_manual`,
            formatPrice3(item.travelled_distance, false)
          )
        }

        if ('emission_co2_diesel_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_co2_diesel_manual`, formatPrice3(item.emission_co2_diesel, false))
        }

        if ('emission_ch4_diesel_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_ch4_diesel_manual`, formatPrice3(item.emission_ch4_diesel, false))
        }

        if ('emission_n2o_diesel_manual' !== ignore_field) {
          setValue(`bus.${i}.emission_n2o_diesel_manual`, formatPrice3(item.emission_n2o_diesel, false))
        }

       {/* if ('number_of_passengers_manual' !== ignore_field) {
          setValue(`bus.${i}.number_of_passengers_manual`, item.number_of_passengers)
        }*/}

        changeScopeBus =
          changeScopeBus +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance +
          '_' +
          item.worked_days
      }
    }

    setBusesManual(itens)
    setChangeScopeBusManual(changeScopeBusManual)
  }

  const setTrainValues = (itens: any, ignore_field?: string) => {
    let changeScopeTrain = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`train.${i}.register`, item.register)
        setValue(`train.${i}.address`, item.address)
        setValue(`train.${i}.description`, item.description)

        setValue(`train.${i}.transport_type_id`, item.transport_type_id)
        setValue(`train.${i}.transport_type_name`, item.transport_type_name)

       {/*  if ('number_of_passengers' !== ignore_field) {
          setValue(`train.${i}.number_of_passengers`, item.number_of_passengers)
        } */}

        if ('travelled_distance' !== ignore_field) {
          setValue( `train.${i}.travelled_distance`, formatPrice3(Number(item.travelled_distance), false))
        }

        if ('worked_days' !== ignore_field) {
          setValue(`train.${i}.worked_days`, item.worked_days)
        }

        if ('factor_gco2_km' !== ignore_field) {
          setValue(`train.${i}.factor_gco2_km`, formatPrice3(item.factor_gco2_km, false))
        }

        if ('factor_gch4_km' !== ignore_field) {
          setValue(`train.${i}.factor_gch4_km`, formatPrice3(item.factor_gch4_km, false))
        }

        if ('factor_gn2o_km' !== ignore_field) {
          setValue(`train.${i}.factor_gn2o_km`, formatPrice3(item.factor_gn2o_km, false))
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`train.${i}.emission_co2`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`train.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`train.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`train.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))
        }

        changeScopeTrain =
          changeScopeTrain +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance +
          '_' +
          item.worked_days
      }
    }

    setTrains(itens)
    setChangeScopeTrain(changeScopeTrain)
  }

  const setTrainValuesManual = (itens: any, ignore_field?: string) => {
    let changeScopeTrain = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`train.${i}.register_manual`, item.register)
        // setValue(`train.${i}.address_manual`, item.address)
        setValue(`train.${i}.description_manual`, item.description)

        setValue(`train.${i}.transport_type_id_manual`, item.transport_type_id)
        setValue(`train.${i}.transport_type_name_manual`, item.transport_type_name)

       {/* if ('number_of_passengers_manual' !== ignore_field) {
          setValue(`train.${i}.number_of_passengers_manual`, item.number_of_passengers)
        } */}

        if ('travelled_distance_manual' !== ignore_field) {
          setValue( `train.${i}.travelled_distance_manual`, formatPrice3(Number(item.travelled_distance), false))
        }

        if ('worked_days_manual' !== ignore_field) {
          setValue(`train.${i}.worked_days_manual`, item.worked_days)
        }

        if ('factor_gco2_km_manual' !== ignore_field) {
          setValue(`train.${i}.factor_gco2_km_manual`, formatPrice3(item.factor_gco2_km, false))
        }

        if ('factor_gch4_km_manual' !== ignore_field) {
          setValue(`train.${i}.factor_gch4_km_manual`, formatPrice3(item.factor_gch4_km, false))
        }

        if ('factor_gn2o_km_manual' !== ignore_field) {
          setValue(`train.${i}.factor_gn2o_km_manual`, formatPrice3(item.factor_gn2o_km, false))
        }

        if ('emission_co2_manual' !== ignore_field) {
          setValue(`train.${i}.emission_co2_manual`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4_manual' !== ignore_field) {
          setValue(`train.${i}.emission_ch4_manual`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o_manual' !== ignore_field) {
          setValue(`train.${i}.emission_n2o_manual`, formatPrice3(item.emission_n2o, false))
        }

        if ('emission_tco2e_manual' !== ignore_field) {
          setValue(`train.${i}.emission_tco2e_manual`, formatPrice3(item.emission_tco2e, false))
        }

        changeScopeTrain =
          changeScopeTrain +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance +
          '_' +
          item.worked_days
      }
    }

    setTrainsManual(itens)
    setChangeScopeTrainManual(changeScopeTrainManual)
  }

  const setFerryValues = (itens: any, ignore_field?: string) => {
    let changeScopeFerry = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`ferry.${i}.register`, item.register)
        setValue(`ferry.${i}.address`, item.address)
        setValue(`ferry.${i}.description`, item.description)

        setValue(`ferry.${i}.transport_type_id`, item.transport_type_id)
        setValue(`ferry.${i}.transport_type_name`, item.transport_type_name)

      {/*  if ('number_of_passengers' !== ignore_field) {
          setValue(`ferry.${i}.number_of_passengers`, item.number_of_passengers)
        } */}

        if ('travelled_distance' !== ignore_field) {
          setValue(
            `ferry.${i}.travelled_distance`,
            formatPrice3(Number(item.travelled_distance), false)
          )
        }

        if ('worked_days' !== ignore_field) {
          setValue(`ferry.${i}.worked_days`, item.worked_days)
        }

        if ('factor_gco2_km' !== ignore_field) {
          setValue(`ferry.${i}.factor_gco2_km`, formatPrice3(item.factor_gco2_km, false))
        }

        if ('factor_gch4_km' !== ignore_field) {
          setValue(`ferry.${i}.factor_gch4_km`, formatPrice3(item.factor_gch4_km, false))
        }

        if ('factor_gn2o_km' !== ignore_field) {
          setValue(`ferry.${i}.factor_gn2o_km`, formatPrice3(item.factor_gn2o_km, false))
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`ferry.${i}.emission_co2`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`ferry.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`ferry.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`ferry.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))
        }

        changeScopeFerry =
          changeScopeTrain +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance +
          '_' +
          item.worked_days
      }
    }

    setFerries(itens)
    setChangeScopeFerry(changeScopeFerry)
  }

  const calculateTotalsBus = (itens: any) => {
    let emissionCo2Bus = 0
    let emissionCh4Bus = 0
    let emissionN2oBus = 0
    let emissionTco2eBus = 0
    let emissionTco2eBioBus = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Bus = emissionCo2Bus + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Bus = emissionCh4Bus + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oBus = emissionN2oBus + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eBus = emissionTco2eBus + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioBus = emissionTco2eBioBus + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2Bus(emissionCo2Bus)
    setEmissionCh4Bus(emissionCh4Bus)
    setEmissionN2oBus(emissionN2oBus)
    setEmissionTco2eBus(emissionTco2eBus)
    setEmissionTco2eBioBus(emissionTco2eBioBus)

    setChangeScopeBus(
      'calculate_iten_' +
        emissionCo2Bus +
        '_' +
        emissionCh4Bus +
        '_' +
        emissionN2oBus +
        '_' +
        emissionTco2eBus +
        '_' +
        emissionTco2eBioBus
    )
  }

  const calculateTotalsBusManual = (itens: any) => {
    let emissionCo2BusManual = 0
    let emissionCh4BusManual = 0
    let emissionN2oBusManual = 0
    let emissionTco2eBusManual = 0
    let emissionTco2eBioBusManual = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2BusManual = emissionCo2BusManual + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4BusManual = emissionCh4BusManual + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oBusManual = emissionN2oBusManual + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eBusManual = emissionTco2eBusManual + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioBusManual = emissionTco2eBioBusManual + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2BusManual(emissionCo2BusManual)
    setEmissionCh4BusManual(emissionCh4BusManual)
    setEmissionN2oBusManual(emissionN2oBusManual)
    setEmissionTco2eBusManual(emissionTco2eBusManual)
    setEmissionTco2eBioBusManual(emissionTco2eBioBusManual)

    setChangeScopeBusManual(
      'calculate_iten_' +
        emissionCo2BusManual +
        '_' +
        emissionCh4BusManual +
        '_' +
        emissionN2oBusManual +
        '_' +
        emissionTco2eBusManual +
        '_' +
        emissionTco2eBioBusManual
    )
  }

  const calculateTotalsTrain = (itens: any) => {
    let emissionCo2Trains = 0
    let emissionTco2eTrains = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Trains = emissionCo2Trains + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eTrains = emissionTco2eTrains + item.emission_tco2e
        }
      }
    }

    setEmissionCo2Trains(emissionCo2Trains)
    setEmissionTco2eTrains(emissionTco2eTrains)

    setChangeScopeTrain('calculate_iten_' + emissionCo2Trains + '_' + emissionTco2eTrains)
  }

  const calculateTotalsTrainManual = (itens: any) => {
    let emissionCo2TrainsManual = 0
    let emissionTco2eTrainsManual = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2TrainsManual = emissionCo2TrainsManual + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eTrainsManual = emissionTco2eTrainsManual + item.emission_tco2e
        }
      }
    }

    setEmissionCo2TrainsManual(emissionCo2TrainsManual)
    setEmissionTco2eTrainsManual(emissionTco2eTrainsManual)

    setChangeScopeTrainManual('calculate_iten_' + emissionCo2TrainsManual + '_' + emissionTco2eTrainsManual)
  }

  const calculateTotalsFerry = (itens: any) => {
    let emissionCo2Ferry = 0
    let emissionTco2eFerry = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Ferry = emissionCo2Ferry + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eFerry = emissionTco2eFerry + item.emission_tco2e
        }
      }
    }

    setEmissionCo2Ferry(emissionCo2Ferry)
    setEmissionTco2eFerry(emissionTco2eFerry)

    setChangeScopeFerry('calculate_iten_' + emissionCo2Ferry + '_' + emissionTco2eFerry)
  }

  const handleChangeValueBus = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const address = buses[index].address
    const company_id = inventorySelected.company.id
    const transport_type_id = buses[index].transport_type_id
    let worked_days = buses[index].worked_days ? parseFloat(buses[index].worked_days) : 0
    let travelled_distance = buses[index].travelled_distance
      ? parseFloat(buses[index].travelled_distance)
      : 0
    let number_of_passengers = buses[index].number_of_passengers
      ? parseFloat(buses[index].number_of_passengers)
      : 0

    worked_days = worked_days ? worked_days : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculatePublicTransportBus(
        year,
        company_id,
        address,
        worked_days,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        buses[index].emission_co2 = parseFloat(result.emission_co2)
        buses[index].emission_ch4 = parseFloat(result.emission_ch4)
        buses[index].emission_n2o = parseFloat(result.emission_n2o)
        buses[index].emission_tco2e = parseFloat(result.emission_tco2e)
        buses[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        buses[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        buses[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        buses[index].emission_co2_bio = parseFloat(result.emission_co2_bio)
        buses[index].emission_ch4_bio = parseFloat(result.emission_ch4_bio)
        buses[index].emission_n2o_bio = parseFloat(result.emission_n2o_bio)
        buses[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        buses[index].factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
        buses[index].factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
        buses[index].factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
        buses[index].travelled_distance = parseFloat(result.travelled_distance)
        buses[index].emission_co2_diesel = parseFloat(result.emission_co2_diesel)
        buses[index].emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
        buses[index].emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

        setChangeScopeBus(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            worked_days +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setBusValues(buses, ignore_field)
        calculateTotalsBus(buses)
      }
    }

    calculate()
  }

  const handleChangeValueBusManual = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const company_id = inventorySelected.company.id
    const transport_type_id = busesManual[index].transport_type_id
    let worked_days = busesManual[index].worked_days ? parseFloat(busesManual[index].worked_days) : 0
    let travelled_distance = busesManual[index].travelled_distance ? parseFloat(busesManual[index].travelled_distance) : 0
    let number_of_passengers = busesManual[index].number_of_passengers ? parseFloat(busesManual[index].number_of_passengers) : 0

    worked_days = worked_days ? worked_days : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculatePublicTransportBusManual(
        year,
        company_id,
        worked_days,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        busesManual[index].emission_co2 = parseFloat(result.emission_co2)
        busesManual[index].emission_ch4 = parseFloat(result.emission_ch4)
        busesManual[index].emission_n2o = parseFloat(result.emission_n2o)
        busesManual[index].emission_tco2e = parseFloat(result.emission_tco2e)
        busesManual[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        busesManual[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        busesManual[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        busesManual[index].emission_co2_bio = parseFloat(result.emission_co2_bio)
        busesManual[index].emission_ch4_bio = parseFloat(result.emission_ch4_bio)
        busesManual[index].emission_n2o_bio = parseFloat(result.emission_n2o_bio)
        busesManual[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        busesManual[index].factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
        busesManual[index].factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
        busesManual[index].factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
        busesManual[index].travelled_distance = parseFloat(result.travelled_distance)
        busesManual[index].emission_co2_diesel = parseFloat(result.emission_co2_diesel)
        busesManual[index].emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
        busesManual[index].emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

        setChangeScopeBusManual(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            worked_days +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setBusValuesManual(busesManual, ignore_field)
        calculateTotalsBusManual(busesManual)
      }
    }

    calculate()
  }

  const handleOpenModalImportTrain = () => {
    setOpenModalImportTrain(!openModalImportTrain)

    setEntityType(1)
    setEntityName("Deslocamento casa-trabalho realizadas em modal metroferroviário")
  }

  const handleOpenModalImportBus = () => {
    setOpenModalImportBus(!openModalImportBus)

    setEntityType(2)
    setEntityName("Deslocamento casa-trabalho realizadas em ônibus")
  }

  const handleOpenModalImportFerry = () => {
    setOpenModalImportFerry(!openModalImportFerry)

    setEntityType(3)
    setEntityName("Deslocamento casa-trabalho realizadas em balsas")
  }

  const handleOpenModalImportTrainManual = () => {
    setOpenModalImportTrainManual(!openModalImportTrainManual)

    setEntityType(4)
    setEntityName("Deslocamento casa-trabalho realizadas em modal metroferroviário Manual")
  }

  const handleOpenModalImportBusManual = () => {
    setOpenModalImportBusManual(!openModalImportBusManual)

    setEntityType(5)
    setEntityName("Deslocamento casa-trabalho realizadas em ônibus manual")
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }
  
  const handleImport = (type?:number, data?:any[]) => {
    if (type === 1){
      handleImportTrain(data)
    } else if (type === 2){
      handleImportBus(data)
    } else if (type === 3){
      handleImportFerry(data)
    } else if (type === 4){
      handleImportTrainManual(data)
    } else if (type === 5){
      handleImportBusManual(data)
    }
  }
  
  const handleImportTrain = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = trains

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][3]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of trainTypes) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
  
            if (obj){
              const register = data[i][0]
              const address = data[i][1]
              const number_of_passengers = data[i][2]
              const admission = data[i][4]
              const resignation = data[i][5]
              const description = "Dados importados (" + register + ")"
              let worked_days = 0

              if (admission && resignation){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (admission){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (resignation){
                const call = await utilsService.getDifferenceDays(
                  { end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else {
                const call = await utilsService.getDifferenceDays( { year: inventorySelected.year } )

                worked_days = call.result
              }
              
              const scope = {
                register: register,
                description: description,
                address: address,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                number_of_passengers: number_of_passengers,
                travelled_distance: 0,
                worked_days: worked_days,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_ch4: 0,
                emission_n2o: 0,
                emission_tco2e: 0
              }

              const result = await scope3Service.calculatePublicTransportTrain(inventorySelected.year, 
                inventorySelected.company.id, scope.address, scope.worked_days, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
        
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.travelled_distance = parseFloat(result.travelled_distance)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeTrain(`import_scope_data_${data?.length}`);
  
        setTrainValues(itens)
        calculateTotalsTrain(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportTrainManual = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = trainsManual

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][1]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of trainTypesManual) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
  
            if (obj){
              const register = data[i][0]
              const number_of_passengers = data[i][2]
              const travelled_distance = data[i][3]
              const admission = data[i][4]
              const resignation = data[i][5]
              const description = "Dados importados (" + register + ")"
              let worked_days = 0

              if (admission && resignation){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (admission){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (resignation){
                const call = await utilsService.getDifferenceDays(
                  { end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else {
                const call = await utilsService.getDifferenceDays( { year: inventorySelected.year } )

                worked_days = call.result
              }
              
              const scope = {
                register: register,
                description: description,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                number_of_passengers: number_of_passengers,
                travelled_distance: travelled_distance,
                worked_days: worked_days,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_ch4: 0,
                emission_n2o: 0,
                emission_tco2e: 0
              }

              const result = await scope3Service.calculatePublicTransportTrainManual(inventorySelected.year, 
                inventorySelected.company.id, scope.worked_days, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
        
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.travelled_distance = parseFloat(result.travelled_distance)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeTrainManual(`import_scope_data_${data?.length}`);
  
        setTrainValuesManual(itens)
        calculateTotalsTrainManual(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }
  
  const handleImportBus = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = buses

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][3]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of busTypes) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
            // Excel onibus
            if (obj){
              const register = data[i][0]
              const address = data[i][1]
              const description = "Dados importados (" + register + ")"
              const number_of_passengers = data[i][2]
              const admission = data[i][4]
              const resignation = data[i][5]
              let worked_days = 0

              if (admission && resignation){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (admission){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (resignation){
                const call = await utilsService.getDifferenceDays(
                  { end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else {
                const call = await utilsService.getDifferenceDays(
                  { year: inventorySelected.year }
                )

                worked_days = call.result
              }
              
              const scope = {
                address: address,
                register: register,
                description: description,
                worked_days: worked_days,
                number_of_passengers: number_of_passengers,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                travelled_distance: 0,
                emission_tco2e: 0,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_co2_bio: 0,
                emission_ch4_bio: 0,
                emission_n2o_bio: 0,
                emission_tco2e_bio: 0,
                factor_gco2_bio_km: 0,
                factor_gch4_bio_km: 0,
                factor_gn2o_bio_km: 0,
                emission_co2_diesel: 0,
                emission_ch4_diesel: 0,
                emission_n2o_diesel: 0,
                emission_ch4: 0,
                emission_n2o: 0
              }
              
              const result = await scope3Service.calculatePublicTransportBus(inventorySelected.year, 
                inventorySelected.company.id, scope.address, scope.worked_days, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
              
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_co2_bio = parseFloat(result.emission_co2_bio)
                scope.emission_ch4_bio = parseFloat(result.emission_ch4_bio)
                scope.emission_n2o_bio = parseFloat(result.emission_n2o_bio)
                scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                scope.factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
                scope.factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
                scope.factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
                scope.travelled_distance = parseFloat(result.travelled_distance)
                scope.emission_co2_diesel = parseFloat(result.emission_co2_diesel)
                scope.emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
                scope.emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeBus(`import_scope_data_${data?.length}`);
  
        setBusValues(itens)
        calculateTotalsBus(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportBusManual = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = busesManual

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][1]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of busTypesManual) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
            // Excel onibus
            if (obj){
              const register = data[i][0]
              const description = "Dados importados (" + register + ")"
              const number_of_passengers = data[i][2]
              const travelled_distance = data[i][3]
              const admission = data[i][4]
              const resignation = data[i][5]
              let worked_days = 0

              if (admission && resignation){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (admission){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (resignation){
                const call = await utilsService.getDifferenceDays(
                  { end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else {
                const call = await utilsService.getDifferenceDays(
                  { year: inventorySelected.year }
                )

                worked_days = call.result
              }
              
              const scope = {
                register: register,
                description: description,
                worked_days: worked_days,
                number_of_passengers: number_of_passengers,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                travelled_distance: travelled_distance,
                emission_tco2e: 0,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_co2_bio: 0,
                emission_ch4_bio: 0,
                emission_n2o_bio: 0,
                emission_tco2e_bio: 0,
                factor_gco2_bio_km: 0,
                factor_gch4_bio_km: 0,
                factor_gn2o_bio_km: 0,
                emission_co2_diesel: 0,
                emission_ch4_diesel: 0,
                emission_n2o_diesel: 0,
                emission_ch4: 0,
                emission_n2o: 0
              }
              
              const result = await scope3Service.calculatePublicTransportBusManual(inventorySelected.year, 
                inventorySelected.company.id, scope.worked_days, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
              
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_co2_bio = parseFloat(result.emission_co2_bio)
                scope.emission_ch4_bio = parseFloat(result.emission_ch4_bio)
                scope.emission_n2o_bio = parseFloat(result.emission_n2o_bio)
                scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                scope.factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
                scope.factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
                scope.factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
                scope.travelled_distance = parseFloat(result.travelled_distance)
                scope.emission_co2_diesel = parseFloat(result.emission_co2_diesel)
                scope.emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
                scope.emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeBusManual(`import_scope_data_${data?.length}`);
  
        setBusValuesManual(itens)
        calculateTotalsBusManual(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }
  
  const handleImportFerry = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = ferries

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][2]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of ferryTypes) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
  
            if (obj){
              const register = data[i][0]
              const address = data[i][1]
              const number_of_passengers = data[i][3]
              const description = "Dados importados (" + register + ")"
              const admission = data[i][4]
              const resignation = data[i][5]
              let worked_days = 0

              if (admission && resignation){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (admission){
                const call = await utilsService.getDifferenceDays(
                  { start_date: admission, year: inventorySelected.year }
                )

                worked_days = call.result
              } else if (resignation){
                const call = await utilsService.getDifferenceDays(
                  { end_date: resignation, year: inventorySelected.year }
                )

                worked_days = call.result
              } else {
                const call = await utilsService.getDifferenceDays(
                  { year: inventorySelected.year }
                )

                worked_days = call.result
              }
              
              const scope = {
                address: address,
                register: register,
                description: description,
                worked_days: worked_days,
                number_of_passengers: number_of_passengers,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                travelled_distance: 0,
                emission_tco2e: 0,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_ch4: 0,
                emission_n2o: 0
              }

              const result = await scope3Service.calculatePublicTransportFerry(inventorySelected.year, 
                inventorySelected.company.id, scope.address, scope.worked_days, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
        
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.travelled_distance = parseFloat(result.travelled_distance)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeFerry(`import_scope_data_${data?.length}`);
  
        setFerryValues(itens)
        calculateTotalsFerry(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleChangeValueTrain = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const address = trains[index].address
    const company_id = inventorySelected.company.id
    const transport_type_id = trains[index].transport_type_id
    let worked_days = trains[index].worked_days ? parseFloat(trains[index].worked_days) : 0
    let travelled_distance = trains[index].travelled_distance ? parseFloat(trains[index].travelled_distance) : 0
    let number_of_passengers = trains[index].number_of_passengers ? parseFloat(trains[index].number_of_passengers) : 0

    worked_days = worked_days ? worked_days : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculatePublicTransportTrain(
        year,
        company_id,
        address,
        worked_days,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        trains[index].emission_co2 = parseFloat(result.emission_co2)
        trains[index].emission_ch4 = parseFloat(result.emission_ch4)
        trains[index].emission_n2o = parseFloat(result.emission_n2o)
        trains[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        trains[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        trains[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        trains[index].emission_tco2e = parseFloat(result.emission_tco2e)
        trains[index].travelled_distance = parseFloat(result.travelled_distance)

        setChangeScopeTrain(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            worked_days +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setTrainValues(trains, ignore_field)
        calculateTotalsTrain(trains)
      }
    }

    calculate()
  }

  const handleChangeValueTrainManual = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const company_id = inventorySelected.company.id
    const transport_type_id = trainsManual[index].transport_type_id
    let worked_days = trainsManual[index].worked_days ? parseFloat(trainsManual[index].worked_days) : 0
    let travelled_distance = trainsManual[index].travelled_distance ? parseFloat(trainsManual[index].travelled_distance) : 0
    let number_of_passengers = trainsManual[index].number_of_passengers ? parseFloat(trainsManual[index].number_of_passengers) : 0

    worked_days = worked_days ? worked_days : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0


    async function calculate() {
      const result = await scope3Service.calculatePublicTransportTrainManual(
        year,
        company_id,
        worked_days,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        trainsManual[index].emission_co2 = parseFloat(result.emission_co2)
        trainsManual[index].emission_ch4 = parseFloat(result.emission_ch4)
        trainsManual[index].emission_n2o = parseFloat(result.emission_n2o)
        trainsManual[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        trainsManual[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        trainsManual[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        trainsManual[index].emission_tco2e = parseFloat(result.emission_tco2e)
        trainsManual[index].travelled_distance = parseFloat(result.travelled_distance)

        setChangeScopeTrain(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            worked_days +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setTrainValuesManual(trainsManual, ignore_field)
        calculateTotalsTrainManual(trainsManual)
      }
    }

    calculate()
  }

  const handleChangeValueFerry = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const address = ferries[index].address
    const company_id = inventorySelected.company.id
    const transport_type_id = ferries[index].transport_type_id
    let worked_days = ferries[index].worked_days
      ? parseFloat(ferries[index].worked_days)
      : 0
    let travelled_distance = ferries[index].travelled_distance
      ? parseFloat(ferries[index].travelled_distance)
      : 0
    let number_of_passengers = ferries[index].number_of_passengers
      ? parseFloat(ferries[index].number_of_passengers)
      : 0

    worked_days = worked_days ? worked_days : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculatePublicTransportFerry(
        year,
        company_id,
        address,
        worked_days,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        ferries[index].emission_co2 = parseFloat(result.emission_co2)
        ferries[index].emission_ch4 = parseFloat(result.emission_ch4)
        ferries[index].emission_n2o = parseFloat(result.emission_n2o)
        ferries[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        ferries[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        ferries[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        ferries[index].emission_tco2e = parseFloat(result.emission_tco2e)
        ferries[index].travelled_distance = parseFloat(result.travelled_distance)

        setChangeScopeTrain(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            worked_days +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setFerryValues(ferries, ignore_field)
        calculateTotalsFerry(ferries)
      }
    }

    calculate()
  }

  function handleDeleteBus(index: number) {
    const itens = []

    for (let i = 0; i < buses?.length; i++) {
      if (i !== index) {
        itens.push(buses[i])
      }
    }

    setChangeScopeBus('remove_scope_item_' + index)

    setValue('transport_type_bus', [])

    setBusValues(itens)
    calculateTotalsBus(itens)
  }

  function handleDeleteBusManual(index: number) {
    const itens = []

    for (let i = 0; i < busesManual?.length; i++) {
      if (i !== index) {
        itens.push(busesManual[i])
      }
    }

    setChangeScopeBusManual('remove_scope_item_' + index)

    setValue('transport_type_bus', [])

    setBusValuesManual(itens)
    calculateTotalsBusManual(itens)
  }

  function handleDeleteTrain(index: number) {
    const itens = []

    for (let i = 0; i < trains?.length; i++) {
      if (i !== index) {
        itens.push(trains[i])
      }
    }

    setChangeScopeTrain('remove_scope_item_' + index)

    setValue('transport_type_train', [])

    setTrainValues(itens)
    calculateTotalsTrain(itens)
  }

  function handleDeleteTrainManual(index: number) {
    const itens = []

    for (let i = 0; i < trainsManual?.length; i++) {
      if (i !== index) {
        itens.push(trainsManual[i])
      }
    }

    setChangeScopeTrainManual('remove_scope_item_' + index)

    setValue('transport_type_train', [])

    setTrainValuesManual(itens)
    calculateTotalsTrainManual(itens)
  }

  function handleDeleteFerry(index: number) {
    const itens = []

    for (let i = 0; i < trains?.length; i++) {
      if (i !== index) {
        itens.push(trains[i])
      }
    }

    setChangeScopeFerry('remove_scope_item_' + index)

    setValue('transport_type_ferry', [])

    setFerryValues(itens)
    calculateTotalsFerry(itens)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'deslocamento_casa_trabalho',
          'transporte_publico'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'deslocamento_casa_trabalho',
        file: base64,
        filename: filename,
        second_name: 'transporte_publico'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'deslocamento_casa_trabalho',
          'transporte_publico'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  function moeda(a: string, e: string, r: string): string {
    // Remove caracteres não numéricos
    let n = a.replace(/[^\d]+/g, '');

    if (n.length === 0) return '0' + r + '000';

    while (n.length < 4) {  
        n = '0' + n;
    }

    const len = n.length;
    const intPart = n.substring(0, len - 4);
    const decPart = n.substring(len - 4);

    // Remove zeros desnecessários da parte inteira
    const cleanedIntPart = intPart.replace(/^0+/, '') || '0';

    let formattedValue = cleanedIntPart.replace(/\B(?=(\d{3})+(?!\d))/g, e);
    formattedValue += r + decPart;

    return formattedValue;
}

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 1 - Cálculo de emissões por transporte de funcionários para deslocamento
                    casa-trabalho realizadas em modal metroferroviário, de acordo com
                    quilômetros percorridos no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>

                </div>

                {!isCommonUser && trainTypes && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_train"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_train')}
                          options={trainTypes}
                          onChange={handleChangeTrain}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportTrain()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeTrain && trains && trains.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de transporte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Número de passageiros'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Distancia percorrida (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Dias trabalhados / ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fatores de emissão'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões N₂O (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Endereço'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gCO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gCH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gN₂O/ P.km'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {trains.map((train: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`train.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `train.${index}.register`,
                                      event.target.value
                                    )
                                    trains[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`train.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `train.${index}.description`,
                                      event.target.value
                                    )
                                    trains[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`train.${index}.address`)}
                                  onBlur={(event) => {
                                    setValue(`train.${index}.address`, event.target.value)
                                    trains[index].address = event.target.value

                                    handleChangeValueTrain(index, 'address')
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {train.transport_type_name}
                                </span>
                              </td>
                              <td>
                              <input
                                    readOnly={true}
                                    style={{ width: 120, textAlign: 'right', background: '#E6E6E6' }}
                                    className="input-item"
                                    {...register(`train.${index}.number_of_passengers`, {
                                      required: true,
                                    })}
                                    defaultValue={1} 
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      const numberValue = toNumberValues(value);

                                      // Evite modificar diretamente event.target.value
                                      // Em vez disso, utilize o `setValue` para atualizar o valor no formulário
                                      setValue(`train.${index}.number_of_passengers`, numberValue);

                                      // Atualize o estado local se necessário
                                      trains[index].number_of_passengers = numberValue;

                                      handleChangeValueTrain(index, 'number_of_passengers');
                                    }}
                                  />

                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`train.${index}.travelled_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    trains[index].travelled_distance = value

                                    setValue(
                                      `train.${index}.travelled_distance`,
                                      formatValue
                                    )

                                    handleChangeValueTrain(index, 'travelled_distance')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`train.${index}.worked_days`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    trains[index].worked_days = numberValue

                                    setValue(`train.${index}.worked_days`, numberValue)

                                    handleChangeValueTrain(index, 'worked_days')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.factor_gco2_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.factor_gch4_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.factor_gn2o_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(train.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteTrain(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={13}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >

                            {formatPrice3(emissionTco2eTrains, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {trains && trains.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.train && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '98%',
                  marginTop: 10,
                  marginLeft: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 0,
                    justifyContent: 'space-between',
                    marginBottom: 10
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 30
                    }}
                  >
                    <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                      Opção 1.1 - distância adicionada manualmente - Cálculo de emissões por transporte de funcionários para deslocamento
                      casa-trabalho realizadas em modal metroferroviário, de acordo com
                      quilômetros percorridos no ano inventariado.
                    </span>
                    <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                      Selecione, o tipo de transporte ao lado, para adicionar nova linha
                    </span>

                  </div>

                  {!isCommonUser && trainTypesManual && (
                    <div
                      style={{
                        marginTop: 15,
                        marginRight: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                      }}
                    >
                      <Controller
                        control={control}
                        name="transport_type_train_manual"
                        render={({ field }) => (
                          <Select placeholder="[Selecione]"
                            {...field}
                            styles={{
                              control: (styles) => ({
                                ...styles,
                                width: '350px',
                                height: 40,
                                backgroundColor: 'rgb(246, 246, 246)'
                              }),
                              option: (styles) => {
                                return { ...styles, color: '#31363F' }
                              }
                            }}
                            defaultValue={getValues('transport_type_train_manual')}
                            options={trainTypesManual}
                            onChange={handleChangeTrainManual}
                          />
                        )}
                      />

                      <button
                        className="button-view"
                        style={{ height: 40, marginLeft: 5 }}
                        onClick={() => handleOpenModalImportTrainManual()}
                        type="button"
                      >
                        Importar Dados
                      </button>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    overflow: 'auto',
                    position: 'relative',
                    marginTop: 0,
                    width: 'calc(100vw - 320px)',
                    maxWidth: 'calc(100vw - 320px)'
                  }}
                >
                  {changeScopeTrainManual && trainsManual && trainsManual.length > 0 && (
                    <table
                      style={{
                        maxWidth: '100%',
                        width: '4000px',
                        borderCollapse: 'collapse'
                      }}
                      className="table-scope"
                    >
                      <thead>
                        <tr>
                          <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                            {'Percurso'}
                          </th>
                          <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                            {'Tipo de transporte'}
                          </th>
                          <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                            {'Número de passageiros'}
                          </th>
                          <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                            {'Distancia percorrida (km)'}
                          </th>
                          <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                            {'Dias trabalhados / ano'}
                          </th>
                          <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                            {'Fatores de emissão'}
                          </th>
                          <th
                            className="head-tr-item-bottom"
                            style={{ width: 200 }}
                            rowSpan={2}
                          >
                            {'Emissões CO₂ (t)'}
                          </th>
                          <th
                            className="head-tr-item-bottom"
                            style={{ width: 200 }}
                            rowSpan={2}
                          >
                            {'Emissões CH₄ (t)'}
                          </th>
                          <th
                            className="head-tr-item-bottom"
                            style={{ width: 200 }}
                            rowSpan={2}
                          >
                            {'Emissões N₂O (t)'}
                          </th>
                          <th
                            className="head-tr-item-bottom"
                            style={{ width: 200 }}
                            rowSpan={2}
                          >
                            {'Emissões totais em CO₂e (t)'}
                          </th>
                          {!isCommonUser && (
                            <th
                              className="head-tr-item"
                              style={{ width: 50 }}
                              rowSpan={2}
                            />
                          )}
                        </tr>
                        <tr>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Registro do colaborador'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'Descrição'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'gCO₂/ P.km'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'gCH₄/ P.km'}
                          </th>
                          <th className="head-tr-item-bottom" style={{ width: 200 }}>
                            {'gN₂O/ P.km'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {trainsManual.map((train: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(`train.${index}.register_manual`, {
                                      required: true
                                    })}
                                    onChange={(event) => {
                                      setValue(
                                        `train.${index}.register_manual`,
                                        event.target.value
                                      )
                                      trainsManual[index].register = event.target.value
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 200 }}
                                    className="input-item"
                                    {...register(`train.${index}.description_manual`, {
                                      required: true
                                    })}
                                    onChange={(event) => {
                                      setValue(
                                        `train.${index}.description_manual`,
                                        event.target.value
                                      )
                                      trainsManual[index].description = event.target.value
                                    }}
                                  />
                                </td>
                                <td style={{ width: 200 }}>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 200,
                                      fontSize: 12,
                                      fontWeight: 'bold',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {train.transport_type_name}
                                  </span>
                                </td>
                                <td>
                                <input
  readOnly={isCommonUser}
  style={{ width: 120, textAlign: 'right', background: '#E6E6E6' }}
  className="input-item"
  {...register(`train.${index}.number_of_passengers_manual`, {
    required: true
  })}
  defaultValue={1}
  value={1} 
  onChange={(event) => {
    event.target.value = '1'; 

    
    trainsManual[index].number_of_passengers = 1;

    
    setValue(
      `train.${index}.number_of_passengers_manual`,
      1
    );

    handleChangeValueTrainManual(index, 'number_of_passengers_manual');
  }}
/>

                                </td>
                                <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`train.${index}.travelled_distance_manual`, {
                                    required: true
                                  })}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const inputValue = currency(event);
                                    const formattedValue = moeda(inputValue, '.', ',');

                                    const valueStr = formattedValue.replace(/[.,]/g, '');
                                    const value = valueStr ? parseFloat(valueStr) / 10000 : 0;

                                    trainsManual[index].travelled_distance = value;

                                    setValue(
                                      `train.${index}.travelled_distance_manual`,
                                      formattedValue
                                    );

                                    handleChangeValueTrainManual(index, 'travelled_distance_manual');
                                    event.target.value = formattedValue;
                                  }}
                                />
                              </td>
                                <td>
                                  <input
                                    readOnly={isCommonUser}
                                    style={{ width: 120, textAlign: 'right' }}
                                    className="input-item"
                                    {...register(`train.${index}.worked_days_manual`, {
                                      required: true
                                    })}
                                    onChange={(event) => {
                                      const { value } = event.target
                                      const numberValue = toNumberValues(value)

                                      event.target.value = numberValue

                                      trainsManual[index].worked_days = numberValue

                                      setValue(`train.${index}.worked_days_manual`, numberValue)

                                      handleChangeValueTrainManual(index, 'worked_days_manual')
                                    }}
                                  />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.factor_gco2_km, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.factor_gch4_km, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.factor_gn2o_km, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.emission_co2, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.emission_ch4, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.emission_n2o, false)}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      textAlign: 'right',
                                      display: 'inline-block'
                                    }}
                                  >
                                    {formatPrice3(train.emission_tco2e, false)}
                                  </span>
                                </td>
                                {!isCommonUser && (
                                  <td style={{ textAlign: 'center' }}>
                                    <div
                                      onClick={() => handleDeleteTrainManual(index)}
                                      style={{
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        width: 70
                                      }}
                                    >
                                      <img src={TrashIcon} alt="lixeirinha"/>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr style={{ fontWeight: 'bold' }}>
                          <td
                            colSpan={12}
                            className="head-tr-item"
                            style={{ paddingRight: 5, textAlign: 'right' }}
                          >
                            {'Total'}
                          </td>
                          <td className="head-tr-item">
                            <span
                              style={{
                                marginRight: 5,
                                width: 120,
                                fontSize: 12,
                                textAlign: 'right',
                                display: 'inline-block'
                              }}
                            >

                              {formatPrice3(emissionTco2eTrainsManual, false)}
                            </span>
                          </td>
                          {!isCommonUser && <td className="head-tr-item" />}
                        </tr>
                      </tfoot>
                    </table>
                  )}

                  {trainsManual && trainsManual.length === 0 && (
                    <Uninformed
                      text={
                        isCommonUser
                          ? `Nenhum tipo de transporte cadastrado`
                          : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                        prosseguimento`
                      }
                    />
                  )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      marginBottom: 10
                    }}
                  >
                    {errors && errors.trainsManual && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          margin: 10
                        }}
                      >
                        <span
                          className="requiredLabel"
                          style={{ fontSize: 14, marginTop: 10 }}
                        >
                          * Todos os campos são obrigatórios
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 2 - Cálculo de emissões por transporte de funcionários para deslocamento
                      casa-trabalho realizadas em ônibus, de acordo com quilômetros
                      percorridos no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && busTypes && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_bus"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_bus')}
                          options={busTypes}
                          onChange={handleChangeBus}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportBus()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeBus && buses && buses.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de transporte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Número de passageiros'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Distancia percorrida (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Dias trabalhados / ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fator de emissão - combustíveis fósseis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fator de emissão - biocombustíveis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Óleo Diesel'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Biodiesel'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Emissões fósseis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Emissões de CO₂e biogênico (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                         <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Endereço'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões N₂O (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões N₂O (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões N₂O (t)'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {buses.map((bus: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`bus.${index}.register`, event.target.value)
                                    buses[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `bus.${index}.description`,
                                      event.target.value
                                    )
                                    buses[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`bus.${index}.address`)}
                                  onBlur={(event) => {
                                    setValue(`bus.${index}.address`, event.target.value)
                                    buses[index].address = event.target.value

                                    handleChangeValueBus(index, 'address')
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {bus.transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={true}
                                  style={{ width: 120, textAlign: 'right', background: '#E6E6E6' }}
                                  className="input-item"
                                  {...register(`bus.${index}.number_of_passengers`, {
                                    required: true
                                  })}
                                  defaultValue={1}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                   {/* event.target.value = numberValue */}

                                    buses[index].number_of_passengers = numberValue

                                    setValue(
                                      `bus.${index}.number_of_passengers`,
                                      numberValue
                                    )

                                    handleChangeValueBus(index, 'number_of_passengers')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.travelled_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    buses[index].travelled_distance = value

                                    setValue(
                                      `bus.${index}.travelled_distance`,
                                      formatValue
                                    )

                                    handleChangeValueBus(index, 'travelled_distance')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.worked_days`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    buses[index].worked_days = numberValue

                                    setValue(`bus.${index}.worked_days`, numberValue)

                                    handleChangeValueBus(index, 'worked_days')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gco2_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gch4_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gn2o_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gco2_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gch4_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gn2o_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_co2_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_ch4_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_n2o_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_tco2e), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_tco2e_bio),
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteBus(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={19}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Bus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4Bus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oBus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioBus, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {buses && buses.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.bus && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* opção 2.2 distância manual */}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 2.1 - distância adicionada manualmente - Cálculo de emissões por transporte de funcionários para deslocamento
                      casa-trabalho realizadas em ônibus, de acordo com quilômetros
                      percorridos no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && busTypesManual && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_bus_manual"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_bus_manual')}
                          options={busTypesManual}
                          onChange={handleChangeBusManual}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportBusManual()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeBusManual && busesManual && busesManual.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={2}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de transporte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Número de passageiros'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Distancia percorrida (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Dias trabalhados / ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fator de emissão - combustíveis fósseis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fator de emissão - biocombustíveis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Óleo Diesel'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Biodiesel'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Emissões fósseis'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Emissões de CO₂e biogênico (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões N₂O (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões N₂O (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões N₂O (t)'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {busesManual.map((bus: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.register_manual`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`bus.${index}.register_manual`, event.target.value)
                                    busesManual[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.description_manual`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `bus.${index}.description_manual`,
                                      event.target.value
                                    )
                                    busesManual[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {bus.transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={true}
                                  style={{ width: 120, textAlign: 'right', background: '#E6E6E6' }}
                                  className="input-item"
                                  {...register(`bus.${index}.number_of_passengers_manual`, {
                                    required: true
                                  })}
                                  defaultValue={1}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    {/*event.target.value = numberValue*/}

                                    busesManual[index].number_of_passengers = numberValue

                                    setValue(
                                      `bus.${index}.number_of_passengers_manual`,
                                      numberValue
                                    )

                                    handleChangeValueBusManual(index, 'number_of_passengers_manual')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.travelled_distance_manual`, {
                                    required: true
                                  })}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const inputValue = currency(event);
                                    const formattedValue = moeda(inputValue, '.', ',');

                                    const valueStr = formattedValue.replace(/[.,]/g, '');
                                    const value = valueStr ? parseFloat(valueStr) / 10000 : 0;
                                    
                                    busesManual[index].travelled_distance = value;

                                    setValue(
                                      `bus.${index}.travelled_distance_manual`,
                                      formattedValue
                                    );

                                    handleChangeValueBusManual(index, 'travelled_distance_manual');
                                    event.target.value = formattedValue;
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.worked_days_manual`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    busesManual[index].worked_days = numberValue

                                    setValue(`bus.${index}.worked_days_manual`, numberValue)

                                    handleChangeValueBusManual(index, 'worked_days_manual')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gco2_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gch4_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gn2o_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gco2_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gch4_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gn2o_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_co2_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_ch4_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_n2o_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_tco2e), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_tco2e_bio),
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteBusManual(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={18}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2BusManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4BusManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oBusManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBusManual, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioBusManual, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {busesManual && busesManual.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.bus && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Opção 3 - Cálculo de emissões por transporte de funcionários para descolamento
                      casa-trabalho realizadas em balsas, de acordo com quilômetros
                      percorridos no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && busTypes && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_ferry"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_ferry')}
                          options={ferryTypes}
                          onChange={handleChangeFerry}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportFerry()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeFerry && ferries && ferries.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Percurso'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de transporte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Número de passageiros'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Distancia percorrida (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Dias trabalhados / ano'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>
                          {'Fatores de emissão'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões CO₂ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões CH₄ (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões N₂O (t)'}
                        </th>
                        <th
                          className="head-tr-item-bottom"
                          style={{ width: 200 }}
                          rowSpan={2}
                        >
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Registro do colaborador'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Descrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Endereço'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gCO₂/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gCH₄/ P.km'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'gN₂O/ P.km'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ferries.map((ferry: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`ferry.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `ferry.${index}.register`,
                                      event.target.value
                                    )
                                    ferries[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`ferry.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `ferry.${index}.description`,
                                      event.target.value
                                    )
                                    ferries[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`ferry.${index}.address`)}
                                  onBlur={(event) => {
                                    setValue(`ferry.${index}.address`, event.target.value)
                                    ferries[index].address = event.target.value

                                    handleChangeValueFerry(index, 'address')
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {ferry.transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={true}
                                  style={{ width: 120, textAlign: 'right', background: '#E6E6E6' }}
                                  className="input-item"
                                  {...register(`ferry.${index}.number_of_passengers`, {
                                    required: true
                                  })}
                                  defaultValue={1}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                   {/* event.target.value = numberValue */}

                                    ferries[index].number_of_passengers = numberValue

                                    setValue(
                                      `ferry.${index}.number_of_passengers`,
                                      numberValue
                                    )

                                    handleChangeValueFerry(index, 'number_of_passengers')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`ferry.${index}.travelled_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    ferries[index].travelled_distance = value

                                    setValue(
                                      `ferry.${index}.travelled_distance`,
                                      formatValue
                                    )

                                    handleChangeValueFerry(index, 'travelled_distance')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`ferry.${index}.worked_days`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    ferries[index].worked_days = numberValue

                                    setValue(`ferry.${index}.worked_days`, numberValue)

                                    handleChangeValueFerry(index, 'worked_days')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.factor_gco2_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.factor_gch4_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.factor_gn2o_km, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(ferry.emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteFerry(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={10}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Ferry, false)}
                          </span>
                        </td>
                        <td colSpan={2} className="head-tr-item" />
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eFerry, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {ferries && ferries.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.ferry && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {!ignoreFill && changeScopeBus && (
            <div
              style={{
                borderStyle: "dashed",
                margin: 5,
                borderWidth: "1px",
                width: "calc(100vw - 330px)",
                maxWidth: "calc(100vw - 330px)",
                borderColor: "#9E9E9E",
                height: "40px",
                display: "flex",
                flexDirection: "row",
                marginLeft: 15,
              }}
            >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  margin: 10,
                  textAlign: "right",
                  width: "30%",
                }}
              >
                {formatPrice3(emissionTco2eBus + emissionTco2eFerry + emissionTco2eTrains + emissionTco2eBusManual + emissionTco2eTrainsManual, false)}
              </span>
            </div>
          )}
          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {openModalImportTrain && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportTrain}
          handleModalVisible={handleOpenModalImportTrain}
          templateFileName="template_displacement_Public1.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalImportTrainManual && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportTrainManual}
          handleModalVisible={handleOpenModalImportTrainManual}
          templateFileName="template_displacement_Public_Manual_1.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalImportBus && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportBus}
          handleModalVisible={handleOpenModalImportBus}
          templateFileName="template_displacement_Public2.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalImportBusManual && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportBusManual}
          handleModalVisible={handleOpenModalImportBusManual}
          templateFileName="template_displacement_Public_Manual_2.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalImportFerry && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportFerry}
          handleModalVisible={handleOpenModalImportFerry}
          templateFileName="template_displacement_Public3.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left" }}>
            <TitleText level={2} title={entityName ? entityName : ""} color="blue" />
          </div>

          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal > 
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    </Container>
  )
}
