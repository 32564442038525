import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import AttachmentIcon from '../../../../assets/icons/attachment.svg'
import DownloadIcon from '../../../../assets/icons/download.svg'
import InfoButtonMin from '../../../../assets/icons/info-button.svg';
import { useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import fileDownload from 'js-file-download';
import { toast } from "react-hot-toast";
import TrashIcon from '../../../../assets/icons/trash.svg'

interface Inputs {
  organizational_limit: any;
  consolidation_approach: any;
  organizational_limit_name: string;
  consolidation_approach_name: string;
  chart: string;
  chart_filename: string;
}

export const InventoryInventoryLimit = () => {
  //variaveis para subir arquivos
  const [files, setFiles] = useState<any>([])



  const history = useHistory();

  const [chartChanged, setChartChanged] = useState(false);
  const location = useLocation();
  const [chartFile, setChartFile] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [infoControlOper, setInfoControlOper] = useState<string | undefined>();
  const [infoPartSocietaria, setInfoPartSocietaria] = useState<string | undefined>();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const [limitOrganizational, setLimitOrganizational] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);

  useEffect(() => {
    const item: any = location?.state;

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    } else {
      history.push({
        pathname: '/inventario',
      });
    }

    setInfoControlOper("Os limites organizacionais abrangem obrigatoriamente o controle operacional e " +
      "opcionalmente a participação societária. O controle operacional contabiliza 100% das emissões das " +
      "operações controladas pela instituição, sem incluir operações em que haja apenas sua participação.")

    setInfoPartSocietaria("A contabilização das emissões é feita de acordo com a porcentagem de participação " +
      "da empresa na operação, e consequentemente, na divisão de riscos e recompensas.")
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newInventory = {
        organizational_limit: formData.organizational_limit.value,
        consolidation_approach: parseInt(formData.consolidation_approach),
        chart: chartChanged ? formData.chart : undefined,
        chart_filename: chartChanged ? formData.chart_filename : undefined,
      };

      const result = await inventoryService.updateInventoryLimitId(
        inventorySelected.company.id, inventorySelected.year, newInventory
      );

      if (result && !result.error) {
        //history.goBack()

       // reset();
       // setErrorApi(undefined);

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  useEffect(() => {
    const options: any[] = []

    options.push({ value: 1, label: 'Matriz' })
    options.push({ value: 2, label: 'Controlada' })
    options.push({ value: 3, label: 'Unidade' })

    setLimitOrganizational(options)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  async function getInventorysById(company_id: number, year: number) {
    const inventory = await inventoryService.getById(company_id, year);

    if (inventory?.organizational_limit === 1) {
      setValue("organizational_limit", { value: 1, label: 'Matriz' });
      setValue("organizational_limit_name", 'Matriz');
    } else if (inventory?.organizational_limit === 2) {
      setValue("organizational_limit", { value: 2, label: 'Controlada' });
      setValue("organizational_limit_name", 'Controlada');
    } else if (inventory?.organizational_limit === 3) {
      setValue("organizational_limit", { value: 3, label: 'Unidade' });
      setValue("organizational_limit_name", 'Unidade');
    }

    if (inventory?.consolidation_approach) {
      setValue("consolidation_approach", inventory?.consolidation_approach.toString());
    }

    setChartFile(inventory?.chart_url)

    setInventorySelected(inventory)

    //Deixar representando os arquivos, deve alterar a função getCompensationFiles.
    const result = await inventoryService.getCompensationFiles(inventory.company.id, inventory.year)

    setFiles(result.files)
  }

  function getFileName(originalFileName: string) {
    let ext = ''
    const index = originalFileName.lastIndexOf('.')

    if (index > -1) {
      ext = "." + originalFileName.substring(index)
    }

    return 'Organograma - ' + inventorySelected?.company?.corporate_name + ' - ' +
      inventorySelected.year.toString() + ext
  }

  function base64toBlob(base64Image: string) {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);

    const uInt8Array = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadFile = () => {
    if (chartFile) {
      if (chartChanged) {
        const filename = getFileName(getValues("chart_filename"))

        fileDownload(base64toBlob(getValues("chart")), filename)
      } else {
        const filename = getFileName(chartFile)

        axios.get(chartFile, { responseType: 'blob' })
          .then((res) => { fileDownload(res.data, filename) })
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const chartChangeHandler = (event: any) => {
    async function saveImage(base64?: string) {
      setValue("chart", base64 ? base64 : "")
      setChartFile(base64 ? base64 : "")
      setValue("chart_filename", file.name)
    }

    setChartChanged(true)

    const file = event.target.files[0]
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;

      saveImage(base64?.toString())
    };
  };
  //Funções para subir arquivos:::
  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        file: base64,
        filename: filename
      }

      const result = await inventoryService.saveCompensationFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getCompensationFiles(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => {
        const base64 = reader.result

        saveImage(base64?.toString(), file.name)
      }
    }
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteCompensationFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getCompensationFiles(
          inventorySelected.company.id,
          inventorySelected.year
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }


  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }
  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name} />
            <TitleText level={4} title="Limites organizacionais" />
          </div>

          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)" }} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50 }} >

          <label style={{
            marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px",
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold"
          }} >
            Limites Organizacionais
          </label>

          {onlyView === false && limitOrganizational && (
            <Controller control={control} name="organizational_limit"
              rules={{ required: true }} render={({ field }) => (
                <Select placeholder="[Selecione]" {...field} styles={{
                  control: (styles) => ({
                    ...styles, width: "95%", height: 40,
                    marginTop: 10, marginRight: 10, backgroundColor: "rgb(246, 246, 246)",
                  }),
                  option: (styles) => { return { ...styles, color: "#31363F", }; },
                }}
                  defaultValue={getValues("organizational_limit")}
                  options={limitOrganizational}
                />
              )}
            />
          )}
          {onlyView === true && (
            <input style={{ width: '95%', height: 100 }} disabled
              {...register("organizational_limit_name")} />
          )}

          {errors.organizational_limit && (
            <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{
            marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px",
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold"
          }} >
            Qual abordagem de consolidação foi utilizado no inventario?
          </label>

          {onlyView === false && (
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: 'start', marginTop: 5 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input type="radio" value="1" disabled={onlyView} style={{ width: 20, marginLeft: 0, marginRight: 10 }}
                  {...register("consolidation_approach", { required: true })}
                  onChange={(event) => {
                    setValue("consolidation_approach", event.target.value);
                  }} />

                <span style={{
                  fontSize: 16, color: "#31363F", marginTop: 8,
                  fontFamily: "Poppins, sans-serif", opacity: 0.9
                }}>
                  Abordagem de controle operacional
                </span>

                <div className="tooltip" style={{ margin: 10 }}>
                  <img src={InfoButtonMin} alt="information"/>
                  <span className="tooltiptext">{infoControlOper}</span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <input type="radio" value="2" disabled={onlyView} style={{ width: 20, marginLeft: 0, marginRight: 10 }}
                  {...register("consolidation_approach", { required: true })}
                  onChange={(event) => {
                    setValue("consolidation_approach", event.target.value);
                  }} />

                <span style={{
                  fontSize: 16, color: "#31363F", marginTop: 8,
                  fontFamily: "Poppins, sans-serif", opacity: 0.9
                }}>
                  Participação societária
                </span>

                <div className="tooltip" style={{ margin: 10 }}>
                  <img src={InfoButtonMin} alt="information"/>
                  <span className="tooltiptext">{infoPartSocietaria}</span>
                </div>
              </div>
            </div>
          )}

          <label style={{
            marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px",
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold"
          }} >
            Insira aqui o organograma da sua empresa.
          </label>

          {onlyView === true && (
            <input style={{ width: '95%', height: 100 }} disabled
              {...register("consolidation_approach_name")} />
          )}

          {errors.consolidation_approach && (
            <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          {onlyView === false && (
            <div style={{
              borderStyle: "dashed", marginTop: 20, borderWidth: "1px",
              paddingTop: 30, paddingBottom: 30, borderColor: "#9E9E9E",
              width: "95%", textAlign: "center", cursor: "pointer"
            }}
              onClick={() => (document.querySelector("#attachmentInput") as HTMLInputElement).click()}>
              <img src={AttachmentIcon} alt="coisa" width={64} />

              <br />

              <span style={{ padding: 5, fontSize: 14, fontStyle: "bold" }}>
                Insira um organograma que ilustre a estrutura da organização <br />(unidades e outras organizações controladas, etc).
              </span>
            </div>
          )}
          
          <input type="file" id="attachmentInput" style={{ display: 'none' }} multiple
            name="file" onChange={handleAttachmentChange} />


          {files && files.length > 0 && (
            <div style={{
              borderStyle: "dashed", marginTop: 5, borderWidth: "1px", margin: 5,
              paddingTop: 5, paddingBottom: 5, borderColor: "#9E9E9E",
              width: "95%", textAlign: "center", cursor: "pointer"
            }} >
              <table style={{ width: '100%', borderCollapse: 'collapse' }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: 200 }}>
                      {'Certidão de verificação'}
                    </th>
                    <th className="head-tr-item" style={{ width: 50 }} />
                  </tr>
                </thead>
                <tbody>
                  {files.map((file: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ width: '90%', textAlign: 'left' }}>
                            <span style={{ marginLeft: 5, fontSize: 12, textAlign: 'left', display: 'inline-block' }} >
                              {file.filename}
                            </span>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-flex', textAlign: 'center' }} >
                              {(user.id === file.user_id || user.profile === 'sinergia') && (
                                <div onClick={() => handleDeleteFile(file.id)}
                                  style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              )}
                              <div onClick={() => handleDownloadFile(file.file_url)}
                                style={{ cursor: 'pointer', textAlign: 'center', width: 30 }} >
                                <img alt="downloadIcon" src={DownloadIcon} width={24} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
