import { api } from "./api";

async function getById(fuelId: number) {
  try {
    const { data } = await api.get(`/fuel/${fuelId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getBySector(sectorId: number, fuelType: number) {
  try {
    const { data } = await api.get(`/fuel/by_sectors/${sectorId}/${fuelType}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getFactorsCh4(fuelId: number, year: number, name: string) {
  try {
    const { data } = await api.get(`/fuel/factors_ch4/${fuelId}/${year}/${name}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getFactorsN2o(fuelId: number, year: number, name: string) {
  try {
    const { data } = await api.get(`/fuel/factors_n2o/${fuelId}/${year}/${name}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/fuel", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(fuel: any) {
  try {
    const { data } = await api.post(`/fuel`, fuel);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(fuelId: number, fuel: any) {
  try {
    const { data } = await api.put(`/fuel/${fuelId}`, fuel);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateFactorsCh4(fuelId: number, year: number, name: string,  itens: any) {
  try {
    const { data } = await api.put(`/fuel/factors_ch4/${fuelId}/${year}/${name}}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateFactorsN2o(fuelId: number, year: number, name: string,  itens: any) {
  try {
    const { data } = await api.put(`/fuel/factors_n2o/${fuelId}/${year}/${name}}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(fuelId: number) {
  try {
    const { data } = await api.delete(`/fuel/${fuelId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function getMobileCombustion() {
  try {
    const { data } = await api.get("/fuel/mobile_combustion");
    return data
  } catch (error) {
    console.log(error);
  }
}

async function updateMonthly(fuelId: number, year: number, name: string, itens: any) {
  try {
    const { data } = await api.put(`/fuel/monthly_factor/${fuelId}/${year}/${name}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function getMonthly(fuelId: number, year: number, name: string) {
  try {
    const { data } = await api.get(`/fuel/monthly_factor/${fuelId}/${year}/${name}`);
    return data
  } catch (error) {
    console.log(error);
  }
}



async function getByNameAndYear(name: string, year: number) {
  try {
    const { data } = await api.get(`/fuel/${year}/${name}`);
    return data; 
  } catch (error) {
    console.error("Erro ao buscar combustível por nome e ano:", error);
    throw new Error('Erro ao buscar combustível por nome e ano.');
  }
}


async function getByName(name: any) {
  try {
    const { data } = await api.get(`/fuel/get_name/${name}`);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const fuelService = {
  getByName,
  getById,
  getBySector,
  getMonthly,
  getFactorsCh4,
  getFactorsN2o,
  getByParams,
  create,
  updateById,
  deleteById,
  updateMonthly,
  updateFactorsCh4,
  updateFactorsN2o,
  getMobileCombustion,
  getByNameAndYear
};

export default fuelService;
