import Parser from 'html-react-parser'
import Select from 'react-select'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice, formatPrice3, formatPrice5 } from '../../../../utils/transform'
import inventoryService from '../../../../services/inventoryService'
import vehicleFuelService from '../../../../services/vehicleFuelService'
import scope2Service from '../../../../services/scope2Service'
import scopeService from '../../../../services/scopeService'
import TrashIcon from '../../../../assets/icons/trash.svg'
import { Button } from '../../../../components/Button/styles'
import { Modal } from '../../../../components/Modal'
import { DescriptionText } from '../../../../components/DescriptionText'
import { TitleText } from '../../../../components/TitleText'
import { GoBack } from '../../../../components/GoBack'
import ReportRecordsCheckbox from '../../components/ReportRecordsCheckbox'
import { Uninformed } from '../../../../components/Uninformed'
import ScopeEvidenceAttachments from '../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../components/Loading'
import fuelService from '../../../../services/fuelService'

export const PurchaseThermalEnergy = () => {
  const history = useHistory()
  const [files, setFiles] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModalInfo, setOpenModalInfo] = useState(false)
  const [fuelType, setFuelTyoe] = useState<any>()
  const [openModal, setOpenModal] = useState(false)
  const [totalsBio, setTotalsBio] = useState<any>([])
  const [totalsFossil, setTotalsFossil] = useState<any>([])
  const [fuelTotals, setFuelTotals] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  const location = useLocation()
  const [scopes, setScopes] = useState<any>([])
  const [fuels, setFuels] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [changeScope, setChangeScope] = useState<string | undefined>()
  const [emissionCo2, setEmissionCo2] = useState<any>()
  const [emissionCh4, setEmissionCh4] = useState<any>()
  const [emissionN2o, setEmissionN2o] = useState<any>()
  const [emissionTCo2e, setEmissionTCo2e] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()
  const [emissionCh4Bio, setEmissionCh4Bio] = useState<any>()
  const [emissionN2oBio, setEmissionN2oBio] = useState<any>()
  const [emissionTCo2eBio, setEmissionTCo2eBio] = useState<any>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-2'
      })
    }

    getGuidelines()
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySlug(2, 'compra_de_energia_termica')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModalInfo(!openModalInfo)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itens: any[] = scopes.map((item: any) => {
        return {
          source: item.source,
          description: item.description,
          vehicle_fuel_id: item.vehicle_fuel_id,
          kettle_efficiency: item.kettle_efficiency,
          purchased_steam_gj: item.purchased_steam_gj,
          fuel_consumption_gj: item.fuel_consumption_gj
        }
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        emission_tco2e: emissionCo2, 
        emission_tco2e_bio: emissionCo2Bio,
        itens: itens
      }

      const result = await scope2Service.savePurchaseThermalEnergy(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('PurchaseThermalEnergy', JSON.stringify(scope));

        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const fuelsItens = await vehicleFuelService.getThermalEnergy()

    const fuels = fuelsItens?.itens.map((fuel: any) => {
      return {
        label: fuel.name,
        value: fuel.id
      }
    })

    setFuels(fuels)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope2Service.getPurchaseThermalEnergy(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      2,
      'compra_de_energia_termica'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setScopeSelected(entity)

      setScopeItens(entity?.itens)
      setIgnoreFill(entity.ignore_fill)
      setChangeScope('load_scope_' + entity.id + '_scopes_' + entity?.itens.length)
    } else {
      setScopeSelected({})

      setScopeItens([])
      setIgnoreFill(false)
      setChangeScope('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          kettle_efficiency: undefined,
          purchased_steam_gj: undefined,
          fuel_consumption_gj: undefined
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setChangeScope('new_scope_item_' + index)

        setScopeItens(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuels', [])
  }

  const setScopeItens = (scopes: any, ignore_field?: string) => {
    let changeScore = 'change_scope_item_'

    if (scopes) {
      for (let i = 0; i < scopes?.length; i++) {
        const item = scopes[i]

        if (item) {
          setValue(`scope.${i}.source`, item.source)
          setValue(`scope.${i}.description`, item.description)

          setValue(`scope.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
          setValue(`scope.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

          if ('kettle_efficiency' !== ignore_field) {
            setValue(`scope.${i}.kettle_efficiency`, item.kettle_efficiency)
          }

          if ('purchased_steam_gj' !== ignore_field) {
            setValue(`scope.${i}.purchased_steam_gj`, item.purchased_steam_gj)
          }

          if ('fuel_consumption_gj' !== ignore_field) {
            setValue(`scope.${i}.fuel_consumption_gj`, item.fuel_consumption_gj)
          }

          changeScore =
            changeScore +
            '_' +
            item.vehicle_fuel_id +
            '_' +
            item.kettle_efficiency +
            '_' +
            item.purchased_steam_gj
        }
      }
    }

    setScopes(scopes)
    calculateTotals(scopes)
    setChangeScope(changeScore)
  }

  function handleDeleteScope(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setChangeScope('remove_scope_item_' + index)

    setValue('fuels', [])

    setScopeItens(itens)
  }

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const kettle_efficiency = scopes[index].kettle_efficiency
    const purchased_steam_gj = scopes[index].purchased_steam_gj

    async function calculate() {
      const result = await scope2Service.calculatePurchaseThermalEnergy(
        kettle_efficiency,
        purchased_steam_gj
      )

      if (result) {
        scopes[index].fuel_consumption_gj = result.fuel_consumption_gj

        setChangeScope(
          'change_scope_item_' +
            index +
            '_' +
            kettle_efficiency +
            '_' +
            purchased_steam_gj
        )

        setScopeItens(scopes, ignore_field)
      }
    }

    calculate()
  }

  const handleOpenFuelModal = (type?: number) => {
    async function load() {
      const fuels = await vehicleFuelService.getByParams({ limit: 9000, page: 1 })

      if (fuels && fuels.itens) {
        const itens: any[] = []

        for (const item of fuels.itens) {
          if (
            item.fuel_type === type &&
            (item.factor_thermal_energy_co2 ||
              item.factor_thermal_energy_ch4 ||
              item.factor_thermal_energy_n2o)
          ) {
            itens.push(item)
          }
        }

        setFuelTyoe(type)
        setFuelTotals(itens)
        setOpenModal(!openModal)
      }
    }

    load()
  }

  const calculateTotals = (scopes: any) => {
    async function load() {
      const fuels = await fuelService.getByParams({ limit: 9000, page: 1 })
      const percents = await vehicleFuelService.getAnualFactor()

      if (fuels && fuels.itens) {
        const totalsBio: any[] = []
        const totalsFossil: any[] = []

        let emissionCo2 = 0
        let emissionCh4 = 0
        let emissionN2o = 0
        let emissionTCo2e = 0
        let emissionCo2Bio = 0
        let emissionCh4Bio = 0
        let emissionN2oBio = 0
        let emissionTCo2eBio = 0

        for (const item of fuels.itens) {
          const a: any = {}
          let energy_consumption_gj = 0
          let emission_co2 = 0
          let emission_ch4 = 0
          let emission_n2o = 0
          let emission_tco2e = 0

          for (const param of percents){
            if (param.fuel_id === item.id || param.bio_fuel_id === item.id){
              for (const scope of scopes) {
                if (scope.vehicle_fuel_id === param.id) {
                  let calc_fuel_consumption_gj = 0

                  if (param.fuel_id === item.id && param.fuel_anual_factor > 0){
                    calc_fuel_consumption_gj = scope.fuel_consumption_gj * (param.fuel_anual_factor / 100)
                  }else if ( param.bio_fuel_id === item.id && param.bio_fuel_anual_factor > 0){
                    calc_fuel_consumption_gj = scope.fuel_consumption_gj * (param.bio_fuel_anual_factor / 100)
                  }

                  energy_consumption_gj = energy_consumption_gj + calc_fuel_consumption_gj
                }
              }
            }
          }

          if (energy_consumption_gj && energy_consumption_gj > 0){
            if (item.factor_thermal_energy_co2) {
              emission_co2 = (energy_consumption_gj * item.factor_thermal_energy_co2) / 1000

              if (item.fuel_type === 1) {
                emissionCo2 = emissionCo2 + emission_co2
              } else {
                emissionCo2Bio = emissionCo2Bio + emission_co2
              }
            }
  
            if (item.factor_thermal_energy_ch4) {
              emission_ch4 = (energy_consumption_gj * item.factor_thermal_energy_ch4) / 1000

              if (item.fuel_type === 1) {
                emissionCh4 = emissionCh4 + emission_ch4
              } else {
                emissionCh4Bio = emissionCh4Bio + emission_ch4
              }
            }
  
            if (item.factor_thermal_energy_n2o) {
              emission_n2o = (energy_consumption_gj * item.factor_thermal_energy_n2o) / 1000

              if (item.fuel_type === 1) {
                emissionN2o = emissionN2o + emission_n2o
              } else {
                emissionN2oBio = emissionN2oBio + emission_n2o
              }
            }
            
            emission_tco2e = (emission_co2 * 1) + (emission_ch4 * 28) + (emission_n2o * 265)
  
            if (item.fuel_type === 1) {
              emissionTCo2e = emissionTCo2e + emission_tco2e
            } else {
              emissionTCo2eBio = emissionTCo2eBio + (emission_ch4 * 28) + (emission_n2o * 265)
            }

            if (emission_co2 || emission_ch4 || emission_n2o || emission_tco2e){
              a['name'] = item.name
              a['unity'] = item.unity
              a['fuel_type'] = item.fuel_type
              a['energy_consumption_gj'] = energy_consumption_gj
              a['emission_co2'] = emission_co2
              a['emission_ch4'] = emission_ch4
              a['emission_n2o'] = emission_n2o
              a['emission_tco2e'] = emission_tco2e
  
              if (item.fuel_type === 1){
                totalsFossil.push(a)
              } else {
                totalsBio.push(a)
              }
            }
          }
        }

        setTotalsBio(totalsBio)
        setTotalsFossil(totalsFossil)

        setEmissionCo2(emissionCo2 ? emissionCo2 : 0)
        setEmissionCo2Bio(emissionCo2Bio ? emissionCo2Bio : 0)

        setEmissionCh4(emissionCh4 ? emissionCh4 : 0)
        setEmissionCh4Bio(emissionCh4Bio ? emissionCh4Bio : 0)

        setEmissionN2o(emissionN2o ? emissionN2o : 0)
        setEmissionN2oBio(emissionN2oBio ? emissionN2oBio : 0)

        setEmissionTCo2e(emissionTCo2e ? emissionTCo2e : 0)
        setEmissionTCo2eBio(emissionTCo2eBio ? emissionTCo2eBio : 0)
      }
    }

    load()
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'compra_de_energia_termica'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 2,
        name: 'compra_de_energia_termica',
        file: base64,
        filename: filename,
        second_name: ''
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          2,
          'compra_de_energia_termica'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 02 - ' + title}  />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && (
          <div
            style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
          />
        )}

        {!ignoreFill && (
          <div
            style={{
              overflow: 'hidden auto',
              position: 'relative',
              marginTop: 0,
              height: 'calc(100vh - 230px)',
              maxHeight: 'calc(100vh - 230px)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 0,
                justifyContent: 'space-between',
                marginBottom: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginTop: 5
                  }}
                >
                  Dados de Combustível, Fervedor, Vapor, Fator de Emissão do Vapor
                  Comprado e emissões por fonte
                </span>
                <span style={{ textAlign: 'start', fontSize: 14 }}>
                  Selecione, ao lado, o combustível desejado para incluir abaixo
                </span>
              </div>

              {!isCommonUser && fuels && (
                <Controller
                  control={control}
                  name="fuels"
                  render={({ field }) => (
                    <Select placeholder="[Selecione]"
                      {...field}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: '350px',
                          height: 40,
                          marginTop: 5,
                          marginRight: 20,
                          backgroundColor: 'rgb(246, 246, 246)'
                        }),
                        option: (styles) => {
                          return { ...styles, color: '#31363F' }
                        }
                      }}
                      defaultValue={getValues('fuels')}
                      options={fuels}
                      onChange={handleChangeFuel}
                    />
                  )}
                />
              )}
            </div>

            <div
              style={{
                overflow: 'auto',
                position: 'relative',
                marginTop: 20,
                width: 'calc(100vw - 300px)',
                maxHeight: 'calc(100vh - 440px)'
              }}
            >
              {changeScope && scopes && scopes.length > 0 && (
                <table
                  style={{
                    maxWidth: '100%',
                    width: '4000px',
                    borderCollapse: 'collapse'
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                        {'Fonte'}
                      </th>
                      <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                        {'Combustível'}
                      </th>
                      <th className="head-tr-item" style={{ width: 120 }} rowSpan={2}>
                        {'Eficiência do fervedor (%)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 120 }} rowSpan={2}>
                        {'Vapor comprado (GJ)'}
                      </th>
                      <th className="head-tr-item" style={{ width: 120 }} rowSpan={2}>
                        {'Consumo de combustível (GJ)'}
                      </th>
                      {!isCommonUser && (
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={2} />
                      )}
                    </tr>
                    <tr>
                      <th className="head-tr-item-bottom" style={{ width: 200 }}>
                        {'Registro'}
                      </th>
                      <th className="head-tr-item-bottom" style={{ width: 200 }}>
                        {'Descrição'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {scopes.map((scope: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scope.${index}.source`, { required: true })}
                                onChange={(event) => {
                                  setValue(`scope.${index}.source`, event.target.value)
                                  scopes[index].source = event.target.value
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`scope.${index}.description`, {
                                  required: true
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `scope.${index}.description`,
                                    event.target.value
                                  )
                                  scopes[index].description = event.target.value
                                }}
                              />
                            </td>
                            <td style={{ width: 200 }}>
                              <span
                                style={{
                                  marginLeft: 5,
                                  width: 200,
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  display: 'inline-block'
                                }}
                              >
                                {scope.vehicle_fuel_name}
                              </span>
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 120, textAlign: 'right' }}
                                className="input-item"
                                {...register(`scope.${index}.kettle_efficiency`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].kettle_efficiency = value

                                  setValue(
                                    `scope.${index}.kettle_efficiency`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'kettle_efficiency')
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 120, textAlign: 'right' }}
                                className="input-item"
                                {...register(`scope.${index}.purchased_steam_gj`)}
                                onChange={(event) => {
                                  const formatValue = event.target.value;
                                  const valueStr = formatValue.replace('.', '')
                                  const value = valueStr
                                    ? parseFloat(valueStr.replace(',', '.'))
                                    : 0

                                  scopes[index].purchased_steam_gj = value

                                  setValue(
                                    `scope.${index}.purchased_steam_gj`,
                                    formatValue
                                  )

                                  handleChangeValue(index, 'purchased_steam_gj')
                                }}
                              />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 120,
                                  fontSize: 12,
                                  textAlign: 'right',
                                  display: 'inline-block'
                                }}
                              >
                                {formatPrice5(scope.fuel_consumption_gj, false)}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: 'center' }}>
                                <div
                                  onClick={() => handleDeleteScope(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img alt="deleteIcon" src={TrashIcon} />
                                </div>
                              </td>
                            )}
                          </tr>
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </table>
              )}

              {!scopes.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum combustível cadastrado`
                      : `Nenhum combustível foi selecionado, favor selecionar para dar
                    prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginBottom: 10
                }}
              >
                {errors && errors.scope && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      margin: 10
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            {changeScope && ((totalsFossil && totalsFossil.length > 0) || (totalsBio && totalsBio.length > 0)) && (
              <div style={{ marginTop: 10, marginBottom: 20, marginRight: 5 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                    <span
                      style={{
                        textAlign: 'start',
                        fontSize: 16,
                        fontWeight: 'bold',
                        marginTop: 5
                      }}
                    >
                      Dados de emissões por combustível utilizado
                    </span>
                  </div>
                </div>

                <table style={{ maxWidth: '100%', height: 'auto', marginTop: 10, borderCollapse: 'collapse' }} className="table-scope" >
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "40%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Combustível'}
                      </th>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "20%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Consumo energético por combustível (GJ)'}
                      </th>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "10%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Emissões de CO₂ (t)'}
                      </th>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "10%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Emissões de Ch4 (t)'}
                      </th>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "10%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Emissões de N₂O (t)'}
                      </th>
                      <th style={{ backgroundColor: '#D8D7D7', fontSize: '12px', height: '25px', width: "10%",
                            borderColor: 'white', borderWidth: '1px', borderStyle: 'solid' }} >
                        {'Emissões de CO₂e (t)'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalsFossil.map((fuel: any) => {
                      return (
                        <React.Fragment key={fuel.id}>
                          <tr>
                            <td style={{ width: "40%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  fontWeight: 'bold', display: 'inline-block' }} >
                                {fuel.name}
                              </span>
                              <br />
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  display: 'inline-block' }} >
                                {fuel.fuel_type === 1 ? 'Combustível fóssil' : 'Biocombustível'}
                              </span>
                            </td>
                            <td style={{ width: "20%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.energy_consumption_gj, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_n2o, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_tco2e, false)}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                    <tr>
                      <td className="head-tr-item" style={{ paddingRight: 5, textAlign: 'right', fontWeight: "bold" }} colSpan={2}>
                        {'Emissões totais por utilização de combustíveis fósseis'}
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionCo2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionCh4, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionN2o, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionTCo2e, false)}
                        </span>
                      </td>
                    </tr>
                    {totalsBio.map((fuel: any) => {
                      return (
                        <React.Fragment key={fuel.id}>
                          <tr>
                            <td style={{ width: "40%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  fontWeight: 'bold', display: 'inline-block' }} >
                                {fuel.name}
                              </span>
                              <br />
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  display: 'inline-block' }} >
                                {fuel.fuel_type === 1 ? 'Combustível fóssil' : 'Biocombustível'}
                              </span>
                            </td>
                            <td style={{ width: "20%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12, 
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.energy_consumption_gj, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_co2, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_ch4, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_n2o, false)}
                              </span>
                            </td>
                            <td style={{ width: "10%" }}>
                              <span style={{ marginRight: 5, width: "100%", fontSize: 12,
                                  textAlign: 'right', display: 'inline-block' }} >
                                {formatPrice5(fuel.emission_tco2e, false)}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })}
                    <tr>
                      <td className="head-tr-item" style={{ paddingRight: 5, textAlign: 'right', fontWeight: "bold" }} colSpan={2}>
                        {'Emissões totais por utilização de combustíveis fósseis'}
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionCo2Bio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionCh4Bio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionN2oBio, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block', fontWeight: "bold" }} >
                          {formatPrice5(emissionTCo2eBio, false)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {!ignoreFill && changeScope && (
              <div
                className="row"
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTCo2e + emissionTCo2eBio, false)}
                </span>
              </div>
            )}
  
            {!ignoreFill && changeScope && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionCo2Bio, false)}
                </span>
              </div>
            )}

            {changeScope && scopes && scopes.length > 0 && (
              <div style={{ marginTop: 20, marginRight: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <span
                      style={{
                        textAlign: 'start',
                        fontSize: 16,
                        fontWeight: 'bold',
                        marginTop: 5
                      }}
                    >
                      Fatores de emissão de CO₂, CH₄ e N₂O padrão
                    </span>
                    <span style={{ textAlign: 'start', fontSize: 14 }}>
                      Clique ao lado para ver os detalhes por tipo de combustível
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '50%',
                      justifyContent: 'right'
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        width: '180px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px',
                        marginRight: 10
                      }}
                      onClick={() => handleOpenFuelModal(1)}
                    >
                      {`Combustíveis fósseis`}
                    </button>

                    <button
                      type="button"
                      style={{
                        width: '180px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleOpenFuelModal(2)}
                    >
                      {`Biocombustíveis`}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={handleAttachmentChange}
                />
              </div>
            </div>
          </div>
        )}
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenFuelModal()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText
            level={1}
            title={fuelType === 1 ? 'Combustíveis fósseis' : 'Biocombustíveis'}
          />
          <DescriptionText title={`Fatores de emissão de CO₂, CH₄ e N₂O padrão`} />

          <table
            style={{
              maxWidth: '100%',
              width: '450px',
              height: 'auto',
              marginTop: 10,
              borderCollapse: 'collapse'
            }}
            className="table-scope"
          >
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'Combustível'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'Fator de emissão de CO₂ (kg/GJ)'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'Fator de emissão de CH₄ (kg/GJ)'}
                </th>
                <th
                  style={{
                    backgroundColor: '#D8D7D7',
                    fontSize: '12px',
                    height: '25px',
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                  }}
                >
                  {'Fator de emissão de N₂O (kg/GJ)'}
                </th>
              </tr>
            </thead>
            <tbody>
              {fuelTotals.map((fuel: any) => {
                return (
                  <React.Fragment key={fuel.id}>
                    <tr>
                      <td style={{ width: 500 }}>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 250,
                            fontSize: 12,
                            fontWeight: 'bold',
                            display: 'inline-block'
                          }}
                        >
                          {fuel.name}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(fuel.factor_thermal_energy_co2, false)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(fuel.factor_thermal_energy_ch4, false)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            marginLeft: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: 'right',
                            display: 'inline-block'
                          }}
                        >
                          {formatPrice5(fuel.factor_thermal_energy_n2o, false)}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        isOpen={openModalInfo}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
