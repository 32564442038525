import { Container } from './styles'
import React from 'react'
import { DownloadResumePDFButton } from './pdf'
import { firstTableItems } from './utils/firstTableItems'
import { fourthTableItems } from './utils/fourthTableItems'
import { fifthTableItems } from './utils/fifthTableItems'
import { sixthTableItems } from './utils/sixthTableItems'
import { seventhTableItems } from './utils/seventhTableItems'
import { Company } from './interfaces/Company'
import { Option } from './interfaces/Option'
import companyService from '../../services/companyService'
import resumeService from '../../services/resumeService'
import { GoBack } from '../../components/GoBack'
import { Line } from '../../components/Line'
import { Table } from './components/Table'
import { TitleText } from '../../components/TitleText'
import { Uninformed } from '../../components/Uninformed'
import Select from 'react-select'
import { ValueType } from 'react-select'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { formatPrice3 } from '../../utils/transform'
import inventoryService from '../../services/inventoryService'
import scope3Service from '../../services/scope3Service'
import { Loading } from '../../components/Loading'

export const RpeResume = ()  => {
  const [pdfData, setPdfData] = useState<any>();
  const [dataReady, setDataReady] = useState(false);
  const [totais, setTotais] = useState<any>();
  const [years, setYears] = useState<Option[]>();
  const [companies, setCompanies] = useState<Company[]>();
  const [selectedYear, setSelectedYear] = useState<any>();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectYearValue, setSelectYearValue] = useState<any>();
  const [selectCompanyValue, setSelectCompanyValue] = useState<any>();
  const [upstreamTco2e, setUpstreamTco2e] = useState<number | undefined>();
  const [upstreamCo2Bio, setUpstreamCo2bio] = useState<number | undefined>();
  const [downstreamTco2e, setDownstreamTco2e] = useState<number | undefined>();
  const [downstreamCo2Bio, setDownstreamCo2bio] = useState<number | undefined>();
  const [wasteTco2e, setWasteTco2e] = useState<any | undefined>();
  const [wasteCo2Bio, setWasteCo2bio] = useState<number | undefined>();
  const [businessTco2e, setBusinessTco2e] = useState<number | undefined>();
  const [businessCo2Bio, setBusinessCo2bio] = useState<number | undefined>();
  const [homeWorkTco2e, setHomeWorkTco2e] = useState<number | undefined>();
  const [homeWorkCo2Bio, setHomeWorkCo2bio] = useState<number | undefined>();
  const [loadingModal, setLoadingModal] = useState(false);

  const handleSelectedCompanyChange = useCallback(
    (item: ValueType<Option, false>) => {
      const company = companies?.find((c: Company) => c.id === item?.value);
      setSelectedCompany(company);
      setSelectCompanyValue(item);
    },
    [companies]
  );

  const handleSelectedYearChange = useCallback(
    (item: ValueType<Option, false>) => {
      setSelectedYear(item?.value);
      setSelectYearValue(item);
    },
    []
  );

  const getCompanies = useCallback(async () => {
    try {
      const result = await companyService.getByParams({ limit: 9000, page: 1 })
      setCompanies(result?.itens);
    } catch (error) {
      console.error('Erro ao obter empresas:', error);
    }
  }, []);

  const getSelectedCompanyYears = useCallback(() => {
    const companyInfo = companies?.find((c: Company) => c.id === selectedCompany?.id);
    const yearsData = companyInfo?.years || [];
  
    if (typeof yearsData === 'string') {
      const yearsArray = yearsData.split(', ');
      const yearsFormatted = yearsArray.map((yearItem: string) => ({
        label: yearItem,
        value: yearItem,
      }));
  
      setYears(yearsFormatted);
  
      if (yearsFormatted.length > 0) {
        const defaultYear = yearsFormatted[0].value;
        setSelectedYear(defaultYear);
        setSelectYearValue({ label: defaultYear, value: defaultYear });
      } else {
        setSelectedYear(null);
        setSelectYearValue(null);
      }
    }
  }, [companies, selectedCompany]);

  const getPDFResumeData = useCallback(async () => {
    setLoadingModal(true)
    try {
        if (selectedCompany && selectedYear) {
            const companyId = selectedCompany.id;

            const data = await resumeService.getResume(companyId, selectedYear);
            const upstream = await scope3Service.getTransportDistributionUpstream(companyId, selectedYear);
            const downstream = await scope3Service.getTransportDistributionDownstream(companyId, selectedYear);
            const homework = await scope3Service.getHomeWorkDisplacement(companyId, selectedYear);
            const waste = await scope3Service.getSolidWaste(companyId, selectedYear);
            const business = await scope3Service.getBusinessTrip(companyId, selectedYear);

            // Obter as emissões diretamente do nível superior do objeto e arredondar
            const { emission_tco2e: upstream_tco2e, emission_tco2e_bio: upstream_co2bio } = upstream || {};
            setUpstreamTco2e(upstream_tco2e ? parseFloat(upstream_tco2e.toFixed(4)) : 0);
            setUpstreamCo2bio(upstream_co2bio ? parseFloat(upstream_co2bio.toFixed(4)) : 0);

            const { emission_tco2e: downstream_tco2e, emission_tco2e_bio: downstream_co2bio } = downstream || {};
            setDownstreamTco2e(downstream_tco2e ? parseFloat(downstream_tco2e.toFixed(4)) : 0);
            setDownstreamCo2bio(downstream_co2bio ? parseFloat(downstream_co2bio.toFixed(4)) : 0);

            const { emission_tco2e: homework_tco2e, emission_tco2e_bio: homework_co2bio } = homework || {};
            setHomeWorkTco2e(homework_tco2e ? parseFloat(homework_tco2e.toFixed(4)) : 0);
            setHomeWorkCo2bio(homework_co2bio ? parseFloat(homework_co2bio.toFixed(4)) : 0);

            const { emission_tco2e: waste_tco2e, emission_tco2e_bio: waste_co2bio } = waste || {};
            setWasteTco2e(waste_tco2e ? parseFloat(waste_tco2e.toFixed(4)) : 0);
            setWasteCo2bio(waste_co2bio ? parseFloat(waste_co2bio.toFixed(4)) : 0);

            const { emission_tco2e: business_tco2e, emission_tco2e_bio: business_co2bio } = business || {};
            setBusinessTco2e(business_tco2e ? parseFloat(business_tco2e.toFixed(4)) : 0);
            setBusinessCo2bio(business_co2bio ? parseFloat(business_co2bio.toFixed(4)) : 0);

            setPdfData(data);
        }
    } catch (error) {
        console.error('Erro ao obter dados do PDF:', error);
    }
    setLoadingModal(false)
}, [selectedCompany, selectedYear]);


  const getTotais = useCallback(async () => {
    try {
      if (selectedCompany && selectedYear) {
        const companyId = selectedCompany.id;
        const totals = await inventoryService.getTotals(companyId, selectedYear);

        if (totals && totals.scope3) {
          setTotais(totals.scope3);
        } else {
          console.error('Erro: A propriedade "scope3" não está presente nos totais recebidos.');
        }
      }
    } catch (error) {
      console.error('Erro ao obter totais:', error);
    }
  }, [selectedCompany, selectedYear]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    getTotais();
  }, [getTotais]);

  useEffect(() => {
    getSelectedCompanyYears();
  }, [getSelectedCompanyYears]);

  useEffect(() => {
    if (selectedCompany && selectedYear) {
      getPDFResumeData();
    }
  }, [getPDFResumeData, selectedCompany, selectedYear]);

  useEffect(() => {
    if (
      pdfData &&
      upstreamTco2e !== undefined &&
      upstreamCo2Bio !== undefined &&
      downstreamTco2e !== undefined &&
      downstreamCo2Bio !== undefined &&
      wasteTco2e !== undefined &&
      wasteCo2Bio !== undefined &&
      businessTco2e !== undefined &&
      businessCo2Bio !== undefined &&
      homeWorkTco2e !== undefined &&
      homeWorkCo2Bio !== undefined
    ) {
      setDataReady(true);
    }
  }, [
    pdfData,
    upstreamTco2e,
    upstreamCo2Bio,
    downstreamTco2e,
    downstreamCo2Bio,
    wasteTco2e,
    wasteCo2Bio,
    businessTco2e,
    businessCo2Bio,
    homeWorkTco2e,
    homeWorkCo2Bio,
  ]);

  const companiesOptions = useMemo(
    () =>
      companies?.map((company: Company): Option => ({
        label: company.corporate_name,
        value: company.id,
      })),
    [companies]
  );

  const yearsOptions = useMemo(
    () =>
      years?.map((year: Option): Option => ({
        label: year.label,
        value: year.value,
      })),
    [years]
  );

  const optionColor = '#31363f';

  const companySelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'rgb(246, 246, 246)',
      marginBottom: 5,
      marginRight: 20,
      minWidth: 300,
    }),
    option: (styles: any) => ({ ...styles, color: optionColor }),
  };

  const yearSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'rgb(246, 246, 246)',
      marginBottom: 5,
      marginRight: 20,
      minWidth: 100,
    }),
    option: (styles: any) => ({ ...styles, color: optionColor }),
  };

  useEffect(() => {
    if (companiesOptions && companiesOptions.length > 0) {
      handleSelectedCompanyChange(companiesOptions[0]);
    }
  }, [handleSelectedCompanyChange, companiesOptions]);

  return (
    <Container>
      <GoBack />

      <div className="row space-between">
        <TitleText title="Registro Público de Emissões - GHG" level={1} />
        <div className="row">
          {years && (
            <Select 
              value={selectYearValue}
              options={years}
              styles={yearSelectStyles}
              placeholder="Ano"
              onChange={handleSelectedYearChange}
            />
          )}
          <Select 
            value={selectCompanyValue}
            options={companiesOptions}
            styles={companySelectStyles}
            placeholder="Empresa"
            onChange={handleSelectedCompanyChange}
          />


          {!!selectedCompany && !!selectedYear && !!pdfData && (
            <DownloadResumePDFButton
              pdfData={pdfData}
              upstreamTco2e={upstreamTco2e ?? 0}
              upstreamCo2Bio={upstreamCo2Bio ?? 0}
              downstreamTco2e={downstreamTco2e ?? 0}
              downstreamCo2Bio={downstreamCo2Bio ?? 0}
              homeWorkTco2e={homeWorkTco2e ?? 0}
              homeWorkCo2Bio={homeWorkCo2Bio ?? 0}
              businessTco2e={businessTco2e ?? 0}
              businessCo2Bio={businessCo2Bio ?? 0}
              wasteTco2e={wasteTco2e ?? 0}
              wasteCo2Bio={wasteCo2Bio ?? 0}
              data={{ year: selectedYear, ...selectedCompany }}
            />
          )}
        </div>
      </div>

      <Line />

      {!!selectedCompany && !!selectedYear && !!pdfData ? (
        <div className="container">
          <section>
            <TitleText title="Resumo das emissões totais de GEE" level={3} />

            <span style={{ paddingBottom: 15, display: 'block' }}>
              Emissões consolidadas, por tipo de GEE e escopos
            </span>

            <div className="table-container">
              <Table
                theadItems={firstTableItems}
                tbodyItems={
                  <>
                    <tr>
                      <td className="tbody-item-bold">CO₂</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_co2, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.location?.emission_co2,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.purchase_choice?.emission_co2,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3.emission_co2, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_co2, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.location?.emission_co2,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.purchase_choice?.emission_co2,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_co2, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">CH₄</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_ch4, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.location?.emission_ch4,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.purchase_choice?.emission_ch4,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_ch4, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_ch4 * 28, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.location?.emission_ch4,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.purchase_choice?.emission_ch4,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_ch4, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">N₂O</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_n2o, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.location?.emission_n2o,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.tons_of_gas.scope2?.purchase_choice?.emission_n2o,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_n2o, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_n2o, false)}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.location?.emission_n2o,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.metric_tons.scope2?.purchase_choice?.emission_n2o,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_n2o, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">HFCs</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_hfcs, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_hfcs, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_hfcs, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_hfcs, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">PFCs</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_pfcs, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_pfcs, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_pfcs, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_pfcs, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">SF₆</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_sf6, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_sf6, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_sf6, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_sf6, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">NF₃</td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope1?.emission_nf3, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.tons_of_gas.scope3?.emission_nf3, false)}
                      </td>

                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope1?.emission_nf3, false)}
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        -
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(pdfData.metric_tons.scope3?.emission_nf3, false)}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                        Total
                      </td>
                      <td className="tbody-item_total">
                        
                      </td>
                      <td className="tbody-item_total">
                        
                      </td>
                      <td className="tbody-item_total">
                        
                      </td>
                      <td className="tbody-item_total">
                        
                      </td>

                      <td className="tbody-item_total">
                        {/* {formatPrice3(pdfData.metric_tons.totals?.scope1_total, false)} */}
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.totals?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.metric_tons.totals?.scope2_location_total,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.metric_tons.totals?.scope2_purchase_choice_total,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {/* {formatPrice3(pdfData.metric_tons.totals?.scope3_total, false)} */}
                        {formatPrice3(
                          ((pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e)),
                          false
                        )}
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </section>

          <section>
            <TitleText
              title="Resumo das emissões de GEE da organização, por escopo e categoria"
              level={3}
            />

            <span style={{ paddingBottom: 15, display: 'block' }}>
              Emissões de Escopo 1 desagregadas por categoria
            </span>

            <div className="table-container">
              <Table
                theadItems={fourthTableItems}
                tbodyItems={
                  <>
                    <tr>
                      <td className="tbody-item-bold" style={{ width: 230 }}>
                        Combustão móvel
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.mobile_combustion
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.mobile_combustion
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.mobile_combustion
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Combustão estacionária</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.stationary_combustion
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.stationary_combustion
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.stationary_combustion
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Processos industriais</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.industrial_processes
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.industrial_processes
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.industrial_processes
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">
                        Resíduos sólidos e efluentes líquidos
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.solid_waste_and_liquid_effluents?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Fugitivas</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.fugitives?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.fugitives?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.fugitives?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Atividades agrícolas</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.agricultural_activities
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.agricultural_activities
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.agricultural_activities
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Mudança no uso do solo</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.change_in_land_use
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.change_in_land_use
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.change_in_land_use
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                        Total de emissões Escopo 1
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.totals?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.totals?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope1?.totals?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                  </>
                }
              />

              <span style={{ paddingBottom: 15, display: 'block' }}>
                Emissões de Escopo 2 desagregadas por categoria
              </span>

              <Table
                theadItems={fifthTableItems}
                tbodyItems={
                  <>
                    <tr>
                      <td className="tbody-item-bold" style={{ width: 230 }}>
                        Aquisição de energia elétrica
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.electricity
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.electricity
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.electricity
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Aquisição de energia térmica</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.thermal_energy
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.thermal_energy
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.thermal_energy
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">
                        Perdas por transmissão e distribuição
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location
                            ?.transmission_distribution_losses?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location
                            ?.transmission_distribution_losses?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location
                            ?.transmission_distribution_losses?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                        Total de emissões Escopo 2 (localização)
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.totals
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.totals
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.location?.totals
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                  </>
                }
              />

              <Table
                theadItems={fifthTableItems}
                tbodyItems={
                  <>
                    <tr>
                      <td className="tbody-item-bold" style={{ width: 230 }}>
                        Aquisição de energia elétrica
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.electricity
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.electricity
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.electricity
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">Aquisição de energia térmica</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.thermal_energy?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.thermal_energy?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.thermal_energy?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item-bold">
                        Perdas por transmissão e distribuição
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.transmission_distribution_losses?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.transmission_distribution_losses?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice
                            ?.transmission_distribution_losses?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                        Total de emissões Escopo 2 (escolha de compra)
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.totals
                            ?.emission_tco2e,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.totals
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope2?.purchase_choice?.totals
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                  </>
                }
              />

              <span style={{ paddingBottom: 15, display: 'block' }}>
                Emissões de Escopo 3 desagregadas por categoria
              </span>

              <Table
                theadItems={sixthTableItems}
                tbodyItems={
                  <>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold" style={{ width: 230 }}>
                        1. Bens e serviços comprados
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_purchased_goods_services,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.purchased_goods_services
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.purchased_goods_services
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">2. Bens de capital</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_capital_goods,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.capital_goods
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.capital_goods
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        3. Atividades relacionadas com combustível e energia não inclusas
                        nos Escopos 1 e 2
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_fuel_energy_not_scope_1_2,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.fuel_energy_not_scope_1_2
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.fuel_energy_not_scope_1_2
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        4. Transporte e distribuição (upstream)
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          (pdfData.emissions_by_category?.scope3?.transport_distribution_upstream?.emission_tco2e) + 
                          (pdfData.emissions_by_category?.scope3?.transport_distribution_upstream?.emission_co2), false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.transport_distribution_upstream?.emission_co2_bio, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.transport_distribution_upstream?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        5. Resíduos gerados nas operações
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.waste_generated_operations?.emission_co2 + wasteTco2e, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.waste_generated_operations?.emission_co2_bio, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.waste_generated_operations?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">6. Viagens a negócios</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          (pdfData.emissions_by_category?.scope3?.business_travel?.emission_tco2e) +
                          (pdfData.emissions_by_category?.scope3?.business_travel?.emission_co2), false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.business_travel?.emission_co2_bio , false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.business_travel?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        7. Deslocamento de funcionários (casa-trabalho)
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          (pdfData.emissions_by_category?.scope3?.home_work_displacement?.emission_co2) + homeWorkTco2e , false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.home_work_displacement?.emission_co2_bio, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.home_work_displacement?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        8. Bens arrendados (a organização como arrendatária)
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_leased_assets_as_lessee, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.leased_assets_as_lessee?.emission_co2_bio, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.leased_assets_as_lessee?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        9. Transporte e distribuição (downstream)
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          (pdfData.emissions_by_category?.scope3?.transport_distribution_downstream?.emission_tco2e) +
                          (pdfData.emissions_by_category?.scope3?.transport_distribution_downstream?.emission_co2), false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.transport_distribution_downstream?.emission_co2_bio, false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.transport_distribution_downstream?.removal_of_bio_co2, false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        10. Processamento de produtos vendidos
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_processing_sold_products,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.processing_sold_products
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.processing_sold_products
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        11. Uso de bens e serviços vendidos
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_use_sold_goods_services,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.use_sold_goods_services
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.use_sold_goods_services
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        12. Tratamento de fim de vida dos produtos vendidos
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_end_life_products_sold,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.end_life_products_sold
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.end_life_products_sold
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        13. Bens arrendados (a organização como arrendadora)
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_leased_assets_as_lessor,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.leased_assets_as_lessor
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.leased_assets_as_lessor
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">14. Franquias</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_deductibles,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.deductibles
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.deductibles
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">15. Investimentos</td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_investments,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.investments
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.investments
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item-bold">
                        Emissões de Escopo 3 não classificáveis nas categorias 1 a 15
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e_not_classifiable_categories,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.not_classifiable_categories
                            ?.emission_co2_bio,
                          false
                        )}
                      </td>
                      <td className="tbody-item">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.not_classifiable_categories
                            ?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="tbody-item-container">
                      <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                        Total de emissões Escopo 3
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          ((pdfData.emissions_by_category?.scope3?.totals?.emission_tco2e)),
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          ((pdfData.emissions_by_category?.scope3?.totals?.emission_co2_bio) ),
                          false
                        )}
                      </td>
                      <td className="tbody-item_total">
                        {formatPrice3(
                          pdfData.emissions_by_category?.scope3?.totals?.removal_of_bio_co2,
                          false
                        )}
                      </td>
                    </tr>
                  </>
                }
              />
            </div>
          </section>

          <section>
            <TitleText
              title="Emissões de outros GEE não regulados pelo Protocolo de Quioto"
              level={4}
            />

            <div className="table-container" style={{ marginTop: '1rem' }}>
              <Table
                theadItems={seventhTableItems}
                tbodyItems={
                  <> 
                    {pdfData.not_kyoto.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          {item.id > 0 && (
                            <tr>
                              <td className="tbody-item-bold" style={{ width: 230 }}>
                                {item.label}
                              </td>
                              <td className="tbody-item">
                                {formatPrice3(item.emission_tco2e, false)}
                              </td>
                            </tr>
                          )}
                          {item.id === 0 && (
                            <tr className="tbody-item-container">
                              <td className="tbody-item_total" style={{ textAlign: 'left' }}>
                                Total
                              </td>
                              <td className="tbody-item_total">
                                {formatPrice3(item.emission_tco2e, false)}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </>
                }
              />
            </div>
          </section>
        </div>
      ) : (
        <Uninformed text="Nenhuma empresa e ano selecionados"  />
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
