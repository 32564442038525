import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack";
import { TitleText } from "../../../../components/TitleText";
import useAuth from "../../../../hooks/useAuth";
import inventoryService from "../../../../services/inventoryService";
import { Container } from "./styles";
import { formatPrice3, toNumberValues } from "../../../../utils/transform";
import { DefaultText } from "../../../../components/DefaulText";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";


import InfoButtonMin from '../../../../assets/icons/info-button.svg';
// import Graphic from "../../../../components/GraphicGoal";

export const InventoryGoal = () => {
  const history = useHistory();

  const location = useLocation();
  const [totals, setTotals] = useState<any>()
  const [onlyView, setOnlyView] = useState(false);
  const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [scopeTotal, setScopeTotal] = useState<any>()
  const [emissionTco2e, setEmissionTco2e] = useState<any>()
  const [absoluteScope1, setAbsoluteScope1] = useState<any>()
  const [absoluteScope2, setAbsoluteScope2] = useState<any>(0)
  const [absoluteScope3, setAbsoluteScope3] = useState<any>()
  const [absoluteTotalScopes, setAbsoluteTotalScopes] = useState<any>()
  const [absoluteEmissionTco2e, setAbsoluteEmissionTco2e] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [showTargetScopes, setShowTargetScopes] = useState(false);
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [goalYearScope1, setGoalYearScope1] = useState<number | string>();
  const [goalYearScope2, setGoalYearScope2] = useState<number | string>();
  const [goalYearScope3, setGoalYearScope3] = useState<number | string>();
  const [goalYearScopeTotal, setGoalYearScopeTotal] = useState<any>();
  const [goalPercentage1, setGoalPercentage1] = useState<any>();
  const [goalPercentage2, setGoalPercentage2] = useState<any>();
  const [goalPercentage3, setGoalPercentage3] = useState<any>();
  const [goalPercentageScopes, setGoalPercentageScopes] = useState<any>(0);
  const [goalTotalScopePercentage, setGoalTotalScopePercentage] = useState<any>()
  const [goalTotalItems, setGoalTotalItems] = useState<any>()
  const [goalTotalItemsPercentage, setGoalTotalItemsPercentage] = useState<any>()
  const [goalYearBuildingArea, setGoalYearBuildingArea] = useState<any>();
  const [goalYearNumberOfEmployess, setGoalYearNumberOfEmployees] = useState<any>();
  const [goalYearInvoicing, setGoalYearInvoicing] = useState<any>();
  const [goalYearNumberOfProjects, setGoalYearNumberOfProjects] = useState<any>();
  const [goalYearNumberOfProducts, setGoalYearNumberOfProducts] = useState<any>()
  const [goalAbsoluteBuildingArea, setGoalAbsoluteBuildingArea] = useState<any>();
  const [goalAbsoluteNumberOfEmployees, setGoalAbsoluteNumberOfEmployees] = useState<any>();
  const [goalAbsoluteInvoicing, setGoalAbsoluteInvoicing] = useState<any>();
  const [goalAbsoluteNumberOfProjects, setGoalAbsoluteNumberOfProjects] = useState<any>();
  const [goalAbsoluteNumberOfProducts, setGoalAbsoluteNumberOfProducts] = useState<any>();
  const [goalBuildingAreaPercentage, setGoalBuildingAreaPercentage] = useState<any>()
  const [goalNumberOfEmployeesPercentage, setGoalNumberOfEmployeesPercentage] = useState<any>()
  const [goalInvoicingPercentage, setGoalInvoicingPercentage] = useState<any>()
  const [goalNumberOfProjectsPercentage, setGoalNumberOfProjectsPercentage] = useState<any>()
  const [goalNumberOfProductsPercentage, setGoalNumberOfProductsPercentage] = useState<any>()

  const toNumberOrEmpty = (value: string): number | string => {
    if (value.trim() === "") {
      return "0";
    } else {
      const parsed = parseFloat(value);
      return isNaN(parsed) ? "" : parsed;
    }
  };

  const handleOptionChange: SubmitHandler<any> = async (option) => {
    setSelectedOption(option);
    setSelectedScope(null);
  };

  const handleScopeChange: SubmitHandler<any> = async (scope) => {
    setSelectedScope(scope);
  };

  useEffect(() => {
    const storedFieldValue = localStorage.getItem('goal_year_total');
    if (storedFieldValue !== null && storedFieldValue !== "0") {
      setOtherFieldsDisabled(true);
    }
  }, []);

  const handleGoalYearScopesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtherFieldsDisabled(value !== "0" && !!value);
    if (value) {
      localStorage.setItem('goal_year_total', value);
    } else {
      localStorage.removeItem('goal_year_total');
    }
  };

  useEffect(() => {
    const item: any = location?.state;

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    } else {
      history.push({
        pathname: '/inventario',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]);

  useEffect(() => {
    calculateTotals()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [absoluteScope1, absoluteScope2, absoluteScope3, absoluteTotalScopes]);

  useEffect(() => {
    calculateTotalsPercentage()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalPercentage1, goalPercentage2, goalPercentage3, goalPercentageScopes]);

  useEffect(() => {
    calculateTotalsItems()
  },
    [
      goalAbsoluteBuildingArea,
      goalAbsoluteInvoicing,
      goalAbsoluteNumberOfEmployees,
      goalAbsoluteNumberOfProducts,
      goalAbsoluteNumberOfProjects]
  );

  useEffect(() => {
    calculateTotalsItemsPercentage()
  },
    [
      goalInvoicingPercentage,
      goalBuildingAreaPercentage,
      goalNumberOfEmployeesPercentage,
      goalNumberOfProductsPercentage,
      goalNumberOfProjectsPercentage
    ]
  )

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const reduction_target = formData.reduction_target === '1'
      const company_id = inventorySelected.company.id;
      const year = inventorySelected.year;
      const goal_scope1 = formData.goal_scope1.replace(".", "");
      const goal_scope2 = formData.goal_scope2.replace(".", "");
      const goal_scope3 = formData.goal_scope3.replace(".", "");
      const goal_scope_total = formData.goal_scope_total.replace(".", "");

      const goal_scope1_percentage = formData.goal_scope1_percentage;
      const goal_scope2_percentage = formData.goal_scope2_percentage;
      const goal_scope3_percentage = formData.goal_scope3_percentage;
      const goal_total_percentage = formData.goal_total_percentage;

      const goal_building_area_percentage = formData.goal_building_area_percentage;
      const goal_number_of_employees_percentage = formData.goal_number_of_employees_percentage;
      const goal_invoicing_percentage = formData.goal_invoicing_percentage;
      const goal_number_of_projects_percentage = formData.goal_number_of_projects_percentage;
      const goal_number_of_products_percentage = formData.goal_number_of_products_percentage;

      const goal_invoicing = formData.goal_invoicing.replace(".", "");
      const goal_building_area = formData.goal_building_area.replace(".", "");
      const goal_number_of_projects = formData.goal_number_of_projects.replace(".", "");
      const goal_number_of_products = formData.goal_number_of_products.replace(".", "");
      const goal_number_of_employees = formData.goal_number_of_employees.replace(".", "");

      const newInventory = {
        reduction_target: reduction_target,
        company_id: company_id,
        year: year,
        goal_scope1_unit: formData.goal_scope1_unit,
        goal_scope2_unit: formData.goal_scope2_unit,
        goal_scope3_unit: formData.goal_scope3_unit,
        goal_year_scope1: formData.goal_year_scope1,
        goal_year_scope2: formData.goal_year_scope2,
        goal_year_scope3: formData.goal_year_scope3,
        goal_year_total: formData.goal_year_total,
        goal_scope1_percentage: goal_scope1_percentage ? parseFloat(goal_scope1_percentage) : 0,
        goal_scope2_percentage: goal_scope2_percentage ? parseFloat(goal_scope2_percentage) : 0,
        goal_scope3_percentage: goal_scope3_percentage ? parseFloat(goal_scope3_percentage) : 0,
        goal_total_percentage: goal_total_percentage ? parseFloat(goal_total_percentage) : 0,
        goal_invoicing_unit: formData.goal_invoicing_unit,
        goal_building_area_unit: formData.goal_building_area_unit,
        goal_number_of_projects_unit: formData.goal_number_of_projects_unit,
        goal_number_of_products_unit: formData.goal_number_of_products_unit,
        goal_number_of_employees_unit: formData.goal_number_of_employees_unit,
        goal_scope1: goal_scope1 ? parseFloat(goal_scope1.replace(",", ".")) : 0,
        goal_scope2: goal_scope2 ? parseFloat(goal_scope2.replace(",", ".")) : 0,
        goal_scope3: goal_scope3 ? parseFloat(goal_scope3.replace(",", ".")) : 0,
        goal_scope_total: goal_scope_total ? parseFloat(goal_scope_total.replace(",", ".")) : 0,
        goal_invoicing: goal_invoicing ? parseFloat(goal_invoicing.replace(",", ".")) : 0,
        goal_building_area: goal_building_area ? parseFloat(goal_building_area.replace(",", ".")) : 0,
        goal_number_of_projects: goal_number_of_projects ? parseFloat(goal_number_of_projects.replace(",", ".")) : 0,
        goal_number_of_products: goal_number_of_products ? parseFloat(goal_number_of_products.replace(",", ".")) : 0,
        goal_number_of_employees: goal_number_of_employees ? parseFloat(goal_number_of_employees.replace(",", ".")) : 0,
        goal_year_building_area: formData.goal_year_building_area,
        goal_year_number_of_employees: formData.goal_year_number_of_employees,
        goal_year_invoicing: formData.goal_year_invoicing,
        goal_year_number_of_projects: formData.goal_year_number_of_projects,
        goal_year_number_of_products: formData.goal_year_number_of_products,
        goal_building_area_percentage: goal_building_area_percentage ? parseFloat(goal_building_area_percentage) : 0,
        goal_number_of_employees_percentage: goal_number_of_employees_percentage ? parseFloat(goal_number_of_employees_percentage) : 0,
        goal_invoicing_percentage: goal_invoicing_percentage ? parseFloat(goal_invoicing_percentage) : 0,
        goal_number_of_projects_percentage: goal_number_of_projects_percentage ? parseFloat(goal_number_of_projects_percentage) : 0,
        goal_number_of_products_percentage: goal_number_of_products_percentage ? parseFloat(goal_number_of_products_percentage) : 0,
      };

      const result = await inventoryService.updateGoalId(
        inventorySelected.company.id, inventorySelected.year, newInventory);

      if (result && !result.error) {
        //history.goBack()

       // reset();
        //setErrorApi(undefined);

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getInventorysById(company_id: number, year: number) {
    const entity = await inventoryService.getById(company_id, year);

    if (entity.reduction_target) {
      setShowTargetScopes(true)
      setValue("reduction_target", "1")
    } else {
      setShowTargetScopes(false)
      setValue("reduction_target", "0")
    }

    const totals = await inventoryService.getTotals(company_id, year);

    setTotals(totals)

    let scopeTotal = 0

    if (totals?.scope1?.emission_tco2e) {
      scopeTotal = scopeTotal + totals?.scope1?.emission_tco2e
    }

    if (totals?.scope2?.emission_tco2e) {
      scopeTotal = scopeTotal + totals?.scope2?.emission_tco2e
    }

    if (totals?.scope3?.emission_tco2e) {
      scopeTotal = scopeTotal + totals?.scope3?.emission_tco2e
    }


    setValue("goal_scope1_unit", entity.goal_scope1_unit);
    setValue("goal_scope2_unit", entity.goal_scope2_unit);
    setValue("goal_scope3_unit", entity.goal_scope3_unit);

    setValue("goal_year_scope1", entity.goal_year_scope1);
    setValue("goal_year_scope2", entity.goal_year_scope2);
    setValue("goal_year_scope3", entity.goal_year_scope3);
    setValue("goal_year_total", entity.goal_year_total);

    setValue("goal_year_building_area", entity.goal_year_building_area);
    setValue("goal_year_number_of_employees", entity.goal_year_number_of_employees);
    setValue("goal_year_invoicing", entity.goal_year_invoicing);
    setValue("goal_year_number_of_projects", entity.goal_year_number_of_projects);
    setValue("goal_year_number_of_products", entity.goal_year_number_of_products);

    setValue("goal_scope1", formatPrice3(Number(entity.goal_scope1 ? entity.goal_scope1 : 0), false));
    setValue("goal_scope2", formatPrice3(Number(entity.goal_scope2 ? entity.goal_scope2 : 0), false));
    setValue("goal_scope3", formatPrice3(Number(entity.goal_scope3 ? entity.goal_scope3 : 0), false));
    setValue("goal_scope_total", formatPrice3(Number(entity.goal_scope_total ? entity.goal_scope_total : 0), false));

    setAbsoluteScope1(entity.goal_scope1);
    setAbsoluteScope2(entity.goal_scope2);
    setAbsoluteScope3(entity.goal_scope3);
    setAbsoluteTotalScopes(entity.goal_scope_total);

    setGoalAbsoluteBuildingArea(entity.goal_building_area);
    setGoalAbsoluteInvoicing(entity.goal_invoicing);
    setGoalAbsoluteNumberOfEmployees(entity.goal_number_of_employees);
    setGoalAbsoluteNumberOfProducts(entity.goal_number_of_products);
    setGoalAbsoluteNumberOfProjects(entity.goal_number_of_projects);

    setGoalPercentage1(entity.goal_scope1_percentage);
    setGoalPercentage2(entity.goal_scope2_percentage);
    setGoalPercentage3(entity.goal_scope3_percentage);
    setGoalPercentageScopes(entity.goal_total_percentage);

    setGoalBuildingAreaPercentage(entity.goal_building_area_percentage);
    setGoalNumberOfEmployeesPercentage(entity.goal_number_of_employees_percentage);
    setGoalInvoicingPercentage(entity.goal_invoicing_percentage);
    setGoalNumberOfProjectsPercentage(entity.goal_number_of_projects_percentage);
    setGoalNumberOfProductsPercentage(entity.goal_number_of_products_percentage);

    setValue("goal_invoicing_unit", entity.goal_invoicing_unit);
    setValue("goal_building_area_unit", entity.goal_building_area_unit);
    setValue("goal_number_of_projects_unit", entity.goal_number_of_projects_unit);
    setValue("goal_number_of_products_unit", entity.goal_number_of_products_unit);
    setValue("goal_number_of_employees_unit", entity.goal_number_of_employees_unit);

    setValue("goal_invoicing", formatPrice3(Number(entity.goal_invoicing ? entity.goal_invoicing : 0), false));
    setValue("goal_building_area", formatPrice3(Number(entity.goal_building_area ? entity.goal_building_area : 0), false));
    setValue("goal_number_of_projects", formatPrice3(Number(entity.goal_number_of_projects ? entity.goal_number_of_projects : 0), false));
    setValue("goal_number_of_products", formatPrice3(Number(entity.goal_number_of_products ? entity.goal_number_of_products : 0), false));
    setValue("goal_number_of_employees", formatPrice3(Number(entity.goal_number_of_employees ? entity.goal_number_of_employees : 0), false));

    setValue("goal_scope1_percentage", (entity.goal_scope1_percentage ? parseFloat(entity.goal_scope1_percentage).toFixed(1) : '0') + '%');
    setValue("goal_scope2_percentage", (entity.goal_scope2_percentage ? parseFloat(entity.goal_scope2_percentage).toFixed(1) : '0') + '%');
    setValue("goal_scope3_percentage", (entity.goal_scope3_percentage ? parseFloat(entity.goal_scope3_percentage).toFixed(1) : '0') + '%');
    setValue("goal_total_percentage", (entity.goal_total_percentage ? parseFloat(entity.goal_total_percentage).toFixed(1) : '0') + '%');

    setValue("goal_building_area_percentage", (entity.goal_building_area_percentage ? parseFloat(entity.goal_building_area_percentage).toFixed(1) : 0) + '%');
    setValue("goal_number_of_employees_percentage", (entity.goal_number_of_employees_percentage ? parseFloat(entity.goal_number_of_employees_percentage).toFixed(1) : 0) + '%');
    setValue("goal_invoicing_percentage", (entity.goal_invoicing_percentage ? parseFloat(entity.goal_invoicing_percentage).toFixed(1) : 0) + '%');
    setValue("goal_number_of_projects_percentage", (entity.goal_number_of_projects_percentage ? parseFloat(entity.goal_number_of_projects_percentage).toFixed(1) : 0) + '%');
    setValue("goal_number_of_products_percentage", (entity.goal_number_of_products_percentage ? parseFloat(entity.goal_number_of_products_percentage).toFixed(1) : 0) + '%');

    setInventorySelected(entity)
    setScopeTotal(scopeTotal)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{4})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  }

  function handleInfoHelp() {
    history.push('/ajuda');
  }

  const calculateTotals = () => {
    setEmissionTco2e(
      (absoluteScope1 ? absoluteScope1 : 0) +
      (absoluteScope2 ? absoluteScope2 : 0) +
      (absoluteScope3 ? absoluteScope3 : 0) +
      (absoluteTotalScopes ? absoluteTotalScopes : 0));
  }

  const calculateTotalsPercentage = () => {
    setGoalTotalScopePercentage(
      (goalPercentage1 ? goalPercentage1 : 0) +
      (goalPercentage2 ? goalPercentage2 : 0) +
      (goalPercentage3 ? goalPercentage3 : 0) +
      (goalPercentageScopes ? goalPercentageScopes : 0));
  }

  const calculateTotalsItems = () => {
    setGoalTotalItems(
      (goalAbsoluteBuildingArea ? goalAbsoluteBuildingArea : 0) +
      (goalAbsoluteInvoicing ? goalAbsoluteInvoicing : 0) +
      (goalAbsoluteNumberOfEmployees ? goalAbsoluteNumberOfEmployees : 0) +
      (goalAbsoluteNumberOfProducts ? goalAbsoluteNumberOfProducts : 0) +
      (goalAbsoluteNumberOfProjects ? goalAbsoluteNumberOfProjects : 0)
    );
  }

  const calculateTotalsItemsPercentage = () => {
    setGoalTotalItemsPercentage(
      (goalBuildingAreaPercentage ? goalBuildingAreaPercentage : 0) +
      (goalInvoicingPercentage ? goalInvoicingPercentage : 0) +
      (goalNumberOfEmployeesPercentage ? goalNumberOfEmployeesPercentage : 0) +
      (goalNumberOfProductsPercentage ? goalNumberOfProductsPercentage : 0) +
      (goalNumberOfProjectsPercentage ? goalNumberOfProjectsPercentage : 0)
    );
  }

  async function updatePercentage(scope: string, goalYear: any, absoluteScope: any, setGoalPercentage: any) {
    try {
      if (goalYear) {
        const reduction_target = true;
        const reduction_percentage = (absoluteScope);
        const emission_tco2e = (totals?.[`${scope}`]?.emission_tco2e);
        const goal_year = parseInt(goalYear);
        const inventory_id = inventorySelected.id;

        const data = await inventoryService.calculateGoalId(
          inventorySelected.year,
          inventorySelected.company_id,
          emission_tco2e,
          reduction_percentage,
          goal_year,
          reduction_target,
          inventory_id
        );

        setValue(`goal_${scope}_percentage`, data[`goal_${scope}_percentage`] ? `${data[`goal_${scope}_percentage`].toFixed(1)}%` : '0');
        setGoalPercentage(data[`goal_${scope}_percentage`]);
      }
    } catch (error) {
      console.error('Erro ao atualizar porcentagem:', error);
    }
  }

  useEffect(() => {
    if (goalYearScope1) {
      updatePercentage('scope1', goalYearScope1, absoluteScope1, setGoalPercentage1);
    }
  }, [goalYearScope1, absoluteScope1]);

  useEffect(() => {
    if (goalYearScope2) {
      updatePercentage('scope2', goalYearScope2, absoluteScope2, setGoalPercentage2);
    }
  }, [goalYearScope2, absoluteScope2]);

  useEffect(() => {
    if (goalYearScope3) {
      updatePercentage('scope3', goalYearScope3, absoluteScope3, setGoalPercentage3);
    }
  }, [goalYearScope3, absoluteScope3]);

  async function updatePercentageTotals(scope: string, goalYear: any, absoluteScope: any, setGoalPercentage: any) {
    try {
      if (goalYear) {
        const reduction_target = true;
        const reduction_percentage = (absoluteScope);
        const emission_tco2e = (scopeTotal);
        const goal_year = parseInt(goalYear);
        const inventory_id = inventorySelected.id;

        const data = await inventoryService.calculateGoalId(
          inventorySelected.year,
          inventorySelected.company_id,
          emission_tco2e,
          reduction_percentage,
          goal_year,
          reduction_target,
          inventory_id
        );

        setValue(`goal_${scope}_percentage`, data[`goal_${scope}_percentage`] ? `${data[`goal_${scope}_percentage`].toFixed(1)}%` : '0');
        setGoalPercentage(data[`goal_${scope}_percentage`]);
      }
    } catch (error) {
      console.error('Erro ao atualizar porcentagem:', error);
    }
  }

  useEffect(() => {
    if (goalYearScopeTotal) {
      updatePercentageTotals('total', goalYearScopeTotal, absoluteTotalScopes, setGoalPercentageScopes);
    }
  }, [goalYearScopeTotal, absoluteTotalScopes]);


  async function updatePercentageItems(item: string, goalYear: any, goalEmission: any, setGoalPercentage: any) {
    try {
      if (goalYear) {
        const reduction_target = true;
        const reduction_percentage = goalEmission;
        let emission_tco2e = 0;
        if (item === 'invoicing') {
          emission_tco2e = (inventorySelected.invoicing ? scopeTotal / inventorySelected.invoicing : 0);
        } else if (item === 'building_area') {
          emission_tco2e = (inventorySelected.building_area ? scopeTotal / inventorySelected.building_area : 0);
        } else if (item === 'number_of_employees') {
          emission_tco2e = (inventorySelected.number_of_employees ? scopeTotal / inventorySelected.number_of_employees : 0);
        } else if (item === 'number_of_projects') {
          emission_tco2e = (inventorySelected.number_of_projects ? scopeTotal / inventorySelected.number_of_projects : 0);
        } else if (item === 'number_of_products') {
          emission_tco2e = (inventorySelected.number_of_products ? scopeTotal / inventorySelected.number_of_products : 0);
        }
        const goal_year = parseFloat(goalYear);
        const inventory_id = inventorySelected.id;

        const data = await inventoryService.calculateGoalId(
          inventorySelected.year,
          inventorySelected.company_id,
          emission_tco2e,
          reduction_percentage,
          goal_year,
          reduction_target,
          inventory_id
        );


        setValue(`goal_${item}_percentage`, data[`goal_${item}_percentage`] ? `${data[`goal_${item}_percentage`].toFixed(1)}%` : '0');
        setGoalPercentage(data[`goal_${item}_percentage`]);

      }
    } catch (error) {
      console.error('Erro ao atualizar porcentagem:', error);
    }
  }

  useEffect(() => {
    if (goalYearBuildingArea) {
      updatePercentageItems('building_area', goalYearBuildingArea, goalAbsoluteBuildingArea, setGoalBuildingAreaPercentage);
    }
  }, [goalYearBuildingArea, goalAbsoluteBuildingArea]);

  useEffect(() => {
    if (goalYearNumberOfEmployess) {
      updatePercentageItems('number_of_employees', goalYearNumberOfEmployess, goalAbsoluteNumberOfEmployees, setGoalNumberOfEmployeesPercentage);
    }
  }, [goalYearNumberOfEmployess, goalAbsoluteNumberOfEmployees]);

  useEffect(() => {
    if (goalYearInvoicing) {
      updatePercentageItems('invoicing', goalYearInvoicing, goalAbsoluteInvoicing, setGoalInvoicingPercentage);
    }
  }, [goalYearInvoicing, goalAbsoluteInvoicing]);

  useEffect(() => {
    if (goalYearNumberOfProjects) {
      updatePercentageItems('number_of_projects', goalYearNumberOfProjects, goalAbsoluteNumberOfProjects, setGoalNumberOfProjectsPercentage);
    }
  }, [goalYearNumberOfProjects, goalAbsoluteNumberOfProjects]);

  useEffect(() => {
    if (goalYearNumberOfProducts) {
      updatePercentageItems('number_of_products', goalYearNumberOfProducts, goalAbsoluteNumberOfProducts, setGoalNumberOfProductsPercentage);
    }
  }, [goalYearNumberOfProducts, goalAbsoluteNumberOfProducts]);

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name} />
            <TitleText level={4} title="Metas do inventário" />
          </div>

          <div className="row" >
            {showTargetScopes && (
              <Button color="blue" size="md" type="button" onClick={handleInfoHelp}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            {onlyView === false && (
              <Button color="green" size="md" type="submit" style={{ margin: 'auto 10px auto auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)" }} />

        <div style={{
          display: "flex", flexDirection: "column", overflow: "auto",
          position: "absolute", top: "150px", right: "30px", left: "280px", bottom: "20px"
        }} >

          <label style={{
            fontFamily: "'Roboto', sans-serif", fontSize: "16px",
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold"
          }} >
            Sua empresa já tem alguma meta de redução de emissões?
          </label>

          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
            <input type="radio" value="1" disabled={onlyView} style={{ width: 20, marginLeft: 0, marginRight: 10 }}
              {...register("reduction_target", { required: true })}
              onChange={(event) => {
                setShowTargetScopes(true)
                setValue("reduction_target", event.target.value);
              }} />
            <DefaultText title='Sim'
            />
            <input type="radio" value="0" disabled={onlyView} style={{ width: 20, marginRight: 20 }}
              {...register("reduction_target", { required: false })}
              onChange={(event) => {
                setShowTargetScopes(false)
                setValue("reduction_target", event.target.value);
              }} />
            <DefaultText title='Não'
            />
          </div>

          {errors.reduction_target && (
            <span style={{ color: "#E33F3F", marginTop: 10 }}>
              Esse campo é obrigatório
            </span>
          )}

          {showTargetScopes && (
            <div style={{ display: "flex", flexDirection: "column" }} >
              <div style={{
                borderStyle: "dashed", margin: 5, borderWidth: "1px",
                width: "95%", borderColor: "#9E9E9E", height: "auto", display: "flex",
                flexDirection: "row"
              }} >
                <span style={{ margin: 10, width: "95%" }}>
                  <b>Meta absoluta: </b>Adotar metas absolutas significa limitar explicitamente a contribuição da empresa a esse aumento de concentração. As metas podem ser elaboradas separadamente para cada escopo ou  pode ter uma meta que defina um limite total de emissões para a organização.<br />Por exemplo: Manter as emissões de 2021 até o prazo de 2031.
                </span>
              </div>

              <table style={{ width: '95%', borderCollapse: 'collapse', marginLeft: 5 }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: "30%" }} rowSpan={2}>
                      {'Escopo'}
                    </th>
                    <th className="head-tr-item" style={{ width: "30%" }} rowSpan={2}>
                      {'Emissão no ano do Inventário (tCO₂e)'}
                    </th>
                    <th className="head-tr-item" style={{ width: "40%", textAlign: 'center' }} colSpan={3}>
                      {'Meta de redução'}
                    </th>
                  </tr>
                  <tr>
                    <th className="head-tr-item" >
                      {'Ano'}
                    </th>
                    <th className="head-tr-item">
                      {'Emissões (tCO₂e)'}
                    </th>
                    <th className="head-tr-item">
                      {'%'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                        {'Escopo 01'}
                      </span>
                    </td>
                    <td style={{ width: "20%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                        {formatPrice3(totals?.scope1?.emission_tco2e, false)}
                      </span>
                    </td>
                    <td style={{ width: "10%" }}>
                      <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 12 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_year_scope1")}
                        onChange={(event) => {
                          const value = toNumberOrEmpty(event.target.value);

                          setGoalYearScope1(value);

                          setValue("goal_year_scope1", value);
                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 5 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_scope1")}
                        onChange={(event) => {
                          const formatValue = event.target.value;
                          const valueStr = formatValue.replace(".", "");
                          const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                          setAbsoluteScope1(value)

                          setValue("goal_scope1", formatValue);

                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input
                        readOnly
                        style={{ width: "88%", textAlign: 'right' }}
                        className="input-item-percentage"
                        {...register("goal_scope1_percentage")}
                        onChange={(event) => {
                          setGoalPercentage1(goalPercentage1)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                        {'Escopo 02'}
                      </span>
                    </td>
                    <td style={{ width: "20%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                        {formatPrice3(totals?.scope2?.emission_tco2e, false)}
                      </span>
                    </td>
                    <td style={{ width: "10%" }}>
                      <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 12 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_year_scope2")}
                        onChange={(event) => {
                          const value = toNumberOrEmpty(event.target.value);

                          setGoalYearScope2(value)

                          setValue("goal_year_scope2", value);
                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 5 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_scope2")}
                        onChange={(event) => {
                          const formatValue = event.target.value;
                          const valueStr = formatValue.replace(".", "");
                          const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                          setAbsoluteScope2(value)

                          setValue("goal_scope2", formatValue);
                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly style={{ width: "88%", textAlign: 'right' }}
                        className="input-item-percentage"
                        {...register("goal_scope2_percentage")}
                        onChange={(event) => {
                          setGoalPercentage2(goalPercentage2)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                        {'Escopo 03'}
                      </span>
                    </td>
                    <td style={{ width: "20%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                        {formatPrice3(totals?.scope3?.emission_tco2e, false)}
                      </span>
                    </td>
                    <td style={{ width: "10%" }}>
                      <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 12 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_year_scope3")}
                        onChange={(event) => {
                          const value = toNumberOrEmpty(event.target.value);

                          setGoalYearScope3(value)
                          setValue("goal_year_scope3", value);
                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 5 }}
                        className="input-item"
                        disabled={otherFieldsDisabled}
                        {...register("goal_scope3")}
                        onChange={(event) => {
                          const formatValue = event.target.value;
                          const valueStr = formatValue.replace(".", "");
                          const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                          setAbsoluteScope3(value)

                          setValue("goal_scope3", formatValue);
                        }} />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly style={{ width: "88%", textAlign: 'right' }}
                        className="input-item-percentage"
                        {...register("goal_scope3_percentage")}
                        onChange={(event) => {
                          setGoalPercentage3(goalPercentage3)
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                        {'Total'}
                      </span>
                    </td>
                    <td style={{ width: "20%" }}>
                      <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                        {formatPrice3(scopeTotal, false)}
                      </span>
                    </td>
                    <td style={{ width: "10%" }}>
                      <input
                        readOnly={onlyView}
                        style={{ width: "75%", textAlign: 'center', marginLeft: 12 }}
                        className="input-item"
                        {...register("goal_year_total")}
                        onChange={(event) => {
                          const value = toNumberOrEmpty(event.target.value);

                          setGoalYearScopeTotal(value);

                          setValue("goal_year_total", value);

                          handleGoalYearScopesChange(event);
                        }}
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 5 }}
                        className="input-item"
                        {...register("goal_scope_total")}
                        onChange={(event) => {
                          const formatValue = event.target.value;
                          const valueStr = formatValue.replace(".", "");
                          const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                          setAbsoluteTotalScopes(value)

                          setValue("goal_scope_total", formatValue);
                        }}
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <input readOnly style={{ width: "88%", textAlign: 'right' }}
                        className="input-item-percentage"
                        {...register("goal_total_percentage")}
                        onChange={(event) => {

                          setGoalPercentageScopes(goalPercentageScopes)

                          setValue("goal_total_percentage", event.target.value)
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: 'bold' }}>
                    <td className="head-tr-item" style={{ paddingRight: 5, textAlign: 'right' }} colSpan={3}>
                      {'Total'}
                    </td>
                    <td className="head-tr-item">
                      <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                        {formatPrice3(emissionTco2e, false)}
                      </span>
                    </td>
                    <td className="head-tr-item">
                      <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                        {(goalTotalScopePercentage).toFixed(1) + '%'}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>


              <div style={{
                borderStyle: "dashed", margin: 5, borderWidth: "1px",
                width: "95%", borderColor: "#9E9E9E", height: "auto", display: "flex",
                flexDirection: "row"
              }} >
                <span style={{ margin: 10, width: "95%" }}>
                  <b>Meta por intensidade: </b>É expressa como uma redução na razão entre as emissões de GEE e uma medida de desempenho organizacional. Por exemplo: <br /> * Emissões de GEE por unidade de produto produzido;<br /> * Emissões de GEE pela receita bruta;<br /> * Emissões de GEE pelo número de funcionários;<br /> * Emissões de GEE pela área da empresa;
                </span>
              </div>

              <table style={{ width: '95%', borderCollapse: 'collapse', marginLeft: 5 }} className="table-scope" >
                <thead>
                  <tr>
                    <th className="head-tr-item" style={{ width: "20%" }} rowSpan={2}>
                      {'Item'}
                    </th>
                    <th className="head-tr-item" style={{ width: "20%" }} rowSpan={2}>
                      {'Fator'}
                    </th>
                    <th className="head-tr-item" style={{ width: "30%" }} rowSpan={2}>
                      {'Emissão no ano do Inventário (tCO₂e) / fator'}
                    </th>
                    {/* <th className="head-tr-item" style={{ width: "15%" }} rowSpan={2}>
                      {'Emissões (tCO₂e)'}
                    </th> */}
                    <th className="head-tr-item" style={{ width: "20%", textAlign: 'center' }} colSpan={3}>
                      {'Meta de redução'}
                    </th>
                  </tr>
                  <tr>
                    <th className="head-tr-item">
                      {'Ano'}
                    </th>
                    <th className="head-tr-item">
                      {'Emissão (tCO₂e) / fator'}
                    </th>
                    <th className="head-tr-item">
                      {'%'}
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {(!inventorySelected.building_area || inventorySelected.building_area === 0) && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por área construída'}
                        </span>
                      </td>
                      <td style={{ width: "65%" }} colSpan={2}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          A área construída não foi informada  <br /> nos dados do inventário.
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_building_area")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearBuildingArea(value)

                            setValue("goal_year_building_area", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "86%", textAlign: 'right', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_building_area")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteBuildingArea(value)

                            setValue("goal_building_area", formatValue );
                          }} />
                      </td>
                      <td>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_building_area_percentage")}
                          onChange={(event) => {
                            setGoalBuildingAreaPercentage(goalBuildingAreaPercentage)

                            setValue("goal_building_area_percentage", event.target.value)
                          }}
                        />
                      </td>
                    </tr>
                  )}

                  {inventorySelected.building_area > 0 && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por área construída'}
                        </span>
                      </td>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          {formatPrice3(inventorySelected.building_area, false) + ' m² de área construida.'}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                          {formatPrice3(inventorySelected.building_area ? scopeTotal / inventorySelected.building_area : 0, false)}
                        </span>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'right', marginLeft: 20 }}
                          className="input-item"
                          {...register("goal_building_area_unit")}
                          onChange={(event) => {
                            const formatValue = currency(event)
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0


                            setAbsoluteEmissionTco2e(value)

                            setValue("goal_building_area_unit", currency(event));
                          }} />
                      </td> */}
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 12 }}
                          className="input-item"
                          {...register("goal_year_building_area")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearBuildingArea(value)

                            setValue("goal_year_building_area", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 8 }}
                          className="input-item"
                          {...register("goal_building_area")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteBuildingArea(value)

                            setValue("goal_building_area", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_building_area_percentage")}
                          onChange={(event) => {
                            setGoalBuildingAreaPercentage(goalBuildingAreaPercentage)

                            setValue("goal_building_area_percentage", event.target.value)

                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {(!inventorySelected.number_of_employees || inventorySelected.number_of_employees === 0) && (
                    <tr style={{ height: 30 }}>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por número de funcionários'}
                        </span>
                      </td>
                      <td style={{ width: "75%" }} colSpan={2}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          O número de funcionários não foi <br />informado nos dados do inventário.
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_employees")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfEmployees(value)

                            setValue("goal_year_number_of_employees", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "86%", textAlign: 'right', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_number_of_employees")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteNumberOfEmployees(value)

                            setValue("goal_number_of_employees", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_employees_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfEmployeesPercentage(goalNumberOfEmployeesPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {inventorySelected.number_of_employees > 0 && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por número de funcionários'}
                        </span>
                      </td>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          {inventorySelected.number_of_employees + ' funcionários.'}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                          {formatPrice3(inventorySelected.number_of_employees ? scopeTotal / inventorySelected.number_of_employees : 0, false)}
                        </span>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'right', marginLeft: 20 }}
                          className="input-item"
                          {...register("goal_number_of_employees_unit")}
                          onChange={(event) => {
                            const formatValue = currency(event)
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setAbsoluteEmissionTco2e(value)

                            setValue("goal_number_of_employees_unit", currency(event));
                          }} />
                      </td> */}
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_employees")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfEmployees(value)

                            setValue("goal_year_number_of_employees", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 8 }}
                          className="input-item"
                          {...register("goal_number_of_employees")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteNumberOfEmployees(value)

                            setValue("goal_number_of_employees", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_employees_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfEmployeesPercentage(goalNumberOfEmployeesPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {(!inventorySelected.invoicing || inventorySelected.invoicing === 0) && (
                    <tr style={{ height: 30 }}>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por faturamento'}
                        </span>
                      </td>
                      <td style={{ width: "75%" }} colSpan={2}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          O Faturamento não foi informado nos <br />dados do inventário.
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_invoicing")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearInvoicing(value)

                            setValue("goal_year_invoicing", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "86%", textAlign: 'right', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_invoicing")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteInvoicing(value)

                            setValue("goal_invoicing", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_invoicing_percentage")}
                          onChange={(event) => {
                            setGoalInvoicingPercentage(goalInvoicingPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {inventorySelected.invoicing > 0 && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por faturamento'}
                        </span>
                      </td>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          {'R$ ' + formatPrice3(inventorySelected.invoicing, false) + ' de faturamento.'}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                          {formatPrice3(inventorySelected.invoicing ? scopeTotal / inventorySelected.invoicing : 0, false)}
                        </span>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'right', marginLeft: 20 }}
                          className="input-item"
                          {...register("goal_invoicing_unit")}
                          onChange={(event) => {
                            setValue("goal_invoicing_unit", currency(event));
                          }} />
                      </td> */}
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_invoicing")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearInvoicing(value)

                            setValue("goal_year_invoicing", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 8 }}
                          className="input-item"
                          {...register("goal_invoicing")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteInvoicing(value)

                            setValue("goal_invoicing", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_invoicing_percentage")}
                          onChange={(event) => {
                            setGoalInvoicingPercentage(goalInvoicingPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {(!inventorySelected.number_of_projects || inventorySelected.number_of_projects === 0) && (
                    <tr style={{ height: 30 }}>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por projeto'}
                        </span>
                      </td>
                      <td style={{ width: "75%" }} colSpan={2}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          O número de projetos não foram <br />informados nos dados do inventário.
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_projects")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfProjects(value)

                            setValue("goal_year_number_of_projects", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "86%", textAlign: 'right', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_number_of_projects")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteNumberOfProjects(value)

                            setValue("goal_number_of_projects", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_projects_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfProjectsPercentage(goalNumberOfProductsPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {inventorySelected.number_of_projects > 0 && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por projeto'}
                        </span>
                      </td>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          {inventorySelected.number_of_projects + ' projetos.'}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                          {formatPrice3(inventorySelected.number_of_projects ? scopeTotal / inventorySelected.number_of_projects : 0, false)}
                        </span>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'right', marginLeft: 20 }}
                          className="input-item"
                          {...register("goal_number_of_projects_unit")}
                          onChange={(event) => {
                            setValue("goal_number_of_projects_unit", currency(event));
                          }} />
                      </td> */}
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_projects")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfProjects(value)

                            setValue("goal_year_number_of_projects", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 8 }}
                          className="input-item"
                          {...register("goal_number_of_projects")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteNumberOfProjects(value)

                            setValue("goal_number_of_projects", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_projects_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfProjectsPercentage(goalNumberOfProjectsPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {(!inventorySelected.number_of_products || inventorySelected.number_of_products === 0) && (
                    <tr style={{ height: 30 }}>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por produtos fabricados'}
                        </span>
                      </td>
                      <td style={{ width: "75%" }} colSpan={2}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          O número de produtos fabricados não <br />foram informados nos dados do inventário.
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_products")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfProducts(value)

                            setValue("goal_year_number_of_products", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "86%", textAlign: 'right', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_number_of_products")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0

                            setGoalAbsoluteNumberOfProducts(value)

                            setValue("goal_number_of_products", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_products_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfProductsPercentage(goalNumberOfProductsPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {inventorySelected.number_of_products > 0 && (
                    <tr>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, fontWeight: 'bold', display: 'inline-block', textAlign: 'left' }} >
                          {'Por produtos fabricados'}
                        </span>
                      </td>
                      <td style={{ width: "20%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'left' }} >
                          {inventorySelected.number_of_products + ' produtos.'}
                        </span>
                      </td>
                      <td style={{ width: "15%" }}>
                        <span style={{ marginLeft: 5, width: "98%", fontSize: 12, display: 'inline-block', textAlign: 'right' }} >
                          {formatPrice3(inventorySelected.number_of_products ? scopeTotal / inventorySelected.number_of_products : 0, false)}
                        </span>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "78%", textAlign: 'right', marginLeft: 20 }}
                          className="input-item"
                          {...register("goal_number_of_products_unit")}
                          onChange={(event) => {
                            setValue("goal_number_of_products_unit", currency(event));
                          }} />
                      </td> */}
                      <td style={{ width: "10%" }}>
                        <input readOnly={onlyView} style={{ width: "75%", textAlign: 'center', marginLeft: 10 }}
                          className="input-item"
                          {...register("goal_year_number_of_products")}
                          onChange={(event) => {
                            const value = toNumberOrEmpty(event.target.value);

                            setGoalYearNumberOfProducts(value)

                            setValue("goal_year_number_of_products", value);
                          }} />
                      </td>
                      <td style={{ width: "15%" }}>
                        <input readOnly={onlyView} style={{ width: "88%", textAlign: 'right', marginLeft: 8 }}
                          className="input-item"
                          {...register("goal_number_of_products")}
                          onChange={(event) => {
                            const formatValue = event.target.value;
                            const valueStr = formatValue.replace(".", "");
                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0
                            setGoalAbsoluteNumberOfProducts(value)

                            setValue("goal_number_of_products", formatValue);
                          }} />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          readOnly
                          style={{ width: "88%", textAlign: 'right' }}
                          className="input-item-percentage"
                          {...register("goal_number_of_products_percentage")}
                          onChange={(event) => {
                            setGoalNumberOfProductsPercentage(goalNumberOfProductsPercentage)
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: 'bold' }}>
                    <td className="head-tr-item" style={{ paddingRight: 5, textAlign: 'right' }} colSpan={4}>
                      {/* {'Total'} */}
                    </td>
                    <td className="head-tr-item">
                      <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                        {/* {formatPrice3(goalTotalItems, false)} */}
                      </span>
                    </td>
                    <td className="head-tr-item">
                      <span style={{ marginRight: 5, width: "100%", fontSize: 12, textAlign: 'right', display: 'inline-block' }} >
                        {/* {(goalTotalItemsPercentage).toFixed(1) + '%'} */}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div>
                <div style={{
                  borderStyle: "dashed",
                  margin: 5,
                  borderWidth: "1px",
                  width: "95%",
                  borderColor: "#9E9E9E",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row"
                }}>
                  <span style={{ margin: 10, width: "95%" }}>
                    <b>Meta baseada na ciência: </b>
                    <div className="tooltip" style={{ marginLeft: 5 }}>
                      <img alt="infoButton" src={InfoButtonMin} style={{ width: 25, marginBottom: -6 }} />
                      <span className="tooltiptext">
                        Conforme recomendações do SBTi
                      </span>
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                      <input
                        type="radio"
                        id="opcao1"
                        name="opcao"
                        value="Escopo 1"
                        checked={selectedOption === "Escopo 1"}
                        onChange={() => handleOptionChange("Escopo 1")}
                        style={{ width: 15, marginLeft: 0, marginRight: 10 }}
                      />
                      <label htmlFor="opcao1">Escopo 1</label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                      <input
                        type="radio"
                        id="opcao2"
                        name="opcao"
                        value="Escopo 2"
                        checked={selectedOption === "Escopo 2"}
                        onChange={() => handleOptionChange("Escopo 2")}
                        style={{ width: 15, marginLeft: 0, marginRight: 10 }}
                      />
                      <label htmlFor="opcao2">Escopo 2</label>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                      <input
                        type="radio"
                        id="opcao3"
                        name="opcao"
                        value="Escopo 3"
                        checked={selectedOption === "Escopo 3"}
                        onChange={() => handleOptionChange("Escopo 3")}
                        style={{ width: 15, marginLeft: 0, marginRight: 10 }}
                      />
                      <label htmlFor="opcao3">Escopo 3</label>
                    </div>
                  </span>
                </div>
                {selectedOption === "Escopo 2" && (
                  <div style={{ margin: 10, width: "95%" }}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                      <input
                        type="radio"
                        id="curtoPrazo"
                        name="curtoPrazo"
                        value="Meta Baseada na Ciência de Curto Prazo"
                        checked={selectedScope === "Meta Baseada na Ciência de Curto Prazo"}
                        onChange={() => handleScopeChange("Meta Baseada na Ciência de Curto Prazo")}
                        style={{ width: 15, marginLeft: 0, marginRight: 10 }}
                      />
                      <label htmlFor="escopo2">Meta Baseada na Ciência de Curto Prazo</label>
                      <div className="tooltip" style={{ marginLeft: 5 }}>
                        <img alt="infoButton" src={InfoButtonMin} style={{ width: 25, marginBottom: -5 }} />
                        <span className="tooltiptext">
                          Período: 5 a 10 anos do ano do inventário<br></br>
                          Meta de Eletricidade Renovável:<br></br>
                          80% para 2025<br></br>
                          100% para 2030
                        </span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5 }}>
                      <input
                        type="radio"
                        id="longoPrazo"
                        name="escopo"
                        value="Meta Baseada na Ciência de Longo Prazo"
                        checked={selectedScope === "Meta Baseada na Ciência de Longo Prazo"}
                        onChange={() => handleScopeChange("Meta Baseada na Ciência de Longo Prazo")}
                        style={{ width: 15, marginLeft: 0, marginRight: 10 }}
                      />
                      <label htmlFor="escopo2">Meta Baseada na Ciência de Longo Prazo</label>
                      <div className="tooltip" style={{ marginLeft: 5 }}>
                        <img alt="infoButton" src={InfoButtonMin} style={{ width: 25, marginBottom: -5 }} />
                        <span className="tooltiptext">
                          Período: Até 2050<br></br>
                          Meta de Eletricidade Renovável: 100%
                        </span>
                      </div>
                      {selectedScope === "Escopo 2" && (
                        <div>
                          <p>Opções:</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* <Graphic /> */}
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
