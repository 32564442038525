import React, { useEffect, useState } from 'react'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import useAuth from '../../../../../hooks/useAuth'
import inventoryService from '../../../../../services/inventoryService'
import { Container } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { Button } from '../../../../../components/Button/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { formatPrice3 } from '../../../../../utils/transform'
import scope1Service from '../../../../../services/scope1Service'
import { Modal } from '../../../../../components/Modal'
import Parser from 'html-react-parser'
import scopeService from '../../../../../services/scopeService'
import { Uninformed } from '../../../../../components/Uninformed'
import { toast } from 'react-hot-toast'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import { Loading } from '../../../../../components/Loading'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'

export const StationaryOthers = () => {
  const history = useHistory()

  const { isCommonUser } = useAuth()

  const [files, setFiles] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fuelType, setFuelTyoe] = useState<any>()
  const [openModal, setOpenModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModalFuel, setOpenModalFuel] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sectorInfo, setSectorInfo] = useState<string | undefined>()
  const location = useLocation()
  const [others, setOthers] = useState<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [changeOther, setChangeOther] = useState<string | undefined>()

  const [emissionCh4Other, setEmissionCh4Other] = useState<any>()
  const [emissionN2oOther, setEmissionN2oOther] = useState<any>()
  const [emissionCo2Other, setEmissionCo2Other] = useState<any>()
  const [emissionTco2eOther, setEmissionTco2eOther] = useState<any>()
  const [emissionCo2BioOther, setEmissionCo2BioOther] = useState<any>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'combustao_estacionaria', 'relato_de_emissoes')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itemOthers: any[] = others.map((scope: any, index: number) => {
        return {
          source: scope.source,
          description: scope.description,
          emission_co2: scope.emission_co2,
          emission_ch4: scope.emission_ch4,
          emission_n2o: scope.emission_n2o,
          emission_tco2e: scope.emission_tco2e,
          emission_co2_bio: scope.emission_co2_bio
        }
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        others: itemOthers,
      }

      const result = await scope1Service.saveStationaryCombustionOther(
        inventorySelected.company.id,
        inventorySelected.year,  
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('StationaryCombustionOther', JSON.stringify(scope));

        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getStationaryCombustionOther(company_id, year)

    const result = await inventoryService.getFiles(inventory.company.id, inventory.year, 1, 'combustao_estacionaria', 'relato_de_emissoes' )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setScopeSelected(entity)

      setOtherValues(entity?.others)
      calculateOtherTotals(entity?.others)

      setIgnoreFill(entity.ignore_fill)

      setChangeOther('load_scope_' + entity.id + '_scopes_' + entity?.others.length)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      calculateOtherTotals([])

      setChangeOther('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos
    return value;
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const handleNewItem = () => {
    const item = {
      source: undefined,
      description: undefined,
      emission_co2: undefined,
      emission_ch4: undefined,
      emission_n2o: undefined,
      emission_tco2e: undefined,
      emission_co2_bio: undefined
    }

    const index = others.length

    const itens = others.concat(item)

    setChangeOther('new_scope_item_' + index)

    setOtherValues(itens)
    calculateOtherTotals(itens)
  }

  const calculateOtherTotals = (itens: any) => {
    let _emissionCo2 = 0
    let _emissionCh4 = 0
    let _emissionN2o = 0
    let _emissionTco2e = 0
    let _emissionCo2Bio = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          _emissionCo2 = _emissionCo2 + item.emission_co2
        }
        if (item.emission_ch4) {
          _emissionCh4 = _emissionCh4 + item.emission_ch4
        }
        if (item.emission_n2o) {
          _emissionN2o = _emissionN2o + item.emission_n2o
        }
        if (item.emission_tco2e) {
          _emissionTco2e = _emissionTco2e + item.emission_tco2e
        }
        if (item.emission_co2_bio) {
          _emissionCo2Bio = _emissionCo2Bio + item.emission_co2_bio
        }
      }
    }

    setEmissionCo2Other(_emissionCo2)
    setEmissionCh4Other(_emissionCh4)
    setEmissionN2oOther(_emissionN2o)
    setEmissionTco2eOther(_emissionTco2e)
    setEmissionCo2BioOther(_emissionCo2Bio)

    setChangeOther(
      'calculate_iten_' +
        _emissionCo2 +
        '_' +
        _emissionCh4 +
        '_' +
        _emissionN2o +
        '_' +
        _emissionTco2e +
        '_' +
        _emissionCo2Bio
    )
  }

  function handleDeleteOther(index: number) {
    const itens = []

    for (let i = 0; i < others?.length; i++) {
      if (i !== index) {
        itens.push(others[i])
      }
    }

    setChangeOther('remove_scope_item_' + index)

    setOtherValues(itens)
    calculateOtherTotals(itens)
  }

  const handleChangeOther = (index: number, ignore_field?: string) => {
    const emission_co2 = others[index].emission_co2
    const emission_ch4 = others[index].emission_ch4
    const emission_n2o = others[index].emission_n2o

    async function calculate() {
      const result = await scope1Service.calculateStationaryCombustionOther(
        emission_co2,
        emission_ch4,
        emission_n2o
      )

      if (result) {
        others[index].emission_tco2e = result.emission_tco2e

        setChangeOther(
          'change_scope_item_' +
            index +
            '_' +
            emission_co2 +
            '_' +
            emission_ch4 +
            '_' +
            emission_n2o
        )

        setOtherValues(others, ignore_field)
        calculateOtherTotals(others)
      }
    }

    calculate()
  }

  const setOtherValues = (itens: any, ignore_field?: string) => {
    let changeScope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`other.${i}.source`, item.source)
        setValue(`other.${i}.description`, item.description)

        if ('emission_co2' !== ignore_field) {
          setValue(`other.${i}.emission_co2`, formatPrice3(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`other.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`other.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`other.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))
        }

        if ('emission_co2_bio' !== ignore_field) {
          setValue(`other.${i}.emission_co2_bio`, formatPrice3(item.emission_co2_bio, false))
        }

        changeScope = changeScope + '_' + item.emission_co2 + '_' + item.emission_ch4 + '_' + item.emission_n2o
      }
    }

    setOthers(itens)
    setChangeOther(changeScope)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'combustao_estacionaria',
        file: base64,
        filename: filename,
        second_name: 'relato_de_emissoes'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_estacionaria',
          'relato_de_emissoes'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_estacionaria',
          'relato_de_emissoes'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {ignoreFill === false && (
          <div
            style={{
              overflow: 'hidden auto',
              position: 'relative',
              marginTop: 0,
              height: 'calc(100vh - 280px)',
              maxHeight: 'calc(100vh - 280px)'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 0
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0,
                    width: '80%'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        marginRight: 30
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'start',
                          fontSize: 16,
                          marginTop: 5,
                          marginLeft: 10,
                          fontWeight: 'bold'
                        }}
                      >
                        Relato de emissões estacionárias calculadas em outras ferramentas.
                      </span>
                      <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                        Clique, ao lado em novo registro, para adicionar nova linha
                      </span>
                    </div>
                  </div>
                </div>
                {!isCommonUser && (
                  <div style={{ textAlign: 'right', width: '30%', marginBottom: 10 }}>
                    <button
                      type="button"
                      style={{
                        width: '160px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleNewItem()}
                    >
                      {`Novo registro`}
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeOther && others && others.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan = {2}>
                          {'Fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan = {2}>
                          {'Emissões de'}
                          <br />
                          {'CO₂ fóssil (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan = {2}>
                          {'Emissões de'}
                          <br />
                          {'CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan = {2}>
                          {'Emissões de'}
                          <br />
                          {'N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan = {2}>
                          {'Emissões de CO₂'}
                          <br />
                          {'biogênico (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan = {2}>
                          {'Emissões'}
                          <br />
                          {'fósseis totais (t CO₂e)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 50 }}  rowSpan = {2}/>
                      </tr>
                      <tr>
                        <th className="head-tr-item" style={{ width: 150 }}>
                          {'Registro'}
                        </th>
                        <th className="head-tr-item" style={{ width: 150 }}>
                          {'Descrição'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {others.map((other: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`other.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`other.${index}.source`, event.target.value)
                                    others[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`other.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `other.${index}.description`,
                                      event.target.value
                                    )
                                    others[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`other.${index}.emission_co2`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    others[index].emission_co2 = value

                                    setValue(`other.${index}.emission_co2`, formatValue)

                                    handleChangeOther(index, 'emission_co2')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`other.${index}.emission_ch4`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    others[index].emission_ch4 = value

                                    setValue(`other.${index}.emission_ch4`, formatValue)

                                    handleChangeOther(index, 'emission_ch4')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`other.${index}.emission_n2o`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    others[index].emission_n2o = value

                                    setValue(`other.${index}.emission_n2o`, formatValue)

                                    handleChangeOther(index, 'emission_n2o')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`other.${index}.emission_co2_bio`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event);
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    others[index].emission_co2_bio = value

                                    setValue(
                                      `other.${index}.emission_co2_bio`,
                                      formatValue
                                    )

                                    handleChangeOther(index, 'emission_co2_bio')
                                  }}
                                />
                              </td>
                              <td style={{ width: 100 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 100,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(other.emission_tco2e, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <div
                                  onClick={() => handleDeleteOther(index)}
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    width: 70
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={2}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Other, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4Other, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oOther, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2BioOther, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eOther, false)}
                          </span>
                        </td>
                        <td colSpan={5} className="head-tr-item" />
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!others.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum registro cadastrado`
                        : `Nenhum registro foi informado, clique em 'novo' para incluir seu
                      registro`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.other && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da Fonte e Descrição da Fonte são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {ignoreFill === false && changeOther && others && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15,
                  marginTop: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionTco2eOther, false)}
                </span>
              </div>
            )}

            {ignoreFill === false && changeOther && others && (
              <div
                style={{
                  borderStyle: 'dashed',
                  margin: 5,
                  borderWidth: '1px',
                  width: 'calc(100vw - 330px)',
                  maxWidth: 'calc(100vw - 330px)',
                  borderColor: '#9E9E9E',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 15
                }}
              >
                <span style={{ margin: 10, width: '70%' }}>
                  Emissões totais em CO₂ biogênico (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    margin: 10,
                    textAlign: 'right',
                    width: '30%'
                  }}
                >
                  {formatPrice3(emissionCo2BioOther, false)}
                </span>
              </div>
            )}

            {!ignoreFill && (
              <div className="column" style={{ margin: 10 }}>
                <div
                  style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
                />

                <div
                  className="column"
                  style={{
                    marginTop: 5,
                    marginRight: 30
                  }}
                >
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div className="column" style={{ margin: 10 }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: '100%', height: 100 }}
                    {...register('evidence')}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">Esse campo é obrigatório</span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={downloadFile}
                    onChangeAttachment={attachmentChangeHandler}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
