import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import fuelService from "../../services/fuelService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const Fuel = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [fuels, setFuels] = useState<any>([]);
  const [fuelSelected, setFuelSelected] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();

  // Função para obter combustíveis e garantir que apenas um registro por nome seja exibido
  async function getFuels(page: number, filter: string) {
    const result = await fuelService.getByParams({ limit: 55, page, filter: filter });
    // Filtra combustíveis para mostrar apenas um por nome
    const uniqueFuels = Array.from(new Map(result.itens.map((fuel: any) => [fuel.name, fuel])).values());
    setFuels({ ...result, itens: uniqueFuels });
    setCurrentPage(page);
  }

  useEffect(() => {
    const savedSearch = localStorage.getItem('fuelSearch');
    const savedPage = localStorage.getItem('currentPage');

    if (savedSearch) {
      setSearchValue(savedSearch);
    }
    if (savedPage) {
      setCurrentPage(parseInt(savedPage));
    } else {
      getFuels(1, searchDebounced);
    }
  }, []);

  useEffect(() => {
    if (searchDebounced !== undefined) {
      localStorage.setItem('fuelSearch', searchDebounced);
    }
    localStorage.setItem('currentPage', currentPage.toString());

    getFuels(currentPage, searchDebounced);
  }, [searchDebounced, currentPage]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('fuelSearch');
      localStorage.removeItem('currentPage');
    };
  }, []);
  
  

  async function handleDeleteFuel() {
    if (fuelSelected) {
      const result = await fuelService.deleteById(fuelSelected?.id);
      if (result) {
        setOpenModalDelete(false);
        getFuels(1, searchDebounced);
        toast.success("Operação realizada com sucesso!");
      }
    }
  }

  async function getFuelsById(id: number) {
    const fuel = await fuelService.getById(id);
    setFuelSelected(fuel);
  }

  function redirectToAdd(fuel?: any) {
    if (fuel) {
      history.push({
        pathname: '/combustivel/add',
        state: { fuel_id: fuel.id }
      });
    } else {
      history.push('/combustivel/add');
    }
  }

  function redirectToEdit(fuel?: any) {
    if (fuel) {
      history.push({
        pathname: '/combustivel/edicao',
        state: { fuel_id: fuel.id }
      });
    } else {
      history.push('/combustivel/edicao');
    }
  }

  function redirectToMonthly(fuel: any) {
    history.push({
      pathname: '/combustivel/fatores-mensais',
      state: { fuel_id: fuel.id }
    });
  }

  function redirectToFactorCh4(fuel: any) {
    history.push({
      pathname: '/combustivel/fatores-ch4',
      state: { fuel_id: fuel.id }
    });
  }

  function redirectToFactorN2o(fuel: any) {
    history.push({
      pathname: '/combustivel/fatores-n2o',
      state: { fuel_id: fuel.id }
    });
  }

  function handleOpenModalDelete(fuel?: any) {
    setOpenModalDelete(!openModalDelete);
    if (fuel) {
      getFuelsById(fuel.id);
    } else {
      setFuelSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getFuels(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getFuels(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Combustíveis" />
        <input
          className="input-search"
          placeholder="Pesquisar combustíveis"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Button color="blue" size="md" onClick={() => redirectToAdd()}>
          + Novo combustível
        </Button>
      </div>

      {fuels?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10, height: "calc(100vh - 130px)", maxHeight: "calc(100vh - 130px)" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                    {"Nome"}
                  </th>
                  <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}>
                    {"Unidade"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fuels.itens.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ padding: "10px", width: "45%" }}>
                        <span className="title-text-underline">
                          {item.name}
                        </span>
                      </td>
                      <td style={{ padding: "10px", width: "45%" }}>
                        <span>
                          {item.unity}
                        </span>
                      </td>
                      <td>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                        <button
                            className="button-month"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToMonthly(item)}
                          >
                            Fator de Ajuste Mensal
                          </button>
                          <button
                            className="button-ch4"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToFactorCh4(item)}
                          >
                            Fatores de<br />Emissão CH₄
                          </button>
                          <button
                            className="button-n2o"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToFactorN2o(item)}
                          >
                            Fatores de<br />Emissão N₂O
                          </button>
                          <button
                            className="button-edit"
                            style={{ marginRight: 5 }}
                            onClick={() => redirectToEdit(item)}
                          >
                            Editar
                          </button>
                          <button
                            className="button-delete"
                            style={{ marginRight: 5 }}
                            onClick={() => handleOpenModalDelete(item)}
                          >
                            Excluir
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {fuels.itens.length > 0 && (
              <Pagination
                total={fuels.total}
                limit={55}
                goNext={goNext}
                goPrev={goPrev}
                currentPage={currentPage}
                length={fuels.itens.length}
              />
            )}
          </div>
        )
      }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={fuelSelected.name}
          handleOk={handleDeleteFuel}
        />
      )}
    </Container>
  );
};
