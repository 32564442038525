import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import carFleetService from "../../../services/carFleetService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import fuelService from "../../../services/fuelService";
import { toast } from "react-hot-toast";
import { Alert } from "../../../components/Alert";

interface Inputs {
  name: string;
  fuel: any;
  bio_fuel: any;
  description: string;
  year: any;
}

export const CarFleetEdit = () => {
  const history = useHistory();

  const [fuels, setFuels] = useState<any>([]);
  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const availableYears = [2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [bioFuels, setBioFuels] = useState<any>([]);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.carFleet_id){
      getCarFleetsById(item.carFleet_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  useEffect(() => {
    if (selectedYear) {
      getFuels(selectedYear)
    }
  }, [selectedYear]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newCarFleet = {
        name: formData.name,
        description: formData.description,
        fuel_id: formData.fuel ? formData.fuel.value : undefined,
        bio_fuel_id: formData.bio_fuel ? formData.bio_fuel.value : undefined,
        year_id: parseInt(formData.year)
      };

      try {
        let result;
        if (carFleetSelected?.id) {
          result = await carFleetService.updateById(carFleetSelected.id, newCarFleet);
        } else {
          result = await carFleetSelected.create(newCarFleet);
        }

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        } else {
          setErrorApi(result.error);
          setModalMessage(result.error);
          setModalIsOpen(true);
        }
      } catch (error) {
        console.error("Erro ao realizar operação:", error);
        setErrorApi("Erro ao realizar a operação.");
        setModalMessage("Erro ao realizar a operação.");
        setModalIsOpen(true);
      }
    }
  };

  async function getFuels(year: number) {
    try {
      const result = await fuelService.getByParams({ limit: 9000, page: 1 });

      const fuels = [];
      const bioFuels = [];

      if (result.itens) {
        for (const fuel of result.itens) {
          if (fuel.fuel_type === 1 && fuel.year === year) {
            fuels.push({ label: fuel.name, value: fuel.id });
          }
          if (fuel.fuel_type === 2 && fuel.year === year) {
            bioFuels.push({ label: fuel.name, value: fuel.id });
          }
        }
      }

      setFuels(fuels);
      setBioFuels(bioFuels);
    } catch (error) {
      console.error("Erro ao buscar combustíveis:", error);
      setErrorApi("Erro ao buscar combustíveis.");
      setModalMessage("Erro ao buscar combustíveis.");
      setModalIsOpen(true);
    }
  }

  async function getCarFleetsById(id: number) {
    try{
      const carFleet = await carFleetService.getById(id);
      if (carFleet) {
        setValue("name", carFleet?.name);
        setValue("description", carFleet?.description);

        if (carFleet?.fuel){
          setValue("fuel", {value: carFleet?.fuel.id, label: carFleet?.fuel.name});
        }
    
        if (carFleet?.bioFuel){
          setValue("bio_fuel", {value: carFleet?.bioFuel.id, label: carFleet?.bioFuel.name});
        }
    
        setCarFleetSelected(carFleet)
      }
    }
    catch (error) {
      console.error("Erro ao buscar combustível por ID:", error);
      setErrorApi("Erro ao buscar combustível por ID.");
      setModalMessage("Erro ao buscar combustível por ID.");
      setModalIsOpen(true);
    }
  }

  async function handleYearChange(event:React.ChangeEvent<HTMLSelectElement>) {

    const selectedYear = parseInt(event.target.value, 10);
    setSelectedYear(selectedYear);

    try {
      const carFleetFuel = await carFleetService.getByNameAndYearCarFleet(carFleetSelected?.name || '', selectedYear);

      if (carFleetFuel) {
        setValue("name", carFleetFuel.name);
        setValue("description", carFleetFuel.description);

        if (carFleetFuel.fuel) {
          setValue("fuel", { value: carFleetFuel.fuel.id, label: carFleetFuel.fuel.name});
        }

        if (carFleetFuel.bio_fuel) {
          setValue("bio_fuel", { value: carFleetFuel.bioFuel.id, label: carFleetFuel.bioFuel.name})
        }

        setCarFleetSelected(carFleetFuel)
      } else {
        reset({
          name: carFleetFuel?.name || '',
          description: '',
          year: selectedYear.toString(),
        });

        setCarFleetSelected(undefined);
      }
    }catch (error) {
      console.error("Erro ao buscar dados para o ano selecionado", error);
      setErrorApi("Erro ao buscar dados para o ano selecionado.");
      setModalMessage("Erro ao buscar dados para o ano selecionado.");
      setModalIsOpen(true);
    } 
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tipo de frota de veículos" />
          <div style={{ display: "flex", alignItems: "right", marginLeft: 50, marginBottom: -10 }}>
            <TitleText level={4} title="Selecione o ano do inventário: "/>
            <select
              style={{ 
                width: '35%',
                height: "75%", 
                padding: '10px', 
                borderRadius: '4px', 
                border: '1px solid #ccc', 
                backgroundColor: '#f9f9f9',
                marginTop: 5,
                marginLeft: -55 
              }}
              {...register("year", { required: true })}
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Combustível fóssil
          </label>

          <Controller control={control} name="fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("fuel")}
                options={fuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Biocombustível
          </label>

          <Controller control={control} name="bio_fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("bio_fuel")}
                options={bioFuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
      <Alert
        isOpen={modalIsOpen}
        handleModalVisible={() => setModalIsOpen(false)}
        message={modalMessage}
      />
    </Container>
  );
};
