import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import { Container } from "./styles";
import { formatPrice14, formatPrice17 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import carFleetService from "../../../services/carFleetService";
import { toast } from "react-hot-toast";

export const CarFleetYear = () => {
  const history = useHistory();
  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [years, setYears] = useState<any>([]);
  const [selectedYear, setSelectedYear] = useState<any>(null); // Estado para o ano selecionado

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.fuel_id){
      getFuelsById(item.fuel_id)
    }else{
      history.push('/tipo-frota')
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  const { user } = useAuth();
  const { register, handleSubmit, setValue, reset } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const factor_ch4_g_km = formData.factor_ch4_g_km.replace(".", "");
      const factor_n2o_g_km = formData.factor_n2o_g_km.replace(".", "");
      const average_consumption = formData.average_consumption.replace(".", "");

      const request = {
        itens: [{
          year: selectedYear.year, // Usa o ano selecionado
          factor_ch4_g_km: factor_ch4_g_km ? parseFloat(factor_ch4_g_km.replace(",", ".")) : 0,
          factor_n2o_g_km: factor_n2o_g_km ? parseFloat(factor_n2o_g_km.replace(",", ".")) : 0,
          average_consumption: average_consumption ? parseFloat(average_consumption.replace(",", ".")) : 0,
        }],
      };
      const result = await carFleetService.updateItensByCarFleet(
        carFleetSelected.id,
        request
      );
      if (result && !result.error) {
        await getFuelsById(carFleetSelected.id);
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getFuelsById(id: number) {
    const carFleet = await carFleetService.getById(id);
    const itens = await carFleetService.getItensByCarFleet(id);
    const yearLength = new Date().getFullYear() - 2000 + 1;
    const yearsList = [];

    for (let i = 0; i < yearLength; i++) {
      let year = i === 0 ? null : 2000 + i;
      const yearLabel = i === 0 ? 'até 2000' : 2000 + i;
      year = year === null ? 2000 : year;

      yearsList.push({
        year: year as number,
        year_label: yearLabel,
      });
    }

    setYears(yearsList);
    setCarFleetSelected(carFleet);
  }

  function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedYearValue = years.find((year: any) => year.year == event.target.value);
    setSelectedYear(selectedYearValue);
    loadYearData(selectedYearValue.year); // Carrega os dados do ano selecionado
  }

  async function loadYearData(year: number) {
    const itens = await carFleetService.getItensByCarFleet(carFleetSelected.id);
    const item = getItem(year, itens.itens);

    if (item) {
      setValue("factor_ch4_g_km", formatPrice17(Number(item.factor_ch4_g_km), false));
      setValue("factor_n2o_g_km", formatPrice17(Number(item.factor_n2o_g_km), false));
      setValue("average_consumption", formatPrice14(Number(item.average_consumption), false));
    } else {
      reset(); // Limpa os campos se não houver dados para o ano selecionado
    }
  }

  function getItem(year: number | undefined, itens?: any) {
    if (itens) {
      for (const item of itens) {
        if (item.year === year || (year === undefined && item.year === 2000)) {
          return item;
        }
      }
    }
    return undefined;
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TitleText level={1} title={carFleetSelected.name} />
          </div>

          <div style={{ display: "flex", alignItems: "right", marginBottom: -10 }}>
            <TitleText level={2} title="Selecione o ano: "/>
              <select id="year" onChange={handleYearChange} value={selectedYear?.year || ""} 
                style={{ 
                      width: '30%',
                      height: "75%", 
                      marginLeft: 10, 
                      padding: '10px', 
                      borderRadius: '4px', 
                      border: '1px solid #ccc', 
                      backgroundColor: '#f9f9f9',
                      marginTop: 5 
                    }}>
              {years.map((year: any, index: number) => (
                <option key={index} value={year.year}>{year.year_label}</option>
              ))}
            </select>
          </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, justifyContent: "space-between" }}>
            <TitleText level={2} title="Fatores de Emissão - MMA (2014) p/ ano" />
          </div>

          {selectedYear && (
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TitleText level={4} title ="CH₄g/km:" />
                  <input
                    {...register("factor_ch4_g_km", { required: true })}
                    onChange={(event) => setValue("factor_ch4_g_km", currency(event))}
                    style={{
                      marginLeft: 10,
                      border: '1px solid #ccc',
                      padding: '8px',
                      borderRadius: '4px',
                      width: '140px',
                      marginTop: 10
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TitleText level={4} title="N₂Og/km:" />
                  <input
                    {...register("factor_n2o_g_km", { required: true })}
                    onChange={(event) => setValue("factor_n2o_g_km", currency(event))}
                    style={{
                      marginLeft: 10,
                      border: '1px solid #ccc',
                      padding: '8px',
                      borderRadius: '4px',
                      width: '140px',
                      marginTop: 10
                    }}
                  />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TitleText level={4} title="Consumo médio:" />
                  <input
                    {...register("average_consumption", { required: true })}
                    onChange={(event) => setValue("average_consumption", currency(event))}
                    style={{
                      marginLeft: 10,
                      border: '1px solid #ccc',
                      padding: '8px',
                      borderRadius: '4px',
                      width: '140px',
                      marginTop: 10
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </Container>
  );
};
