import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import useAuth from "../../../../../hooks/useAuth";
import inventoryService from "../../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { formatPrice3, formatPrice5 } from "../../../../../utils/transform";
import scope3Service from "../../../../../services/scope3Service";
import { Modal } from "../../../../../components/Modal";
import scopeService from "../../../../../services/scopeService";
import Parser from 'html-react-parser';
import { toast } from "react-hot-toast";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";
import { Loading } from "../../../../../components/Loading";
import transportTypeService from '../../../../../services/transportTypeService'
import ReportRecordsCheckbox from "../../../components/ReportRecordsCheckbox";
import { Uninformed } from "../../../../../components/Uninformed";
import { ModalImportData } from "../../../../../components/ModalImportData";

export const TduWaterTransport = () => {
  const history = useHistory();
  const { isCommonUser } = useAuth()
  const [files, setFiles] = useState<any>([]);
  const [ignoreFill, setIgnoreFill] = useState(true);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  const [inventorySelected, setInventorySelected] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const location = useLocation();
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [openModalImport1, setOpenModalImport1] = useState(false)
  const [transportTypes, setTransportTypes] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emissionTco2eWaterFuel, setEmissionTco2eWaterFuel] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emissionTco2eBioWaterFuel, setEmissionTco2eBioWaterFuel] = useState<any>();
  const [scopeWaterVessels, setScopeWaterVessels] = useState<string | undefined>();
  const [waterVessel, setWaterVessel] = useState<any>([])
  const [emissionCo2WaterVessels, setEmissionCo2WaterVessels] = useState<any>();
  const [emissionCh4WaterVessels, setEmissionCh4WaterVessels] = useState<any>();
  const [emissionN2oWaterVessels, setEmissionN2oWaterVessels] = useState<any>();
  const [emissionTco2eWaterVessels, setEmissionTco2eWaterVessels] = useState<any>();
  const [emissionTco2eTotal, setEmissionTco2eTotal] = useState<any>()
  const [emissionTco2eBioTotal, setEmissionTco2eBioTotal] = useState<any>()

  useEffect(() => {
    const item:any = location?.state;
    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({pathname: '/inventario/escopo-3/transp-distribuicao-upstream',});
    }
    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [location]);

  useEffect(() => {
    setEmissionTco2eTotal((emissionTco2eWaterFuel ? emissionTco2eWaterFuel: 0)+(emissionTco2eWaterVessels ? emissionTco2eWaterVessels : 0))
  }, [emissionTco2eWaterFuel, emissionTco2eWaterVessels])

  useEffect(() => {
    setEmissionTco2eBioTotal((emissionTco2eBioWaterFuel ? emissionTco2eBioWaterFuel : 0))
  }, [emissionTco2eBioWaterFuel])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'transp_distribuicao_upstream', 'transporte_hidroviario')
    setTitle(item.label)
    setGuidelines(item.guidelines)
  }
  const { register, handleSubmit, setValue, control, getValues, formState: { errors }} = useForm<any>();
  const handleChangeWaterVessel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id);
      if (result) {
        const item = { transport_type_id: result.id, transport_type_name: result.name };
        const index = waterVessel.length;
        const itens = waterVessel.concat(item);
        setScopeWaterVessels('new_scope_item_' + index);
        setWaterVesselValues(itens);
        calculateWaterVessel(itens);
      }
    }
    if (e) {
      retrieveServiceById(e.value);
    }
    setValue('transport_type', []);
  }
  const handleChangeValueVessel = async (index: number, ignore_field?: string) => {
    const year = inventorySelected.year;
    const transport_type_id = waterVessel[index].transport_type_id
    const distance = waterVessel[index].distance ? parseFloat(waterVessel[index].distance) : 0
    const transported_freight = waterVessel[index].transported_freight ? parseFloat(waterVessel[index].transported_freight) : 0;
    async function calculate() {
      const result = await scope3Service.calculateTransportDistributionUpstreamWaterVessel(year,distance,transport_type_id,transported_freight);
      if (result) {
        waterVessel[index].distance_mass = parseFloat(result.distance_mass) || 0;
        waterVessel[index].factor_co2_kg = parseFloat(result.factor_co2_kg) || 0;
        waterVessel[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg) || 0;
        waterVessel[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg) || 0;
        waterVessel[index].emission_co2 = parseFloat(result.emission_co2) || 0;
        waterVessel[index].emission_ch4 = parseFloat(result.emission_ch4) || 0;
        waterVessel[index].emission_n2o = parseFloat(result.emission_n2o) || 0;
        waterVessel[index].emission_tco2e = parseFloat(result.emission_tco2e) || 0;
        setScopeWaterVessels('change_scope_item_' + index + '_' + year + '_' + transport_type_id + '_' + distance + '_' + transported_freight);
        setWaterVesselValues(waterVessel, ignore_field);
        calculateWaterVessel(waterVessel);
      }
    }
    calculate()
  }
  function handleDeleteWaterVessel(index: number) {
    const itens = []
    for (let i = 0; i < waterVessel?.length; i++) {
      if (i !== index) {
        itens.push(waterVessel[i])
      }
    }
    setScopeWaterVessels('remove_scope_item_' + index)
    setValue('transport_type', [])
    setWaterVesselValues(itens)
    calculateWaterVessel(itens)
  }
  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)
      if (result && !result.error) {
        const result = await inventoryService.getFiles( inventorySelected.company.id, inventorySelected.year, 3, 'transp_distribuicao_upstream', 'transporte_rodoviario')
        setFiles(result.files)
      }
    }
    deleteFile(id)
  }
  const setWaterVesselValues = (itens: any, ignore_field?: string) => {
    let changeWaterVessel = 'change_scope_item_';
    if (itens) {
      for (let i = 0; i < itens.length; i++) {
        const item = itens[i];
        setValue(`vessel.${i}.source`, item.source);
        setValue(`vessel.${i}.description`, item.description);
        setValue(`vessel.${i}.transport_type_id`, item.transport_type_id);
        setValue(`vessel.${i}.transport_type_name`, item.transport_type_name);      
        if ('distance' !== ignore_field) {setValue(`vessel.${i}.distance`, formatPrice5(item.distance, false))}
        if ('transported_freight' !== ignore_field) {setValue(`vessel.${i}.transported_freight`, formatPrice5(item.transported_freight, false))}
        if ('distance_mass' !== ignore_field) {setValue(`vessel.${i}.distance_mass`, formatPrice5(Number(item.distance_mass), false))}
        if ('factor_co2_kg' !== ignore_field) {setValue(`vessel.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))}
        if ('factor_ch4_kg' !== ignore_field) {setValue(`vessel.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))}
        if ('factor_n2o_kg' !== ignore_field) {setValue(`vessel.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))}
        if ('emission_co2' !== ignore_field) {setValue(`vessel.${i}.emission_co2`, formatPrice5(item.emission_co2, false))}
        if ('emission_ch4' !== ignore_field) {setValue(`vessel.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))}
        if ('emission_n2o' !== ignore_field) {setValue(`vessel.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))}        
        if ('emission_tco2e' !== ignore_field) {setValue(`vessel.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))}
        changeWaterVessel = changeWaterVessel + '_' + item.source + '_' + item.transport_type_id + '_' + item.distance + '_' + item.transported_freight
      }
    }
    setWaterVessel(itens)
    setScopeWaterVessels(changeWaterVessel)
  } 
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos
    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }
    return value;
  }
  async function getInventorysById(company_id: number, year: number, scope:number) {
    setLoadingModal(true)
    const transportTypes = await transportTypeService.getByParams({ limit: 9000, page: 1, type: 5})
    setTransportTypes(transportTypes?.itens.map((type: any) => {return { label: type.name, value: type.id}}))
    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getTransportDistributionUpstreamWater(company_id, year)
    const result = await inventoryService.getFiles(inventory.company.id, inventory.year, 3, 'transp_distribuicao_upstream', 'transporte_hidroviario');
    setFiles(result.files)
    if (entity){
      setValue("reason", entity.reason)
      setValue("evidence", entity.evidence)
      setValue("ignore_fill", entity.ignore_fill)
      setIgnoreFill(entity.ignore_fill)
      setScopeSelected(entity)
      setWaterVesselValues(entity?.vessels)
      calculateWaterVessel(entity?.vessels)
      setScopeWaterVessels("load_scope_" + entity.id + "_scopes_" + entity?.vessels.length)
    }else{
      setScopeSelected({})
      setWaterVesselValues([])
      calculateWaterVessel([])
      setIgnoreFill(false)
      setScopeWaterVessels("load_scope_0_scopes_0")
    }
    setInventorySelected(inventory)
    setLoadingModal(false)
  }
  const calculateWaterVessel = (itens: any) => {
    let emissionCo2 = 0
    let emissionCh4 = 0
    let emissionN2o = 0
    let emissionTco2e = 0
    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {emissionCo2 = emissionCo2 + item.emission_co2}
        if (item.emission_ch4) {emissionCh4 = emissionCh4 + item.emission_ch4}
        if (item.emission_n2o) {emissionN2o = emissionN2o + item.emission_n2o}
        if (item.emission_tco2e) {emissionTco2e = emissionTco2e + item.emission_tco2e}
      }
    }
    setEmissionCo2WaterVessels(emissionCo2)
    setEmissionCh4WaterVessels(emissionCh4)
    setEmissionN2oWaterVessels(emissionN2o)
    setEmissionTco2eWaterVessels(emissionTco2e)
    setScopeWaterVessels('calculate_iten_' + emissionCo2 + '_' + emissionCh4 + '_' + emissionN2o + '_' + emissionTco2e)
  }
  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)
    if (formData) {
      const waterVesselItens: any[] = waterVessel.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          transport_type_id: item.transport_type_id,
          distance: item.distance ? parseFloat(item.distance) : 0,
          transported_freight: item.transported_freight ? parseFloat(item.transported_freight) : 0,
          distance_mass: item.distance_mass ? parseFloat(item.distance_mass) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e) : 0,
        };
      });
      const scope = { vessels: waterVesselItens, evidence: formData.evidence, ignore_fill: formData.ignore_fill, reason: formData.ignore_fill ? formData.reason : ''}
      const result = await scope3Service.saveTransportDistributionUpstreamWater( inventorySelected.company.id, inventorySelected.year, scope)
      if (result && !result.error) {
        localStorage.setItem('TransportDistributionUpstreamWater', JSON.stringify(scope));
        setErrorApi(undefined)
        toast.success("Operação realizada com sucesso!")
      }
      if (result.error) {
        setErrorApi(result.error)
      }
    }
    setLoadingModal(false)
  }
	const downloadFile = (filename:string) => {
    window.open(filename, "_blank")
  }
	const attachmentChangeHandler = (event:any) => {
    async function saveImage(base64?:string, filename?:string) {
      const image = { type: 3, name: 'transp_distribuicao_upstream', file: base64, filename: filename, second_name: 'transporte_hidroviario',};
      const result = await inventoryService.saveFile(inventorySelected.company.id, inventorySelected.year, image);
      if (result && !result.error) {
        const result = await inventoryService.getFiles(inventorySelected.company.id, inventorySelected.year, 3, 'transp_distribuicao_upstream', 'transporte_hidroviario');
        setFiles(result.files)
      }
    }
    for (const file of event.target.files) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64 = reader.result
        saveImage(base64?.toString(), file.name)
      }
    }
	};
  const handleImportVehicle = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)
        const errors:any[] = []
        const itens = waterVessel

        for (let i = 1; i < data?.length; i++) {
          const vessel = data[i][2]
          if (vessel){
            let obj:any = undefined
            for (const t of transportTypes) {
              if (t.label === vessel){
                obj = t
                break;
              }
            }

            if (obj){
              const source = data[i][0]
              const description = data[i][1]
              const distance = data[i][3]
              const transported_freight = data[i][4]
              const year = inventorySelected.year
              const scope = {  source: source, description: description, distance: distance, transported_freight: transported_freight, distance_mass: 0, year: year,
                                transport_type_id: obj.value, transport_type_name: obj.label, factor_co2_kg: 0, factor_ch4_kg: 0, factor_n2o_kg: 0,
                                emission_co2: 0, emission_ch4: 0, emission_n2o: 0, emission_tco2e: 0
                }

              const result = await scope3Service.calculateTransportDistributionUpstreamWaterVessel(scope.year, scope.distance, scope.transport_type_id, scope.transported_freight)
              if (result) {
                
                scope.transported_freight     = parseFloat(scope.transported_freight)
                scope.distance                = parseFloat(scope.distance)
                scope.distance_mass           = parseFloat(result.distance_mass)
                scope.factor_co2_kg           = parseFloat(result.factor_co2_kg)   
                scope.factor_ch4_kg           = parseFloat(result.factor_ch4_kg)   
                scope.factor_n2o_kg           = parseFloat(result.factor_n2o_kg)
                scope.emission_co2            = parseFloat(result.emission_co2)                      
                scope.emission_ch4            = parseFloat(result.emission_ch4)     
                scope.emission_n2o            = parseFloat(result.emission_n2o)     
                scope.emission_tco2e          = parseFloat(result.emission_tco2e) 
                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: waterVessel})
            }
          }
        } 
          setScopeWaterVessels(`import_scope_data_${data?.length}`);
          setWaterVesselValues(itens)
          calculateWaterVessel(itens)
        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }
        setLoadingModal(false)
      }
    }
    if (data){
      importData()
    }
  }
  function handleOpenInfo() {
    setOpenModal(!openModal)
  }
  const handleOpenModalImport1 = () => {
    setOpenModalImport1(!openModalImport1)
  }
  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }
  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }} >
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}

            {!isCommonUser && (
              <Button color="green" size="md" style={{margin: "auto"}}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
          register={register}
          setIgnoreFill={setIgnoreFill}
        />
        )}

        {ignoreFill === true && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10}} >
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Informe abaixo uma justificativa para o não preenchimento do escopo selecionado
            </label>

            <textarea style={{width: '95%', height: 100}}
                  {...register("reason", { required: true })}/>
                        
            {errors.reason && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 230px)", 
            maxHeight: "calc(100vh - 230px)"}} >
          

          {ignoreFill === false && (
            <div style={{display: 'flex',flexDirection: 'column',width: '98%',marginTop: 10,marginLeft: 10}}>
              <div style={{display: 'flex',flexDirection: 'row',marginTop: 0,justifyContent: 'space-between',marginBottom: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}>
                  <span style={{textAlign: 'start',fontSize: 14,marginTop: 25,fontWeight: 'bold'}}>
                    Opção 1 - Cálculo de emissões por transporte hidroviário de carga fracionada com base no tipo de navio no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione o tipo de embarcação ao lado para adicionar nova linha
                  </span>
                </div>
                {transportTypes && !isCommonUser && (
                <div style={{marginTop: 15,marginRight: 10,display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
                  <Controller
                    control={control}
                    name="transport_type"
                    render={({ field }) => (
                      <Select 
                        placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                          ...styles,
                          width: '350px',
                          height: 40,
                          backgroundColor: 'rgb(246, 246, 246)'
                        }),
                          option: (styles) => { 
                            return { ...styles, color: '#31363F' };
                          },
                        }} 
                        defaultValue={getValues('transport_type')}
                        options={transportTypes}
                        onChange={handleChangeWaterVessel}
                      />
                    )}
                  />
                  <button
                    className="button-view"
                    style={{ height: 40, marginLeft: 5 }}
                    onClick={() => handleOpenModalImport1()}
                    type="button"
                  >
                    Importar Dados
                  </button>
                </div>
              )}
              </div>
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeWaterVessels && waterVessel && waterVessel.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }} rowSpan={2}>
                          {'Registro da embarcação'}
                        </th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>
                          {'Descrição da frota'}
                        </th>
                        <th className="head-tr-item" style={{ width: 360 }} rowSpan={2}>
                          {'Tipo de navio'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Distância percorrida'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Carga transportada'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Distância x massa (t.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'CO₂ (kg CO₂/t.km)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'CH₄ (kg CH₄/t.km)'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'N₂O (kg N₂O/t.km)'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {waterVessel.map((vessel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`vessel.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`vessel.${index}.source`,event.target.value)
                                    vessel.source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`vessel.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`vessel.${index}.description`, event.target.value)
                                    vessel.description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {waterVessel[index].transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`vessel.${index}.distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0
                                    vessel.distance = value
                                    setValue(`vessel.${index}.distance`, formatValue)
                                    handleChangeValueVessel(index, 'distance')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`vessel.${index}.transported_freight`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0
                                    vessel.transported_freight = value
                                    setValue(`vessel.${index}.transported_freight`, formatValue)
                                    handleChangeValueVessel(index, 'transported_freight')
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(Number(vessel.distance_mass), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(waterVessel[index].emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} >
                                  <div
                                    onClick={() => handleDeleteWaterVessel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="qualquer_coisa"  />
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={9}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2WaterVessels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4WaterVessels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oWaterVessels, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eWaterVessels, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {waterVessel && waterVessel.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de embarcação cadastrada`
                        : `Nenhum tipo de embarcação foi selecionada, favor selecionar para dar prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.waterVessel && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eTotal, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}}>
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eBioTotal, false)}
              </span>
            </div>
          )}


            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}
      {openModalImport1 && (
        <ModalImportData
          entityType={1}
          isOpen={openModalImport1}
          handleModalVisible={handleOpenModalImport1}
          entityName="Cálculo de emissões por transporte hidroviário de carga fracionada com base no tipo de navio no ano inventariado."
          templateFileName="template_upstream_embarcacoes_carga.xlsx"
          handleImportData={handleImportVehicle}
        />
      )}

      {loadingModal && ( 
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  );
};
