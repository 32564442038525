import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import Parser from 'html-react-parser'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice17, formatPrice3, toNumberValues } from '../../../../../utils/transform'
import scope3Service from '../../../../../services/scope3Service'
import inventoryService from '../../../../../services/inventoryService'
import transportTypeService from '../../../../../services/transportTypeService'
import scopeService from '../../../../../services/scopeService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { ModalImportData } from '../../../../../components/ModalImportData'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import { Loading } from '../../../../../components/Loading'
import { toast } from 'react-hot-toast'

export const BusTrip = () => {
  const history = useHistory()

  const location = useLocation()
  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false);
  const [importErrors, setImportErrors] = useState<any>([])
  const [title, setTitle] = useState<string | undefined>();
  const [entityType, setEntityType] = useState<number | undefined>();
  const [entityName, setEntityName] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const [openModalImportBus, setOpenModalImportBus] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [buses, setBuses] = useState<any>([])
  const [busTypes, setBusTypes] = useState<any>([])
  const [emissionCo2Bus, setEmissionCo2Bus] = useState<any>()
  const [emissionCh4Bus, setEmissionCh4Bus] = useState<any>()
  const [emissionN2oBus, setEmissionN2oBus] = useState<any>()
  const [emissionTco2eBus, setEmissionTco2eBus] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emissionTco2eBioBus, setEmissionTco2eBioBus] = useState<any>()
  const [changeScopeBus, setChangeScopeBus] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeScopeTrain, setChangeScopeTrain] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'viagens_a_negocios', 'viagens_em_onibus')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      
      const busItens: any[] = buses.map((item: any, index: number) => {
        return {
          register: item.register.toString(),
          description: item.description.toString(),
          address1: item.address1.toString(),
          address2: item.address2.toString(),
          transport_type_id: item.transport_type_id,
          number_of_passengers: parseInt(item.number_of_passengers),
          travelled_distance: item.travelled_distance ? item.travelled_distance : '0',
          factor_gco2_km: item.factor_gco2_km ? item.factor_gco2_km : '0',
          factor_gch4_km: item.factor_gch4_km ? item.factor_gch4_km : '0',
          factor_gn2o_km: item.factor_gn2o_km ? item.factor_gn2o_km : '0',
          factor_gco2_bio_km: item.factor_gco2_bio_km ? item.factor_gco2_bio_km : '0',
          factor_gch4_bio_km: item.factor_gch4_bio_km ? item.factor_gch4_bio_km : '0',
          factor_gn2o_bio_km: item.factor_gn2o_bio_km ? item.factor_gn2o_bio_km : '0',
          emission_co2_diesel: item.emission_co2_diesel ? item.emission_co2_diesel : '0',
          emission_ch4_diesel: item.emission_ch4_diesel ? item.emission_ch4_diesel : '0',
          emission_n2o_diesel: item.emission_n2o_diesel ? item.emission_n2o_diesel : '0',
          emission_co2_bio: item.emission_co2_bio ? item.emission_co2_bio : '0',
          emission_ch4_bio: item.emission_ch4_bio ? item.emission_ch4_bio : '0',
          emission_n2o_bio: item.emission_n2o_bio ? item.emission_n2o_bio : '0',
          emission_co2: item.emission_co2 ? item.emission_co2 : '0',
          emission_ch4: item.emission_ch4 ? item.emission_ch4 : '0',
          emission_n2o: item.emission_n2o ? item.emission_n2o : '0',
          emission_tco2e: item.emission_tco2e ? item.emission_tco2e : '0',
          emission_tco2e_bio: item.emission_tco2e_bio ? item.emission_tco2e_bio : '0'
        }
      })

      const scope = {
        bus: busItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }
      const result = await scope3Service.saveBusinessTripBus(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )
      
      if (result && !result.error) {
        localStorage.setItem('BusinessTripBus', JSON.stringify(scope));

        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const busTypes = await transportTypeService.getByParams({
      limit: 9000,
      page: 1,
      type: 2
    })

    setBusTypes(
      busTypes?.itens.map((type: any) => {
        return {
          label: type.name,
          value: type.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getBusinessTripBus(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'viagens_a_negocios',
      'viagens_em_onibus'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)
      setIgnoreFill(entity.ignore_fill)
      setScopeSelected(entity)
      setBusValues(entity?.bus)
      calculateTotalsBus(entity?.bus)
      setChangeScopeBus('load_scope_' + entity.id + 'bus' + entity?.bus.length)

    } else {
      setScopeSelected({})
      setIgnoreFill(false)
      setBusValues([])
      calculateTotalsBus([])
      setChangeScopeBus('load_scope_0_bus_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const handleChangeBus = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await transportTypeService.getById(id)

      if (result) {
        const item = {
          transport_type_id: result.id,
          transport_type_name: result.name
        }
        const index = buses.length
        const itens = buses.concat(item)

        setChangeScopeBus('new_scope_item_' + index)
        setBusValues(itens)
        calculateTotalsBus(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('transport_type_bus', [])
  }

  const setBusValues = (itens: any, ignore_field?: string) => {
    let changeScopeBus = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`bus.${i}.register`, item.register)
        setValue(`bus.${i}.description`, item.description)
        setValue(`bus.${i}.address1`, item.address1)
        setValue(`bus.${i}.address2`, item.address2)
        setValue(`bus.${i}.transport_type_id`, item.transport_type_id)
        setValue(`bus.${i}.transport_type_name`, item.transport_type_name)

        if ('emission_co2' !== ignore_field) {setValue(`bus.${i}.emission_co2`, formatPrice3(item.emission_co2, false))}
        if ('emission_ch4' !== ignore_field) {setValue(`bus.${i}.emission_ch4`, formatPrice3(item.emission_ch4, false))}
        if ('emission_n2o' !== ignore_field) {setValue(`bus.${i}.emission_n2o`, formatPrice3(item.emission_n2o, false))}
        if ('factor_gco2_km' !== ignore_field) {setValue(`bus.${i}.factor_gco2_km`, formatPrice3(item.factor_gco2_km, false))}
        if ('factor_gch4_km' !== ignore_field) {setValue(`bus.${i}.factor_gch4_km`, formatPrice3(item.factor_gch4_km, false))}
        if ('factor_gn2o_km' !== ignore_field) {setValue(`bus.${i}.factor_gn2o_km`, formatPrice3(item.factor_gn2o_km, false))}
        if ('emission_tco2e' !== ignore_field) {setValue(`bus.${i}.emission_tco2e`, formatPrice3(item.emission_tco2e, false))}
        if ('emission_co2_bio' !== ignore_field) {setValue(`bus.${i}.emission_co2_bio`, formatPrice3(item.emission_co2_bio, false))}
        if ('emission_ch4_bio' !== ignore_field) {setValue(`bus.${i}.emission_ch4_bio`, formatPrice3(item.emission_ch4_bio, false))}
        if ('emission_n2o_bio' !== ignore_field) {setValue(`bus.${i}.emission_n2o_bio`, formatPrice3(item.emission_n2o_bio, false))}
        if ('emission_tco2e_bio' !== ignore_field) {setValue(`bus.${i}.emission_tco2e_bio`, formatPrice3(item.emission_tco2e_bio, false))}
        if ('factor_gco2_bio_km' !== ignore_field) {setValue(`bus.${i}.factor_gco2_bio_km`, formatPrice3(item.factor_gco2_bio_km, false))}
        if ('factor_gch4_bio_km' !== ignore_field) {setValue(`bus.${i}.factor_gch4_bio_km`, formatPrice3(item.factor_gch4_bio_km, false))}
        if ('factor_gn2o_bio_km' !== ignore_field) {setValue(`bus.${i}.factor_gn2o_bio_km`, formatPrice3(item.factor_gn2o_bio_km, false))}
        if ('travelled_distance' !== ignore_field) {setValue(`bus.${i}.travelled_distance`, formatPrice3(item.travelled_distance, false))}
        if ('emission_co2_diesel' !== ignore_field) {setValue(`bus.${i}.emission_co2_diesel`, formatPrice3(item.emission_co2_diesel, false))}
        if ('emission_ch4_diesel' !== ignore_field) {setValue(`bus.${i}.emission_ch4_diesel`, formatPrice3(item.emission_ch4_diesel, false))}
        if ('emission_n2o_diesel' !== ignore_field) {setValue(`bus.${i}.emission_n2o_diesel`, formatPrice3(item.emission_n2o_diesel, false))}
        if ('number_of_passengers' !== ignore_field) {setValue(`bus.${i}.number_of_passengers`, item.number_of_passengers)}
        changeScopeBus =
        changeScopeBus +
          '_' +
          item.transport_type_id +
          '_' +
          item.number_of_passengers +
          '_' +
          item.travelled_distance
      }
    }

    setBuses(itens)
    setChangeScopeBus(changeScopeBus)
  }

  const calculateTotalsBus = (itens: any) => {
    let emissionCo2Bus = 0
    let emissionCh4Bus = 0
    let emissionN2oBus = 0
    let emissionTco2eBus = 0
    let emissionTco2eBioBus = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {emissionCo2Bus = emissionCo2Bus + item.emission_co2}
        if (item.emission_ch4) {emissionCh4Bus = emissionCh4Bus + item.emission_ch4}
        if (item.emission_n2o) {emissionN2oBus = emissionN2oBus + item.emission_n2o}
        if (item.emission_tco2e) {emissionTco2eBus = emissionTco2eBus + item.emission_tco2e}
        if (item.emission_tco2e_bio) {emissionTco2eBioBus = emissionTco2eBioBus + item.emission_tco2e_bio}
      }
    }

    setEmissionCo2Bus(emissionCo2Bus)
    setEmissionCh4Bus(emissionCh4Bus)
    setEmissionN2oBus(emissionN2oBus)
    setEmissionTco2eBus(emissionTco2eBus)
    setEmissionTco2eBioBus(emissionTco2eBioBus)

    setChangeScopeBus(
      'calculate_iten_' +
        emissionCo2Bus +
        '_' +
        emissionCh4Bus +
        '_' +
        emissionN2oBus +
        '_' +
        emissionTco2eBus +
        '_' +
        emissionTco2eBioBus
    )
  }

  const handleChangeValueBus = (index: number, ignore_field?: string) => {
    const year = inventorySelected.year
    const description = buses[index].description
    const address1 = buses[index].address1
    const address2 = buses[index].address2
    const company_id = inventorySelected.company.id
    const transport_type_id = buses[index].transport_type_id
    let travelled_distance = buses[index].travelled_distance ? parseFloat(buses[index].travelled_distance) : 0
    let number_of_passengers = buses[index].number_of_passengers ? parseFloat(buses[index].number_of_passengers) : 0
    travelled_distance = travelled_distance ? travelled_distance : 0
    number_of_passengers = number_of_passengers ? number_of_passengers : 0

    async function calculate() {
      const result = await scope3Service.calculateBusinessTripBus(
        year,
        company_id,
        description,
        address1,
        address2,
        travelled_distance,
        transport_type_id,
        number_of_passengers
      )

      if (result) {
        buses[index].emission_co2 = parseFloat(result.emission_co2)
        buses[index].emission_ch4 = parseFloat(result.emission_ch4)
        buses[index].emission_n2o = parseFloat(result.emission_n2o)
        buses[index].emission_tco2e = parseFloat(result.emission_tco2e)
        buses[index].factor_gco2_km = parseFloat(result.factor_gco2_km)
        buses[index].factor_gch4_km = parseFloat(result.factor_gch4_km)
        buses[index].factor_gn2o_km = parseFloat(result.factor_gn2o_km)
        buses[index].emission_co2_bio = parseFloat(result.emission_co2_bio)
        buses[index].emission_ch4_bio = parseFloat(result.emission_ch4_bio)
        buses[index].emission_n2o_bio = parseFloat(result.emission_n2o_bio)
        buses[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        buses[index].factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
        buses[index].factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
        buses[index].factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
        buses[index].travelled_distance = parseFloat(result.travelled_distance)
        buses[index].emission_co2_diesel = parseFloat(result.emission_co2_diesel)
        buses[index].emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
        buses[index].emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

        setChangeScopeTrain(
          'change_scope_item_' +
            index +
            '_' +
            year +
            '_' +
            transport_type_id +
            '_' +
            travelled_distance +
            '_' +
            number_of_passengers
        )

        setBusValues(buses, ignore_field)
        calculateTotalsBus(buses)
      }
    }

    calculate()
  }

  const handleOpenModalImportBus = () => {
    setOpenModalImportBus(!openModalImportBus)

    setEntityType(1)
    setEntityName("Viagens a negócios - Viagens em ônibus")
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }
  
  const handleImport = (type?:number, data?:any[]) => {
    if (type === 1){
      handleImportBus(data)
    }
  }

  const handleImportBus = (data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)

        const errors:any[] = []
        const itens = buses

        for (let i = 1; i < data?.length; i++) {
          const transport = data[i][3]
  
          if (transport){
            let obj:any = undefined
  
            for (const t of busTypes) {
              if (t.label === transport){
                obj = t
                break;
              }
            }
            // Excel onibus
            if (obj){
              const register = data[i][0]
              const description = "Dados importados (" + register + ")"
              const address1 = data[i][1]
              const address2 = data[i][2]
              const number_of_passengers = 1

              const scope = {
                register: register,
                description: description,
                address1: address1,
                address2: address2,
                number_of_passengers: number_of_passengers,
                transport_type_id: obj.value,
                transport_type_name: obj.label,
                travelled_distance: 0,
                emission_tco2e: 0,
                factor_gco2_km: 0,
                factor_gch4_km: 0,
                factor_gn2o_km: 0,
                emission_co2: 0,
                emission_co2_bio: 0,
                emission_ch4_bio: 0,
                emission_n2o_bio: 0,
                emission_tco2e_bio: 0,
                factor_gco2_bio_km: 0,
                factor_gch4_bio_km: 0,
                factor_gn2o_bio_km: 0,
                emission_co2_diesel: 0,
                emission_ch4_diesel: 0,
                emission_n2o_diesel: 0,
                emission_ch4: 0,
                emission_n2o: 0
              }
              
              const result = await scope3Service.calculateBusinessTripBus(inventorySelected.year, 
                inventorySelected.company.id, scope.description, scope.address1,scope.address2, scope.travelled_distance,
                scope.transport_type_id, scope.number_of_passengers)
              
              if (result) {
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                scope.factor_gco2_km = parseFloat(result.factor_gco2_km)
                scope.factor_gch4_km = parseFloat(result.factor_gch4_km)
                scope.factor_gn2o_km = parseFloat(result.factor_gn2o_km)
                scope.emission_co2_bio = parseFloat(result.emission_co2_bio)
                scope.emission_ch4_bio = parseFloat(result.emission_ch4_bio)
                scope.emission_n2o_bio = parseFloat(result.emission_n2o_bio)
                scope.emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
                scope.factor_gco2_bio_km = parseFloat(result.factor_gco2_bio_km)
                scope.factor_gch4_bio_km = parseFloat(result.factor_gch4_bio_km)
                scope.factor_gn2o_bio_km = parseFloat(result.factor_gn2o_bio_km)
                scope.travelled_distance = parseFloat(result.travelled_distance)
                scope.emission_co2_diesel = parseFloat(result.emission_co2_diesel)
                scope.emission_ch4_diesel = parseFloat(result.emission_ch4_diesel)
                scope.emission_n2o_diesel = parseFloat(result.emission_n2o_diesel)

                itens.push(scope)
              }
            }else{
              errors.push({id: i, label: transport})
            }
          }
        }
        
        setChangeScopeBus(`import_scope_data_${data?.length}`);
  
        setBusValues(itens)
        calculateTotalsBus(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }


  function handleDeleteBus(index: number) {
    const itens = []

    for (let i = 0; i < buses?.length; i++) {
      if (i !== index) {
        itens.push(buses[i])
      }
    }

    setChangeScopeBus('remove_scope_item_' + index)

    setValue('transport_type_bus', [])

    setBusValues(itens)
    calculateTotalsBus(itens)
  }


  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_onibus'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }


  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'viagens_a_negocios',
        file: base64,
        filename: filename,
        second_name: 'viagens_em_onibus'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'viagens_a_negocios',
          'viagens_em_onibus'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
        
        {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30
                  }}
                >
                  <span style={{ textAlign: 'start', fontSize: 14, marginTop: 5, marginLeft: 10, fontWeight: 'bold' }} >
                  Cálculo de emissões por transporte de funcionários para deslocamento
                      casa-trabalho realizadas em <span style={{ color: 'Blue' }}>ônibus</span>, de acordo com quilômetros
                      percorridos no ano inventariado.
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5, marginLeft: 10, }} >
                    Selecione, o tipo de transporte ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && busTypes && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <Controller
                      control={control}
                      name="transport_type_bus"
                      render={({ field }) => (
                        <Select placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: '350px',
                              height: 40,
                              backgroundColor: 'rgb(246, 246, 246)'
                            }),
                            option: (styles) => {
                              return { ...styles, color: '#31363F' }
                            }
                          }}
                          defaultValue={getValues('transport_type_bus')}
                          options={busTypes}
                          onChange={handleChangeBus}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImportBus()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {changeScopeBus && buses && buses.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={4}>{'Percurso'}</th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>{'Tipo de transporte'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Número de passageiros'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Distancia percorrida (km)'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>{'Fator de emissão - combustíveis fósseis'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>{'Fator de emissão - biocombustíveis'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>{'Óleo Diesel'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>{'Biodiesel'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} colSpan={3}>{'Emissões fósseis'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Emissões totais em CO₂e (t)'}</th>
                        <th className="head-tr-item" style={{ width: 200 }} rowSpan={2}>{'Emissões de CO₂e biogênico (t)'}</th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Registro do colaborador'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Descrição'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Endereço 1'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Endereço 2'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CO₂/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CH₄/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg N₂O/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CO₂/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg CH₄/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'kg N₂O/ P.km'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CO₂ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CH₄ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões N₂O (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CO₂ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CH₄ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões N₂O (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CO₂ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>{'Emissões CH₄ (t)'}</th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }} rowSpan={2}>{'Emissões N₂O (t)'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {buses.map((bus: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`bus.${index}.register`, event.target.value)
                                    buses[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`bus.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`bus.${index}.description`,event.target.value)
                                    buses[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`bus.${index}.address1`)}
                                  onBlur={(event) => {
                                    setValue(`bus.${index}.address1`, event.target.value)
                                    buses[index].address1 = event.target.value
                                    handleChangeValueBus(index, 'address1')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 250 }}
                                  className="input-item"
                                  {...register(`bus.${index}.address2`)}
                                  onBlur={(event) => {
                                    setValue(`bus.${index}.address2`, event.target.value)
                                    buses[index].address2 = event.target.value
                                    handleChangeValueBus(index, 'address2')
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 200,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'inline-block'
                                  }}
                                >
                                  {bus.transport_type_name}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.number_of_passengers`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    buses[index].number_of_passengers = numberValue

                                    setValue(
                                      `bus.${index}.number_of_passengers`,
                                      numberValue
                                    )

                                    handleChangeValueBus(index, 'number_of_passengers')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`bus.${index}.travelled_distance`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    buses[index].travelled_distance = value

                                    setValue(
                                      `bus.${index}.travelled_distance`,
                                      formatValue
                                    )

                                    handleChangeValueBus(index, 'travelled_distance')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gco2_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gch4_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.factor_gn2o_km), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gco2_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gch4_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.factor_gn2o_bio_km),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_co2_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_ch4_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_n2o_diesel),
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o_bio), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(parseFloat(bus.emission_tco2e), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(
                                    parseFloat(bus.emission_tco2e_bio),
                                    false
                                  )}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteBus(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={19}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2Bus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4Bus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oBus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBus, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eBioBus, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {buses && buses.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de transporte cadastrado`
                        : `Nenhum tipo de transporte foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.bus && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && changeScopeBus && (
            <div
              style={{
                borderStyle: "dashed",
                margin: 5,
                borderWidth: "1px",
                width: "calc(100vw - 330px)",
                maxWidth: "calc(100vw - 330px)",
                borderColor: "#9E9E9E",
                height: "40px",
                display: "flex",
                flexDirection: "row",
                marginLeft: 15,
              }}
            >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  margin: 10,
                  textAlign: "right",
                  width: "30%",
                }}
              >
                {formatPrice3(emissionTco2eBus , false)}
              </span>
            </div>
          )}
          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}}>
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eBioBus, false)}
              </span>
            </div>
          )}
          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

   
      {openModalImportBus && entityName && entityType && (
        <ModalImportData
          entityName={entityName}
          entityType={entityType}
          isOpen={openModalImportBus}
          handleModalVisible={handleOpenModalImportBus}
          templateFileName="template_business_Bus1.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left" }}>
            <TitleText level={2} title={entityName ? entityName : ""} color="blue" />
          </div>

          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Tipo de transporte não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal > 
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}

    </Container>
  )
}
