import Parser from 'html-react-parser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3 } from '../../../../../utils/transform'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import scope3Service from '../../../../../services/scope3Service'
import scopeService from '../../../../../services/scopeService'
import inventoryService from '../../../../../services/inventoryService'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import { Button } from '../../../../../components/Button/styles'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const AnaerobicCompost3 = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [emissionCh4, setEmissionCh4] = useState<any>()
  const [emissionN2o, setEmissionN2o] = useState<any>()
  const [emissionTco2, setEmissionTco2] = useState<any>()
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>()

  const [changeScope, setChangeScope] = useState<string | undefined>()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/residuos-solidos-gerados'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'residuos_solidos_gerados', 'compostagem_anaerobica')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      let biogas_destination = formData.biogas_destination
      let compost_mass = formData.compost_mass.replace('.', '')
      let emission_factor_ch4 = formData.emission_factor_ch4.replace('.', '')
      let emission_factor_n2o = formData.emission_factor_n2o.replace('.', '')
      let amount_recovered_ch4 = formData.amount_recovered_ch4.replace('.', '')

      biogas_destination = biogas_destination ? parseInt(biogas_destination) : 1
      compost_mass = compost_mass ? parseFloat(compost_mass.replace(',', '.')) : 0
      emission_factor_ch4 = emission_factor_ch4 ? parseFloat(emission_factor_ch4.replace(',', '.')) : 0
      emission_factor_n2o = emission_factor_n2o ? parseFloat(emission_factor_n2o.replace(',', '.')) : 0
      amount_recovered_ch4 = amount_recovered_ch4 ? parseFloat(amount_recovered_ch4.replace(',', '.')) : 0

      const scope = {
        reason: formData.reason,
        compost_mass: compost_mass,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        emission_ch4: emissionCh4 ? emissionCh4 : 0,
        emission_n2o: emissionN2o ? emissionN2o : 0,
        emission_tco2e: emissionTco2 ? emissionTco2 : 0,
        emission_co2_bio: emissionCo2Bio ? emissionCo2Bio : 0,
        biogas_destination: biogas_destination ? biogas_destination : 0,
        emission_factor_ch4: emission_factor_ch4 ? emission_factor_ch4 : 0,
        emission_factor_n2o: emission_factor_n2o ? emission_factor_n2o : 0,
        amount_recovered_ch4: amount_recovered_ch4 ? amount_recovered_ch4 : 0
      }

      const result = await scope3Service.saveAnaerobicCompost(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('AnaerobicCompost', JSON.stringify(scope));

        reset()
        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  const { isCommonUser } = useAuth()

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getAnaerobicCompost(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'residuos_solidos_gerados',
      'compostagem_anaerobica'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setValue('biogas_destination', entity.biogas_destination)
      setValue('compost_mass', formatPrice3(entity.compost_mass, false))
      setValue('emission_factor_ch4', formatPrice3(entity.emission_factor_ch4, false))
      setValue('emission_factor_n2o', formatPrice3(entity.emission_factor_n2o, false))
      setValue('amount_recovered_ch4', formatPrice3(entity.amount_recovered_ch4, false))

      setEmissionCh4(entity.emission_ch4)
      setEmissionN2o(entity.emission_n2o)
      setEmissionTco2(entity.emission_tco2e)
      setEmissionCo2Bio(entity.emission_co2_bio)

      setChangeScope('load_scope_' + entity.id)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setChangeScope('load_scope_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const handleChangeValue = async () => {
    let compost_mass: string | number = getValues('compost_mass');
    let biogas_destination: string | number = getValues('biogas_destination');
    let emission_factor_ch4: string | number = getValues('emission_factor_ch4');
    let emission_factor_n2o: string | number = getValues('emission_factor_n2o');
    let amount_recovered_ch4: string | number = getValues('amount_recovered_ch4');
  
    if (typeof compost_mass === "string") {
      compost_mass = compost_mass.replace(/\./g, '');
      const dynamicString = 'change_scope_item_' + compost_mass;
    }  
  
    if (typeof emission_factor_ch4 === "string") {
      emission_factor_ch4 = emission_factor_ch4.replace('.', '');
    }
  
    if (typeof emission_factor_n2o === "string") {
      emission_factor_n2o = emission_factor_n2o.replace('.', '');
    }
  
    if (typeof amount_recovered_ch4 === "string") {
      amount_recovered_ch4 = amount_recovered_ch4.replace('.', '');
    }
  
    biogas_destination = biogas_destination ? parseInt(biogas_destination.toString()) : 1;
    compost_mass = compost_mass ? parseFloat(compost_mass.toString().replace(',', '.')) : 0;
    emission_factor_ch4 = emission_factor_ch4 ? parseFloat(emission_factor_ch4.toString().replace(',', '.')) : 0;
    emission_factor_n2o = emission_factor_n2o ? parseFloat(emission_factor_n2o.toString().replace(',', '.')) : 0;
    amount_recovered_ch4 = amount_recovered_ch4 ? parseFloat(amount_recovered_ch4.toString().replace(',', '.')) : 0;
  
    const result = await scope3Service.calculateAnaerobicCompost(
      compost_mass,
      emission_factor_ch4,
      emission_factor_n2o,
      amount_recovered_ch4,
      biogas_destination
    );
  
    if (result) {
      setEmissionCh4(result.emission_ch4);
      setEmissionN2o(result.emission_n2o);
      setEmissionTco2(result.emission_tco2e);
      setEmissionCo2Bio(result.emission_co2_bio);
  
      setChangeScope(
        'change_scope_item_' +
        result.emission_ch4 +
        '_' +
        result.emission_n2o +
        '_' +
        result.emission_co2e +
        '_' +
        result.emission_co2_bio
      );
    }
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
      value = '0';
    }

    return value;
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'compostagem_anaerobica'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'residuos_solidos_gerados',
        file: base64,
        filename: filename,
        second_name: 'compostagem_anaerobica'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'residuos_solidos_gerados',
          'compostagem_anaerobica'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => {
        const base64 = reader.result

        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year + ' - ' + inventorySelected?.company?.corporate_name} />
                <TitleText level={4} title={'Escopo 03 - ' + title} />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}

            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div className='div-model-13' />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div className='div-model-1'>
          {ignoreFill === false && (
            <div className='div-model-2'>
              <div className='div-model-3'>
                <span className='span-model-1'>
                  Quantidade de resíduo destinado a compostagem
                  <div className="tooltip">
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">
                      O cálculo das emissões por compostagem é baseado na massa úmida de resíduo.
                    </span>
                  </div>
                </span>
              </div>

              <div className='div-model-4'>
                <div className='div-model-3'>
                  <span className='span-model-2'>
                    Caso não haja compostagem, deixe esta seção em branco.
                  </span>
                  <span className='span-model-2'>
                    Massa de resíduo* destinado à compostagem [t/ano]
                  </span>
                </div>
                <div className='.div-model-6'>
                  <input className='input-model'
                    readOnly={isCommonUser}
                    {...register('compost_mass')}
                    onChange={(event) => {
                      setValue('compost_mass', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div className='div-model-3'>
                <span className='span-model-1'>
                  Outros elementos
                  <div className="tooltip">
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">
                      Se possuir um fator de emissão de CH₄ e N₂O específico para o resíduo e/ou a região que se localiza o centro de compostagem, preencha os campos dos fatores de emissão abaixo.
                      Caso não os possua, deixe os campos em branco, será utilizado o default sugerido pelo IPCC (2006).
                    </span>
                  </div>
                </span>
              </div>

              <div className='div-model-4'>
                <div className='div-model-5'>
                  <span className='span-model-2'>
                    Fator de emissão de CH₄ [gCH₄/kg resíduo]
                  </span>
                </div>
                <div className='div-model-6'>
                  <input className='input-model'
                    readOnly={isCommonUser}
                    {...register('emission_factor_ch4')}
                    onChange={(event) => {
                      setValue('emission_factor_ch4', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div className='div-model-4'>
                <div className='div-model-5'>
                  <span className='span-model-2'>
                    Fator de emissão de N₂O [gN₂O/kg resíduo]
                  </span>
                </div>
                <div className='div-model-6'>
                  <input className='input-model'
                    readOnly={isCommonUser}
                    {...register('emission_factor_n2o')}
                    onChange={(event) => {
                      setValue('emission_factor_n2o', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div className='div-model-5'>
                <span className='span-model-1'>
                  Recuperação de CH₄
                  <div className="tooltip">
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">
                      Se houver, preencha a quantidade de CH₄ recuperada** do tratamento por compostagem, para o ano do inventário.
                      **CH₄ Recuperado - Metano gerado que é recuperado, ou seja não é emitido, e queimado em um queimador ("flare") ou utilizado para geração de energia (eletricidade, calor, etc).
                    </span>
                  </div>
                </span>
              </div>

              <div className='div-model-4'>
                <div className='div-model-5'>
                  <span className='span-model-2'>
                    Quantidade de CH₄ recuperada do tratamento por compostagem [tCH₄/ano]
                  </span>

                </div>
                <div className='div-model-6'>
                  <input className='input-model'
                    readOnly={isCommonUser}
                    {...register('amount_recovered_ch4')}
                    onChange={(event) => {
                      setValue('amount_recovered_ch4', currency(event))
                      handleChangeValue()
                    }}
                  />
                </div>
              </div>

              <div className='div-model-13' />

              <div className='div-model-7'>
                <span className='span-model-1'>
                  Emissões totais de resíduos tratados por compostagem no ano inventariado
                </span>
              </div>

              {changeScope && (
                <div className='div-model-8' >
                  <div className='div-model-9'>
                    <span className='span-model-3'>
                      Emissões de CH₄ por compostagem [tCH₄/ano]:
                    </span>
                    <span className='span-model-4'>
                      {formatPrice3(emissionCh4, false)}
                    </span>


                    {emissionCh4 && emissionCh4 < 0 ? (
                      <div className='div-model-10'>
                        O dado de CH₄ recuperado é maior que o de CH₄ gerado. Insira um dado válido
                      </div>
                    ) : null}
                  </div>





                  {changeScope && (
                    <div className='div-model-11'>

                      <span className='span-model-3'>
                        Emissões de N₂O por compostagem [tN₂O/ano]:
                      </span>
                      <span className='span-model-4'>
                        {formatPrice3(emissionN2o, false)}
                      </span>
                    </div>
                  )}


                  {changeScope && (
                    <div className='div-model-11'>
                      <span className='span-model-3'>
                        Emissões em tCO₂e por compostagem [tCO₂e/ano]:
                      </span>
                      <span className='span-model-4'>
                        {formatPrice3(emissionTco2, false)}
                      </span>
                    </div>
                  )}

                  {changeScope && (
                    <div className='div-model-11'>
                      <span className='span-model-3'>
                        Emissões de CO₂ biogênico [tCO₂/ano]:
                      </span>
                      <span className='span-model-4'>
                        {formatPrice3(emissionCo2Bio, false)}
                      </span>
                    </div>
                  )}
                </div>
              )}

            </div>
          )}
          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
