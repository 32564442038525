import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Parser from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useLocation, useHistory } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice3, formatPrice5, toNumberValues } from '../../../../../utils/transform'
import { Button } from '../../../../../components/Button/styles'
import { TitleText } from '../../../../../components/TitleText'
import { GoBack } from '../../../../../components/GoBack'
import { Modal } from '../../../../../components/Modal'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import inventoryService from '../../../../../services/inventoryService'
import scope1Service from '../../../../../services/scope1Service'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'

export const MobileWaterTransport = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [openModal, setOpenModal] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [fuels, setFuels] = useState<any>([])
  const [scopes, setWaterFuel] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [emissionCo2WaterFuel, setEmissionCo2WaterFuel] = useState<any>()
  const [emissionCh4WaterFuel, setEmissionCh4WaterFuel] = useState<any>()
  const [emissionN2oWaterFuel, setEmissionN2oWaterFuel] = useState<any>()
  const [scopeWaterFuel, setScopeWaterFuel] = useState<string | undefined>()
  const [emissionTco2eWaterFuel, setEmissionTco2eWaterFuel] = useState<any>()
  const [emissionTco2eBioWaterFuel, setEmissionTco2eBioWaterFuel] = useState<any>()

  const { isCommonUser } = useAuth()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-1/combustao-movel'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'combustao_movel', 'transporte_hidroviario')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const handleChangeFuel = (index: number, ignore_field?: string) => {
    const vehicle_fuel_id = scopes[index].vehicle_fuel_id
    const consumptionJan = scopes[index].consumption_jan
      ? parseFloat(scopes[index].consumption_jan)
      : 0
    const consumptionFeb = scopes[index].consumption_feb
      ? parseFloat(scopes[index].consumption_feb)
      : 0
    const consumptionMar = scopes[index].consumption_mar
      ? parseFloat(scopes[index].consumption_mar)
      : 0
    const consumptionApr = scopes[index].consumption_apr
      ? parseFloat(scopes[index].consumption_apr)
      : 0
    const consumptionMay = scopes[index].consumption_may
      ? parseFloat(scopes[index].consumption_may)
      : 0
    const consumptionJun = scopes[index].consumption_jun
      ? parseFloat(scopes[index].consumption_jun)
      : 0
    const consumptionJul = scopes[index].consumption_jul
      ? parseFloat(scopes[index].consumption_jul)
      : 0
    const consumptionAug = scopes[index].consumption_aug
      ? parseFloat(scopes[index].consumption_aug)
      : 0
    const consumptionSep = scopes[index].consumption_sep
      ? parseFloat(scopes[index].consumption_sep)
      : 0
    const consumptionOct = scopes[index].consumption_oct
      ? parseFloat(scopes[index].consumption_oct)
      : 0
    const consumptionNov = scopes[index].consumption_nov
      ? parseFloat(scopes[index].consumption_nov)
      : 0
    const consumptionDec = scopes[index].consumption_dec
      ? parseFloat(scopes[index].consumption_dec)
      : 0

    const onlyViewYearly =
      consumptionJan > 0 ||
      consumptionFeb > 0 ||
      consumptionMar > 0 ||
      consumptionApr > 0 ||
      consumptionMay > 0 ||
      consumptionJun > 0 ||
      consumptionJul > 0 ||
      consumptionAug > 0 ||
      consumptionSep > 0 ||
      consumptionOct > 0 ||
      consumptionNov > 0 ||
      consumptionDec > 0

    const consumptionYearly = onlyViewYearly
      ? 0
      : scopes[index].consumption_yearly
      ? parseFloat(scopes[index].consumption_yearly)
      : 0

    async function calculate() {
      const result = await scope1Service.calculateMobileCombustionWaterFuel(
        vehicle_fuel_id,
        consumptionJan,
        consumptionFeb,
        consumptionMar,
        consumptionApr,
        consumptionMay,
        consumptionJun,
        consumptionJul,
        consumptionAug,
        consumptionSep,
        consumptionOct,
        consumptionNov,
        consumptionDec,
        consumptionYearly
      )

      if (result) {
        scopes[index].only_view_yearly = onlyViewYearly

        scopes[index].emission_co2 = parseFloat(result.emission_co2)
        scopes[index].emission_ch4 = parseFloat(result.emission_ch4)
        scopes[index].emission_n2o = parseFloat(result.emission_n2o)
        scopes[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        scopes[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        scopes[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        scopes[index].emission_tco2e = parseFloat(result.emission_tco2e)
        scopes[index].consumption_fossil_jan = parseFloat(result.consumption_fossil_jan)
        scopes[index].consumption_fossil_feb = parseFloat(result.consumption_fossil_feb)
        scopes[index].consumption_fossil_mar = parseFloat(result.consumption_fossil_mar)
        scopes[index].consumption_fossil_apr = parseFloat(result.consumption_fossil_apr)
        scopes[index].consumption_fossil_may = parseFloat(result.consumption_fossil_may)
        scopes[index].consumption_fossil_jun = parseFloat(result.consumption_fossil_jun)
        scopes[index].consumption_fossil_jul = parseFloat(result.consumption_fossil_jul)
        scopes[index].consumption_fossil_aug = parseFloat(result.consumption_fossil_aug)
        scopes[index].consumption_fossil_sep = parseFloat(result.consumption_fossil_sep)
        scopes[index].consumption_fossil_oct = parseFloat(result.consumption_fossil_oct)
        scopes[index].consumption_fossil_nov = parseFloat(result.consumption_fossil_nov)
        scopes[index].consumption_fossil_dec = parseFloat(result.consumption_fossil_dec)
        scopes[index].factor_co2_kg_bio = parseFloat(result.factor_co2_kg_bio)
        scopes[index].factor_ch4_kg_bio = parseFloat(result.factor_ch4_kg_bio)
        scopes[index].factor_n2o_kg_bio = parseFloat(result.factor_n2o_kg_bio)
        scopes[index].emission_tco2e_bio = parseFloat(result.emission_tco2e_bio)
        scopes[index].consumption_fossil_yearly = parseFloat(
          result.consumption_fossil_yearly
        )
        scopes[index].consumption_bio_jan = parseFloat(result.consumption_bio_jan)
        scopes[index].consumption_bio_feb = parseFloat(result.consumption_bio_feb)
        scopes[index].consumption_bio_mar = parseFloat(result.consumption_bio_mar)
        scopes[index].consumption_bio_apr = parseFloat(result.consumption_bio_apr)
        scopes[index].consumption_bio_may = parseFloat(result.consumption_bio_may)
        scopes[index].consumption_bio_jun = parseFloat(result.consumption_bio_jun)
        scopes[index].consumption_bio_jul = parseFloat(result.consumption_bio_jul)
        scopes[index].consumption_bio_aug = parseFloat(result.consumption_bio_aug)
        scopes[index].consumption_bio_sep = parseFloat(result.consumption_bio_sep)
        scopes[index].consumption_bio_oct = parseFloat(result.consumption_bio_oct)
        scopes[index].consumption_bio_nov = parseFloat(result.consumption_bio_nov)
        scopes[index].consumption_bio_dec = parseFloat(result.consumption_bio_dec)
        scopes[index].consumption_bio_yearly = parseFloat(result.consumption_bio_yearly)

        setScopeWaterFuel(
          'change_scope_item_' +
            index +
            '_' +
            vehicle_fuel_id +
            '_' +
            consumptionJan +
            '_' +
            consumptionFeb +
            '_' +
            consumptionMar +
            '_' +
            consumptionApr +
            '_' +
            consumptionMay +
            consumptionJun +
            '_' +
            consumptionJul +
            '_' +
            consumptionAug +
            '_' +
            consumptionSep +
            consumptionOct +
            '_' +
            consumptionNov +
            '_' +
            consumptionDec +
            '_' +
            consumptionYearly
        )

        setWaterFuelValues(scopes, ignore_field)
        calculateWaterFuel(scopes)
      }
    }

    calculate()
  }

  function handleDeleteFuel(index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index) {
        itens.push(scopes[i])
      }
    }

    setScopeWaterFuel('remove_scope_item_' + index)

    setValue('fuel_private_vehicle', [])

    setWaterFuelValues(itens)
    calculateWaterFuel(itens)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const setWaterFuelValues = (itens: any, ignore_field?: string) => {
    let scopeWater = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scopes.${i}.year`, item.year)
        setValue(`scopes.${i}.source`, item.source)
        setValue(`scopes.${i}.description`, item.description)
        setValue(`scopes.${i}.average_consumption_fuel`, item.average_consumption_fuel)

        setValue(`scopes.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
        setValue(`scopes.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

        setValue(`scopes.${i}.fuel_id`, item.fuel_id)
        setValue(`scopes.${i}.fuel_name`, item.fuel_name)

        setValue(`scopes.${i}.bio_fuel_id`, item.bio_fuel_id)
        setValue(`scopes.${i}.bio_fuel_name`, item.bio_fuel_name)

        if ('emission_co2' !== ignore_field) {
          setValue(`scopes.${i}.emission_co2`, formatPrice5(item.emission_co2, false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`scopes.${i}.emission_ch4`, formatPrice5(item.emission_ch4, false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`scopes.${i}.emission_n2o`, formatPrice5(item.emission_n2o, false))
        }

        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_ch4_kg`, formatPrice5(item.factor_ch4_kg, false))
        }

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_co2_kg`, formatPrice5(item.factor_co2_kg, false))
        }

        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`scopes.${i}.factor_n2o_kg`, formatPrice5(item.factor_n2o_kg, false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e`, formatPrice5(item.emission_tco2e, false))
        }

        if ('consumption_jan' !== ignore_field) {
          setValue(`scopes.${i}.consumption_jan`, formatPrice5(item.consumption_jan, false))
        }

        if ('consumption_feb' !== ignore_field) {
          setValue(`scopes.${i}.consumption_feb`, formatPrice5(item.consumption_feb, false))
        }

        if ('consumption_mar' !== ignore_field) {
          setValue(`scopes.${i}.consumption_mar`, formatPrice5(item.consumption_mar, false))
        }

        if ('consumption_apr' !== ignore_field) {
          setValue(`scopes.${i}.consumption_apr`, formatPrice5(item.consumption_apr, false))
        }

        if ('consumption_may' !== ignore_field) {
          setValue(`scopes.${i}.consumption_may`, formatPrice5(item.consumption_may, false))
        }

        if ('consumption_jun' !== ignore_field) {
          setValue(`scopes.${i}.consumption_jun`, formatPrice5(item.consumption_jun, false))
        }

        if ('consumption_jul' !== ignore_field) {
          setValue(`scopes.${i}.consumption_jul`, formatPrice5(item.consumption_jul, false))
        }

        if ('consumption_aug' !== ignore_field) {
          setValue(`scopes.${i}.consumption_aug`, formatPrice5(item.consumption_aug, false))
        }

        if ('consumption_sep' !== ignore_field) {
          setValue(`scopes.${i}.consumption_sep`, formatPrice5(item.consumption_sep, false))
        }

        if ('consumption_oct' !== ignore_field) {
          setValue(`scopes.${i}.consumption_oct`, formatPrice5(item.consumption_oct, false))
        }

        if ('consumption_nov' !== ignore_field) {
          setValue(`scopes.${i}.consumption_nov`, formatPrice5(item.consumption_nov, false))
        }

        if ('consumption_dec' !== ignore_field) {
          setValue(`scopes.${i}.consumption_dec`, formatPrice5(item.consumption_dec, false))
        }

        if ('consumption_fossil_jan' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_jan`, formatPrice5(item.consumption_fossil_jan, false))
        }

        if ('consumption_fossil_feb' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_feb`, formatPrice5(item.consumption_fossil_feb, false))
        }

        if ('consumption_fossil_mar' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_mar`, formatPrice5(item.consumption_fossil_mar, false))
        }

        if ('consumption_fossil_apr' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_apr`, formatPrice5(item.consumption_fossil_apr, false))
        }

        if ('consumption_fossil_may' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_may`, formatPrice5(item.consumption_fossil_may, false))
        }

        if ('consumption_fossil_jun' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_jun`, formatPrice5(item.consumption_fossil_jun, false))
        }

        if ('consumption_fossil_jul' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_jul`, formatPrice5(item.consumption_fossil_jul, false))
        }

        if ('consumption_fossil_aug' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_aug`, formatPrice5(item.consumption_fossil_aug, false))
        }

        if ('consumption_fossil_sep' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_sep`, formatPrice5(item.consumption_fossil_sep, false))
        }

        if ('consumption_fossil_oct' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_oct`, formatPrice5(item.consumption_fossil_oct, false))
        }

        if ('consumption_fossil_nov' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_nov`, formatPrice5(item.consumption_fossil_nov, false))
        }

        if ('consumption_fossil_dec' !== ignore_field) {
          setValue(`scopes.${i}.consumption_fossil_dec`, formatPrice5(item.consumption_fossil_dec, false))
        }

        if ('factor_co2_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_co2_kg_bio`, formatPrice5(item.factor_co2_kg_bio, false))
        }

        if ('factor_ch4_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_ch4_kg_bio`, formatPrice5(item.factor_ch4_kg_bio, false))
        }

        if ('factor_n2o_kg_bio' !== ignore_field) {
          setValue(`scopes.${i}.factor_n2o_kg_bio`, formatPrice5(item.factor_n2o_kg_bio, false))
        }

        if ('emission_tco2e_bio' !== ignore_field) {
          setValue(`scopes.${i}.emission_tco2e_bio`, formatPrice5(item.emission_tco2e_bio, false))
        }

        if ('consumption_fossil_yearly' !== ignore_field) {
          setValue(
            `scopes.${i}.consumption_fossil_yearly`,
            formatPrice5(item.consumption_fossil_yearly, false)
          )
        }

        if ('consumption_bio_jan' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_jan`, formatPrice5(item.consumption_bio_jan, false))
        }

        if ('consumption_bio_feb' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_feb`, formatPrice5(item.consumption_bio_feb, false))
        }

        if ('consumption_bio_mar' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_mar`, formatPrice5(item.consumption_bio_mar, false))
        }

        if ('consumption_bio_apr' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_apr`, formatPrice5(item.consumption_bio_apr, false))
        }

        if ('consumption_bio_may' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_may`, formatPrice5(item.consumption_bio_may, false))
        }

        if ('consumption_bio_jun' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_jun`, formatPrice5(item.consumption_bio_jun, false))
        }

        if ('consumption_bio_jul' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_jul`, formatPrice5(item.consumption_bio_jul, false))
        }

        if ('consumption_bio_aug' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_aug`, formatPrice5(item.consumption_bio_aug, false))
        }

        if ('consumption_bio_sep' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_sep`, formatPrice5(item.consumption_bio_sep, false))
        }

        if ('consumption_bio_oct' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_oct`, formatPrice5(item.consumption_bio_oct, false))
        }

        if ('consumption_bio_nov' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_nov`, formatPrice5(item.consumption_bio_nov, false))
        }

        if ('consumption_bio_dec' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_dec`, formatPrice5(item.consumption_bio_dec, false))
        }

        if ('consumption_bio_yearly' !== ignore_field) {
          setValue(`scopes.${i}.consumption_bio_yearly`, formatPrice5(item.consumption_bio_yearly, false))
        }

        scopeWater =
          scopeWater +
          '_' +
          item.car_fleet_id +
          '_' +
          item.consumption_jan +
          '_' +
          item.consumption_feb +
          '_' +
          item.consumption_mar +
          '_' +
          item.consumption_apr +
          '_' +
          item.consumption_may +
          '_' +
          item.consumption_jun +
          '_' +
          item.consumption_jul +
          '_' +
          item.consumption_aug +
          '_' +
          item.consumption_sep +
          '_' +
          item.consumption_oct +
          '_' +
          item.consumption_nov +
          '_' +
          item.consumption_dec +
          '_' +
          item.consumption_yearly
      }
    }

    setWaterFuel(itens)
    setScopeWaterFuel(scopeWater)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d)(\d{5})$/, '$1,$2')
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')
    return value
  }

  const handleChangeCarFleetFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false
        }

        const index = scopes.length

        const itens = scopes.concat(item)

        setScopeWaterFuel('new_scope_item_' + index)

        setWaterFuelValues(itens)
        calculateWaterFuel(itens)
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuel_private_vehicle', [])
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const fuels = await vehicleFuelService.getByParams({
      limit: 9000,
      page: 1,
      type: 'water'
    })

    setFuels(
      fuels?.itens.map((fuel: any) => {
        return {
          label: fuel.name,
          value: fuel.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getMobileCombustionWater(company_id, year)

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      1,
      'combustao_movel',
      'transporte_hidroviario'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      for (const item of entity?.fuels) {
        item.only_view_yearly =
          item.consumption_jan > 0 ||
          item.consumption_feb > 0 ||
          item.consumption_mar > 0 ||
          item.consumption_apr > 0 ||
          item.consumption_may > 0 ||
          item.consumption_jun > 0 ||
          item.consumption_jul > 0 ||
          item.consumption_aug > 0 ||
          item.consumption_sep > 0 ||
          item.consumption_oct > 0 ||
          item.consumption_nov > 0 ||
          item.consumption_dec > 0
      }

      setWaterFuelValues(entity?.fuels)
      calculateWaterFuel(entity?.fuels)

      setScopeWaterFuel('load_scope_' + entity.id + '_scopes_' + entity?.fuels.length)
    } else {
      setScopeSelected({})

      setWaterFuelValues([])
      calculateWaterFuel([])

      setIgnoreFill(false)

      setScopeWaterFuel('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const calculateWaterFuel = (itens: any) => {
    let emissionCo2Water = 0
    let emissionCh4Water = 0
    let emissionN2oWater = 0
    let emissionTco2eWater = 0
    let emissionTco2eBioWater = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Water = emissionCo2Water + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Water = emissionCh4Water + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oWater = emissionN2oWater + item.emission_co2
        }
        if (item.emission_tco2e) {
          emissionTco2eWater = emissionTco2eWater + item.emission_tco2e
        }
        if (item.emission_tco2e_bio) {
          emissionTco2eBioWater = emissionTco2eBioWater + item.emission_tco2e_bio
        }
      }
    }

    setEmissionCo2WaterFuel(emissionCo2Water)
    setEmissionCh4WaterFuel(emissionCh4Water)
    setEmissionN2oWaterFuel(emissionN2oWater)
    setEmissionTco2eWaterFuel(emissionTco2eWater)
    setEmissionTco2eBioWaterFuel(emissionTco2eBioWater)

    setScopeWaterFuel(
      'calculate_iten_' +
        emissionCo2Water +
        '_' +
        emissionCh4Water +
        '_' +
        emissionN2oWater +
        '_' +
        emissionTco2eWater +
        '_' +
        emissionTco2eBioWater
    )
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const scopesItens: any[] = scopes.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          vehicle_fuel_id: item.vehicle_fuel_id,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e) : 0,
          consumption_jan: item.consumption_jan ? parseFloat(item.consumption_jan) : 0,
          consumption_feb: item.consumption_feb ? parseFloat(item.consumption_feb) : 0,
          consumption_mar: item.consumption_mar ? parseFloat(item.consumption_mar) : 0,
          consumption_apr: item.consumption_apr ? parseFloat(item.consumption_apr) : 0,
          consumption_may: item.consumption_may ? parseFloat(item.consumption_may) : 0,
          consumption_jun: item.consumption_jun ? parseFloat(item.consumption_jun) : 0,
          consumption_jul: item.consumption_jul ? parseFloat(item.consumption_jul) : 0,
          consumption_aug: item.consumption_aug ? parseFloat(item.consumption_aug) : 0,
          consumption_sep: item.consumption_sep ? parseFloat(item.consumption_sep) : 0,
          consumption_oct: item.consumption_oct ? parseFloat(item.consumption_oct) : 0,
          consumption_nov: item.consumption_nov ? parseFloat(item.consumption_nov) : 0,
          consumption_dec: item.consumption_dec ? parseFloat(item.consumption_dec) : 0,
          consumption_fossil_jan: item.consumption_fossil_jan
            ? parseFloat(item.consumption_fossil_jan)
            : 0,
          consumption_fossil_feb: item.consumption_fossil_feb
            ? parseFloat(item.consumption_fossil_feb)
            : 0,
          consumption_fossil_mar: item.consumption_fossil_mar
            ? parseFloat(item.consumption_fossil_mar)
            : 0,
          consumption_fossil_apr: item.consumption_fossil_apr
            ? parseFloat(item.consumption_fossil_apr)
            : 0,
          consumption_fossil_may: item.consumption_fossil_may
            ? parseFloat(item.consumption_fossil_may)
            : 0,
          consumption_fossil_jun: item.consumption_fossil_jun
            ? parseFloat(item.consumption_fossil_jun)
            : 0,
          consumption_fossil_jul: item.consumption_fossil_jul
            ? parseFloat(item.consumption_fossil_jul)
            : 0,
          consumption_fossil_aug: item.consumption_fossil_aug
            ? parseFloat(item.consumption_fossil_aug)
            : 0,
          consumption_fossil_sep: item.consumption_fossil_sep
            ? parseFloat(item.consumption_fossil_sep)
            : 0,
          consumption_fossil_oct: item.consumption_fossil_oct
            ? parseFloat(item.consumption_fossil_oct)
            : 0,
          consumption_fossil_nov: item.consumption_fossil_nov
            ? parseFloat(item.consumption_fossil_nov)
            : 0,
          consumption_fossil_dec: item.consumption_fossil_dec
            ? parseFloat(item.consumption_fossil_dec)
            : 0,
          factor_ch4_kg_bio: item.factor_ch4_kg_bio
            ? parseFloat(item.factor_ch4_kg_bio)
            : 0,
          factor_co2_kg_bio: item.factor_co2_kg_bio
            ? parseFloat(item.factor_co2_kg_bio)
            : 0,
          factor_n2o_kg_bio: item.factor_n2o_kg_bio
            ? parseFloat(item.factor_n2o_kg_bio)
            : 0,
          emission_tco2e_bio: item.emission_tco2e_bio
            ? parseFloat(item.emission_tco2e_bio)
            : 0,
          consumption_fossil_yearly: item.consumption_fossil_yearly
            ? parseFloat(item.consumption_fossil_yearly)
            : 0,
          consumption_yearly: item.consumption_yearly
            ? parseFloat(item.consumption_yearly)
            : 0,
          consumption_bio_jan: item.consumption_bio_jan
            ? parseFloat(item.consumption_bio_jan)
            : 0,
          consumption_bio_feb: item.consumption_bio_feb
            ? parseFloat(item.consumption_bio_feb)
            : 0,
          consumption_bio_mar: item.consumption_bio_mar
            ? parseFloat(item.consumption_bio_mar)
            : 0,
          consumption_bio_apr: item.consumption_bio_apr
            ? parseFloat(item.consumption_bio_apr)
            : 0,
          consumption_bio_may: item.consumption_bio_may
            ? parseFloat(item.consumption_bio_may)
            : 0,
          consumption_bio_jun: item.consumption_bio_jun
            ? parseFloat(item.consumption_bio_jun)
            : 0,
          consumption_bio_jul: item.consumption_bio_jul
            ? parseFloat(item.consumption_bio_jul)
            : 0,
          consumption_bio_aug: item.consumption_bio_aug
            ? parseFloat(item.consumption_bio_aug)
            : 0,
          consumption_bio_sep: item.consumption_bio_sep
            ? parseFloat(item.consumption_bio_sep)
            : 0,
          consumption_bio_oct: item.consumption_bio_oct
            ? parseFloat(item.consumption_bio_oct)
            : 0,
          consumption_bio_nov: item.consumption_bio_nov
            ? parseFloat(item.consumption_bio_nov)
            : 0,
          consumption_bio_dec: item.consumption_bio_dec
            ? parseFloat(item.consumption_bio_dec)
            : 0,
          consumption_bio_yearly: item.consumption_bio_yearly
            ? parseFloat(item.consumption_bio_yearly)
            : 0
        }
      })

      const scope = {
        status: 2,
        fuels: scopesItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }

      const result = await scope1Service.saveMobileCombustionWater(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )

      if (result && !result.error) {
        localStorage.setItem('MobileCombustionWater', JSON.stringify(scope));
        
        setErrorApi(undefined)
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_movel',
          'transporte_hidroviario'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const handleDownloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const changeAttachmentHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 1,
        name: 'combustao_movel',
        file: base64,
        filename: filename,
        second_name: 'transporte_hidroviario'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          1,
          'combustao_movel',
          'transporte_hidroviario'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} className="column">
        <div
          className="row"
          style={{
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div className="column">
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 01 - ' + title}  />
              </div>
            )}
          </div>
          <div className="row">
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />
        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill && (
          <div className="column" style={{ margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {!ignoreFill && (
            <div
              className="column"
              style={{
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div
                className="row"
                style={{
                  marginTop: 0,
                  justifyContent: 'space-between',
                  marginBottom: 10
                }}
              >
                <div className="column" style={{ marginRight: 30 }}>
                  <span
                    style={{
                      textAlign: 'start',
                      fontSize: 14,
                      marginTop: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    Cálculo de emissões de gases de efeito estufa por transporte de carga
                    por consumo de combustível no ano
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione, o combustível ao lado, para adicionar nova linha
                  </span>
                </div>

                {!isCommonUser && fuels && (
                  <Controller
                    control={control}
                    name="fuel_private_vehicle"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                        {...field}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: '350px',
                            height: 40,
                            marginTop: 15,
                            marginRight: 10,
                            backgroundColor: 'rgb(246, 246, 246)'
                          }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('fuel_private_vehicle')}
                        options={fuels}
                        onChange={handleChangeCarFleetFuel}
                      />
                    )}
                  />
                )}
              </div>

              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeWaterFuel && scopes && scopes.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={2}>
                          {'Frota'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de combustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={12}>
                          {'Consumo mensal de combustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo anual'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do combustível fóssil'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do biocombustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ fóssil (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂o (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ biogênico (t CO₂)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 50 }} rowSpan={2} />
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 150 }}>
                          {'Registro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 150 }}>
                          {'Deescrição'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Janeiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Fevereiro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Março'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Abril'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Maio'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Junho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Julho'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Agosto'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Setembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Outubro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Novembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'Dezembro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scopes.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`scopes.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`scopes.${index}.source`, event.target.value)
                                    scopes[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 150 }}
                                  className="input-item"
                                  {...register(`scopes.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `scopes.${index}.description`,
                                      event.target.value
                                    )
                                    scopes[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div className="row">
                                  <div className="column">
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {scopes[index].vehicle_fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Combustível fóssil: '}</b>
                                      {scopes[index].fuel_name}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 300,
                                        fontSize: 12,
                                        display: 'inline-block'
                                      }}
                                    >
                                      <b>{'Biocombustível: '}</b>
                                      {scopes[index].bio_fuel_name}
                                    </span>
                                  </div>

                                  {scopes[index].vehicle_fuel_description && (
                                    <div className="tooltip">
                                      <img alt="infoButton" src={InfoButtonMin} />
                                      <span className="tooltiptext">
                                        {scopes[index].car_fleet_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_jan`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_jan = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_jan`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_jan')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_feb`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_feb = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_feb`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_feb')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_mar`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_mar = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_mar`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_mar')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_apr`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_apr = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_apr`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_apr')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_may`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_may = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_may`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_may')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_jun`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_jun = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_jun`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_jun')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_jul`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_jul = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_jul`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_jul')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_aug`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_aug = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_aug`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_aug')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_sep`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_sep = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_sep`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_sep')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_oct`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_oct = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_oct`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_oct')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_nov`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_nov = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_nov`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_nov')
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'right' }}
                                  className="input-item"
                                  {...register(`scopes.${index}.consumption_dec`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = toNumberValues(value)

                                    event.target.value = numberValue

                                    scopes[index].consumption_dec = numberValue

                                    setValue(
                                      `scopes.${index}.consumption_dec`,
                                      numberValue
                                    )

                                    handleChangeFuel(index, 'consumption_dec')
                                  }}
                                />
                              </td>
                              <td>
                                {!scopes[index].only_view_yearly && (
                                  <input
                                    style={{ width: 100, textAlign: 'center' }}
                                    className="input-item"
                                    {...register(`scopes.${index}.consumption_yearly`)}
                                    onChange={(event) => {
                                      const formatValue = currency(event)
                                      const valueStr = formatValue.replace('.', '')
                                      const value = valueStr
                                        ? parseFloat(valueStr.replace(',', '.'))
                                        : 0

                                      scopes[index].consumption_yearly = value

                                      setValue(
                                        `scopes.${index}.consumption_yearly`,
                                        formatValue
                                      )

                                      handleChangeFuel(index, 'consumption_yearly')
                                    }}
                                  />
                                )}
                                {scopes[index].only_view_yearly && (
                                  <span
                                    style={{
                                      marginRight: 5,
                                      width: 120,
                                      fontSize: 12,
                                      display: 'inline-block',
                                      textAlign: 'right'
                                    }}
                                  >
                                    {formatPrice5(
                                      scopes[index].consumption_yearly,
                                      false
                                    )}
                                  </span>
                                )}
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_co2_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_ch4_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].factor_n2o_kg_bio, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_tco2e, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} rowSpan={3}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice5(scopes[index].emission_tco2e_bio, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} rowSpan={3}>
                                  <div
                                    onClick={() => handleDeleteFuel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img alt="deleteIcon" src={TrashIcon} />
                                  </div>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de combustível fóssil (litros, m³ ou kg)'}
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_jan,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_feb,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_mar,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_apr,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_may,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_jun,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_jul,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_aug,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_sep,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_oct,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_nov,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_dec,
                                    false
                                  )}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_fossil_yearly,
                                    false
                                  )}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="subhead-item"
                                style={{ paddingRight: 5, textAlign: 'right' }}
                              >
                                {'Quantidade de biocombustível (litros)'}
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_jan, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_feb, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_mar, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_apr, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_may, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_jun, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_jul, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_aug, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_sep, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_oct, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_nov, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(scopes[index].consumption_bio_dec, false)}
                                </span>
                              </td>
                              <td style={{ width: 120 }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    display: 'inline-block',
                                    textAlign: 'right'
                                  }}
                                >
                                  {formatPrice5(
                                    scopes[index].consumption_bio_yearly,
                                    false
                                  )}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={22}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCo2WaterFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionCh4WaterFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionN2oWaterFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eWaterFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice5(emissionTco2eBioWaterFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item" />
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!scopes.length && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum combustível cadastrado`
                        : `Nenhum combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  className="row"
                  style={{
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.scopes && (
                    <div
                      className="row"
                      style={{
                        justifyContent: 'left',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Registro da Frota e Descrição da Frota são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ equivalente (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eWaterFuel, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                          width: "calc(100vw - 330px)", maxWidth: "calc(100vw - 330px)",
                          borderColor: "#9E9E9E", height: "40px", display: "flex", 
                          flexDirection: "row", marginLeft: 15}}>
              <span style={{ margin: 10, width: "70%" }}>
                Emissões totais em CO₂ biogênico (toneladas métricas):
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(emissionTco2eBioWaterFuel, false)}
              </span>
            </div>
          )}

          {!ignoreFill && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={handleDownloadFile}
                  onChangeAttachment={changeAttachmentHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div className="column">
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
